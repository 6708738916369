/* ==========================================================================
   icon font
   ========================================================================== */


.icon-navicon:before {
    content: "";
}

.icon-close-big:before {
    content: "";
}

.icon-shopping-cart:before {
    content: "";
}

.icon-search:before {
    content: "";
}

.icon-compare:before {
    content: "";
    font-size: 0.9em;
}

.icon-close:before {
    content: "";
}

.icon-angle-up:before {
    content: "\f106";
}

.icon-angle-down:before {
    content: "\f107";
}

.icon-angle-right:before {
    content: "\f105";
}

.icon-angle-left:before {
    content: "\f104";
}

.icon-filter:before { 
    content: "\f00a"; 
}

.icon-circle-blank:before { 
    content: "\f10c"; 
}

.icon-circle:before { 
    content: "\f111";
}

.icon-remove-circle:before { 
    content: "\f05c"; 
}

.icon-plus:before { 
    content: "\f067"; 
}

.icon-play:before {
    content: "\f04b";
}

.icon-play {
	font-size: 1.8em;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    display: inline-block;
    text-decoration: inherit;
}

li [class^="icon-"], .nav li [class^="icon-"], li [class*=" icon-"], .nav li [class*=" icon-"] {
    display: inline-block;
    text-align: center;
    width: 1.25em;
    padding: 0 0 0 0.75em;
}

a [class^="icon-"], a [class*=" icon-"] {
    display: inline-block;
}

[class^="icon-"], [class*=" icon-"] {
    background-image: none;
    background-position: 0 0;
    background-repeat: repeat;
    display: inline;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    height: auto;
    line-height: normal;
    margin-top: 0;
    text-decoration: inherit;
    vertical-align: baseline;
    width: auto;
}
