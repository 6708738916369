.shopfinder {
    @import 'shopfinder/introduction';
    @import 'shopfinder/map';
    @import 'shopfinder/search-results';
    @import 'shopfinder/shops-list';

    // Detail view
    @import 'shopfinder/detail';

    // Appointment view
    @import 'shopfinder/appointment';

    // Facebook specific shopfinder
    @import 'shopfinder/facebook';
}

.shopfinder--detail {
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }
}

// Mobile adjustments
@include breakpoint(mobile) {
    .shopfinder:not(.shopfinder--detail) {
        display: flex;
        flex-direction: column;
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    .shopfinder-component-mykita-shops {
        order: 1;
    }

    .shopfinder-component-map {
        order: 2;
        margin-top: 4rem;
    }

    .shopfinder-component-search-results {
        order: 3;
        margin-top: 2rem;
    }
}