////
/// Collection of useful functions
/// @group Functions
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

/// Strips the unit from a size statement
///
/// @group humans-machines
/// @param {number} $value - The number to strip
/// @return {number} - The stripped value
@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

/// Replace `$search` with `$replace` in `$string`
///
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

/// Returns the opposite direction of each direction in a list
/// @group humans-machines
/// @param {List} $directions - List of initial directions
/// @return {List} - List of opposite directions
@function opposite-direction($directions) {
    $opposite-directions: ();
    $direction-map: (
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right',
        center: 'center',
        ltr: 'rtl',
        rtl: 'ltr'
    );

    @each $direction in $directions {
        $direction: to-lower-case($direction);

        @if map-has-key($direction-map, $direction) {
            $opposite-directions: append($opposite-directions, unquote(map-get($direction-map, $direction)));
        } @else {
            @warn 'No opposite direction can be found for `#{$direction}`. Direction omitted.';
        }
    }

    @return $opposite-directions;
}

/// Returns the proper z-index for the given key
/// @group humans-machines
/// @param {key} $key - The key for the z-index
/// @return {number} - The related z-index
@function zIndex($key) {
    @return map-get($z-index, $key);
}