/* ==========================================================================
   Sidebar basket
   ========================================================================== */
.sidebar-basket {
    min-height: 16rem;

    .css-loading-wrapper {
        .css-loading-inline {
            width: 100%;
            height: 16rem;
            background: url(../images/icons/loader_32px_t.gif) no-repeat 50% 50% transparent;
        }
    }

    // Remove standard styling from lists
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    // Listing
    .cart-listing {
        border-bottom: solid 1px #000;
        margin-bottom: 2rem;

        .cart-listing-item {
            text-align: left;
            position: relative;
            display: block;
            padding: 1rem 0;
            border-bottom: solid 1px #999999;

            &:last-child {
                border-bottom: none;
            }

            &.cart-product {
                padding-top: 1rem;
                padding-bottom: 2rem;
            }

            &.cart-summary {
                border-bottom: none;

                &:last-child {
                    padding-top: 0;
                }
            }
        }
    }

    .boxLink {
        overflow: hidden;

        &.trans-image {
            .respImage,
            .media-box {
                transition: background $defTransTime $defTransFunc;
                background: #bbb;

                img {
                    opacity: 0.78;
                }
            }
        }
    }

    .boxLink:hover {
        &.trans-image {
            .respImage,
            .media-box {
                background: #dcdcdc;

                img {
                    opacity: 0.6;
                }
            }
        }

        .h3Wrapper,
        .product-details-info {
            opacity: 0.6;
        }
    }

    // Product description
    .product-details {
        opacity: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        li {
            display: inline-block;
        }

        .product-details-info {
            transition: opacity $defTransTime $defTransFunc;

            ul {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
            }
        }

        .product-details-image {
            width: 100%;

            .image-wrapper {
                display: inline-block;
                overflow: hidden;
                width: 10rem;
                height: 6rem;
            }

            @include breakpoint(three) {
                width: 100%;

                .image-wrapper {
                    width: 100%;
                    max-height: none;
                    height: 14rem;
                }
            }

            @include breakpoint(one) {
                width: 100%;

                .image-wrapper {
                    width: 100%;
                    max-height: none;
                    height: 14rem;
                }
            }
        }

        .product-details-name {
            text-align: left;
            flex: 3;

            span {
                @include typoZurichBd;
                text-transform: none;
            }
        }

        .product-details-quantity {
            text-align: center;
            flex: 2;
        }

        .product-details-price {
            text-align: right;
        }

        .product-details-description {
            width: 100%;
        }

    }

    // Definition list
    dl {
        @extend %cf;
        margin: 0;

        dt {
            display: inline-block;
            float: left;
            margin: 0;
            max-width: 70%;
        }

        dd {
            display: inline-block;
            float: right;
            margin: 0;
        }
    }

    .h4Wrapper {
        margin-bottom: 0.5rem;
    }

    // Typography
    li span {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
    }

    .primary span {
        @include typoZurichBd;
        text-transform: none;
    }

    h4 {
        @include typoZurich;
        @include typoZurich0-rem;
        text-transform: none;
    }

    .voucher-submit-container {
        dl {
            display: flex;
            align-items: center;
        }

        dt {
            flex: 1;
        }

        dd {
            margin-left: 1rem;
        }
    }
}

// Add hairlines
.hairlines {
    .sidebar-basket {
        .cart-listing {
            border-width: 0.5px;

            .cart-listing-item {
                border-width: 0.5px;
            }
        }
    }
}
