/* ==========================================================================
   Vimeo Teaser
   ========================================================================== */

.vimeoBox {
	
    /*
    .vimeoBoxInner {
        position: relative;
        padding-bottom: 55%;
        padding-top: 15px;
        height: 0;
        overflow: hidden;
    }
    */
    
    .vimeoBoxInner {
        position: relative;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;     
    }
    
    .vimeoBoxInner.v-16-9 {
        padding-top: 56.25%;
        height: 0;    
    }
    
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    } 
             
}

.L-1-Video-16-9 .vimeoBox .vimeoBoxInner {
    padding: 3px 0;
    iframe {
        top: -3px;
    }
}