/* ==========================================================================
   Collection page
   ========================================================================== */


.productListing,
.legacy-product-listing {
    @extend %cf;
    display: block;
}

.filterable-collection-container {
    margin-bottom: 8rem;

    @include breakpoint(mobile) {
        margin-bottom: 6rem;
    }

    .no-frames-found {
        text-align: center;
        margin-top: 16rem;

        .message {
            @include typoZurichBd;
            @include typoZurich3-rem;
            margin: 0;
        }
    }

    .articlesItem & {
        margin-bottom: 0;
    }

    //@include breakpoint(one) {
    //    width: 26rem;
    //    max-width: 95%;
    //}
}

.back-to-top-container {
    margin-bottom: 8rem;
    text-align: center;
    z-index: 4;

    &.is-sticky {
        position: relative;
        position: sticky;
        bottom: -1px; // Prevent 'Blitzer'
        padding: 2rem 0;
        transform: translate3d(0, 100%, 0);
        transition: transform $trans-time--default $trans-func--default,
                    padding $trans-time--default $trans-func--default;

        //@include fade-cover(top, 1rem);

        &:after {
            opacity: 0;
            pointer-events: none;
            transition: opacity $trans-time--default $trans-func--default;
        }

        .has-scrolled-deep & {
            transform: none;

            &:after {
                opacity: 1;
            }
        }

        &.is-stuck {
            .has-scrolled-up & {
                transform: translate3d(0, 100%, 0);

                &:after {
                    opacity: 0;
                }
            }
        }
    }

    &.no-background {
        background: none;
    }
}

// New products with colors
.product {
    position: relative;

    &.product--has-color,
    &.product--view-more,
    &.product--view-less {
        box-sizing: border-box;
        height: 17rem;

        .module {
            height: 16rem;
        }
    }

    &.product--samplesale {
        box-sizing: border-box;
        height: 16rem;

        .module {
            height: 16rem;
        }
    }

    &.product--info-layer {
        box-sizing: border-box;
        height: 16rem;

        img {
            width: 100%;
            max-height: 100%;
        }
    }
}

// View more / View less
.product.product--view-more,
.product.product--view-less {
    .btn--expand-collection,
    .btn--reduce-collection {
        display: block;
        height: 100%;
        width: 100%;
        overflow: hidden;
        position: relative;
        border: none;
        padding: 0;
        margin: 0;
    }

    .icon-wrapper {
        display: inline-block;
        width: 100%;
        height: 4rem;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -2rem;

        span {
            @include typoZurich;
            font-size: 3rem;
            font-weight: 100;
        }
    }
}

// Product listing, where articles just the first row of articles is shown
.legacy-product-listing--reduced {
    .product.product--view-less {
        display: none;
    }

    .product.product--secondary {
        display: none;
    }

    @include breakpoint(four) {
        .product[data-product="4"] {
            display: none;
        }
    }

    @include breakpoint(three) {
        .product[data-product="3"],
        .product[data-product="4"] {
            display: none;
        }
    }

    @include breakpoint(onethree) {
        .product[data-product="4"] {
            display: none;
        }
    }

    @include breakpoint(one) {
        .product[data-product="3"],
        .product[data-product="4"] {
            display: none;
        }
    }

    // Product listing is expanded
    &.legacy-product-listing--expanded {
        .box.product {
            display: block;
        }

        .product.product--view-more {
            display: none;
        }

        .product.product--view-less {
            display: block;
        }
    }
}


/* Old stuff
   ========================================================================= */
.collection {
    // Position of title
    .hoverWrapper {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        margin-top: -2em;
    }

    // Collection teaser
    .collectionTeaser {
    	clear: both;
        float: none;
    }

    .filterProduct {
        display: none;
    }

    // Small collection teaser
    .filterCollectionTeaser {
        display: none;
        float: left;

        .h2Wrapper {
            position: absolute;
            display: block;
            width: 100%;
            margin-top: -1em;
            top: 50%;
            left: 0;
            text-align: center;

            h2 {
                @include typoZurichBd;
                @include typoZurich3-rem;
            }
        }
    }

    // Load More layer
    .viewLessProducts,
    .loadMoreProducts {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background-color: white;
        top: 0;
        left: 0;
        z-index: 2;
        text-align: center;

        .minus,
        .plus {
            position: absolute;
            display: block;
            margin-top: -2em;
            top: 50%;
            left: 0;
            width: 100%;
            text-align: center;

            h3 {
                @include typoZurich;
                font-size: 3em;
                font-weight: 100;
            }

            .loader {
                display: none;
            }
        }

        .lessLink,
        .moreLink {
            position: absolute;
            display: block;
            width: 100%;
            bottom: 1.25em;
            left: 0;

            span {
                @include typoZurich;
                @include typoZurich1-rem;
            }
        }
    }

    // Set a min height for the listing unless it has no products
    .productListing {
        min-height: 16em;
    }
}

.collections {
    transition-property: margin;
    transition-duration: $toolsHeaderTime;
    transition-timing-function: $toolsHeaderFunc;

    min-height: 32em;

    .noFilterFramesFound {
    	text-align: left;
        max-height: 0;
        opacity: 0;
        transition-property: opacity, max-height, margin;
        transition-duration: $toolsHeaderTime;
        transition-timing-function: $toolsHeaderFunc;

        h1 {
            @include typoZurichBd;
            @include typoZurich3-rem;
        }
    }

    // Collectionteaser in front of products
    .collaborationTeaser {
        text-align: center;

        .h2WrapperCollab {
            margin: 6em 0 1em 0;

            h2 {
                @include typoZurichBd;
                @include typoZurich1-rem;
            }
        }
    }
}

.collections.noFilterFrames {
    .message {
        @include typoZurichBd;
        @include typoZurich3-rem;
        margin: 0;
    }

    .noFilterFramesFound {
    	max-height: 16em;
    	opacity: 1;
    	margin: 16em 0 8em;
    }

    #filterProductsContainer {
        display: none;
    }
}

.filteredCollection .collections {
	margin-top: 6em;
}

.filterOpened.filteredCollection .collections {
    margin-top: 26em;
}

@include breakpoint(three) {
    .filterOpened.filteredCollection .collections {
        margin-top: 29em;
    }
}


/* Collapsed collection settings
   ========================================================================= */
@include breakpoint(five) {
    .collection.collapsed {
        .product[data-product="1"] .loadMoreProducts,
        .product[data-product="2"] .loadMoreProducts,
        .product[data-product="3"] .loadMoreProducts,
        .product[data-product="4"] .loadMoreProducts {
            display: none;
        }
    }
}

@include breakpoint(four) {
    .collection.collapsed {
        .product[data-product="1"] .loadMoreProducts,
        .product[data-product="2"] .loadMoreProducts,
        .product[data-product="3"] .loadMoreProducts {
            display: none;
        }

        .product[data-product="5"] {
            display: none;
        }
    }
}

@include breakpoint(three) {
    .collection.collapsed {
        .product[data-product="1"] .loadMoreProducts,
        .product[data-product="2"] .loadMoreProducts {
            display: none;
        }

        .product[data-product="4"],
        .product[data-product="5"] {
            display: none;
        }
    }
}

@include breakpoint(two) {
    .collection.collapsed {
        .product[data-product="1"] .loadMoreProducts,
        .product[data-product="2"] .loadMoreProducts,
        .product[data-product="3"] .loadMoreProducts {
            display: none;
        }

        .product[data-product="5"] {
            display: none;
        }
    }
}

@include breakpoint(one) {
    .collection.collapsed {
        .product[data-product="1"] .loadMoreProducts,
        .product[data-product="2"] .loadMoreProducts {
            display: none;
        }

        .product[data-product="4"],
        .product[data-product="5"] {
            display: none;
        }
    }
}

/* Collapsed collaboration/project settings
   ========================================================================= */
@include breakpoint(five) {
    .collaboration.collapsed {
        .product[data-product="1"] .loadMoreProducts,
        .product[data-product="2"] .loadMoreProducts,
        .product[data-product="3"] .loadMoreProducts {
            display: none;
        }
    }
}

@include breakpoint(four) {
    .collaboration.collapsed {
        .product[data-product="1"] .loadMoreProducts,
        .product[data-product="2"] .loadMoreProducts {
            display: none;
        }

        .product[data-product="4"] {
            display: none;
        }
    }
}

@include breakpoint(three) {
    .collaboration.collapsed {
        .product[data-product="1"] .loadMoreProducts {
            display: none;
        }

        .product[data-product="3"],
        .product[data-product="4"] {
            display: none;
        }
    }
}

@include breakpoint(two) {
    .collaboration.collapsed {
        .product[data-product="1"] .loadMoreProducts,
        .product[data-product="2"] .loadMoreProducts {
            display: none;
        }

        .product[data-product="4"] {
            display: none;
        }
    }
}

@include breakpoint(one) {
    .collaboration.collapsed {
        .product[data-product="1"] .loadMoreProducts {
            display: none;
        }

        .product[data-product="3"],
        .product[data-product="4"] {
            display: none;
        }
    }
}


@include breakpoint(mobile) {
    .collections.noFilterFrames .noFilterFramesFound {
        margin-top: 8em;

        .message {
            @include typoZurich2-rem;
        }
    }
}

/* Expanded collection settings
   ========================================================================= */
.collaboration.expanded,
.collection.expanded {

    .defaultProduct,
    .loadMoreProducts {
        display: none;
    }

    .defaultProduct.addedDefaultProduct {
        display: block;
    }
}

.collaboration.collapsed .viewLess,
.collection.collapsed .viewLess {
    display: none;
}

// Samplesale stuff
.continue-shopping-container,
.samplesale-continue-shopping-container {
    margin-top: 4rem;
    margin-bottom: 4rem;
    text-align: center;

    .btn {
        width: auto;
        display: inline-block;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}