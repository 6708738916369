.lookbook-v2 {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    &.has-inverted-color-scheme {
        color: #fff;
    }

    * {
        box-sizing: border-box;
    }
}

.lookbook-v2__wrapper {
    cursor: pointer;
    position: relative;
    z-index: 2;
}

// Close Wrapper/Button
.lookbook-v2__close {
    position: fixed;
    z-index: 5;

    @include breakpoint(mobile) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        top: 0;
        left: 50%;
        height: 4.5rem;
        transform: translate3d(-50%, 0, 0);
        pointer-events: none;

        .btn {
            pointer-events: all;
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    @include breakpoint(one) {
        width: $moduleW-rem;
    }

    @include breakpoint(two) {
        width: $moduleW-rem * 2;
    }

    @include breakpoint(desktop) {
        top: 3.1rem;
        right: 2rem;
        width: 1.8rem;
        height: 1.8rem;
    }
}

// Slide item
.lookbook-v2__item {
    position: relative;
    display: flex;
    justify-content: center;
}

// Handle Product/Sticky slides on mobile
@include breakpoint(mobile) {
    .lookbook-v2__item--product {
        z-index: 2;
        background: $back-color--default;
        padding: 0 0 2rem 0;
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: nowrap;
        height: 100vh;
        transform: translate3d(0, 0, 0);

        &:only-child {
            padding: 0;
        }
    }

    .lookbook-v2__item--sticky {
        height: 150vh;

        .lookbook-v2__item-image,
        .lookbook-v2__item-video {
            display: none;
        }
    }

    .lookbook-v2__item--product + .lookbook-v2__item--product {
        margin-top: -2rem;
    }
}

// Handle Product/Sticky slides on desktop
@include breakpoint(desktop) {
    .lookbook-v2__item {
        justify-content: flex-start;
        width: 100vw;
    }

    .lookbook-v2__item--product {
        &[data-image-position="right"] {
            flex-direction: row-reverse;
        }
    }
}

// Image
.lookbook-v2__item-image,
.lookbook-v2__item-video {
    position: relative;

    .media-box img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    &.lookbook-v2__item-image--bigTower,
    &.lookbook-v2__item-video--bigTower {
        @include breakpoint(mobile) {
            height: calc(100vh - 6.5rem);
        }

        @include breakpoint(one) {
            width: 1 * $moduleW-rem;
        }

        @include breakpoint(two) {
            width: 2 * $moduleW-rem;
        }

        @include breakpoint(desktop) {
            flex: 1 1 50%;
            height: 100vh;
        }
    }

    &.lookbook-v2__item-image--huge,
    &.lookbook-v2__item-video--huge,
    &.lookbook-v2__item-image--hero,
    &.lookbook-v2__item-video--hero {
        width: 100%;
        height: 100vh;
    }
}

// Cinema video rendering
.lookbook-v2__item-video.lookbook-v2__item-video--cinema {
    background: #000;
    display: flex;
    align-items: center;

    .lookbook-v2__item-video-wrapper {
        position: relative;
        width: 100%;
        padding-bottom: 41.875%;
    }

    .image-teaser__video-box {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    // We have to place it fixed -> Might break in the future
    .video-mute-toggle {
        position: fixed;
    }
}

// Slide content including article list (Desktop only)
.lookbook-v2__item-content {
    @include breakpoint(mobile) {
        position: sticky;
        top: 0;
        display: flex;
        height: 4.5rem;
        align-items: center;
        background: #fff;
    }

    @include breakpoint(one) {
        width: $moduleW-rem;
    }

    @include breakpoint(two) {
        width: $moduleW-rem * 2;
    }

    @include breakpoint(desktop) {
        display: flex;
        flex: 1 1 50%;
        padding: 3rem 5.5rem 3rem 3rem;

        [data-image-position="left"] & {
            justify-content: flex-end;
        }
    }
}

// Product list
.lookbook-v2__product-list {
    display: flex;
    flex-wrap: wrap;

    li {
        // Add & as connector
        &:not(:first-child) {
            &:before {
                content: '&';
                display: inline-block;
                border-bottom: solid 1px #000;
                line-height: 1.1em;
                padding: 0 0.5rem;

                // Hairlines for &
                .hairlines & {
                    border-width: 0.5px;
                }
            }
        }

        @include breakpoint(desktop) {
            @include typoZurich;
            @include typoZurich2-rem;
        }

        @include breakpoint(mobile) {
            .lookbook-category {
                display: none;
            }
        }
    }
}

.lookbook-v2__products-mobile {
    position: relative;
    width: 1 * $moduleW-rem;
    height: 2rem;

    @include breakpoint(two) {
        width: 2 * $moduleW-rem;
    }
}

.lookbook-v2__page-indicator {
    position: absolute;
    right: 5.5rem;
    bottom: 3rem;
    z-index: 3;

    span {
        @include typoZurich;
        @include typoZurich2-rem;
    }

    @include breakpoint(mobile) {
        display: none;
    }
}

.lookbook-v2__mobile-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @include breakpoint(desktop) {
        display: none;
    }
}

.lookbook-v2__mobile-background-item {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;

    @supports (--var: value) {
        opacity: var(--opacity, 0);
    }
}

.lookbook-v2__mobile-background-item-image {
    width: 100%;
    height: 100%;

    .media-box img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.lookbook-v2__item-link {
    position: absolute;

    a {
        @include typoZurich;
        @include typoZurich2-rem;
        text-transform: none;
        transition: color $fastTransTime $defTransFunc,
                    border $fastTransTime $defTransFunc;

        .has-inverted-color-scheme & {
            color: #fff;
            border-color: currentColor;
        }

        .no-touch & {
            &:hover {
                color: rgb(150, 150, 150);
                border-color: rgb(150, 150, 150);
            }
        }
    }

    @include breakpoint(desktop) {
        top: auto;
        left: auto;
        bottom: 2.7rem;
        right: 3rem;
    }

    @include breakpoint(mobile) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        top: 0;
        left: 50%;
        height: 4.5rem;
        transform: translate3d(-50%, 0, 0);
        pointer-events: none;

        a {
            pointer-events: all;
        }
    }

    @include breakpoint(one) {
        width: $moduleW-rem;

        a {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
        }
    }

    @include breakpoint(two) {
        width: $moduleW-rem * 2;
    }
}

// Body related
.has-lookbook-v2 {
    .outer-wrapper {
        padding-top: 0;
    }

    @include breakpoint(desktop) {
        overflow: hidden;
    }
}