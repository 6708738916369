/* ==========================================================================
   Lookbook
   ========================================================================== */
.lookbookWrapper {
    height: 100%;
    @include breakpoint(desktop) {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lookbook {
        position: relative;
        margin: 0 auto;
        text-align: center;
        box-sizing: border-box;
        @extend .clear;
    }

    @include breakpoint(desktop) {
        .lookbookInnerWrapper {
            position: relative;
            width: 90%;
        }
    }

    @include breakpoint(three) {
        .lookbookInnerWrapper {
            width: 80%;
        }
    }

    @include breakpoint(mobile) {
        .lookbookInnerWrapper {
            position: relative;
            width: 100%;
        }
    }

    .lookbook {
        width: 100%;
        height: 100%;

        .rsSlide {
            background-color: #fff;
        }

        .lookbook__slide {
            background-color: #fff;
            position: relative;
            width: 100%;
            height: 100%;
            display: block;

            @include breakpoint(desktop) {
                margin: 0 !important;
            }

            @include breakpoint(two) {
                margin-bottom: 2.6rem;

                .gridBorder {
                    padding: 0;
                }
            }

            @include breakpoint(one) {
                margin-bottom: 1.3rem;

                .gridBorder {
                    padding: 0;
                }
            }

            &.lookbook__slide--bigTower-mobile {
                @include breakpoint(one) {
                    .box {
                        width: 1 * $moduleW;
                        height: 2 * $moduleH;
                    }
                }

                @include breakpoint(two) {
                    .box {
                        width: 2 * $moduleW;
                        height: 4 * $moduleH;
                    }
                }
            }
        }

        .box {
            position: relative;
            float: none;
            text-align: center;
            margin: 0 auto;
        }

        @include breakpoint(desktop) {
            .huge,
            .bigTower,
            .bigQuad {
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;
                width: 50%;
                height: 50%;
                text-align: center;
            }
        }

        // Set proper sizes

        .huge {
            @include breakpoint(two) {
                width: 2 * $moduleW;
                height: 2 * $moduleH;
            }

            @include breakpoint(one) {
                width: 1 * $moduleW;
                height: 1 * $moduleH;
            }
        }

        .bigTower {
            @include breakpoint(two) {
                width: 2 * $moduleW;
                height: 4 * $moduleH;
            }

            @include breakpoint(one) {
                width: 1 * $moduleW;
                height: 2 * $moduleH;
            }
        }

        .bigQuad {
            @include breakpoint(two) {
                width: 2 * $moduleW;
                height: 3 * $moduleH;
            }

            @include breakpoint(one) {
                width: 1 * $moduleW;
                height: 1.5 * $moduleH;
            }
        }


        // Set arrows
        &.arrowsOuter {
            .outerArrowsContainerLeft {
                margin-left: -4em;

                @include breakpoint(mobile) {
                    display: none;
                }
            }

            .outerArrowsContainerRight {
                margin-right: -4em;

                @include breakpoint(mobile) {
                    display: none;
                }
            }
        }
    }
}

// Lookbook Counter
.lookbookPagination {
    z-index: zIndex(lookbook-pagination);
    position: fixed;
    top: 0;
    right: 6em;
    height: $toolbarHeightDesktop;
    line-height: $toolbarHeightDesktop + 0.4rem;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    box-sizing: border-box;
    transition-property: max-height, opacity;
    transition-duration: $toolsHeaderTime, $toolsHeaderTime;
    transition-timing-function: $defTransFunc;

    span {
    	display: inline-block;
        font-size: 1.4em;
        line-height: 1em;
    }
}

.hasLookbook .lookbookPagination {
    max-height: $toolbarHeightDesktop;
    opacity: 1;
}

@include breakpoint(mobile) {
    .lookbookPagination {
        height: $toolbarHeightMobile;
        line-height: $toolbarHeightMobile + 0.4rem;
    }

    .hasLookbook .lookbookPagination {
        max-height: $toolbarHeightMobile;
    }
}

@include breakpoint(one) {
    .lookbookPagination {
        right: 4em;
    }
}

// Lookbook Article
.lookbook-products {
    transition-property: opacity, visibility;
    transition-duration: $defTransTime, 0s;
    transition-timing-function: $defTransFunc;
    transition-delay: 0s, $defTransTime;
    @extend %cf;
    display: inline-block;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    visibility: hidden;

    &.lookbook-products-active {
        transition-property: opacity;
        transition-duration: $defTransTime;
        transition-timing-function: $defTransFunc;
        transition-delay: 0s;
        visibility: visible;
        opacity: 1;
        z-index: 2;
    }

    li {
        float: left;

        // Add & as connector
        &:not(:first-child) {
            &:before {
                content: '&';
                display: inline-block;
                border-bottom: solid 1px #000;
                line-height: 1.1em;
                padding: 0 0.5rem;

                // Hairlines for &
                .hairlines & {
                    border-width: 0.5px;
                }
            }
        }
    }

    @include breakpoint(one) {
        max-width: 17rem;
        max-height: $header-height--mobile;
    }
}

.lookbook__slide .lookbook-products {
    display: none;
}

.lookbookProductInline {
    position: relative;
    margin-top: 1em;
    min-height: 2em;

    .lookbook-products {
        left: 50%;
        top: 0.5em;
    }
}

.lookbookProductToolbar {
    z-index: zIndex(lookbook-products);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $toolbarHeightMobile;
    line-height: $toolbarHeightMobile;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    text-align: left;

    transition-property: max-height, opacity;
    transition-duration: $toolsHeaderTime, $defTransTime;
    transition-timing-function: $defTransFunc;

    .mykitaContainer {
        text-align: left;
    }

    .lookbook-category {
        display: none;
    }
}

@include breakpoint(mobile) {
    .lookbookProductInline {
        display: none;
    }

    .hasLookbook .lookbookProductToolbar {
        max-height: $toolbarHeightMobile;
        opacity: 1;
    }
}

@include breakpoint(desktop) {
    .lookbookProductToolbar {
        display: none;
    }
}
