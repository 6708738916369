// 5 columns
@include breakpoint(five) {
    .L-1-Teaser-Extended {
        height: 3.5 * $moduleH-rem;

        // Extra
        .b1 {
            top: 0 * $moduleH-rem;
            left: 0 * $moduleW-rem;
        }
    }
}

// 4 columns
@include breakpoint(four) {
    .L-1-Teaser-Extended {
        height: 3 * $moduleH-rem;

        // Extra
        .b1 {
            top: 0 * $moduleH-rem;
            left: 0 * $moduleW-rem;
        }
    }
}

// 3 columns
@include breakpoint(three) {
   .L-1-Teaser-Extended {
        height: 2.5 * $moduleH-rem;

        // Extra
        .b1 {
            top: 0 * $moduleH-rem;
            left: 0 * $moduleW-rem;
        }
    }
}

// 2 columns
@include breakpoint(two) {
    .L-1-Teaser-Extended {
        height: 2 * $moduleH-rem;

        // Extra
        .b1 {
            top: 0 * $moduleH-rem;
            left: 0 * $moduleW-rem;
        }
    }
}

// 1 columns
@include breakpoint(one) {
    .L-1-Teaser-Extended {
        height: 2 * $moduleH-rem;

        // Extra
        .b1 {
            top: 0 * $moduleH-rem;
            left: 0 * $moduleW-rem;
            height: 2 * $moduleH-rem;
        }
    }
}
