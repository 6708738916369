&.shopfinder--facebook {
    display: block;
    width: 100%;

    // Typography for Facebook Shopfinder
    h3 {
        font-size: 13px;
        margin-bottom: 10px;
    }

    p {
        font-size: 12px;
    }

    .text-link {
        font-size: 12px;
        border-bottom: solid 1px #000;
    }

    .btn--link {
        font-size: 12px;
    }

    .shopfinder-map-group--map {
        width: $facebook-shopfinder-map-width;
        height: $facebook-shopfinder-map-height;
        padding: 0;
        margin-left: -36px;
        margin-right: -36px;
    }

    // General container
    .mykita-container {
        width: $facebook-shopfinder-container-width;
        position: relative;
        margin: 0 auto;
    }

    // Map
    .shopfinder-map {
        margin-bottom: 0;
    }

    .shopfinder-map-group--filter {
        justify-content: space-between;
        margin-top: 36px;

        .shopfinder-map-filter {
            width: $facebook-shopfinder-cell-width;
            padding: 0;
        }
    }

    .shops-list {
        justify-content: space-between;
        margin-top: 36px;

        .shops-list-item {
            width: $facebook-shopfinder-cell-width;
            padding: 0 0 36px 0;
        }
    }

    // Form stuff
    input,
    input[type="search"],
    input[type="text"],
    select,
    .select2-container,
    .select2-chosen,
    .select2-results .select2-result-label {
        font-size: 12px;
        height: 36px;
        line-height: 36px;
    }

    .select2-arrow {
        top: 50%;
        margin-top: -0.75rem;
    }

    // Dealer information
    .shopfinder-map-retailer {
        h3 {
            font-size: 13px;
        }

        p {
            font-size: 12px;
        }

        .text-link {
            font-size: 12px;
        }
    }
}