/* ==========================================================================
   Mylon
   ========================================================================== */

.mykitaMylon {

    // Change article container headline
    .textItem h1,
    .textItem h2,
    .articlesItem h2 {
        @include typoZurich;
        @include typoZurich2-rem;

        display: inline-block;
        padding-top: 0.1em;
        border-top: solid 1px black;
    }

    .textItem {
        .textWrapper p,
        .textWrapper span {
            display: inline-block;
            @include typoZurichBd;
            @include typoZurich3-rem;
            text-transform: none;
        }
    }

    // Change big teaser
    .textWrapper.bigMylonText p,
    .textWrapper.bigMylonText span {
        line-height: 1.1em;
    }

    .textItem .textWrapper,
    .textItem .headlineWrapper,
    .articlesItem .headlineWrapper {
        margin: 0;
        max-width: none;
    }
}

@include breakpoint(five) {
    .mykitaMylon {
        .textItem .textWrapper {
            width: 4 * $moduleW;
        }
    }
}

@include breakpoint(four) {
    .mykitaMylon {
        .textItem .textWrapper {
            width: 3 * $moduleW;
        }
    }
}

@include breakpoint(two) {
    .mykitaMylon {
        .textItem h1,
        .textItem h2,
        .articlesItem h2 {
            @include typoZurich2-rem;
        }

        .textItem .textWrapper p,
        .textItem .textWrapper span {
            @include typoZurich2-rem;
            text-transform: none;
        }
    }
}

@include breakpoint(one) {
    .mykitaMylon {
    	.textItem h1,
    	.textItem h2,
        .articlesItem h2 {
            @include typoZurich1-rem;
        }

        .textItem .textWrapper p,
        .textItem .textWrapper span {
            @include typoZurich1-rem;
            text-transform: none;
        }
    }
}