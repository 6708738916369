/* ==========================================================================
   Faq Item
   ========================================================================== */

.faqItem {
    text-align: left;
    max-width: 4 * $moduleW-rem;

    .faq-categories {
        margin-top: 2rem;
        width: 100%;

        .faq-categories__item:before {
            display: none;
        }

        .faq-categories__item {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 0;
            width: 100%;

            &:not(:first-child) {
                margin: 0.25rem 0 0 0;
            }
        }

        .range {
            display: inline-block;
            width: 8rem;
        }

        p {
            margin: 0;
            width: 100%;
        }
    }

    .faq-entries,
    .faq-category-headline {
        margin: 4rem 0 0 0;
    }

    .faq-entry + .faq-entry {
        margin-top: 2rem;
    }

    h3,
    h4,
    h5,
    label,
    button,
    a,
    p,
    li {
        @include typoZurich;
        @include typoZurich3-rem;
        text-align: inherit;

        @include breakpoint(one) {
            @include typoZurich2-rem;
        }
    }

    h4,
    a,
    p,
    li {
        text-transform: none;
        text-align: inherit;
    }

    p,
    h4 {
        margin: 1em 0 0 0;
    }

    .faq-entry__content > p:first-child {
        margin-top: 0.5em;
    }
}

// Accordion
.faq-accordion {
    .faq-accordion__head {
        display: flex;
        align-items: center;
        height: 4rem;
        border-bottom: solid 1px $border-color-dark;

        label {
            cursor: pointer;
            width: 100%;
            margin: 0;
        }
    }

    .faq-accordion__head-toggle {
        width: 3rem;
        padding: 0;
        text-align: center;

        span {
            display: inline-block;
            width: 100%;
            text-align: center;
        }
    }

    .faq-accordion__body {
        overflow: hidden;
    }

    &[aria-expanded="false"] {
        .faq-accordion__body {
            visibility: hidden;
            height: 0;
        }

        .faq-accordion__head-toggle {
            span:last-child {
                display: none;
            }
        }
    }

    &[aria-expanded="true"] {
        .faq-accordion__head-toggle {
            span:first-child {
                display: none;
            }
        }
    }
}

.faq-category-headline + .faq-accordion {
    margin-top: 2rem;
}

// Pictogram for lens categories
.faq-entry .faq-lens-categories-pictogram {
    width: 100%;
    max-width: 1.5 * $moduleW-rem;
    margin-bottom: 4rem;

    ul {
        list-style-type: none;
        width: 100%;
        margin-top: 1rem;

        li {
            display: flex;
            align-items: center;
            border-top: solid 1px $border-color-dark;
            padding: 1rem 0;

            &:before,
            &:after {
                display: none;
            }

            svg {
                width: 100%;
            }

            .faq-lens-categories-pictogram__description {
                width: 60%;
                padding-right: 2rem;
            }

            .faq-lens-categories-pictogram__pictogram {
                width: 30%;
                text-align: center;
            }

            .faq-lens-categories-pictogram__category {
                width: 10%;

                p {
                    text-align: right;
                }
            }

            &:nth-child(1),
            &:nth-child(2) {
                svg {
                    width: 80%;
                }
            }

            &:nth-child(3) {
                svg {
                    width: 30%;
                }
            }

            &:nth-child(4) {
                svg {
                    width: 40%;
                }
            }

            &:nth-child(5) {
                svg {
                    width: 50%;
                }
            }
        }
    }

    h5,
    p, {
        margin: 0;
    }
}