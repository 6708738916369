.L-S-6-2 {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 100%;

    .b1,
    .b2 {
        float: none;
        position: relative;
        padding: 0;

        // Disable box link hover effect
        a.boxLink:hover .media-box img {
            opacity: 1;
        }
    }

    @media (orientation: portrait) and (min-width: 480px) {
        padding-bottom: 66.66666%;

        .b1,
        .b2 {
            position: absolute;
            top: 0;
            width: 50%;
            height: 100%;
        }

        .b1 {
            left: 0;
        }

        .b2 {
            right: 0;
        }
    }

    @media (orientation: landscape) and (min-width: 480px) {
        height: $full-height-vh--desktop;
        height: $full-height-svh--desktop;
        display: flex;
        flex-direction: row;
        padding-bottom: 0;

        .b1,
        .b2 {
            width: 50%;
            height: 100%;
        }
    }

    @include breakpoint(one) {
        display: flex;
        flex-direction: column;

        .b1 {
            width: 100%;
            height: auto;

            > * {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }

        .b2 {
            width: 100%;
            height: auto;
            padding-bottom: 100%;

            > * {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
    }
}