// Catwalk floats
.L-6-3-1 {
    height: auto;
    max-width: 72em;
    margin-left: auto;
    margin-right: auto;
    
    // Tower
    .b1,
    .b2,
    .b3,
    .b4,
    .b5,
    .b6 {
        float: left;
        position: relative;
    }
    
    &:before,
    &:after {
        display: table;
        line-height: 0;
        content: "";
    }
    
    &:after {
        clear: both;
    }
}
