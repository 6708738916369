/* ==========================================================================
   MYKITA HAUS
   ========================================================================== */

.mykitaHaus {
	.defaultHausText {
	    text-align: left;
	}

	.L-HAUS-1 .linkWrapper {
	    padding-top: 0.5em;
	}
}

.pressText .headlineWrapper {
    margin-top: 1em;
    padding-bottom: 0;
}

.pressText .textWrapper p,
.pressText .textWrapper span {
    @include typoZurich;
    @include typoZurich1-rem;
	text-transform: none;
}

@include breakpoint(three) {
    .L-HAUS-1 {
        .headlineWrapper {
            margin-top: 1em;
        }
    }
}

@include breakpoint(mobile) {
    .L-HAUS-1 {
        .headlineWrapper {
            margin-top: 1em;
        }
    }

    .mykitaHaus {
        margin-top: 0;
    }
}

.overlayContainerInner .mykitaHaus {
    margin-top: 0;
}
