// Default Settings
.L-1-Three {
    height: auto;
    
    // First Box
    .b1 {
        position: relative;	
        height: auto;
        top: 0;
    }    
}

// One Column
@include breakpoint(one) {
    .L-1-Three {
        // First Box
        .b1 {
            left: 0;
            width: 24em;    
        }
    }
}

// 2 columns
@include breakpoint(two) {
    .L-1-Three {      
        
        // First Box
        .b1 {
            left: 0;
            width: 48em;    
        }
    }       
}

// 3 columns
@include breakpoint(three) {
    .L-1-Three {    
        // First Box
        .b1 {
            left: 0;
            width: 72em;    
        }
    }       
}

// 4 columns
@include breakpoint(four) {
    .L-1-Three {
        // First Box
        .b1 {
            left: 0;
            width: 72em;    
        }
    }       
}

// 5 columns
@include breakpoint(five) {
    .L-1-Three {      
        // First Box
        .b1 {
            left: 0;
            width: 72em;    
        }
    }      
}

.L-1-Three {  
    overflow: visible;
    
    /*
    .box {
        overflow: visible;    
    } 
    */  
}