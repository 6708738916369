/* ==========================================================================
   custom cursor
   ========================================================================== */

#customCursor {
	position: fixed;
	top: 0;
	left: 0;
	display: none;
	cursor: none;
	z-index: 1000;
	width: 2.4em;
	height: 2.4em;
	background: #fff;
	border-radius: 1.4em;
	box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.05);
}

#customCursor span {
    @include typoZurich;
	font-size: 1.5em;
	line-height: 1.65;
	padding-left: 0.1em;
}
