// 3-5 columns
@include breakpoint(desktop) {
    .L-P-Detail {
    	// Remove top margin, because picture has much whitespace
        margin-top: -4em;
        height: 5.75 * $moduleH;     
        
        // Big Image Slideshow
        .b1 {
            top: 0;
            left: 0;
            height: 2.5 * $moduleH;  
        }   
        
        // Product title
        .b2 {
            top: 2.75 * $moduleH; 
            left: 0;			
        } 
        
        // Colors
        .b3 {
            top: 2.75 * $moduleH; 
            left: 1 * $moduleW;			
        }
        
        // price and cart
        .b4 {
            top: 2.75 * $moduleH; 
            left: 2 * $moduleW;			
        }
        
        // description
        .b5 {
            top: 4 * $moduleH; 
            left: 0;			
        }
        
        // Video
        .b6 {
            display: none;		
        }
        
        // Video Switch
        .b7 {
            display: none;			
        }
        
        // Dimensions
        .b8 {
            top: 4 * $moduleH; 
            left: 2 * $moduleW;			
        }
        
        // About Collection
        .b9 {
            top: 4 * $moduleH; 
            left: 1 * $moduleW;			
        }
        
        // Share
        .b10 {
            top: 5.25 * $moduleH; 
            left: 0 * $moduleW;
            height: 0.5 * $moduleH;
            width: 2 * $moduleW;         
        }
        
        // Existing video
        &.hasVideo {
            height: 9.25 * $moduleH;
            
            // Video
            .b6 {
                top: 5.25 * $moduleH;
                left: 0;
                display: block;
            } 
            
            // Video switch
            .b7 {
                top: 8.25 * $moduleH;
                left: 0;
                display: block;
                height: 0.5 * $moduleH;
            }
            
            // Share
            .b10 {
                top: 8.75 * $moduleH; 
            }   
        }
    }       
}

// 2 columns
@include breakpoint(two) {    
    .L-P-Detail {
        height: 5.5 * $moduleH;     
        
        // Big Image Slideshow
        .b1 {
            top: 0;
            left: 0;  
        }   
        
        // Product title
        .b2 {
            top: 2 * $moduleH; 
            left: 0;			
        } 
        
        // Colors
        .b3 {
            top: 2 * $moduleH; 
            left: 1 * $moduleW;			
        }
        
        // price and cart
        .b4 {
            top: 3 * $moduleH; 
            left: 1 * $moduleW;			
        }
        
        // description
        .b5 {
            top: 3 * $moduleH; 
            left: 0;			
        }
        
        // Video
        .b6 {
            display: none;			
        }
        
        // Video switch
        .b7 {
            display: none;			
        }
        
        // Dimensions
        .b8 {
            top: 4 * $moduleH; 
            left: 1 * $moduleW;			
        }
        
        // About collection
        .b9 {
            top: 4 * $moduleH; 
            left: 0 * $moduleW;		
        }
        
        // Sahre
        .b10 {
            top: 5 * $moduleH; 
            left: 0 * $moduleW;
            height: 0.5 * $moduleH;
            width: 2 * $moduleW;           
        }
        
        
        // Existing video
        &.hasVideo {
            height: 8 * $moduleH;
            
            // Video
            .b6 {
                top: 5 * $moduleH;
                left: 0;
                display: block;
            } 
            
            // Video switch
            .b7 {
                top: 7 * $moduleH;
                left: 0;
                display: block;
                height: 0.5 * $moduleH;
            }
            
            // Share
            .b10 {
                top: 7.5 * $moduleH; 
            }   
        } 
        
    }  
}

// 1 columns
@include breakpoint(one) {  
    .L-P-Detail {
        height: auto;       
        
        
        .b1, .b2, .b3, .b4, .b5, .b6, .b7, .b8, .b9, .b10 {
	        position: relative;
	        float: left;
	        top: 0;
	        left: 0;        
        }
        
        .b2, .b3, .b4, .b5, .b6, .b7 {
	        height: auto;
	        margin-bottom: 2em;	        
        }
        
        .b1 {
            margin-bottom: 2em;
        }
        
        .b6, .b7 {
            display: none;
        }
        
        .b10 {
            height: 0.5 * $moduleH;         
        }
        
        // Existing video
        &.hasVideo { 
            .b6, .b7, .b10 {
                position: relative;
                float: left;
                top: 0;
                left: 0;
                display: block;   
            }
            
            // Video 
            .b6 {
                height: 1 * $moduleH;
            }
            
            // Video switch
            .b7 {
                height: 0.5 * $moduleH;
            }
        } 
    }        
}