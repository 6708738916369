/* ==========================================================================
   Toolbar
   ========================================================================== */

/* Close toolbar
   ========================================================================== */
#toolbarClose {
    transition-property: opacity, top;
    transition-duration: $overlayTime;
    transition-timing-function: $overlayFunc;
    display: block;
    position: fixed;
    width: 6rem;
    height: $toolbarHeightDesktop;
    z-index: zIndex(toolbar-close);
    right: 0;
    top: -$toolbarHeightDesktop;
    text-align: center;
    opacity: 0;

    @include breakpoint(one) {
        width: 4em;
    }
}

#close {
    display: inline-block;
    margin-top: 2rem;

    .svg-ico {
        width: 2rem;
        height: 2rem;
    }

    @include breakpoint(mobile) {
        margin-top: 1rem;
    }
}



/* Toolheader States
   ========================================================================== */
.toolsheader-opened,
.notification-opened,
.overlayActive {
    #toolbarClose {
        top: 0;
        opacity: 1;
    }
}

@include breakpoint(mobile) {
    .has-visible-map {
        #toolbarClose {
            top: -$toolbarHeightDesktop;
            opacity: 0;
        }
    }
}

// Hide on ajaxload
.is-loading-ajax #toolbar {
	opacity: 0.2 !important;
}