.sitemap {
    text-align: left;
    padding-bottom: 4rem;
}

.sitemap__headline {
    @include typoZurich;
    @include typoZurich3-rem;
    margin-bottom: 0.25em;
}

.sitemap__category {
    margin-top: 4rem;

    &:first-child {
        margin-top: 2rem;
    }
}

.sitemap__category-headline  {
    @include typoZurich;
    @include typoZurich2-rem;
}

.sitemap__group-headline {
    @include typoZurich;
    @include typoZurich1-rem;
}

.sitemap__category-groups {
    margin-top: 1rem;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 2rem;
}

.sitemap__group {
    width: 20rem;
}

.sitemap__group-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.25rem;
    margin-top: 0.5rem;
}

.sitemap__group-list-link {
    @include typoZurich;
    @include typoZurich1-rem;
    text-transform: none;
}