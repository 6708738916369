.productlisting-first {
    @extend %cf;
    position: relative;
    border-top: solid 1px #000;
    border-bottom: solid 1px #000;

    .first-introduction {
        position: relative;

        h1,
        h2 {
            transform: translateY(-50%);
            position: absolute;
            display: block;
            width: 100%;
            top: 50%;
            left: 0;
            text-align: center;
            font-size: 1.4em;
        }

        @include breakpoint(five) {
            width: 2 * $moduleW;
        }

        @include breakpoint(three) {
            position: absolute;
            top: 0;
            left: 1 * $moduleW;
            z-index: 1;

            h1,
            h2 {
                transform: translateY(0);
                top: 1em;
            }
        }

        @include breakpoint(one) {
            height: $moduleH / 2;
        }
    }

    @include breakpoint(three) {
        padding-top: 4em;
    }
}
