.notification {
    box-sizing: border-box;
    position: fixed;
    top: 10rem;
    width: 1.5 * $moduleW-rem;
    background: #fff;
    box-shadow: $box-shadow--default;
    padding: 2rem;
    z-index: zIndex(notification);
    opacity: 1;
    transform: none;
    visibility: visible;
    transition: opacity $defTransTime $trans-func--default,
                transform $defTransTime $trans-func--default,
                visibility $defTransTime $trans-func--default 0s;

    * {
        box-sizing: border-box;
    }

    // Close button
    .notification__close {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        cursor: pointer;
        z-index: 3;

        .svg-ico {
            width: 1.8rem;
            height: 1.8rem;
        }
    }

    .notification__wrapper {
        display: block;
        width: 100%;
    }

    .notification__content {
        display: flex;
        align-items: center;
        text-align: left;
    }

    .notification__image {
        @include product-box(10rem, 1.2, 1.2);
        flex-shrink: 0;

        img {
            width: 100%;
            height: auto;
        }
    }

    .notification__message {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        min-height: 6rem;
        justify-content: center;

        p {
            @include typoZurich;
            @include typoZurich2-rem;
            text-transform: none;
            margin-bottom: 0;

            @include breakpoint(one) {
                @include typoZurich;
                @include typoZurich1-rem;
                text-transform: none;
            }
        }

        .sample-sale-reserved-hint {
            display: block;
            margin-top: 1rem;
        }
    }

    .notification__image + .notification__message {
        margin-left: 2rem;
        flex: 1 1 auto;
        min-height: 0;
    }

    &[aria-hidden="true"] {
        transform: translate3d(0, 1rem, 0);
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        transition: opacity $defTransTime $trans-func--default,
                    transform $defTransTime $trans-func--default,
                    visibility $defTransTime $trans-func--default $defTransTime;
    }

    // Handle proper top position
    @include breakpoint(desktop) {
        .has-small-navi & {
            top: 6rem;
        }
    }

    // Rioght position styling
    @include breakpoint(five) {
        //right: calc((100vw - (#{$moduleW-rem} * 5)) / 2);
        right: 4rem;
    }

    @include breakpoint(four) {
        //right: calc((100vw - (#{$moduleW-rem} * 4)) / 2);
        right: 4rem;
    }

    @include breakpoint(three) {
        //right: calc((100vw - (#{$moduleW-rem} * 3)) / 2);
        right: 4rem;
    }

    // General mobile styling
    @include breakpoint(mobile) {
        top: 0;
        left: 0;
        right: 0;
        width: 100%;

        &[aria-hidden="true"] {
            transform: translate3d(0, -100%, 0);
        }
    }

    // Mobile styling
    @include breakpoint(one) {
        padding: 1.5rem;

        .notification__image {
            @include product-box-responsive(8rem, 1, 1.2);
        }

        .notification__message {
            padding-right: 2rem;
        }

        .notification__image + .notification__message {
            margin-left: 1.5rem;
        }
    }
}