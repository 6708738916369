.L-3-Hero-Teaser {
    // 5 Columns
    @include breakpoint(five) {
        height: 3.5 * $moduleH-rem;

        // Extra
        .b1 {
            top: 0 * $moduleH-rem;
            left: 0 * $moduleW-rem;
        }
    }

    // 4 Columns
    @include breakpoint(four) {
        height: 3 * $moduleH;

        // Extra
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;
        }
    }

    // 3 Columns
    @include breakpoint(three) {
        height: 2.5 * $moduleH;

        // Extra
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;
        }
    }

    // Mobile
    @include breakpoint(mobile) {
        position: relative;
        text-align: center;
        height: auto;
        width: 100%;

        // FirstItem
        .b1 {
            width: 100%;
            float: none;
            position: relative;
            padding: 0;
        }
    }
}