/* SMALL BREAKPOINTS - resizing boxes */

// small module: 24(W) * 16(H) em;
//
// smallest size:	 9px 	-> 	216px (H) * 144px (H);
// biggest size: 	15px 	-> 	360px (H) * 240px (H)
//
// Inner box width and height will be smaller because of box padding for grid base


html {
    // Apply fallback fontsize
    font-size: 10px;

    @include fluid-type(font-size, 280px, 398px, 10px, 15px);
    @include fluid-type(font-size, 480px, 753px, 9px, 14.25px);
    @include fluid-type(font-size, 760px, 1112px, 9px, 13px);
    @include fluid-type(font-size, 1180px, 1540px, 10.5px, 13.5px);
    @include fluid-type(font-size, 1720px, 1900px, 12px, 13.5px);
}

/* Start */
/* Starting with a minimum box-width of 240px + 20px border on both sides = 280px minimum viewport
next step: +6px */
//@media (min-width: 280px) { 	html { 		font-size: 10.5px;	}		}
//@media (min-width: 286px) { 	html { 		font-size: 10.75px;	}		}
//
//@media (min-width: 292px) { 	html { 		font-size: 11px;	}		}
//@media (min-width: 298px) { 	html { 		font-size: 11.25px;	}		}
//@media (min-width: 304px) { 	html { 		font-size: 11.5px;	}		}
//@media (min-width: 310px) { 	html { 		font-size: 11.75px;	}		}
//
//@media (min-width: 326px) { 	html { 		font-size: 12px;	}		}
//@media (min-width: 332px) { 	html { 		font-size: 12.25px;	}		}
//@media (min-width: 338px) { 	html { 		font-size: 12.5px;	}		}
//@media (min-width: 344px) { 	html { 		font-size: 12.75px;	}		}
//
//@media (min-width: 350px) { 	html { 		font-size: 13px;	}		}
//@media (min-width: 356px) { 	html { 		font-size: 13.25px;	}		}
//@media (min-width: 362px) { 	html { 		font-size: 13.5px;	}		}
//@media (min-width: 368px) { 	html { 		font-size: 13.75px;	}		}
//
//@media (min-width: 374px) { 	html { 		font-size: 14px;	}		}
//@media (min-width: 380px) { 	html { 		font-size: 14.25px;	}		}
//@media (min-width: 386px) { 	html { 		font-size: 14.5px;	}		}
//@media (min-width: 392px) { 	html { 		font-size: 14.75px;	}		}
//
//@media (min-width: 398px) { 	html { 		font-size: 15px;	}		}
//
//
///* Big Breakpoint 1 --> 2 Spalten */
///* step: +13/+26px */
//@media (min-width: 480px) { 	html { 		font-size: 9px;		}		}
//@media (min-width: 493px) { 	html { 		font-size: 9.25px;	}		}
//@media (min-width: 506px) { 	html { 		font-size: 9.5px;	}		}
//@media (min-width: 519px) { 	html { 		font-size: 9.75px;	}		}
//
//@media (min-width: 532px) { 	html { 		font-size: 10px;	}		}
//@media (min-width: 545px) { 	html { 		font-size: 10.25px;	}		}
//@media (min-width: 558px) { 	html { 		font-size: 10.5px;	}		}
//@media (min-width: 571px) { 	html { 		font-size: 10.75px;	}		}
//
//@media (min-width: 584px) { 	html { 		font-size: 11px;	}		}
//@media (min-width: 597px) { 	html { 		font-size: 11.25px;	}		}
//@media (min-width: 610px) { 	html { 		font-size: 11.5px;	}		}
//@media (min-width: 623px) { 	html { 		font-size: 11.75px;	}		}
//
//@media (min-width: 636px) { 	html { 		font-size: 12px;	}		}
//@media (min-width: 649px) { 	html { 		font-size: 12.25px;	}		}
//@media (min-width: 662px) { 	html { 		font-size: 12.5px;	}		}
//@media (min-width: 675px) { 	html { 		font-size: 12.75px;	}		}
//
//@media (min-width: 688px) { 	html { 		font-size: 13px;	}		}
//@media (min-width: 701px) { 	html { 		font-size: 13.25px;	}		}
//@media (min-width: 714px) { 	html { 		font-size: 13.5px;	}		}
//
//
//@media (min-width: 727px) { 	html { 		font-size: 13.75px;	}		}
//
//@media (min-width: 740px) { 	html { 		font-size: 14px;	}		}
//@media (min-width: 753px) { 	html { 		font-size: 14.25px;	}		}
//
///* Big Breakpoint 2 --> 3 Spalten  */
///* step: +22px */
//@media (min-width: 760px) { 	html { 		font-size: 9px;		}		}
//@media (min-width: 782px) { 	html { 		font-size: 9.25px;	}		}
//@media (min-width: 804px) { 	html { 		font-size: 9.5px;	}		}
//@media (min-width: 826px) { 	html { 		font-size: 9.75px;	}		}
//
//@media (min-width: 848px) { 	html { 		font-size: 10px;	}		}
//@media (min-width: 870px) { 	html { 		font-size: 10.25px;	}		}
//@media (min-width: 892px) { 	html { 		font-size: 10.5px;	}		}
//@media (min-width: 914px) { 	html { 		font-size: 10.75px;	}		}
//
//@media (min-width: 936px) { 	html { 		font-size: 11px;	}		}
//@media (min-width: 958px) { 	html { 		font-size: 11.25px;	}		}
//@media (min-width: 980px) { 	html { 		font-size: 11.5px;	}		}
//@media (min-width: 1002px) { 	html { 		font-size: 11.75px;	}		}
//
//@media (min-width: 1024px) { 	html { 		font-size: 12px;	}		}
//@media (min-width: 1046px) { 	html { 		font-size: 12.25px;	}		}
//@media (min-width: 1068px) { 	html { 		font-size: 12.5px;	}		}
//@media (min-width: 1090px) { 	html { 		font-size: 12.75px;	}		}
//
//@media (min-width: 1112px) { 	html { 		font-size: 13px;	}		}
///*
//@media (min-width: 1134px) { 	html { 		font-size: 13.25px;	}		}
//@media (min-width: 1156px) { 	html { 		font-size: 13.5px;	}		}
//*/
//
///* Big Breakpoint 3 --> 4 Spalten  */
///* step: +30/+60px / slightly bringing the border up to 10% */
//@media (min-width: 1180px) { 	html { 		font-size: 10.5px;	}		}
//@media (min-width: 1210px) { 	html { 		font-size: 10.75px;	}		}
//
//@media (min-width: 1240px) { 	html { 		font-size: 11px;	}		}
//@media (min-width: 1270px) { 	html { 		font-size: 11.25px;	}		}
//@media (min-width: 1300px) { 	html { 		font-size: 11.5px;	}		}
//@media (min-width: 1330px) { 	html { 		font-size: 11.75px;	}		}
//
//@media (min-width: 1360px) { 	html { 		font-size: 12px;	}		}
//@media (min-width: 1390px) { 	html { 		font-size: 12.25px;	}		}
//@media (min-width: 1420px) { 	html { 		font-size: 12.5px;	}		}
//@media (min-width: 1450px) { 	html { 		font-size: 12.75px;	}		}
//
//@media (min-width: 1480px) { 	html { 		font-size: 13px;	}		}
//
//@media (min-width: 1510px) { 	html { 		font-size: 13.25px;	}		}
//@media (min-width: 1540px) { 	html { 		font-size: 13.5px;	}		}
///*
//@media (min-width: 1570px) { 	html { 		font-size: 13.75px;	}		}
//
//@media (min-width: 1600px) { 	html { 		font-size: 14px;	}		}
//
//@media (min-width: 1630px) { 	html { 		font-size: 14.25px;	}		}
//@media (min-width: 1660px) { 	html { 		font-size: 14.5px;	}		}
//@media (min-width: 1690px) { 	html { 		font-size: 14.75px;	}		}
//*/
//
///* Big Breakpoint 4 --> 5 Spalten  */
///* step: +30/+60px */
//@media (min-width: 1720px) { 	html { 		font-size: 12px;	}		}
//@media (min-width: 1750px) { 	html { 		font-size: 12.25px;	}		}
//@media (min-width: 1780px) { 	html { 		font-size: 12.5px;	}		}
//@media (min-width: 1810px) { 	html { 		font-size: 12.75px;	}		}
//
//@media (min-width: 1840px) { 	html { 		font-size: 13px;	}		}
//@media (min-width: 1870px) { 	html { 		font-size: 13.25px;	}		}
//@media (min-width: 1900px) { 	html { 		font-size: 13.5px;	}		}
///*
//@media (min-width: 1930px) { 	html { 		font-size: 13.75px;	}		}
//
//@media (min-width: 1960px) { 	html { 		font-size: 14px;	}		}
//
//@media (min-width: 1990px) { 	html { 		font-size: 14.25px;	}		}
//@media (min-width: 2020px) { 	html { 		font-size: 14.5px;	}		}
//@media (min-width: 2050px) { 	html { 		font-size: 14.75px;	}		}
//
//@media (min-width: 2080px) { 	html { 		font-size: 15px;	}		}
//*/



