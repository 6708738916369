$_h-padding--desktop: 2rem;
$_v-padding--desktop: 3rem;

$_h-padding--mobile: 2rem;
$_v-padding--mobile: 1.5rem;

.navigation-dialog {
    position: fixed;
    top: 0;
    left: -100vw;
    width: 1.75 * $moduleW-rem;
    background: #fff;
    text-align: left;
    z-index: zIndex(navigation-dialog);
    box-shadow: $box-shadow--default;
    box-sizing: border-box;
    opacity: 1;
    transform: none;
    visibility: visible;
    transition: opacity $trans-time--default $trans-func--default,
                transform $trans-time--default $trans-func--default,
                visibility $trans-time--default $trans-func--default 0s;

    // Close button
    .navigation-dialog__close {
        position: absolute;
        top: 1.5rem;
        right: 2rem;
        cursor: pointer;
        z-index: 3;

        .svg-ico {
            width: 1.8rem;
            height: 1.8rem;
        }
    }

    // Scrollable wrapper
    .navigation-dialog__wrapper {
        overflow: auto;
        overflow-x: hidden;
        overscroll-behavior: contain;
        -webkit-overflow-scrolling: touch;
        max-height: 55vh;
        min-height: 12rem;

        @media screen and (orientation: landscape) and (max-height: 1400px) {
            max-height: 65vh;
        }

        @media screen and (orientation: landscape) and (max-height: 1200px) {
            max-height: 75vh;
        }

        @media screen and (orientation: landscape) and (max-height: 800px) {
            max-height: 85vh;
        }

        @media screen and (orientation: portrait) {
            max-height: 85vh;
        }
    }

    // General typography
    p {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
    }

    .navigation-dialog__content {
        padding: 0 $_h-padding--desktop;
        opacity: 1;
        transform: none;
        transition: opacity $trans-time--default $trans-func--default,
                    transform $trans-time--default $trans-func--default;
    }

    // Header
    .navigation-dialog__header {
        padding-top: $_v-padding--desktop;
        padding-bottom: 1rem;

        h3 {
            @include typoZurich;
            @include typoZurich2-rem;
        }

        .navigation-dialog_header-subline {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
        }

        &.is-sticky {
            @include fade-cover(bottom, 1rem);
            position: sticky;
            top: 0;
            background: #fff;
            z-index: 2;
        }
    }

    // Body
    .navigation-dialog__body {
        margin-top: 1rem;
    }

    // Footer / Action
    .navigation-dialog__action,
    .navigation-dialog__footer {
        position: relative;
        margin-top: 2rem;
        padding-bottom:  $_v-padding--desktop;

        &.is-sticky {
            @include fade-cover(top, 2rem);
            position: sticky;
            bottom: 0;
            background: #fff;
            z-index: 2;
        }
    }

    // Loader
    .navigation-dialog__loader {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 32px;
        height: 32px;
        margin: -16px 0 0 -16px;
        opacity: 0;
        pointer-events: none;
        background: transparent url('../images/icons/loader_32px_t.gif') 50% 50% no-repeat;
        background-size: 32px 32px;
        transition: opacity $trans-time--default $trans-func--default;
    }

    // States
    &[aria-busy="true"] {
        .navigation-dialog__loader {
            opacity: 1;
        }
    }

    &[aria-busy="true"].is-loaded {
        .navigation-dialog__content {
            opacity: 0.2;
            pointer-events: none;
        }
    }

    &:not(.is-loaded) {
        .navigation-dialog__content {
            opacity: 0;
        }
    }

    &[aria-hidden="true"] {
        transform: translate3d(0, 1rem, 0);
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        transition: opacity $trans-time--default $trans-func--default,
                    transform $trans-time--default $trans-func--default,
                    visibility $trans-time--default $trans-func--default $trans-time--default;
    }

    // Hide for mobile
    @include breakpoint(mobile) {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;

        .navigation-dialog__content {
            padding: 0 $_h-padding--mobile;
        }

        // Header
        .navigation-dialog__header {
            padding-top: $_v-padding--mobile;
        }

        // Footer / Action
        .navigation-dialog__action,
        .navigation-dialog__footer {
            padding-bottom:  $_v-padding--mobile;
        }

        .navigation-dialog__wrapper {
            max-height: 100%;
        }
    }
}

// Backdrop
.navigation-dialog-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: zIndex(navigation-dialog-backdrop);
    background: none;
    pointer-events: none;
    visibility: hidden;

    .navigation-dialog[aria-hidden="false"] + & {
        pointer-events: all;
        visibility: visible;
        cursor: pointer;
    }

    // No backdrop needed on mobile
    @include breakpoint(mobile) {
        display: none;
    }
}