/* ==========================================================================
   Main Type
   ========================================================================== */

h1, h2, h3, h4 {
    margin: 0;
    padding: 0;
    font-weight: normal;
}

h1 {
    @include typoZurichBd;
    @include typoZurich1-rem;
    @include breakpoint(two) {
        @include typoZurich3-rem;
    }
    @include breakpoint(threefive) {
        @include typoZurich4-rem;
    }
}

h2 {
    @include typoZurichBd;
    @include typoZurich1-rem;

    &.rem-based {
        @include typoZurich1-rem;
        margin-bottom: 0.5rem;
    }
}

h3, h4 {
    @include typoZurich;
    @include typoZurich1-rem;
}

h2.border {
    @include typoZurich;
    @include typoZurich1-rem;
    line-height: 2em;
    border-bottom: solid 1px #000;
    margin-bottom: 0.75em;
}

a .black, .black, h3.black, .imgBox a .black, .imageTeaser a .black {
    color: #000;
}

p {
    text-transform: none;
    margin: 0 0 1em 0;
}

.not-defined,
.not-available {
    color: $text-color--light-1;
}

.textLink {
    @include typoZurich;
    @include typoZurich1-rem;
    line-height: 1;
    text-transform: none;
    border-color: #000;
    display: inline-block;
}

.text-link {
    @include typoZurich;
    @include typoZurich1-rem;
    text-transform: none;
    line-height: 1.1;
    border-color: #000;
    display: inline-block;
}

.link-underlined {
    @include typoZurich;
    @include typoZurich1-link-rem;

    transition: color $fastTransTime $defTransFunc,
                border $fastTransTime $defTransFunc;

    display: inline-block;
    border-bottom: solid 1px black;
    padding: 0;
    text-decoration: none;

    .no-touch & {
        &:hover {
            color: rgb(150, 150, 150);
            border-color: rgb(150, 150, 150);
        }
    }

    .has-inverted-color-scheme & {
        color: #fff;
        border-color: currentColor;
    }
}

.content-link {
    transition: color $fastTransTime $defTransFunc,
                border $fastTransTime $defTransFunc;
    font-family: inherit;
    font-size: 1em;
    display: inline;
    border-bottom: solid 1px black;
    padding: 0;
    text-decoration: none;

    &.content-link--mycare {
        //color: $text-color--mycare;
        //border-color: $text-color--mycare;
    }

    .no-touch & {
        &:hover {
            color: rgb(75, 75, 75);
            border-color: rgb(75, 75, 75);
        }

        &.content-link--mycare:hover {
            //color: darken($text-color--mycare, 10);
            //border-color: darken($text-color--mycare, 10);
        }
    }

    .has-inverted-color-scheme & {
        color: #fff;
        border-color: currentColor;
    }
}

.mykita-mm6-logo {
    font-family: 'ZurichMM6-Light';
}

p .textLink,
span > .textLink,
li > .textLink,
p .text-link,
span > .text-link,
li > .text-link {
    font-size: 1.0em;
}

th {
    @include typoZurich;
    @include typoZurich1-rem;
}

td {
    @include typoZurich;
    @include typoZurich1-rem;
    text-transform: none;
}

i.break {
    display: none;
}

@include breakpoint(one) {
    i.break.br1 {
        display: block;
    }
}

@include breakpoint(two) {
    i.break.br2 {
        display: block;
    }
}

@include breakpoint(three) {
    i.break.br3 {
        display: block;
    }
}

@include breakpoint(four) {
    i.break.br4 {
        display: block;
    }
}

@include breakpoint(five) {
    i.break.br5 {
        display: block;
    }
}

.typoExample {
    margin-bottom: 4em;
    position: relative;
    padding: $border;
    @extend .clear;

    .typoAsHeadline,
    .typoAsText {
        display: block;
        margin-bottom: 20px;
    }

    h2 {
        margin-bottom: 5px;
    }

    h4 {
        margin-bottom: 20px;
        border-bottom: solid 1px black;
        @extend .clear;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            float: left;
            display: inline-block;
        }

        li:before {
            content: '\2f';
            text-align: center;
            display: inline-block;
            width: 3em;
        }

        li:first-child:before {
            content: '';
            display: none;
        }
    }

    .typoLabel,
    .typoValue {
        display: inline-block;
    }
}

// Legal text adjustments
.legal-text-container,
.service .legal-text-container,
.textItem.isHtml .legal-text-container,
.textItem.isHtml:not(.filter-product-text):not(.large-intro-text):not(.large-intro-text-expandable):not(.large-intro-text-studio) .legal-text-container {
    $main-section-indent: 0;
    $main-section-indent--mobile: 2rem;
    $legal-ol-indent: 0;
    $legal-ul-indent: 2rem;

    h1 {
        margin: 1em 0 0 0;
    }

    h2 {
        margin: 2em 0 0 0;

        em {
            font-style: inherit;
            font-size: inherit;
            display: inline;
        }
    }

    h3 {
        margin-top: 1em;
        display: inline-block;
    }

    p {
        margin: 1em 0 0 0;
    }

    ol {
        list-style-type: none;
        counter-reset: legal-section-ol;
        margin: 0 0 0 $main-section-indent;
        padding-left: $legal-ol-indent;

        & > li {
            position: relative;
            text-align: left;
            padding-right: 1rem;
        }

        & > li + li {
            margin-top: 2rem;
        }

        & > li:before {
            //@include typoZurichBd;
            //@include typoZurich1-rem;
            position: relative;
            counter-increment: legal-section-ol;
            content: counter(legal-section-ol) ".";

        }
    }

    ul {
        & > li {
            position: relative;
            display: block;
            padding-left: $legal-ul-indent;
            padding-right: 0;
        }

        & > li:before {
            position: absolute;
            top: 0;
            left: 0;
            content: '\2f';
            text-align: left;
            display: inline-block;
            text-indent: 0;
        }

        & > li p:only-child {
            margin: 0;
        }
    }

    @include breakpoint(one) {
        h2 em {
            width: $main-section-indent--mobile;
        }

        ol {
            margin-left: 0;
        }
    }
}

.black-friday-mycare-hint {
    @include typoZurich;
    @include typoZurich1-rem;
    text-transform: none;
    color: $text-color--mycare;

    .content-link {
        color: currentColor;
        border-color: currentColor;
    }
}

.mycare-text-highlight,
.text-teaser.text-teaser--startpage .mycare-text-highlight,
.text-teaser.text-teaser--startpage-large .mycare-text-highlight,
.mycare-text-link,
.text-teaser.text-teaser--startpage .mycare-text-link,
.text-teaser.text-teaser--startpage-large .mycare-text-link {
    color: $text-color--mycare;
    border-color: $text-color--mycare;
}

.head-nav-dropdown__item-link.head-nav-dropdown__item-link--sample-sale,
.navigation-overlay__sub-navigation-item-link.navigation-overlay__sub-navigation-item-link--sample-sale {
    color: $text-color--sample-sale;
    border-color: $text-color--sample-sale;
}