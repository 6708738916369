.navigation-dialog-cart {
    .listing {
        @include pseudo-list;
    }

    .listing__item {
        display: block;
        border-top: solid 1px $border-color-dark;
        padding-top: 1rem;
        opacity: 0;
        transform: translate3d(0, 1rem, 0);
        transition: opacity $trans-time--default $trans-func--easing,
                    transform $trans-time--default $trans-func--easing;
        @include transition-delay(0.1s, 0s, 20);

        &:not(:first-child) {
            margin-top: 1rem;
        }

        .is-loaded & {
            opacity: 1;
            transform: none;
        }

        &.is-removed {
            opacity: 0;
        }

        &[hidden] {
            display: none;
        }
    }

    .listing__article {
        display: flex;
        opacity: 1;
        transition: opacity $trans-time--default $trans-func--easing;

        &:hover {
            //opacity: 0.6;
        }
    }

    // Image
    .listing__article-image {
        @include product-box(12rem, 1.2, 1.2);
        flex-shrink: 0;

        .ie &,
        .edge & {
            border: solid 1px $border-color--light-1;
        }
    }

    // Information
    .listing__article-info {
        flex: 1 1 auto;
        margin-left: 1rem;
    }

    .listing__article-main {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .listing__article-name {
        @include typoZurich;
        @include typoZurich2-rem;
        display: inline-block;
        flex: 1 1 auto;
    }

    .listing__article-price {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        display: inline-block;
        width: 8rem;
        text-align: right;

        &.is-samplesale {
            span {
                display: block;

                &:first-child {
                    color: $text-color--light-1;
                    text-decoration: line-through;
                }
            }
        }
    }

    .listing__article-variant {
        display: flex;
        align-items: flex-start;
        margin-top: 2rem;

        p {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
            margin-bottom: 0;
            flex: 1 1 auto;
        }
    }

    .listing__article-quantity {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 5rem;

        .quantity-label {
            @include typoZurich;
            @include typoZurich1-rem;
            display: inline-block;
            line-height: 1;
        }

        .btn--quantity {
            display: inline-block;
            height: 1.5rem;
            padding-top: 0;

            &:not([disabled]) {
                cursor: pointer;
            }

            &[disabled] {
                cursor: not-allowed;
            }

            &[data-action="decrease-quantity"] {
                margin-right: 0.75rem;
            }

            &[data-action="increase-quantity"] {
                margin-left: 0.75rem;
            }

            .svg-ico {
                width: 1.2rem;
                height: 1.2rem;
            }
        }
    }

    .listing__article-dismiss {
        margin-left: 4rem;

        .svg-ico {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    // Summary
    .summary {
        border-top: solid 1px $border-color-dark;
        padding: 1rem 0 2rem 0;
    }

    .summary__section--total {
        margin-top: 1rem;

        dt,
        dd {
            @include typoZurichBd;
            text-transform: none;
        }
    }

    dl {
        @include clearfix;
        margin: 0;

        dt {
            display: inline-block;
            float: left;
            margin: 0;
            max-width: 70%;
        }

        dd {
            display: inline-block;
            float: right;
            margin: 0;
        }

        dt,
        dd {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
        }
    }

    @include breakpoint(one) {
        .listing__article {
            flex-direction: column;
        }

        .listing__article-image {
            width: 100%;
            height: 24rem * 0.6;

            img {
                transform: scale(1.1);
            }
        }

        .listing__article-info {
            margin-left: 0;
            margin-top: 1rem;
        }

        .listing__article-variant {
            margin-top: 1rem;
        }
    }

    // Empty cart
    .cart-empty {
        border-top: solid 1px $border-color-dark;
        padding: 1rem 0 3rem 0;

        h4 {
            @include typoZurich;
            @include typoZurich2-rem;
        }

        .cart-empty__teaser {
            margin-top: 1.5rem;
            text-align: center;

            .box {
                position: relative;
                padding: 0 0 66.66% 0;
                width: 100%;
                height: auto;
                float: none;
            }

            .module {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
    }
}

// User login inside cart
.navigation-dialog-cart-user {
    display: block;
    margin-top: 1rem;

    ul {
        @include pseudo-list;
        display: flex;
        flex-wrap: wrap;
    }

    li {
        display: inline-block;

        &:not(:first-child) {
            margin-left: 2rem;
        }
    }

    .navigation-dialog-cart-user__link {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;

        &.navigation-dialog-cart-user__link--secondary {
            color: $text-color--light-1;
        }
    }
}