.head-nav {
    box-sizing: border-box;
    transition: background $trans-time--fast $trans-func--default,
                color $trans-time--fast $trans-func--default,
                transform $trans-time--fast $trans-func--default;

    * {
        box-sizing: border-box;
    }

    @include breakpoint(desktop) {
        position: relative;
        background: transparent;
    }

    @include breakpoint(mobile) {
        position: fixed;
        display: flex;
        align-items: center;
        left: 0;
        width: 50%;
        z-index: zIndex(header);
        bottom: 0;
        height: $header-height--mobile;
        background: #fff;
        --text-color: #000;

        @include hide-mobile-nav;
    }

    @include breakpoint(two) {
        padding-left: calc((100% - #{2 * $moduleW-rem}) / 2);
    }

    @include breakpoint(one) {
        padding-left: calc((100% - #{$moduleW-rem}) / 2);
    }
}

.head-nav__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 1.25rem;
}

.head-nav__item {
    @include breakpoint(mobile) {
        display: none;

        &:nth-child(1),
        &:nth-child(2) {
            display: inline-block;
        }
    }
}

.head-nav__item-link {
    color: var(--text-color, $text-color--default);
    transition: color $trans-time--fast $trans-func--default;
    display: grid;
    place-content: start;

    > * {
        @include typoZurich;
        @include typoZurich2-rem;
        grid-area: 1 / 1;
        transition: opacity 0.1s $trans-func--default;
    }

    .head-nav__item-link-text--bold {
        @include typoZurichBd;
        opacity: 0;
    }

    &:hover,
    .head-nav__item[aria-selected="true"] & {
        .head-nav__item-link-text--default {
            opacity: 0;
        }

        .head-nav__item-link-text--bold {
            opacity: 1;
        }
    }
}

// Dropdown
.header__container--dropdown {
    margin-top: -1.8rem;
    z-index: 5;
    pointer-events: none;

    .has-expanded-subnav & {
        pointer-events: all;
    }

    @include breakpoint(mobile) {
        display: none;
    }
}

.head-nav-dropdowns {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.head-nav-dropdowns__list {
    width: 100%;
    display: grid;
    list-style-type: none;

    > * {
        grid-area: 1 / 1;
    }
}

.head-nav-dropdowns__item {
    width: 100%;
    transition: opacity $trans-time--fast $trans-func--default;
    overflow: hidden;

    &[aria-expanded="false"] {
        opacity: 0;
        pointer-events: none;
    }
}

.head-nav-dropdown {
    padding: 3rem 0 4rem 0;
    height: 100%;
    display: flex;
    gap: 8rem;
    align-items: flex-start;
    justify-content: flex-start;

    @include breakpoint(three) {
        gap: 4rem;
    }
}

.head-nav-dropdown__nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.head-nav-dropdown__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.25rem;
}

.head-nav-dropdown__group-label + .head-nav-dropdown__list {
    margin-top: 0.5rem;
}

.head-nav-dropdown__item {
    text-align: left;
}

.head-nav-dropdown__group-label,
.head-nav-dropdown__item-link {
    @include typoZurich;
    @include typoZurich2-rem;
}

.head-nav-dropdown__item-link {
    text-transform: none;
}

// Teaser
.head-nav-dropdown__teaser-group {
    display: flex;
    margin-left: auto;
    align-items: flex-start;
    gap: 2rem;

    @include breakpoint(four) {
        .head-nav-dropdown__teaser:nth-child(3) {
            display: none;
        }
    }

    @include breakpoint(three) {
        .head-nav-dropdown__teaser:nth-child(2),
        .head-nav-dropdown__teaser:nth-child(3) {
            display: none;
        }
    }
}

.head-nav-dropdown__teaser {
    display: inline-block;
}

.head-nav-dropdown__teaser-image {
    width: 20rem;
    padding-bottom: 100%;
    position: relative;

    > * {
        position: absolute;
        inset: 0 0 0 0;
    }

    .media-box figure img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
    }

    @include breakpoint(three) {
        width: 16rem;
    }
}

.head-nav-dropdown__teaser-title {
    @include typoZurich;
    @include typoZurich2-rem;
    text-transform: none;
    text-align: left;
    margin: 0.75rem 0 0 0;
}

.head-nav-dropdown__teaser-description {
    @include typoZurich;
    @include typoZurich1-rem;
    text-transform: none;
    margin: 0;
    text-align: left;
}