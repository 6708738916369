$notification-bar-height: 8rem;
$notification-bar-height-cookie--mobile: 11rem;

.notification-bar {
    transition: transform $overlayTime $overlayFunc, height $overlayTime $overlayFunc;
    transform: translate3d(0, -100%, 0);
    position: fixed;
    z-index: zIndex(notification-bar);
    top: 0;
    left: 0;
    width: 100%;
    height: $notification-bar-height;
    overflow: hidden;
    background-color: $backGrey;

    .notification-bar-wrapper {
        transition-property: opacity, visibility;
        transition-duration: $toolsHeaderTime, 0s;
        transition-timing-function: $toolsHeaderFunc;
        transition-delay: 0s, $toolsHeaderTime;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
    }

    .notification-bar-inner {
        position: relative;
        height: 100%;
        text-align: left;
    }

    .valign {
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
    }

    span {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        display: inline-block;
        max-width: 90%;
    }

    .notification-opened & {
        transform: translate3d(0, 0, 0);
    }

    .notification-cookie & {
        @include breakpoint(mobile) {
            height: $notification-bar-height-cookie--mobile;
        }
    }
}

// Pseudoclasses
%notification-module-active {
    transition-property: opacity;
    transition-delay: 0s;
    visibility: visible;
    opacity: 1;
}

// Message is opened
.notification-message {
    .notification-bar-module-message {
        @extend %notification-module-active;
    }
}

// Cookie is opened
.notification-cookie {
    .notification-bar-module-cookie {
        @extend %notification-module-active;
    }
}

.notification-opened {
    #header,
    #filter,
    #wrapper footer,
    .siteContainer {
        transform: translate3d(0, $notification-bar-height, 0);
    }

    &.notification-cookie {
        @include breakpoint(mobile) {
            #header,
            #filter,
            #wrapper footer,
            .siteContainer {
                transform: translate3d(0, $notification-bar-height-cookie--mobile, 0);
            }
        }
    }
}