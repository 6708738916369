/* ==========================================================================
   Content Typo
   ========================================================================== */

/**
 * Zuerich
 */

@mixin typoZ {
	font-family: ZurichBT-Light, Arial, Helvetica, sans-serif;
}

// Zurich Light
@mixin typoZurich {
	font-family: ZurichBT-Light, Arial, Helvetica, sans-serif;
	text-transform: uppercase;
	font-weight: 100;
}

// Zurich Regular
@mixin typoZurichReg {
	font-family: ZurichBT-Roman, Arial, Helvetica, sans-serif;
	text-transform: uppercase;
	font-weight: 100;
}

// Zurich Bold
@mixin typoZurichBd {
	font-family: ZurichBT-Bold, "Arial Black", Gadget, sans-serif;
	text-transform: uppercase;
	font-weight: 100;
}

// small sizes

// tiny Zurich for half small elements -> mobile
@mixin typoZurichS-rem {
    font-size: 0.8rem;
    line-height: 1.5;
    letter-spacing: 0;
}

// tiny Zurich for half small elements -> mobile
@mixin typoZurichS2-rem {
    font-size: 0.9rem;
    line-height: 1.5;
    letter-spacing: 0;
}

// tiny Zurich // smaller button style
@mixin typoZurich0-rem {
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0;
}

// Small Zurich
@mixin typoZurich1-rem {
	font-size: 1.1rem;
	line-height: 1.5;
	letter-spacing: 0;
}

@mixin typoZurich1-link-rem {
	font-size: 1.1rem;
	line-height: 1.1;
	letter-spacing: 0;
    text-transform: none;
}

// Small Zurich
@mixin typoZurich1-1-rem {
    font-size: 1.2rem;
    line-height: 1.5em;
    letter-spacing: 0;
}

// Medium Zurich
@mixin typoZurich2-rem {
	font-size: 1.35rem;
	line-height: 1.5;
	letter-spacing: 0;
}

// Big Zurich
@mixin typoZurich3-rem {
    font-size: 1.75rem;
    line-height: 1.5;
    letter-spacing: 0;
}

@mixin typoZurich3-rem-studio {
    font-size: 2.7rem;
    line-height: 1.5;
    letter-spacing: 0;
}

// Extra Zurich
@mixin typoZurich4-rem {
    font-size: 2.4rem;
    line-height: 1.5;
    letter-spacing: 0;
}

@mixin typoZurich4-rem-studio {
    font-size: 3.5rem;
    line-height: 1.5;
    letter-spacing: 0;
}

// Mylon Zurich
@mixin typoZurich5-rem {
    font-size: 3.5rem;
    line-height: 1.5em;
    letter-spacing: 0;
}

@mixin typoZurich5-rem-studio {
    font-size: 4.5rem;
    line-height: 1.5em;
    letter-spacing: 0;
}

@mixin typoZurich6-rem {
    font-size: 4.5rem;
    line-height: 1.5em;
    letter-spacing: 0;
}



