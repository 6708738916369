// 5 columns
@include breakpoint(five) {
    .L-4-9-2 {
        height: 4 * $moduleH;        
        
        // tower
        .b1 {
            top: 0 * $moduleH;
            left: 1 * $moduleW;  
        }
        
        // Medium
        .b2 {
            top: 0 * $moduleH;
            left: 2 * $moduleW;    
        }
        
        // Medium
        .b3 {
            top: 2 * $moduleH;
            left: 1 * $moduleW;    
        }
        
        // Tower
        .b4 {
            top: 2 * $moduleH;
            left: 3 * $moduleW;    
        }
    }       
}

// 4 columns
@include breakpoint(four) {
    .L-4-9-2 {
        height: 4 * $moduleH;        
        
        // tower
        .b1 {
            top: 0 * $moduleH;
            left: 0.5 * $moduleW;  
        }
        
        // Medium
        .b2 {
            top: 0 * $moduleH;
            left: 1.5 * $moduleW;    
        }
        
        // Medium
        .b3 {
            top: 2 * $moduleH;
            left: 0.5 * $moduleW;    
        }
        
        // Tower
        .b4 {
            top: 2 * $moduleH;
            left: 2.5 * $moduleW;    
        }
    }       
}

// 3 columns
@include breakpoint(three) {
    .L-4-9-2 {
        height: 4 * $moduleH;        
        
        // tower
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;  
        }
        
        // Medium
        .b2 {
            top: 0 * $moduleH;
            left: 1 * $moduleW;    
        }
        
        // Medium
        .b3 {
            top: 2 * $moduleH;
            left: 0 * $moduleW;    
        }
        
        // Tower
        .b4 {
            top: 2 * $moduleH;
            left: 2 * $moduleW;    
        }
    }       
}

// 2 columns
@include breakpoint(two) {
    .L-4-9-2 {
        height: 6 * $moduleH;        
        
        // tower
        .b1 {
            top: 2 * $moduleH;
            left: 0 * $moduleW;  
        }
        
        // Medium
        .b2 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;    
        }
        
        // Medium
        .b3 {
            top: 4 * $moduleH;
            left: 0 * $moduleW;    
        }
        
        // Tower
        .b4 {
            top: 2 * $moduleH;
            left: 1 * $moduleW;    
        }
    }       
}

// 1 columns
@include breakpoint(one) {
    .L-4-9-2 {
        height: 6 * $moduleH;        
        
        // tower
        .b1 {
            top: 1 * $moduleH;
            left: 0 * $moduleW;  
        }
        
        // Medium
        .b2 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;    
        }
        
        // Medium
        .b3 {
            top: 5 * $moduleH;
            left: 0 * $moduleW;    
        }
        
        // Tower
        .b4 {
            top: 3 * $moduleH;
            left: 0 * $moduleW;    
        }
    }          
}
