/* ==========================================================================
   Main Setup
   ========================================================================== */


/* Basic normalize
   ========================================================================== */
html, a, div, select, input { outline: none; }

html {
    // Fix for safari bug: Permanent grid scale fails
    // https://css-tricks.com/snippets/css/fluid-typography/#comment-1753016
    &.is-safari {
        min-height: 1vw; // 0vw gets stripped in production build
    }
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: ZurichBT-Light, Arial, Helvetica, sans-serif;
    font-weight: 100;
    color: #000;
    text-align: center;
    background-color: #fff;
    overflow-y: scroll;
    overflow-x: hidden;
    /*
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    */
}

a {
    text-decoration: none;
}

img, iframe, input {
    border:none;
}

ul, ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

.textLink,
label a,
a.textLink,
a.text-link {
    display: inline-block;
    border-bottom: solid 1px black;
    padding: 0;
    text-decoration: none;
    line-height: 1.1em;

    &.text-link--multi {
        display: inline;
    }
}

.hairlines {
    .textLink,
    .text-link,
    .link-underlined,
    .content-link {
        border-width: 0.5px;
    }
}

/* Container & Wrapper
   ========================================================================== */
#wrapper {
    min-height: 100vh;
    display: flex;
    height: 100%;
    flex-direction: column;
}

.outer-wrapper--checkout #wrapper {
    min-height: 100vh;
}

.outer-wrapper {
    box-sizing: border-box;
    max-width: 100%;
    overflow: visible;
    position: relative;
    width: 100%;

    .has-filter &,
    .ctl-note &,
    .ctl-detail & {
        overflow: visible;
    }
}

// Default 5 column Mykita Container
.mykitaContainer,
.mykita-container {
    @extend %cf;
    width: 24rem; /* mobile first approach */
    margin: auto;
    position: relative;
    text-align: center;

    @include breakpoint(two) {
  		  width: 48rem;
  	}

    @include breakpoint(three) {
  		  width: 72rem;
  	}

    @include breakpoint(four) {
  		  width: 96rem;
  	}

    @include breakpoint(five) {
  		  width: 120rem;
  	}
}

// Small 3 Column Mykita Container
//.overlayContainer .mykitaContainer,
.mykitaSmallContainer,
.mykita-container--small {
    @extend %cf;
    width: 24rem; /* mobile first approach */
    margin: auto;
    position: relative;
    text-align: center;

    @include breakpoint(two) {
        width: 48rem;
    }

    @include breakpoint(three) {
        width: 72rem;
    }

    @include breakpoint(four) {
        width: 72rem;
    }

    @include breakpoint(five) {
        width: 72rem;
    }
}

// Ohh we have heros again ^^
.mykitaHeroContainer {
    @extend %cf;
    width: 100%;
    height: auto;
    margin: 0;
    position: relative;
    text-align: center;
    display: block;
}

.siteContainer {
    position: relative;
    z-index: 1;
    padding-top: 0;
    flex: 1 1 auto;

    @include breakpoint(desktop) {
        .has-global-banner:not(.is-startpage) & {
            margin-top: $global-banner-height--desktop;
        }
    }

    @include breakpoint(mobile) {
        .has-global-banner:not(.is-startpage) & {
            margin-top: $global-banner-height--mobile;
        }
    }
}

@keyframes site-container-scroll-animation {
    0% {
        margin-top: 0;
    }

    50% {
        margin-top: -4rem;
    }

    100% {
        margin-top: 0;
    }
}


.siteContainer,
footer {
    transition: left $overlayTime $overlayFunc,
                transform $overlayTime $overlayFunc,
                opacity 0.3s $defTransFunc,
                padding $overlayTime $overlayFunc,
                margin $overlayTime $overlayFunc;

    @include breakpoint(desktop) {
        .has-global-banner & {
            transition: left $overlayTime $overlayFunc,
                        transform $overlayTime $overlayFunc,
                        margin $overlayTime $overlayFunc,
                        opacity 0.3s $defTransFunc;
        }
    }
}


/* sections */

.layout {
    @extend %cf;
    display: block;

    &.published {
        box-shadow: inset 0 0 0 1px $colorPublished;
    }

    &.published-time {
        box-shadow: inset 0 0 0 1px $colorPublishedTime;
    }

    &.unpublished {
        box-shadow: inset 0 0 0 1px $colorUnpublished;
    }
}

section {
	position: relative;
}

.fond {
    padding: 6rem 0;

    @supports (--css: variables) {
        background-color: var(--bg-color, transparent);
        color: var(--text-color, #{$text-color--default});
    }

    @include breakpoint(two) {
        padding: 4rem 0;
    }

    @include breakpoint(one) {
        padding: 3rem 0;
    }

    &:has(#mykita-rimowa-signup) {
        @include breakpoint(desktop) {
            padding: 0;
        }
    }
}

// spacing between sections

.noSpace {
    margin-bottom: 0; /* no padding */
}

.quarterSpace {
    margin-bottom: 4rem; /* half height of a quarter */

    @include breakpoint(one) {
        margin-bottom: 2rem;
    }
}

.quarterSpaceMobile {
    margin-bottom: 4rem; /* half height of a quarter */

    @include breakpoint(one) {
        margin-bottom: 0;
    }
}

.quarterSpaceTop {
    margin-top: 4rem; /* half height of a quarter */

    @include breakpoint(one) {
        margin-top: 2rem;
    }
}

.halfSpace,
.space--half {
	margin-bottom: 8rem; /* half height of a small */

	@include breakpoint(one) {
        margin-bottom: 6rem;

        .filtered-collection-content & {
            margin-bottom: 3rem;
        }
    }
}

.threeQuarterSpace,
.space--three-quarter {
    margin-bottom: 12rem;

    @include breakpoint(one) {
        margin-bottom: 8rem;
    }
}

.halfSpaceMobile,
.space--half-mobile {
	margin-bottom: 8rem; /* half height of a small */

	@include breakpoint(one) {
        margin-bottom: 0;
    }
}

.halfSpaceTop,
.space--half-top{
    margin-top: 8rem; /* half height of a small */

    @include breakpoint(one) {
        margin-top: 6rem;
    }
}

.fullSpaceStudio {
	margin-bottom: 16rem; /* full height of a small */

    @include breakpoint(one) {
		margin-bottom: 9rem;
	}
}

.fullSpace,
.space--full {
	margin-bottom: 16rem; /* full height of a small */

    @include breakpoint(one) {
		margin-bottom: 12rem;
	}
}

.fullSpaceMobile,
.space--full-mobile {
	margin-bottom: 16rem; /* full height of a small */

    @include breakpoint(one) {
		margin-bottom: 4rem;
	}
}

.fullSpaceTop,
.space--full-top {
    margin-top: 16rem; /* full height of a small */

    @include breakpoint(one) {
        margin-top: 12rem;
    }
}

.loadingContainer {
    width: 100%;
    min-height: 32rem;
    background: url(../images/icons/loader_32px_t.gif) no-repeat 50% 50% transparent;
}

// Loading ajax
.ajax-loading-layer {
    transition-property: opacity, visibility;
    transition-duration: 0.4s, 0s;
    transition-timing-function: linear;
    transition-delay: 0s, 0.4s;
    z-index: zIndex(loading-layer);
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(255, 255, 255, 0.8);
    pointer-events: none;
}

.is-loading-ajax .ajax-loading-layer {
    transition-property: opacity;
    transition-delay: 0s;
    visibility: visible;
    opacity: 1;
    pointer-events: all;
}

/* Tables
   ========================================================================== */
table {
    margin: 1.5em 0;

    td,
    th {
        padding: 0 4em 0 0;
    }

    th {
        height: 2em;
    }

    td {
        height: 1.7em;
    }
}

/* Unpublished stuff from CMS
   ========================================================================== */
.unpublished {
    background-color: rgba(200, 0, 0, 0.02);
    box-shadow: inset 0 0 0 1px $colorUnpublished;

    span,
    p,
    a,
    h1,
    h2,
    h3,
    h4,
    .headlineWrapper h2 {
        //color: #320000 !important;
        border-color: #320000;
    }
}

::selection {
    background: #000;
    color: #fff;
}

::-moz-selection {
    background: #000;
    color: #fff;
}

/* Scrollbar measure helper
   ========================================================================== */
.scrollbar-measure {
    width: 100px;
    height: 100px;
    overflow: scroll;
    position: absolute;
    top: -9999px;
}
