/* ==========================================================================
   Register / Checkout Page
   ========================================================================== */

.registerContainer,
.accountSettingsContainer {
    margin-top: 8rem;

    @include breakpoint(mobile) {
        margin-top: 6rem;
    }

    h3 {
    	 @include typoZurich;
    	 @include typoZurich1-rem;
    }

    p {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
    }

    .fade-password,
    .fade-field {
        max-height: 6rem;
        opacity: 1;
        transition-property: max-height, opacity;
        transition-duration: $defTransTime, $defTransTime;
        transition-timing-function: $easeInOutQuint, $easeInOutQuint;
    }

    .passwordDescription {
        @include typoZurich;
        @include typoZurich0-rem;
        text-transform: none;
    }

    .salutation-ms + label,
    #newsletterUnsubscribe + label,
    #accountNewsletterSubscribeOff + label {
        margin-left: 2rem;
    }

    input[type="checkbox"] + label,
    input[type="radio"] + label {
        text-transform: none;
    }

    .mandatoryHint {
        margin-top: 1rem;
    }

    .salutation label {
        margin-bottom: 1rem;
    }

    .formError {
        padding-bottom: 2rem;
    }

    .newsletter-register-toggle {
        max-height: 0;
        opacity: 0;
    }


    .payment-listing--default,
    .payment-listing--checkout {
        @include breakpoint(twofive) {
            display: flex;
            flex-wrap: wrap;
            margin-top: -1rem;

            .payment-entry {
                width: calc(50% - 1.1rem);
                margin-top: 1rem;
            }
        }

        .description {
            padding-left: 0;
        }

        p {
            display: none;
        }

        .payLogoSmall,
        .payment-logo {
            max-width: 4rem;
            float: left;
        }
    }

    .form-row--salutation,
    .form-row--salutation-placeholder {
        min-height: 2rem;
    }

    @include breakpoint(one) {
        .form-row--salutation-placeholder {
            display: none;
        }
    }
}

.shipping-address,
.invoice-address {
    margin-bottom: 0;
    transition: margin $defTransTime $easeInOutQuint;

    .form-row,
    .gridFormCell {
        overflow: hidden;
        max-height: 0;
        opacity: 0;
        margin: 0;
        transition: max-height $defTransTime $easeInOutQuint,
                    opacity $defTransTime $easeInOutQuint,
                    margin $defTransTime $easeInOutQuint;
    }
}


// Shipping fields
.registerContainer.changeShipping,
.registerContainer.diffShippingAddress,
.accountSettingsContainer.diffShippingAddress {
    .shippingSettings,
    .shipping-address {
        margin-bottom: 4rem;

        @include breakpoint(one) {
            margin-bottom: 2rem;
        }

        .form-row,
        .gridFormCell {
            overflow: visible;
            max-height: 6rem;
            opacity: 1;
        }

        .form-row, {
            margin-bottom: 1rem;
        }
    }
}

// Invoice fields
.registerContainer.diff-invoice-address,
.accountSettingsContainer.diff-invoice-address {
    .invoice-address {
        margin-bottom: 4rem;

        @include breakpoint(one) {
            margin-bottom: 2rem;
        }

        .form-row {
            overflow: visible;
            max-height: 6rem;
            opacity: 1;
        }
    }
}

// Password field
.registerContainer.noAccount {
    .fade-password {
        max-height: 0;
        opacity: 0;
    }
}

// Newsletter Language
.registerContainer.newsletterChecked,
.accountSettingsContainer.newsletterChecked {
    .newsletter-register-toggle  {
        max-height: 8rem;
        opacity: 1;
    }
}

// USA State selection
section[data-role="customer-registration"],
section[data-role="checkout-account"],
section[data-role="account-settings"] {
    .country-state-selection {
        transition-property: max-height, opacity;
        transition-duration: $defTransTime;
        transition-timing-function: $easeInOutQuint;

        overflow: hidden;
        max-height: 0;
        opacity: 0;
    }

    .shipping-address {
        .form-row.country-state-selection,
        .gridFormCell.country-state-selection {
            overflow: hidden;
            max-height: 0;
            opacity: 0;
        }
    }

    &.country-is-canada {
        .country-state-selection--16 {
            max-height: 6rem;
            opacity: 1;
            overflow: visible;
        }

        .shipping-address {
            .form-row.country-state-selection--16,
            .gridFormCell.country-state-selection--16 {
                overflow: hidden;
                max-height: 0;
                opacity: 0;
            }
        }
    }

    &.country-is-canada.diffShippingAddress,
    &.country-is-canada.diffShippingAddress {
        .shipping-address {
            .form-row.country-state-selection--16,
            .gridFormCell.country-state-selection--16 {
                max-height: 6rem;
                opacity: 1;
                overflow: visible;
            }
        }
    }

    &.country-is-usa {
        .country-state-selection--28 {
            max-height: 6rem;
            opacity: 1;
            overflow: visible;
        }

        .shipping-address {
            .form-row.country-state-selection--28,
            .gridFormCell.country-state-selection--28 {
                overflow: hidden;
                max-height: 0;
                opacity: 0;
            }
        }
    }

    &.country-is-usa.diffShippingAddress,
    &.country-is-usa.diffShippingAddress {
        .shipping-address {
            .form-row.country-state-selection--28,
            .gridFormCell.country-state-selection--28 {
                max-height: 6rem;
                opacity: 1;
                overflow: visible;
            }
        }
    }
}

/* Responsive
   ========================================================================== */
@include breakpoint(one) {
    .registerContainer,
    .accountSettingsContainer {
        .spacerCell {
            display: none;
        }

        .shippingSettings,
        .billingSettings {
            margin-top: 2rem;
        }
    }
}

// Deactivate account
[data-action="deactivate-webshop"] {
    cursor: pointer;

    .css-loading {
        display: none;
    }

    &.is-loading {
        span {
            display: none;
        }

        .css-loading {
            display: block;
        }
    }
}

/* New Checkout
   ========================================================================== */
section[data-role="checkout-account"] {
    .register-introduction.has-login-button {
        @include breakpoint(one) {
            padding: 0 1rem;
        }

        .h2Wrapper,
        .textWrapper {
            padding-right: 12rem;

            @include breakpoint(one) {
                padding-right: 0;
                padding-top: 1rem;
            }
        }

        .h2Wrapper {
            margin-bottom: 0.5rem;
        }

        .login-wrapper {
            position: absolute;
            top: 0;
            right: 2rem;
            width: 8rem;

            @include breakpoint(one) {
                right: 1rem;
            }
        }
    }

    .shipping-address-container {
        @extend %hideable-container;
        @extend %hideable-container-hidden;
    }

    .form-row-fade-password {
        @extend %hideable-row;
        opacity: 1;
        max-height: 8rem;
    }

    // States
    &.diff-shipping-address {
        .shipping-address-container {
            opacity: 1;
            max-height: 64rem;
        }
    }

    &.no-user-account {
        .form-row-fade-password {
            @extend %hideable-row-hidden;
        }
    }
}
