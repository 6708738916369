/* ==========================================================================
   Grid for different modules that doesnt have layouts
   ========================================================================== */
.row,
.thFormRow,
.gbox,
.cell {
    @extend .clear;
    position: relative;
}

.gbox {
    display: block;
    box-sizing: border-box;
    float: left;
    text-align: left;
}

.igbox {
    display: inline-block;
    box-sizing: border-box;
    float: left;
}

.box8 {
    width: 8rem;
}

.box10 {
    width: 10rem;
}

.box12 {
    width: 12rem;
}

.box16 {
    width: 16rem;
}

.box18 {
    width: 18rem;
}

.box20 {
    width: 20rem;
}

.box24 {
    width: 24rem;
}

.box32 {
    width: 32rem;
}

.box36 {
    width: 36rem;
}

.box42 {
    width: 42rem;
}

.box48 {
    width: 48rem;
}

.box54 {
    width: 54rem;
}

.box72 {
    width: 72rem;
}

.box96 {
    width: 96rem;
}

.box120 {
    width: 120rem;
}

.gridFormCell {
    height: 6rem;
    display: block;
    position: relative;
    @extend .clear;
}

.gridFormCell.smallCell {
    height: 4rem;
}

.gridFormCell.submitCell {
    height: 5rem;
}

.gridFormCell.tinyCell {
    height: 3rem;
}

.gridFormCell.bigCell {
    height: 8rem;
}

.gridFormCell.mediumCell {
    height: 7rem;
}

@include breakpoint(one) {
    .gridFormCell.smallCell.emptyCell {
        height: 0;
    }

    .spacerCell {
        display: none;
    }
}

.gridFormCell.error {
	height: auto;
	padding-bottom: 1rem;
}

.emptyRow {
    height: 3rem;
}

.formRow.checkboxRow {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
}

.thFormRow {
    padding-bottom: 2rem;

    &.thFormRow--small-padding {
        padding-bottom: 1rem;
    }
}

.thFormRow.row2 {
    height: 8rem;
}

.thFormRow.row3 {
    height: 13rem;
}

.thFormRow.row4 {
    height: 18rem;
}

.thFormSubmit {
    position: absolute;
    left: 0;
    bottom: 1px;
}

.box36 {
    @include input() {
        width: 30rem;

        &.full-width {
            width: 100%;
        }
    }

    .sbSelector,
    .select2-chosen {
        width: 26rem;
    }
}

.box24 {
    @include input() {
        width: 20rem;

        &.full-width {
            width: 100%;
        }
    }

    .sbSelector,
    .select2-chosen {
        width: 16rem;
    }
}

.wu1 { width: 6rem; }

/* Tow column boxes
   ========================================================================== */
.two-column {
    text-align: left;

    .gbox {
        @extend .clear;
        position: relative;
        width: 32rem;

        @include breakpoint(two) {
            width: 22rem;
        }

        @include breakpoint(one) {
            width: 100%;

            h3 {
                margin-top: 1rem;
            }
        }
    }

    .gbox-left {
        float: left;

        @include breakpoint(one) {
            float: none;
        }
    }

    .gbox-right {
        float: right;

        @include breakpoint(one) {
            float: none;
        }
    }

    // Set sizing for input fields
    @include input() {
        width: 100%;
    }

    // Action rows
    &.row-action {
        @include breakpoint(mobile) {
            .gbox {
                width: 100%;

                &.gbox-left,
                &.gbox-right {
                    float: none;
                    display: block;
                }

                &.gbox-left .button,
                &.gbox-left .button.fullBorder {
                    border-top: none;
                }
            }
        }
    }
}

/* Responsive
   ========================================================================== */
@include breakpoint(four) {
    .box120 {
        width: 96rem;
    }

    .box96 {
        width: 72rem;
    }
}

@include breakpoint(three) {
    .box120 {
        width: 72rem;
    }

    .box96 {
        width: 48rem;
    }
}

@include breakpoint(two) {
    .box120,
    .box72 {
        width: 48rem;
    }

    .box36 {
        width: 24rem;

        @include input() {
            width: 20rem;

            &.full-width {
                width: 100%;
            }
        }

        .sbSelector
        .select2-chosen {
            width: 16rem;
        }
    }
}


@include breakpoint(one) {
    .box120,
    .box72,
    .box48,
    .box42,
    .box36,
    .box24 {
        width: 24rem;

        @include input() {
            width: 24rem;

            &.full-width {
                width: 100%;
            }
        }

        .sbSelector,
        .select2-chosen {
            width: 20rem;
        }
    }
}

// Max size fo input fields
.toolsheader {
    .box .thFormRow,
    .gbox .thFormRow {
        @include input() {
            width: 100%;
        }
    }
}

/* Collapsable rows
   ========================================================================== */
.row-collapsible {
    .row-collapsible-head {
        text-align: left;
        padding-bottom: 1rem;

        h4 {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
        }
    }
}

/* 3 Colums grid
   ========================================================================== */
.column-container {
    @extend %cf;
    margin-left: -1rem;

    .column {
        box-sizing: border-box;
        position: relative;
        float: left;
        text-align: left;

        label {
            font-size: 0.9em;
            line-height: 2.22em;
        }

        input[type="checkbox"] + label,
        input[type="radio"] + label {
            text-transform: none;
        }

        input,
        select,
        textarea {
            width: 100%;
            height: 2.5rem;
            line-height: 2.5rem;
        }

        .select2,
        .select2-container {
            width: 100%;
            height: 2.5rem;
            line-height: 2.5rem;

            a {
                height: 2.5rem;
                line-height: 2.5rem;
                padding-right: 3rem;
            }

            .select2-chosen {
                height: 2.5rem;
                line-height: 2.5rem;
            }

            .select2-choice .select2-arrow {
                margin-top: 0.5rem;
            }
        }

        // Custom sizing for 2 fields in one row
        .street,
        .streetnumber,
        .zipcode,
        .city,
        .retailer-firstname,
        .retailer-lastname {
            display: inline-block;
            float: left;
        }

        .street {
            width: 16rem;
        }

        .streetnumber {
            width: 5rem;
            margin-left: 1rem;
        }

        .zipcode {
            width: 6rem;
        }

        .city {
            width: 15rem;
            margin-left: 1rem;
        }

        .retailer-firstname {
            width: 10.5rem;
        }

        .retailer-lastname {
            width: 10.5rem;
            margin-left: 1rem;
        }


    }

    .column-1-3 {
        width: $moduleW;
        padding: 1rem;
    }

    .column-2-3 {
        width: 2 * $moduleW;

        @include breakpoint(one) {
            width: $moduleW;
        }
    }
}


/* Form rows
   ========================================================================== */
.form-row {
    @extend %cf;
    position: relative;
    margin-bottom: 1rem;

    &.form-row-collapsible {
        @extend %hideable-row;
    }

    &.is-collapsed {
        @extend %hideable-row-hidden;
    }

    // Hint
    .form-row-hint {
        margin-top: 2rem;
    }

    .form-row-hint-small {
        @include typoZurich;
        @include typoZurich0-rem;
        text-transform: none;
        margin-top: 0.5rem;
    }

    &.form-row-fade-password p {
        @include typoZurich;
        @include typoZurich0-rem;
        text-transform: none;
    }

    &.form-row--message {
        margin-bottom: 2rem;

        p {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
            margin-bottom: 0;
        }
    }

    .form-row--hint {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;

        .text-link {
            font-size: 1em;
            line-height: 1.1;
        }
    }
}
