/* ==========================================================================
   boxes and modules
   ========================================================================== */

.gridBorder,
.grid-border {
	padding: $border;
    box-sizing: border-box;
}

.box {
    float: left;

    &:not(.articlesItem) {
        overflow: hidden;
    }

    &.box--overflow {
        overflow: visible;
    }
}

.box-collapsable {
    transition-property: max-height, opacity;
    transition-duration: $toolsHeaderTime, $toolsHeaderTime;
    transition-timing-function: $easeInOutQuint, $easeInOutQuint;
    overflow: hidden;
}

.box-collapsable.box-collapsed {
    max-height: 0;
    min-height: 0;
    opacity: 0;
}

.lbox {
    overflow: hidden;
    position: absolute;
}

.lbox.noOverflow,
.box.noOverflow {
    overflow: visible;
}

// If hero box is inside site container
.siteContainer {
    @include breakpoint(mobile) {
        .L-3-Hero-Teaser .b1,
        .L-4-Hero-Teaser .b1 {
            height: $full-height-vh--mobile;
            height: $full-height-svh--mobile;
            //.has-global-banner & {
            //    height: calc(100vh - #{$header-height--mobile} - #{$global-banner-height--mobile});
            //}
        }
    }

    @include breakpoint(one) {
        .L-S-5-2,
        .L-S-5-2 .b1,
        .L-S-5-2 .b2,
        .L-S-6-2 .b1 {
            height: $full-height-vh--mobile;
            height: $full-height-svh--mobile;
            //.has-global-banner & {
            //    height: calc(100vh - #{$header-height--mobile} - #{$global-banner-height--mobile});
            //}
        }
    }
}

// We use the gridborder for layoutboxes
.layout .lbox {
    @extend .gridBorder;
}

// Default module definition
.module {
	position: relative;
	overflow: hidden;
	height: 100%;
	width: 100%;
}

// Box link definition
a.boxLink,
.box-link {
    display: block;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
}

// position in set layouts
.moduleLayout .box {
	position: absolute;
	float: none;
}

// Product
.product {
    text-align: center;
}

// Default box size
.small, .product, .big, .extra, .medium, .bigger, .biggersf, .medT, .landscapeB {
	width: 1 * $moduleW-rem;
	height: 1 * $moduleH-rem;
}

// Default box sizes for tower ...
.tower,
.bigTower,
.quad,
.bigQuad {
    width: 1 * $moduleW-rem;
    height: 2 * $moduleH-rem;
}

.smallQuad {
    width: 1 * $moduleW-rem;
    height: 1.5 * $moduleH-rem;
}

// Huge element
.huge {
    @include breakpoint(five) {
        width: 5 * $moduleW-rem;
        height: 4 * $moduleH-rem;
    }

    @include breakpoint(four) {
        width: 4 * $moduleW-rem;
        height: 3 * $moduleH-rem;
    }

    @include breakpoint(three) {
        width: 3 * $moduleW-rem;
        height: 3 * $moduleH-rem;
    }

    @include breakpoint(two) {
        width: 2 * $moduleW-rem;
        height: 2 * $moduleH-rem;
    }

    @include breakpoint(one) {
        width: 1 * $moduleW-rem;
        height: 2 * $moduleH-rem;
    }
}

// Bigger element
.bigger {
    @include breakpoint(five) {
        width: 3 * $moduleW-rem;
        height: 3 * $moduleH-rem;
    }

    @include breakpoint(four) {
        width: 3 * $moduleW-rem;
        height: 3 * $moduleH-rem;
    }

    @include breakpoint(three) {
        width: 3 * $moduleW-rem;
        height: 3 * $moduleH-rem;
    }

    @include breakpoint(two) {
        width: 2 * $moduleW-rem;
        height: 2 * $moduleH-rem;
    }
}

// Medium element
.medium {
    @include breakpoint(twofive) {
        width: 2 * $moduleW-rem;
        height: 2 * $moduleH-rem;
    }
}

// Medium element
.medT {
    @include breakpoint(five) {
        width: 2 * $moduleW-rem;
        height: 2 * $moduleH-rem;
    }

    @include breakpoint(four) {
        width: 1 * $moduleW-rem;
        height: 3 * $moduleH-rem;
    }

    @include breakpoint(three) {
        width: 1 * $moduleW-rem;
        height: 2 * $moduleH-rem;
    }

    @include breakpoint(two) {
        width: 2 * $moduleW-rem;
        height: 1 * $moduleH-rem;
    }
}


// Big Element
.big {
	  @include breakpoint(two) {
        width: 2 * $moduleW-rem;
        height: 2 * $moduleH-rem;
	  }

	  @include breakpoint(desktop) {
        width: 3 * $moduleW-rem;
        height: 2 * $moduleH-rem;
	  }
}

// Tower Element
.tower {
    @include breakpoint(desktop) {
        width: 1 * $moduleW-rem;
        height: 2 * $moduleH-rem;
    }

    @include breakpoint(two) {
        width: 1 * $moduleW-rem;
        height: 2 * $moduleH-rem;
    }
}

// Big Tower Element
.bigTower {
    @include breakpoint(fourfive) {
        width: 2 * $moduleW-rem;
        height: 4 * $moduleH-rem;
    }
}

// Quad Element
.quad {
    @include breakpoint(desktop) {
        width: 2 * $moduleW-rem;
        height: 3 * $moduleH-rem;
    }

    @include breakpoint(two) {
        width: 2 * $moduleW-rem;
        height: 3 * $moduleH-rem;
    }
}

// Big Quad Element
.bigQuad {
    @include breakpoint(five) {
        width: 3 * $moduleW-rem;
        height: 4 * $moduleH-rem;
    }

    @include breakpoint(four) {
        width: 2 * $moduleW-rem;
        height: 3 * $moduleH-rem;
    }

    @include breakpoint(three) {
        width: 3 * $moduleW-rem;
        height: 4 * $moduleH-rem;
    }

    @include breakpoint(two) {
        width: 2 * $moduleW-rem;
        height: 3 * $moduleH-rem;
    }
}

// extra Module
.extra {
	@include breakpoint(two) {
        width: 2 * $moduleW-rem;
        height: 2 * $moduleH-rem;
	}

	@include breakpoint(three) {
        width: 3 * $moduleW-rem;
        height: 2.5 * $moduleH-rem;
	}

	@include breakpoint(four) {
        width: 4 * $moduleW-rem;
        height: 3 * $moduleH-rem;
	}

	@include breakpoint(five) {
        width: 5 * $moduleW-rem;
        height: 3.5 * $moduleH-rem;
	}
}


// extra2 element
.extra2 {
    width: 1 * $moduleW-rem;
    height: auto;
    @include breakpoint(two) {
        width: 2 * $moduleW-rem;
    }
    @include breakpoint(three) {
        width: 3 * $moduleW-rem;
    }
    @include breakpoint(four) {
        width: 4 * $moduleW-rem;
    }
    @include breakpoint(five) {
        width: 4 * $moduleW-rem;
    }
}


// Bigger Element for shopfinder
.biggersf {
    @include breakpoint(two) {
        width:  2 * $moduleW-rem;
        height: 2 * $moduleH-rem;
    }

    @include breakpoint(three) {
        width:  3 * $moduleW-rem;
        height: 2.5 * $moduleH-rem;
    }

    @include breakpoint(four) {
        width:  3 * $moduleW-rem;
        height: 3 * $moduleH-rem;
    }

    @include breakpoint(five) {
        width:  4 * $moduleW-rem;
        height: 3.5 * $moduleH-rem;
    }
}

// Landscape box
.landscapeB {
    @include breakpoint(desktop) {
        width:  2 * $moduleW-rem;
        height: 1 * $moduleH-rem;
    }

    @include breakpoint(two) {
        width:  2 * $moduleW-rem;
        height: 1 * $moduleH-rem;
    }
}

@include breakpoint(one) { // mobile view for testing
	.product:nth-child(2), .product:nth-child(5) {
		display: inline-block;
	}
}

.lbox.contains-text-teaser.contains-shop-list {
    @include breakpoint(mobile) {
        height: auto;
        padding: 0;

        .text-teaser {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            bottom: auto;
        }
    }
}