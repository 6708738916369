.global-banner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: $backGrey;
    transition: transform $overlayTime $overlayFunc,
                background $overlayTime $overlayFunc;
    transform: translate3d(0, -100%, 0);

    .global-banner__content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: $global-banner-height--mobile;
        list-style-type: none;
    }

    .global-banner__content__item {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $backGrey;
        transition: opacity $defTransTime $defTransFunc;
        opacity: 1;
        z-index: 1;

        .box-link {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        p {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
            margin: 0;
        }

        @include breakpoint(mobile) {
            p {
                @include typoZurich;
                @include typoZurichS-rem;
                text-transform: none;
            }
        }
    }

    .global-banner__close {
        position: absolute;
        top: 50%;
        right: 2rem;
        margin-top: -1rem;
        z-index: 10;
        transition:  opacity $overlayTime $overlayFunc;
        opacity: 1;

        button {
            background: none;
            border: none;
            width: 2rem;
            height: 2rem;
            line-height: 1;
        }

        .svg-ico {
            width: 100%;
            height: 100%;
        }

        @include breakpoint(mobile) {
            margin-top: -0.8rem;
            right: 1.6rem;

            button {
                width: 1.6rem;
                height: 1.6rem;
            }
        }
    }

    .has-global-banner & {
        transform: none;
    }

    .toolsheader-opened & {
        .global-banner__close {
            opacity: 0;
            pointer-events: none;
        }
    }

    // Banner inside overlay container
    .overlay-container & {
        position: absolute;

        .global-banner__close {
            button {
                padding: 0.2rem;
            }
        }

        @include breakpoint(one) {
            .global-banner__close {
                right: 1rem;
            }
        }
    }

    @include breakpoint(desktop) {
        .global-banner__content {
            height: $global-banner-height--desktop;
        }

        .global-banner__content__title--mobile {
            display: none;
        }
    }

    @include breakpoint(fourfive) {
        .global-banner__content__item {
            flex: 1 1 auto;

            &:not(:only-child):not(:last-child) {
                padding-right: 2rem;
                border-right: solid 1px $borderGrey;
            }

            &:first-child:not(:only-child) {
                justify-content: flex-end;

                .box-link {
                    justify-content: flex-end;
                }
            }

            &:not(:only-child):not(:first-child) {
                padding-left: 2rem;
            }

            &:last-child:not(:only-child) {
                justify-content: flex-start;

                .box-link {
                    justify-content: flex-start;
                }
            }
        }
    }

    @include breakpoint(onethree) {
        .global-banner__content__item {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            &:not([aria-hidden]):first-child {
                z-index: 2;
            }

            &[aria-hidden='true'] {
                opacity: 0;
                pointer-events: none;
            }

            &[aria-hidden='false'] {
                z-index: 2;
            }
        }
    }

    @include breakpoint(mobile) {
        .global-banner__content__item {
            box-sizing: border-box;
            padding-right: 2.5rem;
        }

        .global-banner__content__title--mobile {
            display: block;
        }

        .global-banner__content__title--mobile + .global-banner__content__title--default {
            display: none;
        }

        .has-global-banner.has-open-nav:not(.toolsheader-opened) & {
            transform: translate3d($moduleW-rem * (-1), 0, 0);
        }
    }

    .is-black-friday & {
        color: $text-color--light-2;
        background-color: $back-color--mycare;

        .global-banner__content__item {
            background-color: $back-color--mycare;

            a,
            p {
                color: $text-color--light-2;
            }
        }

        .global-banner__close {
            svg, path {
                stroke: $text-color--light-2;
            }
        }
    }
}

// Handle banner states
.has-global-banner {
    //@include breakpoint(mobile) {
    //    .siteContainer {
    //        padding-top: $global-banner-height--mobile;
    //    }
    //}
    //
    //@include breakpoint(desktop) {
    //    .siteContainer {
    //        margin-top: $global-banner-height--desktop;
    //    }
    //}
}