/* ==========================================================================
   Preview for images and layounts
   ========================================================================== */
.rowPreview {
    margin-bottom: 4em;
    
    h1 {
        text-align: left; 
    }
    
    .box {
        border: dashed 1px rgb(50, 50, 50);
        position: relative;	
    }
}
