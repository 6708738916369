// 5 columns
@include breakpoint(five) {
    .L-HAUS-1 {
        height: 3.5 * $moduleH;        
        
        // Bigger
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;
            width: 4 * $moduleW; 
            height: 3.5 * $moduleH; 
        }
        
        // Tower
        .b2 {
            top: 0 * $moduleH;
            left: 4 * $moduleW;
            height: 3.5 * $moduleH;   
        }
    }       
}

// 4 columns
@include breakpoint(four) {
    .L-HAUS-1 {
        height: 3 * $moduleH;        
        
        // Bigger
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;  
            height: 3 * $moduleH; 
        }
        
        // Tower
        .b2 {
            top: 0 * $moduleH;
            left: 3 * $moduleW;
            height: 3 * $moduleH;  
        }
    }         
}

// 3 columns
@include breakpoint(three) {
   .L-HAUS-1 {
        height: 3.5 * $moduleH;        
        
        // Bigger
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW; 
            height: 2.5 * $moduleH;   
        }
        
        // Tower
        .b2 {
            top: 2.5 * $moduleH;
            left: 0 * $moduleW;
            width: 3 * $moduleW;
            height: 1 * $moduleH;   
        }
    }
    
    .L-HAUS-1.halfSpace {
        padding-bottom: 4em;
    }
}

// 2 columns
@include breakpoint(two) {
    .L-HAUS-1 {
        height: 2.75 * $moduleH;        
        
        // Bigger
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;  
        }
        
        // Tower
        .b2 {
            top: 2 * $moduleH;
            left: 0 * $moduleW;
            width: 2 * $moduleW;
            height: 0.75 * $moduleH;   
        }
    }       
}

// 1 columns
@include breakpoint(one) {
    .L-HAUS-1 {
        height: 2 * $moduleH;        
        
        // Bigger
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;  
        }
        
        // Tower
        .b2 {
            top: 1 * $moduleH;
            left: 0 * $moduleW;
            width: 1 * $moduleW;
            height: 1 * $moduleH;   
        }
    }         
}

.L-HAUS-1 {
    .b2 {
        .headlineWrapper,
        .textWrapper,
        .linkWrapper {
            padding-left: 0;
        }       
    }
} 

// Padding for Textbox
@include breakpoint(fourfive) {
    .L-HAUS-1 {
        .b2 {
            .headlineWrapper,
            .textWrapper,
            .linkWrapper {
                padding-left: 1em;
            }       
        }
    }    
}
