.textTeaser,
.imageTeaser,
.imgBox,
.moduleVideo {
    .textBoxWrapper.valign {
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

    .playLink,
    .pauseLink {
        position: absolute;
        margin: -3em 0 0 -3em;
        width: 6em;
        height: 6em;
        top: 50%;
        left: 50%;

        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        z-index: 15;

        transform: translate3d(0, 0, 0);

        @include breakpoint(one) {
            width: 4em;
            height: 4em;
            margin: -2em 0 0 -2em;
        }
    }

    .playLink {
        background-image: url(../images/icons/svg/play-white.svg);
    }

    .pauseLink {
    	opacity: 0;
        background-image: url(../images/icons/svg/pause-white.svg);
    }
}

// Smaller Links for small boxes
.small .imageTeaser .playLink {
    width: 4em;
    height: 4em;
    margin: -2em 0 0 -2em;
}

// Hide pause for touch
.touch .pauseLink {
    display: none;
}

.imageTeaser.teaserColorBlack .playLink {
    background-image: url(../images/icons/svg/play.svg);
}

// New video modules -> Productdetailpage
.module--video {
    .video-button {
        transform: translate3d(0, 0, 0);
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -3rem 0 0 -3rem;
        width: 6rem;
        height: 6rem;
        z-index: 1;
        opacity: 0;
        pointer-events: none;

        .svg-ico {
            width: 100%;
            height: 100%;
            fill: #fff;
        }

        @include breakpoint(one) {
            width: 4rem;
            height: 4rem;
            margin: -2rem 0 0 -2rem;
        }
    }
}

// Sticky slideshow teaser
.sticky-slideshow-teaser {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;

    .sticky-slideshow-teaser__box {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .text-teaser__wrapper {
        pointer-events: all;
    }
}