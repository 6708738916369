.css-loading {
    display: inline-block;
    margin: 0;
    text-align: center;
}

.css-loading > div {
    width: 10px;
    height: 10px;
    margin-right: 4px;
    background-color: #333;
    opacity: 0.8;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
    animation: bouncedelay 1.4s infinite ease-in-out;

    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

    &:last-child {
        margin-right: 0;
    }
}

.css-loading.css-loading-big > div {
    width: 14px;
    height: 14px;
    margin-right: 6px;
}

.css-loading .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.css-loading .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0.0)
    }
    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes bouncedelay {
    0%, 80%, 100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    }
    40% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}
