// 3-5 columns
@include breakpoint(desktop) {
    .L-P-Detail-Palm {
    	// Remove top margin, because picture has much whitespace
        margin-top: -4em;
        height: 9.25 * $moduleH;
        
        // Big Image Slideshow
        .b1 {
            top: 0;
            left: 0;
            height: 2.5 * $moduleH;  
        }   
        
        // Product title
        .b2 {
            top: 2.75 * $moduleH; 
            left: 0;			
        }
        
        // price and cart
        .b4 {
            top: 2.75 * $moduleH; 
            left: 2 * $moduleW;			
        }
        
        // description
        .b5 {
            top: 4 * $moduleH; 
            left: 0;			
        }

        // Video
        .b6 {
            top: 5.5 * $moduleH;
            left: 0 * $moduleW;
        }

        // Photographer
        .b8 {
            top: 4 * $moduleH; 
            left: 2 * $moduleW;	
            height: 1.25 * $moduleH;		
        }
        
        // About
        .b9 {
            top: 4 * $moduleH; 
            left: 1 * $moduleW;
            height: 1.25 * $moduleH;  			
        }
        
        // Sahre
        .b10 {
            top: 8.75 * $moduleH;
            left: 0 * $moduleW;
            height: 0.5 * $moduleH;
            width: 2 * $moduleW;             
        }
        
    }       
}

// 2 columns
@include breakpoint(two) {    
    .L-P-Detail-Palm {
        height: 8.25 * $moduleH;
        
        // Big Image Slideshow
        .b1 {
            top: 0;
            left: 0;  
        }   
        
        // Product title
        .b2 {
            top: 2 * $moduleH; 
            left: 0;			
        }
        
        // price and cart
        .b4 {
            top: 3 * $moduleH;
            left: 1 * $moduleW;			
        }
        
        // description
        .b5 {
            top: 3 * $moduleH; 
            left: 0;			
        }

        // Video
        .b6 {
            top: 5.5 * $moduleH;
            left: 0 * $moduleW;
        }
        
        // Photographer
        .b8 {
            top: 4 * $moduleH; 
            left: 1 * $moduleW;	
            height: 1.25 * $moduleH; 		
        }
        
        // About
        .b9 {
            top: 4 * $moduleH; 
            left: 0 * $moduleW;	
            height: 1.25 * $moduleH; 	
        }
        
        // Sahre
        .b10 {
            top: 7.75 * $moduleH;
            left: 0 * $moduleW;
            height: 0.5 * $moduleH;  
            width: 2 * $moduleW;          
        } 
        
    }  
}

// 1 columns
@include breakpoint(one) {
    .L-P-Detail-Palm {
        height: auto;       
        
        
        .b1, .b2, .b3, .b4, .b5, .b6, .b7, .b8, .b9, .b10 {
	        position: relative;
	        float: left;
	        top: 0;
	        left: 0;        
        }
        
        .b2, .b3, .b4, .b5, .b7 {
	        height: auto;
	        margin-bottom: 2em;	        
        }
        
        .b1 {
            margin-bottom: 2em;
        }

        .b6 {
            margin-bottom: 2em;
        }
        
        .b7 {
            display: none;
        }
        
        .b10 {
            height: 0.5 * $moduleH;          
        }
    }        
}
