/* ==========================================================================
   module type
   ========================================================================== */


// module type sizing and style
.bigTower h2,
.extra h2,
.extra2 h2,
.quad h2,
.bigQuad h2,
.big h2,
.bigger h2,
.huge h2,
.medT h2,
.medium h2  {
	@include typoZurich3-rem;
}

@include breakpoint(three) {
    .bigTower h2,
    .extra h2,
    .extra2 h2,
    .quad h2,
    .bigQuad h2,
    .big h2,
    .bigger h2,
    .huge h2,
    .medT h2,
    .medium h2  {
        @include typoZurich2-rem;
    }
}

@include breakpoint(two) {
    .bigTower h2,
    .extra h2,
    .extra2 h2,
    .quad h2,
    .bigQuad h2,
    .big h2,
    .bigger h2,
    .huge h2,
    .medT h2,
    .medium h2  {
        @include typoZurich2-rem;
    }
}

@include breakpoint(one) {
    .bigTower h2,
    .extra h2,
    .extra2 h2,
    .quad h2,
    .bigQuad h2,
    .big h2,
    .bigger h2,
    .huge h2,
    .medT h2,
    .medium h2  {
        @include typoZurich1-rem;
    }
}

.product h3,
.product h4 {
	@include typoZurich;
	@include typoZurich1-rem;
    line-height: 1.3;
}

.product h4 {
    text-transform: none;
}

.textBoxInner span {
	@include typoZurichBd;
	@include typoZurich1-rem;
}

.medium .textBoxInner {
	@include breakpoint(twofive) {
        padding-top: 13.5em;
    }
}

.medium .textBoxInner span {
	@include breakpoint(fourfive) {
		@include typoZurich2-rem;
	}
}

// module type positioning

.module {
	.h1Wrapper {
   		position: absolute;
   		left: 1.25em;
   		top: 1.25em;
		width: 80%;
    }

	.h2Wrapper {
		position: absolute;
		margin-top: -1em;
		top: 50%;
		left: 0;
		width: 100%;
	}

	.h2Wrapper.rows2 {
	   margin-top: -2em;
	}

	.h3Wrapper {
		position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
	}

	.h2Wrapper + .h3Wrapper {
	    margin-top: 2em;
	}

	.h2Wrapper.rows2 + .h3Wrapper {
        margin-top: 3em;
    }
}

.product .module .h3Wrapper {
	bottom: 1.25em;
	top: auto;
	left: 0;
	right: auto;
	width: 100%;
}

.product.product--has-color,
.product.product--view-more,
.product.product--view-less {
    .h3Wrapper,
    .h4Wrapper {
        position: absolute;
        top: auto;
        left: 0;
        right: auto;
        width: 100%;
    }

    .h3Wrapper {
        bottom: 2.25rem;
    }

    .h4Wrapper {
        bottom: 0.5rem;
    }
}

.product.product--samplesale {
    .h3Wrapper {
        position: absolute;
        top: auto;
        left: 0;
        right: auto;
        width: 100%;
        bottom: 1.25rem;
    }
}

.product.product--info-layer {
    .h3Wrapper {
        position: absolute;
        top: auto;
        left: 0;
        right: auto;
        width: 100%;
    }

    .h3Wrapper {
        bottom: 1.25rem;
    }
}


// text boxes
.textBoxInner {
	display: block;
	padding-top: 5.5em;
}

.medium .textBoxInner {
	@include breakpoint(twofive) {
		padding-top: 13.5em;
	}
}

// Type for Headline
.headlineWrapper {
    padding-bottom: 1em;
    max-width: 72rem;
    margin: 0 auto;

    &.fullHeadlineWrapper {
        width: 100%;
        max-width: 100%;
    }

    h1,
    h2 {
        @include typoZurichBd;
        @include typoZurich3-rem;
    }
}

// Responsive stuff
@include breakpoint(mobile) {
    .headlineWrapper {
        h1,
        h2 {
            @include typoZurich2-rem;
        }
    }
}



