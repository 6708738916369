/* ==========================================================================
   IFrame item
   ========================================================================== */

.iframeItem {
    text-align: left;

    .headlineWrapper {
        max-width: none;
    }
}
