.L-2-Hero-Teaser {
    height: auto;
    width: 100%;
    padding-bottom: 56.25%;

    // FirstItem
    .b1 {
        float: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        &.lbox {
            padding: 0;
        }
    }

    .lbox.b2 {
        transform: translate3d(-50%, -50%, 0);
        height: (5 * $moduleW) * 0.5625;
        top: 50%;
        left: 50%;

        @include breakpoint(four) {
            height: (4 * $moduleW) * 0.5625;
        }

        @include breakpoint(three) {
            height: (3 * $moduleW) * 0.5625;
        }

        @include breakpoint(two) {
            height: (2 * $moduleW) * 0.5625;
        }

        @include breakpoint(one) {
            height: (1 * $moduleW) * 0.5625;
        }
    }
}
