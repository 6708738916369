/* ==========================================================================
   Mykita Icons
   ========================================================================== */
.icon {
    line-height: 1em;
}

a [class^='ico-'], a [class*=' ico-'] {
    display: inline-block;
}

[class^='ico-'], [class*=' ico-'] {
    background-position: 0 0;
    background-repeat: no-repeat;
    display: inline-block;
    width: 1em;
    height: 1em;
    background-size: 1em 1em;
    line-height: 1em;
}

.svg-ico {
    display: inline-block;

    svg {
        display: inline-block;
        width: 100%;
        height: 100%;
    }
}

// SVG Icons
.ico-cart {
    background-image: url(../images/icons/svg/cart.svg);
}

// Search
.ico-search {
    background-image: url(../images/icons/svg/search.svg);
}

// Wishlist
.ico-wishlist {
    background-image: url(../images/icons/svg/wishlist.svg);
}

// Navigation
.ico-nav {
    background-image: url(../images/icons/svg/nav.svg);
}

// Navigation
.ico-filter {
    background-image: url(../images/icons/svg/filter.svg);
}

// Close
.ico-close {
    background-image: url(../images/icons/svg/close.svg);
}

// Left Arrow
.ico-left-arrow {
    background-image: url(../images/icons/svg/arrow-left.svg);
}

// Right Arrow
.ico-right-arrow {
    background-image: url(../images/icons/svg/arrow-right.svg);
}

// Calendar icon
.ico-calendar {
    background-image: url(../images/icons/svg/calendar.svg);
}

.svg-ico-close {
    stroke: var(--text-color, $text-color--default)
}

.svg-ico-search {
    stroke: currentColor;
}

// Special handling for icons inside product listings
.product-listing {
    // General SVG icons
    .svg-ico-wishlist,
    .svg-ico-profile {
        stroke: var(--text-color, $text-color--default);
    }

    .svg-ico-wishlist-filled {
        stroke: var(--text-color, $text-color--default);
        fill: var(--text-color, $text-color--default);
    }

    .svg-ico-cart,
    .svg-ico-search {
        stroke: var(--text-color, $text-color--default);
    }
}