// 5 columns
@include breakpoint(five) {
    .L-N-4-2{
        height: 7 * $moduleH;        
        
        // Big Quad
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;  
        }
        
        // Quad
        .b2 {
            top: 0 * $moduleH;
            left: 3 * $moduleW;    
        }
        
        // Bigger
        .b3 {
            top: 4 * $moduleH;
            left: 0 * $moduleW;    
        }
        
        // Big Tower
        .b4 {
            top: 3 * $moduleH;
            left: 3 * $moduleW;    
        }
    }       
}

// 4 columns
@include breakpoint(four) {
    .L-N-4-2{
        height: 5 * $moduleH;        
        
        // Big Quad
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;
            height: 2 * $moduleH;  
        }
        
        // Quad
        .b2 {
            top: 0 * $moduleH;
            left: 2 * $moduleW;    
        }
        
        // Bigger
        .b3 {
            top: 3 * $moduleH;
            left: 2 * $moduleW;
            width: 2 * $moduleW;
            height: 2 * $moduleH;     
        }
        
        // Big Tower
        .b4 {
            top: 2 * $moduleH;
            left: 0 * $moduleW;
            height: 3 * $moduleH;     
        }
    }       
}

// 3 columns
@include breakpoint(three) {
    .L-N-4-2{
        height: 4 * $moduleH;        
        
        // Big Quad
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;
            width: 2 * $moduleW;
            height: 2 * $moduleH;  
        }
        
        // Quad
        .b2 {
            top: 0 * $moduleH;
            left: 2 * $moduleW;
            width: 1 * $moduleW;
            height: 1.5 * $moduleH;    
        }
        
        // Bigger
        .b3 {
            top: 2 * $moduleH;
            left: 0 * $moduleW;  
            width: 2 * $moduleW;
            height: 2 * $moduleH;   
        }
        
        // Big Tower
        .b4 {
            top: 1.5 * $moduleH;
            left: 2 * $moduleW;
            height: 2.5 * $moduleH;     
        }
    }       
}

// 2 columns
@include breakpoint(two) {
    .L-N-4-2{
        height: 2.5 * $moduleH;        
        
        // Big Quad
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;
            width: 1 * $moduleW;
            height: 1 * $moduleH;  
        }
        
        // Quad
        .b2 {
            top: 0 * $moduleH;
            left: 1 * $moduleW;
            width: 1 * $moduleW;
            height: 1.5 * $moduleH;    
        }
        
        // Bigger
        .b3 {
            top: 1.5 * $moduleH;
            left: 1 * $moduleW;  
            width: 1 * $moduleW;
            height: 1 * $moduleH;   
        }
        
        // Big Tower
        .b4 {
            top: 1 * $moduleH;
            left: 0 * $moduleW;
            height: 1.5 * $moduleH;     
        }
    }      
}

// 1 columns
@include breakpoint(one) {
    .L-N-4-2{
        height: 6 * $moduleH;        
        
        // Big Quad
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;
            height: 1.5 * $moduleH;  
        }
        
        // Quad
        .b2 {
            top: 1.5 * $moduleH;
            left: 0 * $moduleW;
            height: 1.5 * $moduleH;    
        }
        
        // Bigger
        .b3 {
            top: 3 * $moduleH;
            left: 0 * $moduleW;   
        }
        
        // Big Tower
        .b4 {
            top: 4 * $moduleH;
            left: 0 * $moduleW;     
        }
    }           
}
