/* ==========================================================================
   Text Teaser
   ========================================================================== */

.textTeaser {
    .headlineWrapper {
        margin-bottom: 1em;
        padding: 0;

        h2 {
            @include typoZurichBd;
            @include typoZurich3-rem;
            margin: 0;
        }
    }

    .headlineWrapper.noText {
        margin: 0;
        padding: 0;

        h2 {
            margin: 0;
            padding: 0;
        }
    }

    .textWrapper {
        margin-bottom: 1em;
        padding: 0 1.5em;

        p {
            margin-bottom: 0;
        }
    }

    .textWrapper.bigText {
        p,
        span {
            @include typoZurichBd;
            @include typoZurich3-rem;
        }

        @include breakpoint(three) {
            p,
            span {
                @include typoZurich2-rem;
            }
        }
    }

    .textWrapper.bigMylonText {
        text-align: left;
        padding: 0 1em;

        p,
        span {
            @include typoZurichBd;
            @include typoZurich5-rem;
        }
    }

    .textWrapper.defaultHausText,
    .textWrapper.defaultText {
        p,
        span {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
        }
    }

    .textBoxWrapper {
        position: absolute;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
    }
}

@include breakpoint(two) {
    .textTeaser {
        .headlineWrapper h2 {
            @include typoZurich2-rem;
        }

        .textWrapper.bigText p,
        .textWrapper.bigText span {
            @include typoZurich2-rem;
        }

        .textWrapper.defaultText p,
        .textWrapper.defaultText span {
            @include typoZurich1-rem;
        }

        .textWrapper.bigMylonText {

            p,
            span {
                @include typoZurich4-rem;
            }
        }
    }
}

@include breakpoint(one) {
    .textTeaser {
        .headlineWrapper h2 {
            @include typoZurich1-rem;
        }

        .textWrapper.bigText p,
        .textWrapper.bigText span {
            @include typoZurich1-rem;
        }

        .textWrapper.defaultText p,
        .textWrapper.defaultText span {
            @include typoZurich1-rem;
        }

        .textWrapper.bigMylonText {
            padding: 0;

            p,
            span {
                @include typoZurich4-rem;
            }
        }
    }
}

// Common stuff for: Startpage text teaser + Startpage (large typo) text teaser
.text-teaser.text-teaser--startpage,
.text-teaser.text-teaser--startpage-large {
    position: relative;
    width: 100%;
    height: 100%;

    // Use CSS vars for background and text
    background-color: var(--bg-color, transparent);
    color: var(--text-color, #{$text-color--default});

    .content-link {
        border-color: var(--text-color, #{$text-color--default});
    }

    // Fix incorrect sizeing for inline links
    .teaser-text__content {
        a {
            font-size: 1em;
        }
    }

    @include breakpoint(one) {
        color: var(--text-color-mobile, var(--text-color, #{$text-color--default}));

        .content-link {
            border-color: var(--text-color-mobile, var(--text-color, #{$text-color--default}));
        }
    }

    .teaser-text__content-mobile {
        display: none;
    }

    @include breakpoint(one) {
        .teaser-text__content-mobile {
            display: block;
        }

        .teaser-text__content-mobile + .teaser-text__content {
            display: none;
        }
    }
}

// Startpage text teaser
.text-teaser.text-teaser--startpage {
    .text-teaser__wrapper {
        position: absolute;
        top: 4rem;
        left: 4rem;
        right: 4rem;
        text-align: left;

        &.text-teaser__wrapper--bottom {
            top: auto;
            bottom: 4rem;
        }
    }

    h2,
    h3,
    p,
    a {
        @include typoZurich;
        @include typoZurich3-rem;
        text-align: left;
        margin: 0;
        text-decoration: none;
        color: inherit;
    }

    p,
    a {
        text-transform: none;
    }

    .teaser-text__subline + .teaser-text__content-mobile,
    .teaser-text__subline + .teaser-text__content {
        margin-top: 1.75rem * 1.5;
    }

    // Expandable
    .text-teaser__expandable {
        display: block;
        overflow: hidden;
        word-wrap: break-word;
        max-height: 1.75rem * 1.5 * 3;
        transition: max-height $trans-time--default $trans-func--easing;
    }

    // Extend size, when teaser has subline
    &.has-subline {
        .text-teaser__expandable {
            max-height: 1.75rem * 1.5 * 4;
        }
    }

    &[aria-expanded='true'] {
        .text-teaser__expandable {
            max-height: 64rem;

            @supports (--css: variables) {
                max-height: var(--expanded-height, 64rem);
            }
        }
    }

    .text-teaser__read-more {
        display: block;

        span {
            @include typoZurich;
            @include typoZurich3-rem;
            text-transform: none;
        }

        button,
        span {
            color: inherit;
        }
    }

    &[aria-expanded='false'] .text-teaser__read-more span:last-child {
        display: none;
    }

    &[aria-expanded='true'] .text-teaser__read-more span:first-child {
        display: none;
    }

    @include breakpoint(mobile) {
        .text-teaser__wrapper {
            top: 2rem;
            left: 2rem;
            right: 2rem;
        }

        h2,
        h3,
        p,
        a {
            @include typoZurich;
            @include typoZurich2-rem;
        }

        p,
        a {
            text-transform: none;
        }

        .teaser-text__subline + .teaser-text__content-mobile,
        .teaser-text__subline + .teaser-text__content {
            margin-top: 1.35rem * 1.5;
        }

        &:not([aria-expanded='true']) .text-teaser__expandable {
            max-height: 1.35rem * 1.5 * 3;
        }

        // Extend size, when teaser has subline
        &.has-subline {
            &:not([aria-expanded='true']) .text-teaser__expandable {
                max-height: 1.35rem * 1.5 * 4;
            }
        }

        .text-teaser__read-more span {
            @include typoZurich;
            @include typoZurich2-rem;
            text-transform: none;
        }

        h2,
        p,
        a,
        button,
        span {
            color: inherit;
        }
    }

    @include breakpoint(two) {
        .text-teaser__wrapper {
            &.text-teaser__wrapper--bottom {
                top: auto;
                bottom: 2rem;
            }
        }
    }

    @include breakpoint(one) {
        .text-teaser__wrapper {
            &.text-teaser__wrapper--bottom {
                top: auto;
                bottom: 2rem;
            }
        }

        // Handle background of text teaser correctly
        .L-S-5 & {
            &:not([aria-expanded]) {
                background: transparent;
            }

            &[aria-expanded] {
                transition: background $trans-time--default $trans-func--easing;
            }

            &[aria-expanded='false'] {
                background: transparent;
            }

            &[aria-expanded='true'] {
                background: rgba(0, 0, 0, 0.35);
            }

            &.has-dark-mobile-text[aria-expanded='true'] {
                background: rgba(255, 255, 255, 0.45);
            }
        }
    }

    // Conditional expandable
    @include breakpoint(twofive) {
        &.no-desktop-expandable {
            .text-teaser__expandable {
                max-height: none;
            }

            .text-teaser__read-more {
                display: none;
            }
        }
    }

    @include breakpoint(one) {
        &.no-mobile-expandable {
            .text-teaser__expandable {
                max-height: none;
            }

            .text-teaser__read-more {
                display: none;
            }
        }
    }
}

// Startpage (large typo) text teaser
.text-teaser.text-teaser--startpage-large {
    .text-teaser__wrapper {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 2rem;
        text-align: left;
    }

    @include breakpoint(desktop) {
        .text-teaser__wrapper {
            padding: 4rem;
        }

        &.text-teaser--has-shop-list {
            .text-teaser__wrapper {
                position: absolute;
                inset: 0 0 0 0;
                z-index: 2;
            }
        }

        h2,
        p,
        .teaser-text__content span,
        .teaser-text__content li {
            @include typoZurich;
            font-size: 4.5vw;
            line-height: 1.3;
            letter-spacing: 0;
        }

        .teaser-text__content span,
        .teaser-text__content li,
        .teaser-text__content a {
            line-height: 1.2;
        }

        a {
            @include typoZurich;
            @include typoZurich3-rem;
        }
    }

    @include breakpoint(two) {
        h2,
        p {
            @include typoZurich;
            @include typoZurich4-rem;
        }

        .teaser-text__content span,
        .teaser-text__content li,
        .teaser-text__content a {
            @include typoZurich;
            @include typoZurich5-rem;
            line-height: 1.3;
            text-transform: none;
        }

        a {
            @include typoZurich;
            @include typoZurich2-rem;
        }
    }

    @include breakpoint(one) {
        h2,
        p {
            @include typoZurich;
            @include typoZurich3-rem;
        }

        .teaser-text__content span,
        .teaser-text__content li,
        .teaser-text__content a {
            @include typoZurich;
            @include typoZurich4-rem;
            line-height: 1.3;
            text-transform: none;
        }

        a {
            @include typoZurich;
            @include typoZurich2-rem;
        }
    }

    h2,
    p,
    a,
    .teaser-text__content span,
    .teaser-text__content li {
        text-align: left;
        margin: 0;
        text-transform: none;
        text-decoration: none;
        color: inherit;
    }

    .teaser-text__link {
        margin-top: auto;
    }

    .teaser-shop-list {
        box-sizing: border-box;
        display: inline;
        list-style: none;
        text-align: left;
    }

    .teaser-shop-list__item {
        display: inline;

        &:not(:last-child) {
            &:after {
                content: ', ';
            }
        }
    }

    .teaser-text__content span,
    .teaser-text__content li {
        transition: color $trans-time--default $trans-func--easing;
        color: var(--text-color, #000);
    }

    .teaser-shop-list__item-link,
    .teaser-text__content a {
        text-decoration-line: underline;
        text-decoration-thickness: 3px;
        text-decoration-skip-ink: none;
        text-underline-offset: 0.1em;


        @include breakpoint(mobile) {
            text-decoration-thickness: 2px;
        }
    }

    .teaser-text__content a:not(.teaser-shop-list__item-link) {
        text-decoration-color: var(--text-color, #000);
        transition: text-decoration-color $trans-time--default $trans-func--easing;
    }

    .teaser-text__content .teaser-shop-list__item-link {
        text-decoration-color: var(--text-color, #000);

        @include breakpoint(desktop) {
            text-decoration-color: transparent;
            transition: text-decoration-color $trans-time--default $trans-func--easing;

            &:hover {
                text-decoration-color: var(--text-color, #000);
            }
        }
    }

    .text-teaser__shop-teaser-list {
        position: absolute;
        inset: 0 0 0 0;
        z-index: 1;
        display: grid;
        grid-template-columns: 1fr;
        pointer-events: none;

        > li {
            grid-row-start: 1;
            grid-column-start: 1;
            position: relative;
            overflow: hidden;
            transition: opacity $trans-time--default $trans-func--easing;
            opacity: 0;

            .box {
                position: absolute;
                inset: 0 0 0 0;
            }

            &.is-visible {
                opacity: 1;
            }
        }

        @include breakpoint(mobile) {
            display: none;
        }
    }
}

// Text teaser hidden on mobile
.text-teaser.text-teaser--hidden-mobile {
    @include breakpoint(one) {
        display: none;
    }
}