.nosto_element,
.nosto-element {
    display: block;
    position: relative;
    transition: opacity $trans-time--default $trans-func--easing,
                max-height $trans-time--default $trans-func--easing,
                padding $trans-time--default $trans-func--easing;

    opacity: 0;
    max-height: 0;
    overflow: hidden;
    padding: 0;

    &.is-loaded {
        opacity: 1;
        max-height: 18rem;
    }

    &.nosto-element--navigation.is-loaded {
        padding: 0 0 4rem 0;
    }

    // Adjust sizing for v2
    &.nosto-element--v2.is-loaded {
        max-height: 40rem;

        &:not(.nosto-element--slideshow) {
            padding-bottom: 4rem;
        }
    }

    @include breakpoint(mobile) {
        &.is-loaded {
            max-height: 36rem;
        }
    }

    @include breakpoint(one) {
        &.nosto-element--v2.is-loaded {
            overflow: visible;
            max-height: 80rem;
        }
    }

    // Check if view is filtered
    .filtered-collection-content.is-filtered & {
        opacity: 0;
        max-height: 0;
        padding: 0;
    }
}

// Nosto product listing - Default Listing
.product-listing--nosto-default {
    @include breakpoint(four) {
        .product[data-product='4'] {
            display: none;
        }
    }

    @include breakpoint(three) {
        .product[data-product='3'],
        .product[data-product='4'] {
            display: none;
        }
    }

    @include breakpoint(onethree) {
        .product[data-product='4'] {
            display: none;
        }
    }

    @include breakpoint(one) {
        .product[data-product='3'],
        .product[data-product='4'] {
            display: none;
        }
    }
}

// Nosto product listing - Navigation Listing
.product-listing--nosto-navigation {
    @include breakpoint(four) {
        .product[data-product='4'] {
            display: none;
        }
    }

    @include breakpoint(three) {
        .product[data-product='3'],
        .product[data-product='4'] {
            display: none;
        }
    }

    @include breakpoint(mobile) {
        display: none;
    }
}

// Nosto product listing - Navigation Listing
.product-listing--nosto-detail {
    @include breakpoint(five) {
        .product[data-product='4'] {
            display: none;
        }
    }

    @include breakpoint(four) {
        .product[data-product='3'],
        .product[data-product='4'] {
            display: none;
        }
    }

    @include breakpoint(three) {
        .product[data-product='4'] {
            display: none;
        }
    }

    @include breakpoint(two) {
        .product[data-product='4'] {
            display: none;
        }
    }

    @include breakpoint(one) {
        .product[data-product='4'],
        .product[data-product='3'],
        .product[data-product='2'] {
            display: none;
        }
    }
}

// Nosto product listing - Related products listing
.product-listing--nosto-related-products {
    @include breakpoint(fourfive) {
        .product[data-product='4'],
        .product[data-product='3'] {
            display: none;
        }
    }

    @include breakpoint(three) {
        .product[data-product='3'] {
            display: none;
        }
    }

    @include breakpoint(mobile) {
        .product[data-product='4'],
        .product[data-product='3'],
        .product[data-product='2'] {
            display: none;
        }
    }
}

// New listing
.product-listing--nosto-v2 {
    @include breakpoint(mobile) {
        li:nth-child(3) {
            display: none;
        }
    }

    // Hide recommended label
    .nosto-wrapper &,
    .nosto-wrapper--cart &,
    .nosto-wrapper--search & {
        .product-listing-item__label {
            display: none;
        }
    }
}

// Wrappers
.nosto-wrapper--detail {
    @include breakpoint(desktop) {
        .nosto-element,
        .nosto-headline {
            margin-left: $moduleW-rem;
        }

        .nosto-headline {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
}

.nosto-wrapper--related-products {
    margin-bottom: 6rem;
}

// Typography
.nosto-headline {
    text-align: left;
    transition: opacity $trans-time--default $trans-func--easing,
                max-height $trans-time--default $trans-func--easing,
                margin $trans-time--default $trans-func--easing;

    opacity: 0;
    max-height: 0;
    overflow: hidden;

    &.is-loaded {
        opacity: 1;
        max-height: 8rem;

        .nosto-wrapper--cart &,
        .nosto-wrapper--search & {
            margin-bottom: 1rem;
        }
    }

    &.nosto-headline--navigation {
        @include typoZurich;
        @include typoZurich1-1-rem;
        margin: 0;
        padding-left: 12px;
        padding-right: 12px;
    }

    &.nosto-headline--detail {
        @include typoZurich;
        @include typoZurich1-1-rem;
    }

    &.nosto-headline--medium {
        @include typoZurichBd;
        @include typoZurich2-rem;
    }

    &.nosto-headline--large {
        @include typoZurichBd;
        @include typoZurich3-rem;

        @include breakpoint(mobile) {
            @include typoZurich2-rem;
        }
    }

    &.nosto-headline--related-products {
        @include typoZurich;
        @include typoZurich1-rem;

        @include breakpoint(one) {
            padding-left: 2rem;
            padding-right: 4rem;
        }
    }

    &.nosto-headline--filtered-collection {
        @include typoZurich;
        @include typoZurich1-1-rem;
        text-align: center;
    }
}

// Hide nosto wrapper
.nosto-wrapper {
    transition: margin $trans-time--default $trans-func--easing;

    &.is-hidden {
        margin-bottom: 0;

        .nosto-element,
        .nosto-element.is-loaded,
        .nosto-headline,
        .nosto-headline.is-loaded {
            opacity: 0;
            max-height: 0;
            padding: 0;
        }
    }
}

// Nosto debug
.nostodebugview {
    td {
        text-align: left;
    }
}
