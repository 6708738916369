
/* ==========================================================================
   Dimensions
   ========================================================================== */
$moduleW: 24em;
$moduleH: 16em;
$moduleW-rem: 24rem;
$moduleH-rem: 16rem;
$border: 6px;
$journalBorder: 2rem;
$borderExtended: 12px;

$box-padding--default: 6px;
$box-padding--extended: 12px;


/* ==========================================================================
   colors
   ========================================================================== */

$inputGrey: 	   #f0f0f0;	// grey for input fields
$inputGrey2: 	   #e1e1e1;	// grey for input fields on grey areas
$inputGrey2Hover:  #ebebeb;	// grey for input fields on grey areas
$inputGrey3: 	   #d7d7d7;	// grey for input fields on grey areas
$inputGrey4: 	   #969696;	// Placholder grey
$inputError:       #f0d7d9;    // Input error color
$inputErrorDark:   #be2020;    // Input error color
$inputSuccessDark: #20BE20;    // Input success color
$textGrey:         #7F7F7F;
$text-grey-light:  #a9a9a9; // Inactive nav points
$color-samplesale: rgb(0, 201, 255); // Coral color for sample sale
$color-mycare: #ff7c1f;

// $borderGrey:	#ededed;	// grey for button outlines
// $borderGrey2:	#e0e0e0;	// grey for button outlines

$borderGrey:	#858585;	// grey for button outlines
$borderGrey2:	#858585;	// grey for button outlines

$backGrey: 		#f0f0f0; 	// grey for wider areas and module hovers
$backGrey2: 	#f2f2f2; 	// grey for wider areas and module hovers
$backGrey3:     #bbbbbb;
$backGrey4:     #696969;

$footerBackground: #f0f0f0;

$colorPublished: #62c128;
$colorUnpublished: #ac3b2c;
$colorPublishedTime: rgb(255, 128, 0);

$text-color--default: #000;
$text-color--light-1: $textGrey;
$text-color--light-2: #fff;
$text-color--light-3: #c4c4c4;
$text-color--hover: #969696;
$text-color--mycare: $color-mycare;
$text-color--sample-sale: #ff0000;

$back-color--default: #fff;
$back-color--input-error: $inputError;
$back-color--light-1: #f7f7f7;
$back-color--mycare: $color-mycare;

$border-color-dark: #000;
$border-color--dark-1: #000;
$border-color--light-1: #e1e1e1;
$border-color--light-2: $borderGrey2;

// Product Detail Image background
$detail-image-background: #f7f7f7;
$portrait-image-background: #f7f7f7;
$product-image-background: #f7f7f7;

$box-shadow--default: 0 0 1rem 0.1rem rgba(0, 0, 0, 0.1);

/* ==========================================================================
   Transitions
   ========================================================================== */

$toolsHeaderTime : 0.4s;
$overlayTime     : 0.4s;
$fastTransTime   : 0.25s;
$defTransTime    : 0.4s;
$slowTransTime   : 0.8s;

$toolsHeaderFunc : cubic-bezier(0.1, 0.6, 0.4, 1);
$overlayFunc     : cubic-bezier(0.1, 0.6, 0.4, 1);
$defTransFunc    : ease-in-out;

$easeInOutQuint: cubic-bezier(0.1, 0.6, 0.4, 1);

$trans-time--default: 0.4s;
$trans-time--fast: 0.25s;
$trans-time--slow: 0.8s;
$trans-func--default: cubic-bezier(0.1, 0.6, 0.4, 1);
$trans-func--easing: ease-in-out;
$trans-func--easing-out: cubic-bezier(0.1, 0.9, 0.3, 1);

/* ==========================================================================
   Inputs
   ========================================================================== */
$input-size-large: 3.5rem;

/* ==========================================================================
   Toolbar
   ========================================================================== */
$toolbarHeightDesktop: 6rem;
$toolbarHeightMobile: 4rem;

/* ==========================================================================
   Header
   ========================================================================== */
$header-height--desktop: 6rem;
$header-height--mobile: 4.5rem;
$header-height--mobile-nav: 3rem;

/* ==========================================================================
   Full height / illusion of completeness reduction
   ========================================================================== */
$full-height-vh--desktop: 100vh;
$full-height-svh--desktop: 100svh;
$full-height-vh--mobile: calc(100vh - #{$header-height--mobile});
$full-height-svh--mobile: calc(100svh - #{$header-height--mobile});

/* ==========================================================================
   Global Banner
   ========================================================================== */
$global-banner-height--desktop: 4rem;
$global-banner-height--mobile: 3rem;

/* ==========================================================================
   rounded corners and border
   ========================================================================== */

$borderWidth: 	1px; 		// eg for buttons

@mixin borderRadius {
	border-radius: 2px;
}

$backdrop-background: rgba(255, 255, 255, 0.8);
$backdrop-background-info-layer: rgba(250, 250, 253, 0.8);
$backdrop-blur: blur(12px);

/* ==========================================================================
   Facebook shopfinder
   ========================================================================== */
$facebook-shopfinder-map-width: 810px;
$facebook-shopfinder-map-height: 480px;
$facebook-shopfinder-container-width: 738px;
$facebook-shopfinder-cell-width: 238px;
$facebook-shopfinder-base-space: 36px;

// z-indexing
$z-index: (
    filter-backdrop: 4,
    header: 5,
    filter-container: 6,
    info-layer: 20,
    mobile-product-filter: 500,
    toolsheader: 1000,
    toolsheader-active: 1001,
    info-layer-nl-signup: 1500,
    overlay-backdrop: 2000,
    overlay: 2001,
    tooltip: 3500,
    lookbook-products: 4000,
    lookbook-pagination: 4001,
    toolbar-elements: 4500,
    toolbar: 4501,
    mobile-nav: 5000,
    navigation-overlay-blur: 5100,
    navigation-overlay: 5101,
    navigation-dialog-backdrop: 5500,
    navigation-dialog: 5501,
    notification: 5600,
    info-layer-newsletter: 5700,
    info-layer-async: 5701,
    notification-bar: 5801,
    select2-mask: 6000,
    select2-dropdown: 6001,
    pikaday: 6100,
    pikaday-label: 6101,
    timify-booking: 6200,
    loading-layer: 7000,
    toolbar-close: 8000,
);