// One Column
@include breakpoint(one) {
    .L-SF-Map {
        height: 2.75 * $moduleH;        
        
        // Bigger SF / Map
        .b1 {
            top:  0;
            left: 0;
            height: 2 * $moduleH;    
        }
        
        // Small / Shop Select
        .b2 {
            top:  2 * $moduleH;
            left: 0 * $moduleW;  
        }
        
        // Small / Shop Search
        .b3 {
            top:  2.5 * $moduleH;
            left: 0 * $moduleW;    
        }
        
        // Small / Other Shops
        .b4 {
            display: none;   
        }
    }
}

// 2 columns
@include breakpoint(two) {
    .L-SF-Map {
        height: 2.25 * $moduleH;        
        
        // Bigger SF / Map
        .b1 {
            top:  0;
            left: 0;    
        }
        
        // Small / Shop Select
        .b2 {
            top:  2 * $moduleH;
            left: 0 * $moduleW;
            height: 0.5 * $moduleH;    
        }
        
        // Small / Shop Search
        .b3 {
            top:  2 * $moduleH;
            left: 1 * $moduleW;
            height: 0.5 * $moduleH;    
        }
        
        // Small / Other Shops
        .b4 {
            display: none;   
        }
    }       
}

// 3 columns
@include breakpoint(three) {
    .L-SF-Map {
        height: 3 * $moduleH;        
        
        // Bigger SF / Map
        .b1 {
            top:  0;
            left: 0;    
        }
        
        // Small / Shop Select
        .b2 {
            top:  2.5 * $moduleH;
            left: 0 * $moduleW;
        }
        
        // Small / Shop Search
        .b3 {
            top:  2.5 * $moduleH;
            left: 1 * $moduleW;  
        }
        
        // Small / Other Shops
        .b4 {
            top: 2.5 * $moduleH;
            left: 2 * $moduleW;    
        }
    }       
}

// 4 columns
@include breakpoint(four) {
    .L-SF-Map {
        height: 3 * $moduleH;        
        
        // Bigger SF / Map
        .b1 {
            top:  0;
            left: 0;
            width: 4 * $moduleW;
            height: 2.5 * $moduleH;     
        }
        
        // Small / Shop Select
        .b2 {
            top:  2.5 * $moduleH;
            left: 0 * $moduleW;  
        }
        
        // Small / Shop Search
        .b3 {
            top:  2.5 * $moduleH;
            left: 1 * $moduleW; 
        }
        
        // Small / Other Shops
        .b4 {
            top: 2.5 * $moduleH;
            left: 2 * $moduleW; 
        }
    }        
}

// 5 columns
@include breakpoint(five) {
    .L-SF-Map {
        height: 3.5 * $moduleH;        
        
        // Bigger SF / Map
        .b1 {
            top:  0;
            left: 0;
            height: 3 * $moduleH; 
            width: 5 * $moduleW;    
        }
        
        // Small / Shop Select
        .b2 {
            top:  3 * $moduleH;
            left: 0 * $moduleW;
        }
        
        // Small / Shop Search
        .b3 {
            top:  3 * $moduleH;
            left: 1 * $moduleW; 
        }
        
        // Small / Other Shops
        .b4 {
            top: 3 * $moduleH;
            left: 2 * $moduleW; 
        }
    }       
}

.L-SF-Map {
    .b2,
    .b3,
    .b4 {
        height: 0.5 * $moduleH;     
    }
} 