// Header navigation
.header__nav-functions {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: background $trans-time--fast $trans-func--default,
                color $trans-time--fast $trans-func--default,
                transform $trans-time--fast $trans-func--default;

    * {
        box-sizing: border-box;
    }

    .nav-main__search {
        .btn {
            color: inherit;
        }

        .nav-item-icon {
            width: 1.7rem;
            height: 1.7rem;
        }

        .svg-ico {
            transition: color $trans-time--fast $trans-func--default;
        }

        svg {
            stroke: currentColor;
        }
    }

    @include breakpoint(mobile) {

    }

    @include breakpoint(desktop) {
        .header__nav-functions-item--search-mobile,
        .header__nav-functions-item--overlay-toggle {
            display: none;
        }
    }

    @include breakpoint(mobile) {
        position: fixed;
        right: 0;
        bottom: 0;
        padding-right: calc((100% - #{$moduleW-rem}) / 2);
        justify-content: flex-end;
        width: 50%;
        z-index: zIndex(header);
        height: $header-height--mobile;
        background: #fff;
        --text-color: #000;

        @include hide-mobile-nav;

        // Hide specific icons on mobile
        .header__nav-functions-item--localisation,
        .header__nav-functions-item--user,
        .header__nav-functions-item--search-desktop {
            display: none;
        }
    }

    @include breakpoint(two) {
        padding-right: calc((100% - #{2 * $moduleW-rem}) / 2);
    }

    @include breakpoint(one) {
        padding-right: calc((100% - #{$moduleW-rem}) / 2);
    }
}

.header__nav-functions-list {
    display: flex;
    align-items: center;
    gap: 1.5rem;

    @include breakpoint(mobile) {
        gap: 1.25rem;
    }
}

.header__nav-functions-item--localisation {
    @include typoZurich;
    @include typoZurich1-rem;
}

// Navigation icons
.nav-item-icon {
    display: inline-block;
    position: relative;
    width: 1.9rem;
    height: 1.9rem;

    .svg-ico {
        width: 100%;
        height: 100%;
    }

    .nav-item-icon__count {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: flex-start;
        width: 1.9rem;
        height: 1.9rem;

        span {
            @include typoZurich;
            display: inline-block;
            width: 100%;
            text-align: center;
            font-size: 0.8rem;
            line-height: 1;
            margin-top: 0.75rem;
            transition: color $trans-time--fast $trans-func--default;
            color: var(--text-color, #000);


            .ios &,
            .safari & {
                margin-top: 0.8rem;
            }
        }

        &[data-count='0'] {
            display: none;
        }
    }

    @include breakpoint(mobile) {
        width: 1.6rem;
        height: 1.6rem;

        .nav-item-icon__count {
            width: 1.6rem;
            height: 1.6rem;

            &.nav-item-icon__count--cart {
                span {
                    margin-top: 0.6rem;

                    .ios &,
                    .safari & {
                        margin-top: 0.7rem;
                    }
                }
            }
        }
    }
}

// Desktop Header search
// ---------------------
.header__nav-functions-item--search-desktop {
    display: flex;
}

.header-search {
    width: 0;
    padding: 0;
    pointer-events: none;
    overflow: hidden;
    transition: width $trans-time--default $trans-func--default,
    opacity $trans-time--default $trans-func--default,
    margin $trans-time--default $trans-func--default;
    transform: none;
    opacity: 1;

    .header-search__input {
        flex: 1 1 auto;
        background: none;
        margin: 0;
        height: 1.9rem;
        line-height: 1.9rem;
        color:  var(--text-color, #000);
        border-bottom: solid 1px var(--text-color, #000);
        padding: 0 0.25rem;
        width: 100%;

        &::placeholder {
            color: $textGrey;
        }
    }

    &[aria-expanded='true'] {
        width: 16rem;
        opacity: 1;
        pointer-events: all;
        margin-right: 0.5rem;
    }

    @include breakpoint(mobile) {
        display: none;
    }
}

.btn.btn--search-toggle {
    display: grid;
    grid-template-columns: 1fr;
    width: 1.9rem;
    height: 1.9rem;

    > .nav-item-icon {
        grid-row-start: 1;
        grid-column-start: 1;
        transition: opacity $trans-time--fast $trans-func--default;
        opacity: 0;
    }

    @include breakpoint(mobile) {
        display: none;
    }
}

.header-search[aria-expanded='false'] + .btn.btn--search-toggle .nav-item-icon:first-child {
    opacity: 1;
}

.header-search[aria-expanded='true'] + .btn.btn--search-toggle .nav-item-icon:last-child {
    opacity: 1;
}


.btn.btn--overlay-toggle {
    display: grid;
    grid-template-columns: 1fr;

    .nav-item-icon {
        grid-row-start: 1;
        grid-column-start: 1;
        transition: opacity $trans-time--fast $trans-func--default;
        opacity: 1;

        &:last-child {
            opacity: 0;
        }
    }

    @include breakpoint(desktop) {
        display: none;
    }

    .has-navigation-overlay & {
        .nav-item-icon {
            &:first-child {
                opacity: 0;
            }

            &:last-child {
                opacity: 1;
            }
        }
    }
}