/* ==========================================================================
   Checkout - General
   ========================================================================== */

/* Checkout Table
   ========================================================================== */
.checkout-table {
    box-sizing: border-box;
    position: relative;
    margin-bottom: $moduleH-rem / 4;

    .checkout-table-row {
        display: flex;
        justify-content: flex-start;
        box-sizing: border-box;

        &.checkout-table-row--head {
            padding-bottom: 1rem;
            border-bottom: solid 1px $border-color-dark;
        }

        &.checkout-table-row--foot {
            padding-top: 1rem;
            border-top: solid 1px $border-color-dark;
        }
    }

    .checkout-table-cell,
    .checkout-table-subcell  {
        box-sizing: border-box;
        position: relative;
        text-align: left;
    }

    .checkout-table-label {
        @include typoZurich;
        @include typoZurich1-rem;
    }

    .checkout-table-label--bold {
        @include typoZurichBd;
        @include typoZurich1-rem;
    }

    .checkout-table-label--small {
        @include typoZurich;
        @include typoZurich0-rem;
        text-transform: none;
    }

    .checkout-table-label--lower {
        text-transform: none;
    }
}

.checkout-process-introduction {
    position: relative;
    margin-bottom: $moduleH-rem / 4;
    text-align: left;

    h2 {
        @include typoZurichBd;
        @include typoZurich2-rem;
    }

    p {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        margin-bottom: 0;
    }
}

/* General outer checkout container
   ========================================================================== */
.checkout-process {
    // Error Messages
    .error-message {
        margin-bottom: 4rem;
        text-align: left;

        h2 {
            @include typoZurichBd;
            @include typoZurich2-rem;
            text-transform: none;
            margin-bottom: 0.5rem;
            color: $inputErrorDark;
        }

        p {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
            color: $inputErrorDark;
            margin-bottom: 0;
        }

        &.error-message--payment-fail {
            p {
                margin-top: 0.5rem;
            }
        }

        &.error-message--hidden {
            transition-property: max-height, opacity, margin;
            transition-duration: $defTransTime;
            transition-timing-function: $defTransFunc;

            opacity: 0;
            max-height: 0;
            overflow: hidden;
            margin-bottom: 0;

            &.is-visible {
                margin-bottom: 4rem;
                max-height: 4rem;
                opacity: 1;
            }
        }
    }
}

/* General container, that contains main info on left and basket on right
   ========================================================================== */
.checkout-process-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .checkout-process-column {
        box-sizing: border-box;
        display: inline-block;
    }

    // Sections
    .checkout-process-section {
        @include flex-display;
        @include flex-wrap;
        width: 100%;
        position: relative;
        box-sizing: border-box;

        &.checkout-process-section--hideable {
            @extend %hideable-container;
        }
    }

    .checkout-process-section-headline {
        display: inline-block;
        text-align: left;
    }

    .checkout-process-section-content {
        transition: opacity $defTransTime linear;
        display: inline-block;
        text-align: left;
        padding-bottom: 4rem;
        opacity: 1;

        &.is-loading {
            opacity: 0.5;
        }
    }

    .checkout-process-section-action {
        display: block;
        width: 100%;
    }


    .checkout-process-section-hint {
        display: block;
        width: 100%;
        margin-top: 1rem;
        text-align: left;

        &.checkout-process-section-hint--terms {
            margin-top: -2rem;
            margin-bottom: 2rem;
        }
    }

    // Typography
    p {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
    }

    h1 {
        @include typoZurichBd;
        @include typoZurich1-rem;
        margin-bottom: 2rem;

        @include breakpoint(two) {
            @include typoZurich3-rem;
        }
        @include breakpoint(desktop) {
            @include typoZurich4-rem;
        }
    }

    h4 {
        @include typoZurich;
        @include typoZurich1-rem;
    }

    h5 {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        margin: 0 0 0.5rem 0;
    }

    label {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        line-height: 2;
    }

    .checkout-process-section-hint p {
        @include typoZurich;
        @include typoZurich0-rem;
        text-transform: none;
        text-align: left;
        margin-bottom: 0;
    }

    // Input groups
    .select2.input--auto {
        width: calc(50% - 0.5rem);

        @include breakpoint(one) {
            width: 100%;
        }
    }

    .input-group--firstname {
        width: calc(50% - 0.5rem);
        margin-right: 0.5rem;

        @include breakpoint(one) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 1rem;
        }
    }

    .input-group--lastname {
        width: calc(50% - 0.5rem);
        margin-left: 0.5rem;

        @include breakpoint(one) {
            width: 100%;
            margin-left: 0;
        }
    }

    .input-group--street {
        width: calc(70% - 0.5rem);
        margin-right: 0.5rem;
    }

    .input-group--street-number {
        width: calc(30% - 0.5rem);
        margin-left: 0.5rem;
    }

    .form-row--address {
        display: flex;

        .country-is-canada &,
        .country-is-usa & {
            flex-direction: row-reverse;

            .input-group--street {
                margin-left: 0.5rem;
                margin-right: 0;
            }

            .input-group--street-number {
                margin-left: 0;
                margin-right: 0.5rem;
            }
        }
    }

    .input-group--zip-code {
        width: calc(40% - 0.5rem);
        margin-right: 0.5rem;
    }

    .input-group--city {
        width: calc(60% - 0.5rem);
        margin-left: 0.5rem;
    }

    .input-group--company {
        width: calc(50% - 0.5rem);
        margin-right: 0.5rem;

        @include breakpoint(one) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 1rem;
        }
    }

    .input-group--phone {
        width: calc(50% - 0.5rem);
        margin-left: 0.5rem;

        @include breakpoint(one) {
            width: 100%;
            margin-left: 0;
        }
    }

    // Basket styling
    .checkout-process-section--basket {
        margin-bottom: 4rem;

        .panel {
            width: 100%;

            h4 {
                @include typoZurich;
                @include typoZurich1-rem;
                text-transform: none;
            }

            @include breakpoint(desktop) {
                margin-top: -1rem;
            }
        }
    }

    .checkout-process-section-content--dispatch {
        .dispatch__name {
            @include typoZurich1-rem;
            display: inline-block;
        }


        // Mark dispatch costs as bold
        .dispatch__costs {
            @include typoZurichBd;
            @include typoZurich1-rem;
            text-transform: none;
            display: inline-block;
            margin-left: 0.25rem;
        }

        .dispatch__description {
            margin-top: 0.5rem;

            p {
                @include typoZurich1-rem;
                padding-bottom: 0;
            }
        }
    }

    // Responsive rendering
    @include breakpoint(desktop) {
        .checkout-process-column--main {
            padding-right: 1rem;
        }

        .checkout-process-column--side {
            padding-left: 1rem;
        }
    }

    @include breakpoint(mobile) {
        .checkout-process-section-headline {
            padding-bottom: 1rem;
        }
    }

    @include breakpoint(five) {
        .checkout-process-column--main {
            width: 3 * $moduleW-rem;
        }

        .checkout-process-column--side {
            width: 2 * $moduleW-rem;
        }

        .checkout-process-section-headline {
            width: $moduleW-rem;
        }

        .checkout-process-section-content {
            width: 2 * $moduleW-rem  - 1rem;
        }

        .sidebar-basket {
            .product-details-image {
                width: 10rem;
            }

            .product-details-info {
                padding-top: 1rem;
                flex: 1;
            }
        }
    }

    @include breakpoint(four) {
        .checkout-process-column--main {
            width: 2 * $moduleW-rem;
        }

        .checkout-process-column--side {
            width: 2 * $moduleW-rem;
        }

        .checkout-process-section-headline {
            width: 2 * $moduleW-rem - 1rem;
            padding-bottom: 1rem;
        }

        .checkout-process-section-content {
            width: 2 * $moduleW-rem - 1rem;
        }

        .sidebar-basket {
            .product-details-image {
                width: 10rem;
            }

            .product-details-info {
                padding-top: 1rem;
                flex: 1;
            }
        }
    }

    @include breakpoint(three) {
        .checkout-process-column--main {
            width: 2 * $moduleW-rem;
        }

        .checkout-process-column--side {
            width: $moduleW-rem;
        }

        .checkout-process-section-headline {
            width: 2 * $moduleW-rem - 1rem;
            padding-bottom: 1rem;
        }

        .checkout-process-section-content {
            width: 2 * $moduleW-rem - 1rem;
        }
    }

    @include breakpoint(two) {
        .checkout-process-column--main {
            width: 2 * $moduleW-rem;
        }

        .checkout-process-column--side {
            width: 2 * $moduleW-rem;
        }

        .checkout-process-section-headline {
            width: 2 * $moduleW-rem;
        }

        .checkout-process-section-content {
            width: 2 * $moduleW-rem;
        }

        .sidebar-basket {
            .product-details-image {
                width: 10rem;
            }

            .product-details-info {
                padding-top: 1rem;
                flex: 1;
            }
        }
    }

    @include breakpoint(one) {
        .checkout-process-column--main {
            width: $moduleW-rem;
        }

        .checkout-process-column--side {
            width: $moduleW-rem;
        }

        .checkout-process-section-headline {
            width: $moduleW-rem;
        }

        .checkout-process-section-content {
            width: $moduleW-rem;
        }
    }

    // Sticky checkout button
    @include breakpoint(desktop) {
        .checkout-process-section--action {
            &.is-fixed {
                position: fixed;
                top: 8rem;
                left: auto;
                width: 2 * $moduleW-rem - 1rem;
            }
        }
    }

    @include breakpoint(three) {
        .checkout-process-section--action {
            &.is-fixed {
                width: $moduleW-rem - 1rem;
            }
        }
    }
}


/* ==========================================================================
   Checkout - Shopping Basket
   ========================================================================== */

/* General Styling
   ========================================================================== */
.checkout-process,
.checkout-process--basket {
    margin-top: 8rem;

    @include breakpoint(mobile) {
        margin-top: 6rem;
    }
}

/* Checkout Table
   ========================================================================== */
.checkout-basket-table {
    // Define general form
    .checkout-basket-item-form {
        transition-property: max-height, opacity;
        transition-duration: $slowTransTime;
        transition-timing-function: $defTransFunc;
        display: block;
        max-height: 64rem;
        opacity: 1;
        overflow: hidden;

        &.is-removed {
            opacity: 0;
            max-height: 0;
        }
    }

    // Define text align
    .checkout-table-cell--quantity,
    .checkout-table-cell--total,
    .checkout-table-cell--unit-price {
        text-align: left;
    }

    // Define widths
    @include breakpoint(five) {
        .checkout-table-cell--product {
            flex: 1;
        }

        .checkout-table-cell--quantity,
        .checkout-table-cell--unit-price,
        .checkout-table-cell--total {
            width: $moduleW-rem;
        }
    }

    @include breakpoint(four) {
        .checkout-table-cell--product {
            flex: 1;
        }

        .checkout-table-cell--quantity {
            width: 12rem;
        }

        .checkout-table-cell--unit-price {
            width: 20rem;
        }

        .checkout-table-cell--total {
            width: 16rem;
        }
    }

    @include breakpoint(three) {
        .checkout-table-cell--product {
            flex: 1;
        }

        .checkout-table-cell--quantity,
        .checkout-table-cell--unit-price,
        .checkout-table-cell--total {
            width: $moduleW-rem / 2;
        }
    }

    @include breakpoint(two) {
        .checkout-table-cell--product {
            width: $moduleW-rem * 1.5;
        }

        .checkout-table-cell--quantity {
            width: $moduleW-rem / 2;
            text-align: right;
        }
    }

    @include breakpoint(one) {
        .checkout-table-cell--product {
            width: $moduleW-rem;
        }

        // Remove Total/Unit in Header
        .checkout-basket-header {
            .checkout-table-cell--quantity,
            .checkout-table-cell--unit-price,
            .checkout-table-cell--total {
                display: none;
            }
        }
    }

    // Checkout Table header
    .checkout-basket-header {
        @include breakpoint(two) {
            .checkout-table-cell--unit-price,
            .checkout-table-cell--total {
                display: none;
            }
        }

        @include breakpoint(one) {
            // Remove Total/Unit in Header
            .checkout-table-cell--quantity,
            .checkout-table-cell--unit-price,
            .checkout-table-cell--total {
                display: none;
            }
        }
    }

    // Checkout Table Item
    .checkout-basket-item {
        align-items: center;
        flex-wrap: wrap;
        padding-bottom: 1rem;

        // General things
        // Mobile labels will always be left aligned
        .checkout-table-subcell--mobile-label {
            text-align: left;

            .checkout-table-label {
                text-transform: none;
            }
        }

        // Image + Product Details
        .checkout-table-cell--product {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .checkout-table-subcell--image,
            .checkout-table-subcell--details {
                width: 50%;
            }

            p {
                margin-bottom: 0;
            }

            .box-link:hover {
                img {
                    opacity: 0.5;
                }
            }
        }

        // Quantity
        .checkout-table-cell--quantity {
            display: flex;
            align-items: flex-start;

            .checkout-table-subcell--action {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            .checkout-table-label--quantity {
                display: inline-block;
                line-height: 1.5rem;
                height: 1.5rem;
            }

            .btn--quantity {
                display: inline-block;
                height: 1.5rem;
                padding-top: 0.25rem;

                &:not([disabled]) {
                    cursor: pointer;
                }

                &[disabled] {
                    cursor: not-allowed;
                }

                &[data-action="decrease-quantity"] {
                    margin-right: 0.75rem;
                }

                &[data-action="increase-quantity"] {
                    margin-left: 0.75rem;
                }

                .svg-ico {
                    width: 1rem;
                    height: 1rem;
                }
            }
        }

        // Unit Price + Total
        .checkout-table-cell--unit-price,
        .checkout-table-cell--total {
            .checkout-table-subcell--price {
                text-align: left;
            }
        }

        // Remove mobile labels for desktop
        @include breakpoint(desktop) {
            .checkout-table-subcell--mobile-label {
                display: none;
            }
        }

        @include breakpoint(fourfive) {
            .checkout-table-subcell--image {
                .module {
                    width: $moduleW-rem;
                    height: $moduleH-rem - 2rem;
                }
            }
        }

        @include breakpoint(three) {
            .checkout-table-subcell--image {
                .module {
                    width: 16rem;
                    height: 10rem;
                }
            }
        }

        // General responsive sizing
        @include breakpoint(mobile) {
            .checkout-table-cell--unit-price,
            .checkout-table-cell--total {
                display: flex;
                align-items: center;
            }

            .checkout-table-cell--quantity,
            .checkout-table-cell--unit-price,
            .checkout-table-cell--total {
                .checkout-table-subcell {
                    width: 50%;
                }

                .checkout-table-subcell--price {
                    text-align: right;
                }
            }

            .checkout-table-cell--quantity {
                justify-content: flex-end;

                .checkout-table-subcell--action {
                    justify-content: flex-end;
                }
            }
        }

        @include breakpoint(two) {
            .checkout-table-cell--product {
                .checkout-table-subcell--image {
                    width: 12rem;

                    .module {
                        width: 12rem;
                        height: 8rem;
                    }
                }

                .checkout-table-subcell--details {
                    width: auto;
                    flex: 1;
                }
            }

            // Do not display mobile label
            .checkout-table-cell--quantity {
                .checkout-table-subcell--mobile-label {
                    display: none;
                }
            }

            .checkout-table-cell--unit-price {
                width: 32rem;
                padding-left: 12rem;
                padding-right: 4rem;
            }

            .checkout-table-cell--total {
                width: 16rem;
                padding-left: 0;
            }
        }

        @include breakpoint(one) {
            .checkout-table-cell--product {
                margin-bottom: 1rem;

                .module {
                    width: $moduleW-rem;
                    height: $moduleH-rem - 2rem;
                }

                .checkout-table-subcell--image,
                .checkout-table-subcell--details {
                    width: 100%;
                }
            }

            .checkout-table-cell--quantity,
            .checkout-table-cell--unit-price,
            .checkout-table-cell--total {
                display: flex;
                align-items: center;
                width: 100%;

                .checkout-table-subcell--mobile-label {
                    width: 50%;
                    text-align: left;
                }

                .checkout-table-subcell--price,
                .checkout-table-subcell--action {
                    width: 50%;
                    text-align: right;
                }
            }
        }
    }

    // Checkout basket footer
    .checkout-basket-footer {
        display: flex;
        flex-direction: column;

        // General styling for the items
        .checkout-table-cell {
            display: flex;
            align-items: center;
            width: 2 * $moduleW-rem;
            margin-left: auto;

            .checkout-table-subcell--label,
            .checkout-table-subcell--value {
                width: 50%;
            }

            .checkout-table-subcell--label {
                text-align: left;
            }

            .checkout-table-subcell--value {
                text-align: left;
            }

            .checkout-table-label,
            .checkout-table-label--bold,
            .checkout-table-label--small {
                text-transform: none;
            }

            // General responsive styling
            @include breakpoint(four) {
                .checkout-table-subcell--label {
                    flex: 1;
                    width: auto;
                }

                .checkout-table-subcell--value {
                    width: 16rem;
                }
            }

        }

        // Special styling for rows
        .checkout-table-cell--total {
            padding: 1rem 0;
            border-bottom: solid 1px $border-color-dark;
        }

        .checkout-table-cell--voucher {
            .remove-voucher-code {
                @include typoZurich;
                @include typoZurich0-rem;
                line-height: 1.1;
                text-transform: none;
            }
        }

        .checkout-table-cell--voucher-input {
            display: block;
            padding: 1rem 0;
            border-bottom: solid 1px $border-color-dark;

            .checkout-table-subcell--response {
                width: 100%;
            }

            .voucher-input-wrapper {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                .checkout-table-subcell--input {
                    flex: 1;
                }

                .checkout-table-subcell--apply {
                    margin-left: 1rem;
                    text-align: right;
                }
            }
        }

        // General responsive styling
        @include breakpoint(four) {
            .checkout-table-cell {
                width: 1.5 * $moduleW-rem;
            }

            .checkout-table-subcell--label {
                flex: 1;
                width: auto;
            }

            .checkout-table-subcell--value {
                width: 16rem;
            }
        }

        @include breakpoint(three) {
            .checkout-table-cell {
                width: 1.5 * $moduleW-rem;

                .checkout-table-subcell--label {
                    flex: 1;
                    width: auto;
                }

                .checkout-table-subcell--value {
                    width: 12rem;
                }
            }
        }

        @include breakpoint(mobile) {
            .checkout-table-cell {
                width: 100%;

                .checkout-table-subcell--value {
                    text-align: right;
                }
            }
        }

        @include breakpoint(one) {
            .checkout-table-cell {
                .checkout-table-subcell--label {
                    width: 70%;
                }

                .checkout-table-subcell--value {
                    width: 30%;
                }
            }
        }
    }
}

/* Checkout Actions
   ========================================================================== */
.checkout-process--basket {
    .checkout-actions {
        display: flex;
        flex-direction: column;

        .checkout-table-cell {
            display: flex;
            align-items: center;
            width: 2 * $moduleW-rem;
            margin-left: auto;
        }

        .checkout-table-cell--hint {
            margin-top: 1rem;
        }

        p {
            @include typoZurich;
            @include typoZurich0-rem;
            text-transform: none;
            text-align: left;
            margin-bottom: 0;
        }

        @include breakpoint(four) {
            .checkout-table-cell {
                width: 1.5 * $moduleW-rem;
            }
        }

        @include breakpoint(three) {
            .checkout-table-cell {
                width: 1.5 * $moduleW-rem;
            }
        }

        @include breakpoint(mobile) {
            .checkout-table-cell {
                width: 100%;
            }
        }
    }
}

/* ==========================================================================
   Checkout - Contact & Delivery
   ========================================================================== */
.checkout-process--register,
.checkout-process--account {
    // Diff Shipping address
    .form-row--diff-shipping-check {
        margin-top: 2rem;
    }

    // Extend spacing
    .form-row {
        margin-bottom: 1.25rem;
    }

    .checkout-process-section--shipping-address {
        max-height: 0;
        opacity: 0;
    }

    &.has-diff-shipping-address {
        .checkout-process-section--shipping-address {
            max-height: 64rem;
            opacity: 1;
        }
    }

    // Special styling for dispatches and payments
    .payment-listing--checkout,
    .dispatch-listing--checkout {
        label span {
            text-transform: none;
        }
    }

    // Dispatch listing two columns
    .dispatch-listing--checkout {
        p {
            margin-bottom: 0;
        }

        .dispatch__name {
            display: inline-block;
        }


        // Mark dispatch costs as bold
        .dispatch__costs {
            @include typoZurichBd;
            @include typoZurich1-rem;
            text-transform: none;
            display: inline-block;
            margin-left: 0.25rem;
        }

        .dispatch__description {
            margin-top: 0.5rem;

            p {
                padding-bottom: 0;
            }
        }
    }

    // Payments listing two columns
    .payment-listing--checkout {
        @include breakpoint(twofive) {
            display: flex;
            flex-wrap: wrap;
            margin-top: -1rem;

            .payment-entry {
                width: calc(50% - 1.1rem);
                margin-top: 1rem;
            }
        }

        .description {
            padding-left: 0;
        }

        p {
            display: none;
        }

        .payLogoSmall,
        .payment-logo {
            margin-top: 0.25rem;
            max-width: 4rem;
            float: left;
        }
    }
}

/* ==========================================================================
   Checkout - Confirmation
   ========================================================================== */
.checkout-process--confirm {
    .checkout-process-section--review {
        .link--change-details {
            position: absolute;
            right: 0;
            top: 0;
        }

        p {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
            margin-bottom: 0;
            max-width: $moduleW-rem;
        }
    }

    // Payment review
    .checkout-process-payment-review {
        p {
            display: none;
        }

        .payLogoSmall,
        .payment-logo--small {
            max-width: 4rem;
        }
    }

    // Newsletter
    .checkout-process-section--newsletter {
        margin-bottom: 4rem;
        text-align: left;

        .form-row {
            @extend %hideable-container;
            width: 100%;
        }

        .form-row--checkbox {
            margin-bottom: 0;
        }

        .form-row--newsletter {
            @extend %hideable-container-hidden;
        }

        p {
            @include typoZurich;
            @include typoZurich0-rem;
            margin-bottom: 0;
            text-transform: none;
        }
    }

    &.has-newsletter-checked {
        .form-row--checkbox {
            margin-bottom: 2rem;
        }

        .form-row--newsletter {
            max-height: 8rem;
            opacity: 1;
            margin-bottom: 2rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

/* ==========================================================================
   Checkout - Payment gateway
   ========================================================================== */
.checkout-process--payment {
    // Input group sizing
    .input-group--cc-expires {
        width: calc(50% - 0.5rem);
        margin-right: 0.5rem;

        @include breakpoint(one) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 1rem;
        }
    }

    .input-group--cc-verification {
        width: calc(50% - 0.5rem);
        margin-left: 0.5rem;

        @include breakpoint(one) {
            width: 100%;
            margin-left: 0;
        }
    }

    .checkout-process-section-action--pay {
        .checkout-payment-cancel {
            padding-top: 2rem;
            text-align: center;
        }
    }

    .checkout-process-section--heidelpay-info {
        p {
            max-width: 2 * $moduleW-rem;
        }

        @include breakpoint(five) {
            padding-left: 1 * $moduleW-rem;
        }
    }

    .heidelpay-logo {
        display: block;
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 4rem;

        img {
            max-width: 6rem;
        }
    }

    .payment-logo-redirect {
        display: block;
        width: 100%;
        margin-bottom: 2rem;
        text-align: center;

        .payment-logo {
            float: none;
        }
    }

    // Supported
    .supported-brands {
        .payment-logo {
            display: inline-block;
            margin: 0.5rem 0.5rem 0.5rem 0;
        }
    }
}

/* ==========================================================================
   Checkout - Finish/Confirmation
   ========================================================================== */
.checkout-process--finish {
    .checkout-process-section--review {
        p {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
            margin-bottom: 0;
            max-width: $moduleW-rem;
        }
    }

    .checkout-process-section--finish {
        width: 100%;

        .checkout-finish-information {
            max-width: 2 * $moduleW-rem;
            margin-bottom: 1rem;

            p {
                margin-bottom: 0;
            }
        }
    }

    .checkout-process-section--basket .panel {
        margin-top: 0;
    }
}

/* Voucher stuff
   ========================================================================== */
.row-voucher {
    margin-top: 2rem;

    .voucher-apply {
        text-align: right;

        .btn {
            display: inline-block;
        }
    }
}

.voucher-label {
    display: block;
}

.voucher-code {
    display: inline-block;
    margin-right: 1rem;
}

.row-cart-footer-voucher {
    margin-bottom: 1rem;

    .remove-voucher-code,
    .voucher-code {
        font-size: 1rem;
    }
}

.voucher-add-response {
    transition-property: max-height, opacity, margin;
    transition-duration: $defTransTime;
    transition-timing-function: $defTransFunc;
    text-align: left;
    margin-bottom: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;

    &.is-visible {
        max-height: 8rem;
        opacity: 1;
        margin-bottom: 1rem;
    }
}

/* Checkout Steps
   ========================================================================== */
.checkout-process-steps {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0 4rem 0;

    // Define steps
    .checkout-process-step {
        box-sizing: border-box;
        display: inline-block;
        position: relative;
        height: 1.5rem;

        // Mark active step as bold
        &.checkout-process-step--active {
            .checkout-process-step-name,
            .checkout-process-step-number {
                @include typoZurichBd;
                text-transform: none;
            }
        }

        &.checkout-process-step--spacer {
            width: 2rem;
            padding-top: 0.1rem;
            text-align: center;

            .svg-ico {
                width: 1.2rem;
                height: 1.2rem;
            }
        }
    }

    // Define step content
    .checkout-process-step-name,
    .checkout-process-step-number {
        @include typoZurich;
        @include typoZurich1-rem;
        line-height: 1.5rem;
        height: 1.5rem;
        text-transform: none;
    }

    .checkout-process-step-link {
        display: inline-block;
        text-decoration: none;
        color: inherit;
    }

    // Responsive
    .checkout-process-step-number--desktop {
        @include breakpoint(one) {
            display: none;
        }
    }

    .checkout-process-step-number--mobile {
        @include breakpoint(twofive) {
            display: none;
        }
    }

    @include breakpoint(one) {
        .checkout-process-step:not(.checkout-process-step--active) {
            display: none;
        }
    }
}

/* Checkout Notes
   ========================================================================== */
.checkout-process-notes {
    display: flex;
    flex-wrap: wrap;

    .checkout-process-note {
        box-sizing: border-box;
        display: inline-block;
        width: $moduleW-rem;
        padding-right: 2rem;
        text-align: left;
    }

    h3 {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        margin-bottom: 1rem;
    }

    p,
    span {
        @include typoZurich;
        @include typoZurich0-rem;
        text-transform: none;
        margin-bottom: 0;
    }

    .payment-logos {
        @extend %cf;
    }

    .payment-logo {
        max-width: 4rem;
        margin: 0 0.5rem 0.5rem 0;
        float: left;
    }
}

/* Style for used payment logos
   ========================================================================== */
.paymentLogo,
.payment-logo {
    max-width: 4rem;
    margin: 0 0.5rem 0.5rem 0;

    &.payment-logo--small {
        max-width: 2.5rem;
    }
}

.payLogoSmall,
.paymentLogoSmall {
    max-width: 2.5rem;
    margin: 0 0.5rem 0.5rem 0;
}

/* Holiday delivery
   ========================================================================== */
.holiday-delivery-hint {
    display: block;
    position: relative;
    text-align: left;
    margin-bottom: 2rem;

    p {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        margin-bottom: 0;
    }

    &.holiday-delivery-hint--cart {
        margin-top: 2rem;
    }

    &.has-border {
        border-top: solid 1px $border-color-dark;
        padding-top: 2rem;
    }

    &.holiday-delivery-hint--basket {
        margin-top: -0.75rem;
    }

    &.holiday-delivery-hint--dialog {
        margin-top: 1rem;
        margin-bottom: 0;
    }
}

/* Sales Tax hint
   ========================================================================== */
.sales-tax-hint {
    display: block;
    position: relative;
    text-align: left;
    margin-bottom: 2rem;

    p {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        margin-bottom: 0;
    }

    &.sales-tax-hint--cart {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    &.has-border {
        border-top: solid 1px $border-color-dark;
        padding-top: 1rem;
    }

    &.sales-tax-hint--basket {
        margin-top: -0.75rem;
    }

    &.sales-tax-hint--shopping-basket {
        margin-top: 1rem;
        margin-bottom: 0;
    }
}

/* Samplesale related stuff
   ========================================================================== */
.samplesale-reservation-hint {
    text-align: left;
    margin-top: 1rem;

    p {
        @include typoZurich;
        @include typoZurich1-rem;
        line-height: 1.25;
        text-transform: none;
        margin-bottom: 0;
    }
}

.checkout-process-section--newsletter {
    margin-top: -2rem;
    margin-bottom: 0.5rem;
}