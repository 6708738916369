/* ==========================================================================
   Forms
   ========================================================================== */

/* Default labels and inputs
   ========================================================================== */
label {
    display: block;
    @include typoZurich;
    @include typoZurich1-rem;
    text-transform: none;
    line-height: 2;
    text-align: left;
    box-sizing: border-box;
    margin-bottom: 0.25rem;

    a,
    .textLink {
        line-height: 1.2em;
    }

    &.label--required:after {
        content: '*';
    }
}

label.error,
label.error a {
    color: $inputErrorDark;
}

input,
input[type="search"],
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="date"],
textarea,
select,
.sbHolder,
.sbOptions li,
.select2-container {
    @include typoZurich;
    @include typoZurich1-rem;
    transition-property: color, background-color;
    transition-duration: $fastTransTime;
    transition-timing-function: $defTransFunc;
    box-sizing: border-box;
    text-transform: none;
    display: inline-block;
    background: $inputGrey2;
    padding: 0 1em;
    text-align: left;
    border: none;
    height: 3rem;
    line-height: 3rem;
    outline: none;
    border-radius: 0;

    &.full-width {
        width: 100%;
    }

    &[disabled],
    &[readonly] {
        cursor: not-allowed;
    }

    &.input--large {
        height: $input-size-large;
        line-height: $input-size-large;
    }

    &.input--fit {
        width: 100%;
    }

    &.input--border {
        background: $back-color--default;
        border: solid 1px $border-color--light-2;

        &::placeholder {
            color: $border-color--light-2;
        }
    }

    &::placeholder {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
    }
}

// Disable date spinners
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.ui-menu-item a,
.sbOptions li {
    height: 2.5rem;
    line-height: 2.5rem;
    display: block;
}

textarea {
    line-height: 1.5em;
    resize: none;
    padding: 1em 0 1em 1em;

    &[data-autosize="true"] {
        padding: 0.75em 1em;
        overflow-y: hidden;
        min-height: 6rem;
    }
}

// Input is not correct
.onGrey .inputError,
.onGrey .input--error,
.inputError,
.input--error {
    background-color: $inputError !important;
}

select.inputError + .sbHolder,
select.input--error + .sbHolder {
    background-color: $inputError !important;
}

.error {
    color: $inputErrorDark;
    text-transform: none;
    text-align: left;

    .textLink,
    .text-link{
        border-color: $inputErrorDark;
    }

    label {
        color: $inputErrorDark;
        a {
            color: $inputErrorDark;
            border-color: $inputErrorDark;
        }
    }

    span,
    h1,
    h2,
    h3,
    h4,
    label,
    a {
        color: $inputErrorDark !important;
    }
}

.success {
    //color: $inputSuccessDark;
    text-transform: none;
    text-align: left;
}

.inputHintWrapper {
    display: block;
    width: 20em;
    padding-top: 1em;
    text-align: left;

    span {
        @include typoZurich;
        @include typoZurich1-rem;
        line-height: 1.3em;
        text-transform: none;

        a,
        .textLink {
            font-size: 1em;
        }
    }
}

.formError {
    h2,
    span {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        color: $inputErrorDark;
    }
}

/* Checkboxes and Radiobuttons
   ========================================================================== */
input[type="checkbox"] + label,
input[type="radio"] + label {
    cursor: pointer;
    position: relative;
    display: inline-block;
    line-height: 2rem;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 0;

    &.inline {
        width: auto;
    }

    span {
        box-sizing: border-box;
        display: block;
        height: inherit;
        line-height: inherit;
    }
}

input[type="radio"],
input[type="checkbox"] {
    @include hide;
}

// Specific styles for checkboxes
input[type="radio"] + label:before,
input[type="checkbox"] + label:before,
input[type="radio"] + label:after,
input[type="checkbox"] + label:after {
    content: '';
    transition-property: opacity, border;
    transition-duration: $fastTransTime;
    transition-timing-function: $defTransFunc;
    box-sizing: border-box;
    position: absolute;
    display: inline-block;
}

// Before
input[type="radio"] + label:before,
input[type="checkbox"] + label:before {
    width: 1.4rem;
    height: 1.4rem;
    top: 0.3rem;
    left: 0;
    background: $inputGrey2;
    opacity: 1;
}

// After
input[type="radio"] + label:after,
input[type="checkbox"] + label:after {
    width: 0.8rem;
    height: 0.8rem;
    top: 0.6rem;
    left: 0.3rem;
    background: #000;
    opacity: 0;
}

// Special styling for radiobuttons
input[type="radio"] + label:before,
input[type="radio"] + label:after {
    border-radius: 100%;
}

// Is checked
input[type="radio"]:checked + label:after,
input[type="checkbox"]:checked + label:after {
    opacity: 1;
}

input[type="radio"] + label.error:before,
input[type="checkbox"] + label.error:before {
    background-color: $inputError;
}

// Enhanced checkbox
//input[type="checkbox"].enhanced-checkbox {
//    & + label {
//        line-height: 1.5;
//        padding-left: 2.5rem;
//        padding-right: 0;
//
//        &:before {
//            top: 0.1rem;
//            border: solid 1px $border-color-dark;
//            background: none;
//        }
//
//        &:after {
//            background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMDAiIGhlaWdodD0iMjAwIj48cGF0aCBkPSJNMTY0IDQzLjUzTDE1Ni40NyAzNiAxMDAgOTIuNDkgNDMuNTMgMzYgMzYgNDMuNTNsNTYuNDcgNTYuNDktNTYuNDQgNTYuNDUgNy41MyA3LjUzTDEwMCAxMDcuNTUgMTU2LjQ0IDE2NGw3LjUzLTcuNTMtNTYuNDQtNTYuNDV6Ii8+PC9zdmc+');
//            background-size: 1rem 1rem;
//            background-position: 50% 50%;
//            background-repeat: no-repeat;
//            top: 0.1rem;
//            left: 0;
//            width: 1.4rem;
//            height: 1.4rem;
//            line-height: 1.4rem;
//            text-align: center;
//        }
//    }
//
//    &.input--error + label {
//        &:before {
//            background: $back-color--input-error;
//        }
//    }
//}

// Enhanced radiobutton
//input[type="radio"].enhanced-radiobutton {
//    & + label {
//        line-height: 1.5;
//        padding-left: 2.5rem;
//        padding-right: 0;
//
//        span {
//            line-height: inherit;
//        }
//
//        .description {
//            margin-top: 0.5rem;
//        }
//
//        &:before {
//            top: 0.1rem;
//            border: solid 1px $border-color-dark;
//            background: none;
//            border-radius: 50%;
//        }
//
//        &:after {
//            background: $border-color-dark;
//            top: 0.4rem;
//            left: 0.3rem;
//            width: 0.8rem;
//            height: 0.8rem;
//        }
//    }
//
//    &.input--error + label {
//        &:before {
//            background: $back-color--input-error;
//        }
//    }
//}

// Option Group
.option-group {
    @include pseudo-list;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    li {
        display: inline-block;

        &:not(:first-child) {
            margin-left: 2rem;
        }
    }
}

/* Buttons and Submits
   ========================================================================== */
button,
input[type="submit"],
a.button,
.btn {
    box-sizing: border-box;
    transition: color $trans-time--default $trans-func--easing,
                opacity $trans-time--default $trans-func--easing,
                border $trans-time--default $trans-func--easing,
                background-color $trans-time--default $trans-func--easing;
}

button,
input[type="submit"],
a.button {
    text-align: left;
    background: transparent;
    display: inline-block;
    cursor: pointer;
    padding: 0 1em 0 0;
    width: 18em;
    height: 3em;
    line-height: 3em;
    border-radius: 0;
    color: black;
}

// Firstbutton has a top border
button:first-child,
button.firstButton,
input[type="submit"]:first-child,
input[type="submit"].firstButton,
a.button:first-child,
a.button.firstButton {
    border-top: $borderWidth solid $borderGrey;
}

// Default button apperance
button,
a.button,
input[type="submit"] {
    @include typoZurich;
    @include typoZurich1-rem;
}

// Primary button appearance
button.primary,
a.button.primary,
input[type="submit"].primary {
    @include typoZurichBd;
    @include typoZurich1-rem;
}

input[type="submit"],
input[type="submit"].primary,
button,
button.primary {
    line-height: 100%;
}

a.button,
a.button.primary {
    line-height: 3em;
}

.btn.btn--left-aligned,
.button.button--left-aligned {
    text-align: left;
}

// Real buttons with full border
button.fullBorder,
a.button.fullBorder,
input[type="submit"].fullBorder {
    border-top: $borderWidth solid $borderGrey;
    border-bottom: $borderWidth solid $borderGrey;
}

// Secondary Buttons
button.halfBorder,
a.button.halfBorder,
input[type="submit"].halfBorder {
    border-bottom: $borderWidth solid $borderGrey;
}

// Forward button
button.buttonForward,
a.button.buttonForward {
    background: url('../images/form/forward.png');
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 95% 50%;
    background-size: 1em 1em;
}

// Hide specific selectboxes
select.selectbox {
    display: none;
}

//.onGrey {
    input:-webkit-autofill,
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="search"],
    .select,
    textarea,
    .sbHolder,
    .sbOptions,
    .sbOptions li,
    .sbToggle,
    .sbToggleOpen {
        background-color: $inputGrey2;
    }

    .sbOptions li:hover {
        background-color: $inputGrey2Hover;
    }
//}

input.readonly {
    background: $inputGrey;
}

.no-touch {
    button:hover,
    a.button:hover,
    input[type="submit"]:hover {
        //background-color: $backGrey;
        color: rgb(150, 150, 150);
    }

    .onGrey button:hover,
    .onGrey a.button:hover,
    .onGrey input[type="submit"]:hover {
        //background-color: $inputGrey2;
        color: rgb(150, 150, 150);
    }
}

legend {
    @include typoZurich;
    @include typoZurich0-rem;
    line-height: 2.5em;
    text-align: left;
}

/* Special sizing for special fields
   ========================================================================== */
.two-column .gbox {
    .street,
    .streetnumber,
    .zipcode,
    .city,
    .retailer-firstname,
    .retailer-lastname {
        display: inline-block;
        float: left;
    }

    .street {
        width: 18rem;
    }

    .streetnumber {
        width: 5rem;
        margin-left: 1rem;
    }

    .zipcode {
        width: 6rem;
    }

    .city {
        width: 17rem;
        margin-left: 1rem;
    }

    .retailer-firstname {
        width: 11.5rem;
    }

    .retailer-lastname {
        width: 11.5rem;
        margin-left: 1rem;
    }
}

@include breakpoint(two) {
    .two-column .gbox {
        .street {
            width: 16rem;
        }

        .streetnumber {
            width: 5rem;
            margin-left: 1rem;
        }

        .zipcode {
            width: 6rem;
        }

        .city {
            width: 15rem;
            margin-left: 1rem;
        }

        .retailer-firstname {
            width: 10.5rem;
        }

        .retailer-lastname {
            width: 10.5rem;
            margin-left: 1rem;
        }
    }
}

@include breakpoint(desktop) {
    .two-column .gbox {
        .street {
            width: 24rem;
            display: inline-block;
        }

        .streetnumber {
            width: 6rem;
            margin-left: 2rem;
            display: inline-block;
        }

        .zipcode {
            width: 10rem;
            display: inline-block;
        }

        .city {
            width: 20rem;
            margin-left: 2rem;
            display: inline-block;
        }

        .retailer-firstname {
            width: 15rem;
            display: inline-block;
        }

        .retailer-lastname {
            width: 15rem;
            margin-left: 2rem;
            display: inline-block;
        }
    }
}

/* Static information
   ========================================================================== */
form .static {
    @include typoZurich;
    @include typoZurich1-rem;
    box-sizing: border-box;
    height: 3rem;
    line-height: 3rem;
    margin-bottom: 0;
}


/* Error Listing
   ========================================================================== */
.form-error-listing,
.form-success-listing {
    text-align: left;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0 0 4rem 0;

        @include breakpoint(mobile) {
            margin-bottom: 2rem;
        }
    }

    &.form-error-listing-small {
        ul {
            margin-bottom: 2rem;
        }
    }

    li {
        display: block;
        margin-bottom: 0.25rem;
        @include typoZurich;
        @include typoZurich0-rem;
        text-transform: none;
    }

    li.indent {
        padding: 0 1.5rem;
        display: block;
        @include typoZurich0-rem;
        text-transform: none;
    }

    li.size-default {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
    }

    li.indent:before {
        content: '\2f';
        text-indent: -1.5rem;
        display: inline-block;
        @include typoZurich0-rem;
    }
}

.form-error-listing {
    li,
    li:before,
    li.indent,
    li.indent:before{
        color: $inputErrorDark;
    }
}

.form-success-listing {
    li {
        margin-bottom: 0;
    }
}

// Remove underlines from links, that behave like buttons
a.btn {
    text-decoration: none;
    border: none;
}


/* Buttons
   ========================================================================== */
button,
.btn {
    @include typoZurich;
    @include typoZurich1-rem;
    display: block;
    height: 3rem;
    line-height: 3rem;
    cursor: pointer;
    border: none;
    text-align: center;
    outline: none;
    text-transform: none;
    padding-right: 0;

    .no-touch & {
        &:hover {
            color: rgb(150, 150, 150);
        }
    }

    &.btn-primary,
    &.btn--primary {
        @include typoZurichBd;
        @include typoZurich1-rem;
        line-height: 3em;

        .no-touch & {
            &:hover {
                color: rgb(150, 150, 150);
                border-color: rgb(150, 150, 150);
            }
        }
    }

    &.btn-outline,
    &.btn--outline {
        border: solid 1px #000;
    }

    &.btn-half-outline,
    &.btn--half-outline {
        border-top: solid 1px #000;
        border-bottom: solid 1px #000;
    }

    &.btn-underlined {
        text-decoration: underline;
    }

    &.btn-centered,
    &.btn--centered {
        text-align: center;
        padding-right: 0;
    }

    &.btn-clean {
        border: none;
    }

    &.btn-auto,
    &.btn--auto {
        width: auto;
        padding-left: 2em;
        padding-right: 2em;
    }

    &.btn-full,
    &.btn--full {
        width: 100%;
    }

    &.btn-ico,
    &.btn--ico,
    &.btn--icon {
        width: auto;
        height: auto;
        line-height: 1;
        border: none;
        text-align: center;
        padding: 0;
    }

    &.btn--clean {
        border: none;
        padding: 0;
        width: auto;
        background: none;
    }

    &.btn--primary {
        @include typoZurichBd;
        @include typoZurich1-rem;
        height: 3.5rem;
        line-height: 3.5rem;
        width: 100%;

        &:not(.btn--half-outline) {
            border: solid 1px #000;
        }

        .no-touch & {
            &:hover {
                color: rgb(150, 150, 150);
                border-color: rgb(150, 150, 150);
            }
        }
    }

    &.btn--secondary {
        @include typoZurich;
        @include typoZurich1-rem;
        height: 3.5rem;
        line-height: 3.5rem;
        width: 100%;

        &:not(.btn--half-outline) {
            border: solid 1px #000;
        }

        &:hover {
            color: rgb(150, 150, 150);
            border-color: rgb(150, 150, 150);
        }
    }

    &.btn--cta {
        @include typoZurich;
        @include typoZurich2-rem;
        text-transform: none;
        height: 3rem;
        line-height: 3rem;
        width: auto;
        border: solid 1px #000;
        padding: 0 2rem;

        &:hover {
            color: rgb(150, 150, 150);
            border-color: rgb(150, 150, 150);
        }

        @include breakpoint(mobile) {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
            height: 3rem;
            line-height: 3rem;
        }
    }

    &.btn--large {
        height: 4rem;
        line-height: 4rem;
    }

    &.btn--inverted {
        background-color: #000;
        color: #fff;

        &.btn--primary,
        &.btn-primary {
            @include typoZurich;
            @include typoZurich2-rem;
            height: 4rem;
            line-height: 4rem;
            border: none;
        }

        .no-touch & {
            &:hover {
                background-color: rgb(50, 50, 50);
                color: #fff;
            }
        }
    }

    &.btn--link {
        font-size: 1.1rem;
        line-height: 1.1;
        display: inline-block;
        height: auto;
        width: auto;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: solid 1px #000;

        .no-touch & {
            &:hover {
                border-color: rgb(150, 150, 150);
            }
        }
    }

    &.btn--link-context {
        display: inline;
        font-size: inherit;
        font-weight: inherit;
    }

    &.btn--clean {
        font-size: 1.1rem;
        line-height: 1.1;
        display: inline-block;
        height: auto;
        width: auto;
        border: none;
    }

    &.btn--dot {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: none;
        text-indent: -999rem;
        overflow: hidden;
        background-color: #b3b3b3;
        border-radius: 100%;

        &.is-active {
            background-color: #000;
        }
    }

    &.box-link {
        border: none;
        background: none;

        &:hover {
            color: inherit;
        }
    }

    &[disabled] {
        cursor: not-allowed;
        color: rgb(150, 150, 150);
        border-color: rgb(150, 150, 150);
    }
}

.hairlines {
    .btn.btn--link {
        border-width: 0.5px;
    }
}

/* Input groups
   ========================================================================== */
.input-group {
    display: inline-block;
    float: left;
}

/* Date select
   ========================================================================== */
.date-field-wrapper {
    position: relative;
    display: inline-block;

    .btn-calendar {
        position: absolute;
        display: inline-block;
        top: 0.5em;
        right: 1em;
        padding: 0;
        width: 2em;
        height: 2em;

        .ico-myk {
            width: 2em;
            height: 2em;
            background-size: 1.25em 1.25em;
            background-position: 50% 50%;

            &.ico-close {
                background-size: 1.5em 1.5em;
            }
        }
    }

    .btn-calendar[data-action="hide-calendar"] {
        display: none;
    }

    &.is-open {
        .btn-calendar[data-action="hide-calendar"] {
            display: inline-block;
        }

        .btn-calendar[data-action="show-calendar"] {
            display: none;
        }
    }
}

// Flex grid
.flex-grid-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-align: left;
    width: 100%;
    max-width: 2 * $moduleW-rem;
}

.flex-row {
    display: flex;
    width: 100%;

    .flex-row__cell {
        display: inline-block;
        position: relative;
        width: 100%;

        &:not(:first-child) {
            margin-left: 1rem;
        }
    }

    & + .flex-row {
        margin-top: 2rem;
    }
}

// Form Response
// -------------
.form-response {
    opacity: 1;
    max-height: 8rem;
    margin-bottom: 2rem;

    &[aria-expanded] {
        overflow: hidden;
        transition: max-height $defTransTime $defTransFunc,
                    opacity $defTransTime $defTransFunc,
                    margin $defTransTime $defTransFunc,
                    color $defTransTime $defTransFunc;
    }

    &[aria-expanded="false"] {
        max-height: 0;
        opacity: 0;
        margin-top: 0;
        margin-bottom: 0;
        overflow: hidden;
    }

    &[aria-expanded="true"] {
        &.form-response--below {
            margin-bottom: 0;
            margin-top: 1rem;
        }
    }

    p {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
    }

    &.is-error {
        color: $inputErrorDark;

        p {
            color: $inputErrorDark !important;
        }
    }
}