@mixin input() {
    input[type="text"],
    input[type="email"],
    input[type="search"],
    input[type="password"],
    input[type="submit"],
    textarea,
    a.button,
    button,
    select,
    .sbHolder,
    .sbOptions,
    .select2,
    .select2-container,
    .form-error-listing {
        @content;
    }
}

@mixin flex-display() {
    display: flex;
}

@mixin flex-wrap() {
    flex-wrap: wrap;
}

@mixin flex-wrap-reverse() {
    flex-wrap: wrap-reverse;
}

@mixin flex-direction-row() {
    flex-direction: row;
}

@mixin flex-direction-column() {
    flex-direction: column;
}

@mixin flex-justify($direction) {
    justify-content: $direction;
}

@mixin flex-justify-content($direction) {
    justify-content: $direction;
}

@mixin flex-align-content($direction) {
    align-content: $direction;
}

@mixin flex-align-items($direction) {
    align-items: $direction;
}

@mixin flex-align-self($direction) {
    align-self: $direction;
}

/// Mixin for fluid typography
///
/// @group humans-machines
/// @param {list} $properties - The properties to apply the calculation e.g. font-size, padding, ...
/// @param {number} $min-vw - The min viewport width in pixel
/// @param {number} $max-vw - The max viewport width in pixel
/// @param {number} $min-value - The min value to apply
/// @param {number} $max-value - The max value to apply
@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
    @each $property in $properties {
        #{$property}: $min-value;
    }

    @media screen and (min-width: $min-vw) {
        @each $property in $properties {
            #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
        }
    }

    @media screen and (min-width: $max-vw) {
        @each $property in $properties {
            #{$property}: $max-value;
        }
    }
}

/// Hide Elements Visually without loosing accessebility
/// Ref: https://css-tricks.com/places-its-tempting-to-use-display-none-but-dont/
///
/// @group humans-machines
@mixin hide {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px !important;
    padding: 0;
    border: 0;
}

/// Mixin for a pseudo list like a navigation
///
/// @group humans-machines
@mixin pseudo-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

/// Nice mixins to have a delay cascade
///
/// @group humans-machines
@mixin transition-delay($delay: 0.1s, $global-delay: 0s, $items: 20) {
    @for $i from 0 through $items {
        &:nth-child(#{$i}) {
            transition-delay: $i * $delay + $global-delay;
        }
    }
}

/// Mixin to fade elements against background with a gradient
///
/// @group humans-machines
/// @param {number} $side - side of the cover element
/// @param {number} $width - width of the overlaying gradient
/// @param {number} $color - background-color of the overlaying gradient
@mixin fade-cover($side: right, $size: 1.6em, $color: $back-color--default) {
    &:after {
        content: '';
        position: absolute;
        z-index: 1;
        pointer-events: none;

        // gradient on the right - default
        @if $side == 'right' {
            background: linear-gradient(to right, rgba($color, 0), $color);
            top: 0;
            bottom: 0;
            width: $size;
            right: 0;

            // gradient on the left
        } @else if $side == 'left' {
            background: linear-gradient(to right, $color, rgba($color, 0));
            left: 0;
            top: 0;
            bottom: 0;
            width: $size;

            // gradient on the bottom
        } @else if $side == 'bottom' {
            background: linear-gradient(to bottom, $color, rgba($color, 0));
            left: 0;
            right: 0;
            bottom: $size * -1;
            height: $size;

            // gradient on the top
        } @else if $side == 'top' {
            background: linear-gradient(to top, $color, rgba($color, 0));
            left: 0;
            right: 0;
            top: $size * -1;
            height: $size;
        }
    }
}

/// Mixin to show a product box
///
/// @group humans-machines
/// @param {number} $width           - The width of the product box
/// @param {number} $ratio           - The related ratio for the height
/// @param {number} $img-scale       - Relative value for image scale/zoom
/// @param {number} $wrapper-padding - Wrapper padding to use
@mixin product-box($width, $ratio: 1.2, $img-scale: 0, $wrapper-padding: 66.6%) {
    display: flex;
    width: $width;
    height: $width * $ratio;
    align-items: center;
    background: $product-image-background;

    .product-box-wrapper {
        position: relative;
        width: 100%;
        height: auto;
        padding-bottom: $wrapper-padding;

        .module {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: auto;
            height: auto;
        }
    }

    img {
        mix-blend-mode: multiply;

        @if $img-scale > 0 {
            transform: scale($img-scale);
        }
    }

    .ie &,
    .edge & {
        background: $back-color--default;
    }
}

/// Mixin to make responsive adjustments for a product box
///
/// @group humans-machines
/// @param {number} $width     - The width of the product box
/// @param {number} $ratio     - The related ratio for the height
/// @param {number} $img-scale - Relative value for image scale/zoom
@mixin product-box-responsive($width, $ratio: 1.2, $img-scale: 0) {
    width: $width;
    height: $width * $ratio;

    @if $img-scale > 0 {
        img {
            transform: scale($img-scale);
        }
    }
}


/// Mixin for a clearfix, since flexbox we might not need it anymore
///
/// @group humans-machines
@mixin clearfix {
    &:before,
    &:after {
        content: ' ';
        display: table;
    }

    &:after {
        clear: both;
    }
}

@mixin hide-mobile-nav {
    .has-scrolled:not(.has-scrolled-up) &,
    .has-visible-map & {
        transform: translate3d(0, 100%, 0);
    }
}