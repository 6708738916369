#mykita-rimowa-signup {
    @include breakpoint(two) {
        .b1 {
            padding-bottom: 0;
            height: $full-height-vh--mobile;
            height: $full-height-svh--mobile;
        }
    }

    @include breakpoint(desktop) {
        .b1 {
            padding-bottom: 0;
            height: 100vh;
            height: 100svh;
        }
    }
}

.mykita-rimowa-signup {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.mykita-rimowa-signup__container {
    width: 90%;

    @include breakpoint(desktop) {
        width: 3 * $moduleW-rem;
    }
}

.mykita-rimowa-signup__logo {
    display: inline-block;
    max-width: 90%;
    width: 90%;
    margin: 0;

    svg {
        width: 100%;
        height: 100%;
    }

    @include breakpoint(desktop) {
        width: 60rem;
    }
}

.mykita-rimowa-signup__content {
    margin-top: 4rem;

    @include breakpoint(desktop) {
        margin-top: 8rem;
    }
}

.mykita-rimowa-signup__content {
    display: grid;
    place-content: center;

    > * {
        grid-area: 1 / 1;
    }
}

.mykita-rimowa-signup__step {
    transition: opacity $trans-func--default $trans-time--slow;
    opacity: 0;
    pointer-events: none;
}

.mykita-rimowa-signup__step--signup {
    .mykita-rimowa-signup__content[data-state="signup"] & {
        opacity: 1;
        pointer-events: all;
    }
}

.mykita-rimowa-signup__step--confirm {
    .mykita-rimowa-signup__content[data-state="confirm"] & {
        opacity: 1;
        pointer-events: all;
    }
}

.mykita-rimowa-signup__form {
    text-align: center;

    &[aria-busy="true"] {
        cursor: wait;

        > * {
            pointer-events: none;
        }
    }
}

.mykita-rimowa-signup__intro,
.mykita-rimowa-signup__confirm-text {
    @include typoZurich;
    @include typoZurich2-rem;
    margin-bottom: 0;
}

.form-response p {
    @include typoZurich;
    @include typoZurich1-rem;
    text-transform: none;
    margin-bottom: 0;

    @include breakpoint(desktop) {
        @include typoZurich;
        @include typoZurich2-rem;
        text-transform: none;
    }
}

.mykita-rimowa-signup__input,
input.mykita-rimowa-signup__input[type="email"] {
    @include typoZurich;
    @include typoZurich2-rem;
    text-transform: none;
    margin-top: 3rem;
    border: none;
    background: none;
    text-align: center;
    width: 100%;

    &::placeholder {
        color: rgba(0, 0, 0, 0.5);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 3rem var(--bg-color, #ebebec) inset !important;
    }
}

button.mykita-rimowa-signup__button {
    @include typoZurich;
    @include typoZurich2-rem;
    margin-top: 3rem;
    display: inline-block;
    border: none;
    background: none;
    text-align: center;
    width: 100%;
    cursor: pointer;
    text-decoration: underline;

    form[aria-busy="true"] & {
        opacity: 0.5;
    }
}