// General slideshow setup
.slideshow {
    position: relative;
    width: 100%;
    height: 100%;
    transition: background $defTransTime $defTransFunc;
    background: #fff;

    &:not(.flickity-enabled) {
        background: $backGrey;
        white-space: nowrap; // Keep elements in one line // flex solution would lead to different heights
        overflow: hidden;

        img,
        a.boxLink img,
        a.box-link img {
            opacity: 0;
        }
    }

    // Outer arrows need overflow
    &.slideshow--arrows-outer {
        overflow: visible;
    }
}

// Flickity slide
.flickity__slide {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
}

// Flickity viewport
.flickity-viewport {
    height: 100%;
    max-height: 100%;
    min-height: 100%;
}

// Dots
.flickity-page-dots {
    bottom: 2rem;
    transition: opacity $trans-time--default $trans-func--easing;

    .dot {
        position: relative;
        display: inline-block;
        width: 18px;
        height: 18px;
        background: none;
        margin: 0 0 -10px 0;
        padding: 0;
        border-radius: 0;
        opacity: 1;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 8px;
            height: 8px;
            margin: -4px 0 0 -4px;
            background: rgba(255, 255, 255, 0.5);
            opacity: 1;
            border-radius: 50%;
            transition: opacity $trans-time--default $trans-func--easing,
                        background $trans-time--default $trans-func--easing;
        }

        &.is-selected:before {
            background: rgb(255, 255, 255);
        }
    }

    @include breakpoint(one) {
        bottom: 1rem;
    }

    // Black dot version
    .journal-archive-listing &,
    .journal-related-listing &,
    .slideshow--dots-black &,
    .detail-media-element-gender-slideshow &,
    .detail-media-element-model-portraits-slideshow &,
    .detail-media-element-rimowa-slideshow & {
        .dot {
            &:before {
                background: $backGrey3;
            }

            &.is-selected:before {
                background: #000;
            }
        }
    }

    // Special positioning for journal
    .journal-archive-listing &,
    .journal-related-listing & {
        bottom: -2rem;
    }
}

// Arrows
.flickity-prev-next-button {
    position: absolute;
    top: 0;
    background: none;
    width: auto;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    border-radius: 0;
    transition: opacity $trans-time--default $trans-func--easing;

    .flickity__arrow-icon {
        display: inline-block;
        margin: 0;
        width: 3rem;
        height: 3rem;
        background-size: 3rem 3rem;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: 50% 50%;

        @include breakpoint(one) {
            width: 2rem;
            height: 2rem;
            background-size: 2rem 2rem;
        }
    }

    // Default / Bottom arrow adjustments
    .slideshow--arrows-default &,
    .slideshow--arrows-bottom &,
    .slideshow--arrows-desktop & {
        &.previous {
            left: 0;
            margin-left: 2rem;
            padding-right: 4rem;

            .flickity__arrow-icon {
                background-image: url(../images/icons/svg/arrow-left-white.svg);
            }

            @include breakpoint(two) {
                margin-left: 1rem;
                padding-right: 3rem;
            }

            @include breakpoint(one) {
                margin-left: 0.5rem;
                padding-right: 2rem;
            }
        }

        &.next {
            right: 0;
            margin-right: 2rem;
            padding-left: 4rem;

            .flickity__arrow-icon {
                background-image: url(../images/icons/svg/arrow-right-white.svg);
            }

            @include breakpoint(two) {
                margin-right: 1rem;
                padding-left: 3rem;
            }

            @include breakpoint(one) {
                margin-right: 0.5rem;
                padding-left: 2rem;
            }
        }
    }

    // Bottom arrows
    .slideshow--arrows-bottom & {
        align-content: flex-end;

        @include breakpoint(desktop) {
            .flickity__arrow-icon {
                margin-bottom: 3rem;
            }
        }

        @include breakpoint(two) {
            .flickity__arrow-icon {
                margin-bottom: 3rem;
            }
        }

        @include breakpoint(one) {
            align-content: center;
        }
    }

    // Desktop arrows
    .slideshow--arrows-desktop & {
        transition: color $trans-time--default $trans-func--easing;

        &:hover {
            color: currentColor;
        }

        svg {
            width: 100%;
            height: 100%;
            fill: currentColor;
        }

        &.previous {
            left: 0;
            margin-left: 2rem;

            .flickity__arrow-icon {
                background: none;
            }
        }

        &.next {
            .flickity__arrow-icon {
                background: none;
            }
        }

        @include breakpoint(mobile) {
            display: none;
        }
    }

    .slideshow--arrows-desktop[data-arrow-color="white"] & {
        color: $text-color--light-2;
    }

    // Outer arrows
    .slideshow--arrows-outer & {
        .flickity__arrow-icon {
            width: 2.5rem;
            height: 2.5rem;
            background-size: 2.5rem 2.5rem;
        }

        // Previous button
        &.previous {
            @include breakpoint(desktop) {
                left: -8rem;

                .flickity__arrow-icon {
                    background-image: url(../images/icons/svg/arrow-left.svg);
                }
            }

            @include breakpoint(three) {
                left: -3.5rem;
            }

            @include breakpoint(mobile) {
                left: 0;

                .flickity__arrow-icon {
                    background-image: url(../images/icons/svg/arrow-left-white.svg);
                }
            }

            @include breakpoint(two) {
                margin-left: 0.75rem;
            }

            @include breakpoint(one) {
                margin-left: 0.5rem;
            }
        }

        // Next button
        &.next {
            @include breakpoint(desktop) {
                right: -8rem;

                .flickity__arrow-icon {
                    background-image: url(../images/icons/svg/arrow-right.svg);
                }
            }

            @include breakpoint(three) {
                right: -3.5rem;
            }

            @include breakpoint(mobile) {
                right: 0;

                .flickity__arrow-icon {
                    background-image: url(../images/icons/svg/arrow-right-white.svg);
                }
            }

            @include breakpoint(two) {
                margin-right: 0.75rem;
            }

            @include breakpoint(one) {
                margin-right: 0.5rem;
            }
        }
    }

    // Outer arrows for lookbook
    .lookbook.slideshow--arrows-outer & {
        &.previous {
            @include breakpoint(desktop) {
                left: -4rem;
            }
        }

        &.next {
            @include breakpoint(desktop) {
                right: -4rem;
            }
        }
    }

    // Outer arrows for journal related articles
    .journal-related-listing.slideshow--arrows-outer & {
        &.previous {
            @include breakpoint(fourfive) {
                left: -5rem;
            }

            @include breakpoint(three) {
                left: -3rem;
            }
        }

        &.next {
            @include breakpoint(fourfive) {
                right: -5rem;
            }

            @include breakpoint(three) {
                right: -3rem;
            }
        }
    }
}