/* ==========================================================================
   SearchResult
   ========================================================================== */

.searchResult {
    .resultInfoWrapper {
    	text-align: left;
    	margin-top: 4em;
    	margin-bottom: 1em;
    }

    .resultInfoWrapper.fullSpace {
    	margin-top: 4em;
    	margin-bottom: 32em;
    }

    h1 {
        @include typoZurichBd;
        @include typoZurich3-rem;
    }
}

@include breakpoint(mobile) {
	.searchResult {
    	h1 {
    	   @include typoZurich2-rem;
    	}
	}
}

@include breakpoint(desktop) {
    .searchResult {
        .resultInfoWrapper,
        .resultInfoWrapper.fullSpace {
            margin-top: 8em;
        }
    }
}

// Hide title
.searchResultTitle {
    display: none;
}
