.cookiefirst-root,
.cookiefirst-root * {
    box-sizing: border-box;
    --banner-font-family: ZurichBT-Light, Arial, Helvetica, sans-serif;
    --banner-font-size: 1.1rem;

}

.cookiefirst-root {
    font-size: inherit;

    // Overlay
    > div:first-child:not([data-cookiefirst-widget='modal']) {
        display: none;
    }

    // Widget
    div[data-cookiefirst-widget='banner'] {
        margin: 0 !important;
        padding: 2rem 3rem;
        --banner-font-size: 1.1rem;

        * {
            --banner-font-size: 1.1rem;
        }

        @include breakpoint(mobile) {
            padding: 1rem 1.5rem;
            --banner-font-size: 0.8rem;

            * {
                --banner-font-size: 0.8rem;
            }
        }

        > div:first-child {
            display: none;
        }

        // Flex wrapper
        > div:last-child {
            flex-wrap: nowrap;
            align-items: center;
            min-width: 0;
            margin-inline: 0;
            justify-content: space-between;

            @include breakpoint(mobile) {
                flex-direction: column;
                gap: 0;

            }

            // Text
            > div:first-child {
                flex: 1 1 auto;
                margin: 0;
                padding: 0;

                // Headline
                > div:first-child {
                    font-weight: normal !important;
                    text-transform: uppercase;
                    padding-bottom: 0 !important;
                }

                > div:last-child {
                    margin-top: 0.25rem;
                    margin-bottom: 0 !important;

                    p:empty {
                        display: none;
                    }

                    p,
                    p + p {
                        margin: 0 !important;
                        display: inline;
                    }

                    a {
                        font-family: inherit;
                        font-size: 1em;
                        display: inline;
                        border-bottom: solid 1px black;
                        padding: 0;
                        text-decoration: none;
                    }
                }
            }

            // Buttons
            > div:last-child {
                flex: 1 1 auto;
                padding-inline: 0;

                @include breakpoint(desktop) {
                    margin-left: 8rem;
                }

                // Spacer
                > div:first-child {
                    display: none;
                }

                // Button group
                > div:last-child {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    gap: 1rem;
                    margin-inline: 0;
                    margin-top: 0;

                    @include breakpoint(mobile) {
                        margin-top: 1.5rem;
                    }

                    // Buttons
                    > div {
                        flex: 1 1 auto;
                        margin: 0;
                        padding: 0;
                        max-width: none;
                    }

                    // Preferences button
                    > div:nth-child(1) {
                        order: 1;
                        text-align: right;
                        padding-right: 2rem;

                        button {
                            --banner-btn-bg: none !important;
                            --banner-btn-hover-bg: none !important;
                            --banner-btn-border-color: transparent !important;
                            --banner-btn-hover-border-color: transparent !important;
                            --banner-btn-text: #000 !important;
                            --banner-btn-hover-text: rgb(150, 150, 150) !important;
                            width: auto;

                            span {
                                text-transform: none;
                                font-size: 1em;
                                display: inline;
                                border-bottom: solid 1px currentColor;
                                padding: 0;
                                text-decoration: none;
                            }
                        }
                    }

                    // Accept button
                    div:nth-child(2) {
                        order: 3;
                    }

                    // Decline button
                    div:nth-child(3) {
                        order: 2;
                    }

                    @include breakpoint(mobile) {
                        flex-direction: column;
                        gap: 0.5rem;

                        > div {
                            width: 100%;

                            button {
                                width: 100%;
                                height: 3rem;
                            }

                        }

                        // Preferences button
                        > div:nth-child(1) {
                            order: 3;
                            text-align: center;
                            padding: 0;

                            button {
                                width: 100%;
                                height: 2.5rem;
                            }
                        }

                        // Accept button
                        div:nth-child(2) {
                            order: 1;
                        }

                        // Decline button
                        div:nth-child(3) {
                            order: 2;
                        }
                    }
                }
            }
        }
    }

    // Buttons
    button:not([data-cookiefirst-button='link']),
    div button:not([data-cookiefirst-button='link']) {
        height: 3.5rem;
        line-height: 1;
        padding: 0 !important;

        span {
            text-transform: uppercase;
            line-height: 1;
        }
    }

    // Hiode loading spinner
    button .cf3qpw {
        display: none;
    }
}

// Settings panel
dialog[aria-labelledby='cookie-preference-panel-title'] {
    --banner-font-family: ZurichBT-Light, Arial, Helvetica, sans-serif;
    --banner-font-size: 1.35rem;
    --modal-max-width: 48rem;

    button,
    button span,
    #cookie-preference-panel-title,
    h1,
    h2,
    h3,
    h4,
    dt strong {
        font-weight: normal !important;
    }

    a {
        font-weight: normal !important;
        font-size: 1em;
        display: inline;
        border-bottom: solid 1px black;
        padding: 0;
        text-decoration: none;
    }

    a,
    p {
        --banner-font-size: 1.1rem;
    }

    h2 {
        line-height: 1.5 !important;
        font-size: 1.35rem !important;
        padding: 0 !important;
        margin: 2rem 0 1rem 0 !important;
    }

    h3,
    h4 {
        line-height: 1.5 !important;
        font-size: 1.35rem !important;
        padding: 0 !important;
        margin: 1rem 0 0.5rem 0 !important;
    }

    dt strong {
        display: block;
        line-height: 1.5 !important;
        font-size: 1.1rem !important;
        padding: 0 !important;
        margin: 0.5rem 0 0.25rem 0 !important;
    }

    button {
        line-height: 1.5;
    }

    // Toggle/Dropdown buttons
    button[data-cookiefirst-button='link'] {
        height: auto;
        line-height: 1.5;
        text-align: left;
        border: none;

        // Hover underline
        > span:after {
            border-width: 1px !important;

            .hairlines & {
                border-width: 0.5px !important;
            }
        }
    }

    // Switch buttons
    button[role="checkbox"] {
        height: 1.6rem;
        line-height: 1;
    }

    // Tab button
    button[role="tab"] {
        height: auto;
        line-height: 1.5;
    }

    // Caret button
    .cf1Fue button {
        width: auto;
    }

    .cf2JM3 {
        padding-top: 0 !important;
    }

    .cfatnz {
        opacity: 1;
    }

    .cf16MQ.cfAdwL {
        @include breakpoint(mobile) {
            flex-direction: column;
        }
    }
}


// Cookietable
#cookiefirst-cookies-table {
    h4 {
        @include typoZurich;
        @include typoZurich2-rem;
        text-transform: uppercase;
        font-weight: normal !important;
        margin-top: 2rem;
    }

    table {
        margin-bottom: 2rem;

        th {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: uppercase;
        }

        td,
        th[scope='row'] {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
            font-weight: normal;

            &:nth-child(4),
            &:nth-child(5) {
                white-space: nowrap;
            }
        }
    }

    @include breakpoint(desktop) {
        margin-bottom: 4rem;

        td[colspan] {
            border-top: solid 1px #dee2e6;
        }

        table > tbody > tr:first-child > td {
            border-top: none;
        }

        th,
        td:not([colspan]){
            &:nth-child(1) {
                width: 15%;
            }
            &:nth-child(2) {
                min-width: 0;
                width: 50%;
            }
            &:nth-child(3) {
                width: 15%;
            }
            &:nth-child(4) {
                width: 10%;
            }
            &:nth-child(5) {
                width: 10%;
            }
        }
    }

    @include breakpoint(mobile) {
        table {
            min-width: 0;
            display: block;
        }

        svg {
            display: none;
        }

        div > div > div {
            overflow-x: visible;
        }

        th[scope='col'] {
            display: none;
        }

        table,
        tbody,
        tr,
        td {
            display: block;
            width: 100%;
            min-width: 0;
            height: auto;
        }

        h4 {
            margin: 0;
        }

        th[scope='row'] {
            margin-top: 1rem;
            padding-top: 1rem;
        }

        td[colspan] {
            display: block;
            width: 100%;
            padding-top: 2rem;

            h4 {
                margin-bottom: -0.5rem;
            }
        }

        tr {
            display: flex;
            flex-direction: column;
        }

        th[scope='row'],
        td:not([colspan]) {
            box-sizing: border-box;
            display: inline-block;
            align-items: flex-start;
            position: relative;
            padding-left: 9rem;
            height: auto;
            min-height: 2.5rem;
            white-space: break-spaces;

            &:before {
                position: absolute;
                left: 0.3rem;
                top: 0.4rem;
                width: 8rem;
                display: inline-block;
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
                border-top: none;
            }

            &:nth-child(1):before {
                content: 'Name';

                html[lang='de'] & {
                    content: 'Name';
                }
            }

            &:nth-child(2):before {
                content: 'Purpose';

                html[lang='de'] & {
                    content: 'Zweck';
                }
            }

            &:nth-child(3):before {
                content: 'Domain name';

                html[lang='de'] & {
                    content: 'Domainname';
                }
            }

            &:nth-child(4):before {
                content: 'Expires';

                html[lang='de'] & {
                    content: 'Ablauf';
                }
            }

            &:nth-child(5):before {
                content: 'Provider';

                html[lang='de'] & {
                    content: 'Anbieter';
                }
            }

            &:empty:after {
                content: ' ';
            }
        }

        th[scope='row']:before {
            top: 1rem;
        }
    }
}