.journal {
    // Top storys
    .journal-top-storys {
        @extend .clear;
        position: relative;
    }

    &.no-top-stories {
        margin-top: 8rem;

        @include breakpoint(mobile) {
            margin-top: 6rem;
        }
    }

    // Set gridborder
    .journal-articles-one,
    .journal-articles-left,
    .journal-articles-right {
        @extend .gridBorder;
    }

    // Style for article
    article {
        @extend .clear;
        text-align: left;
        margin-bottom: 3.5rem;

        .module {
            text-align: center;
        }

        .article-picture {
            @extend .clear;
            position: relative;
            margin-bottom: 2rem;
        }

        .article-category {
            @include typoZurich;
            @include typoZurich0-rem;
            margin-bottom: 0.5rem;
        }

        .article-headline {
            @include typoZurich;
            @include typoZurich3-rem;
            margin-bottom: 2rem;
        }

        .article-introduction {
            @include typoZurich;
            @include typoZurich2-rem;
            text-transform: none;
            margin-bottom: 1.5rem;
        }

        .article-publish-date {
            @include typoZurich;
            @include typoZurich0-rem;
        }
    }

    // Articles container
    .journal-articles--ajax {
        display: none;
    }

    .journal-articles--mobile {
        @extend .clear;
        @include breakpoint(twofive) {
            display: none;
        }

        @include breakpoint(one) {
            min-height: 128rem;
        }
    }

    .journal-articles--desktop {
        @include breakpoint(one) {
            display: none;
        }

        @include breakpoint(twofive) {
            min-height: 128rem;
        }
    }

    // Column size
    .journal-articles-left {
        display: inline-block;
        float: left;
        padding-right: $journalBorder;

        @include breakpoint(five) {
            width: 3 * $moduleW-rem;
        }

        @include breakpoint(four) {
            width: 2 * $moduleW-rem;
        }

        @include breakpoint(three) {
            width: 2 * $moduleW-rem;
        }

        @include breakpoint(two) {
            width: 1 * $moduleW-rem;
        }

        // Set proper size for modules
        .bigger {
            @include breakpoint(four) {
                width: 2 * $moduleW-rem - $journalBorder;
                height: 2 * $moduleH-rem - $journalBorder;
            }

            @include breakpoint(three) {
                width: 2 * $moduleW-rem - $journalBorder;
                height: 2 * $moduleH-rem - $journalBorder;
            }

            @include breakpoint(two) {
                width: 1 * $moduleW-rem - $journalBorder;
                height: 1 * $moduleH-rem - $journalBorder;
            }
        }

        .bigTower {
             @include breakpoint(five) {
                 width: 3 * $moduleW-rem - $journalBorder;
                 height: 6 * $moduleH-rem - $journalBorder;
             }

             @include breakpoint(four) {
                 width: 2 * $moduleW-rem - $journalBorder;
                 height: 4 * $moduleH-rem - $journalBorder;
             }

             @include breakpoint(three) {
                 width: 2 * $moduleW-rem - $journalBorder;
                 height: 4 * $moduleH-rem - $journalBorder;
             }

             @include breakpoint(two) {
                 width: 1 * $moduleW-rem - $journalBorder;
                 height: 2 * $moduleH-rem - $journalBorder;
             }
         }

        .bigQuad {
            @include breakpoint(five) {
                width: 3 * $moduleW-rem - $journalBorder;
                height: 3 * $moduleW-rem - $journalBorder;
            }

            @include breakpoint(four) {
                width: 2 * $moduleW-rem - $journalBorder;
                height: 2 * $moduleW-rem - $journalBorder;
            }

            @include breakpoint(three) {
                width: 2 * $moduleW-rem - $journalBorder;
                height: 2 * $moduleW-rem - $journalBorder;
            }

            @include breakpoint(two) {
                width: 1 * $moduleW-rem - $journalBorder;
                height: 1 * $moduleW-rem - $journalBorder;
            }
        }
    }

    .journal-articles-right {
        display: inline-block;
        float: right;
        padding-left: $journalBorder;

        @include breakpoint(five) {
            width: 2 * $moduleW-rem;
        }

        @include breakpoint(four) {
            width: 2 * $moduleW-rem;
        }

        @include breakpoint(three) {
            width: 1 * $moduleW-rem;
        }

        @include breakpoint(two) {
            width: 1 * $moduleW-rem;
        }

        // Set proper size for modules
        .bigger {
            @include breakpoint(five) {
                width: 2 * $moduleW-rem - $journalBorder;
                height: 2 * $moduleH-rem - $journalBorder;
            }

            @include breakpoint(four) {
                width: 2 * $moduleW-rem - $journalBorder;
                height: 2 * $moduleH-rem - $journalBorder;
            }

            @include breakpoint(three) {
                width: 1 * $moduleW-rem - $journalBorder;
                height: 1 * $moduleH-rem - $journalBorder;
            }

            @include breakpoint(two) {
                width: 1 * $moduleW-rem - $journalBorder;
                height: 1 * $moduleH-rem - $journalBorder;
            }
        }

        .bigTower {
            @include breakpoint(five) {
                width: 2 * $moduleW-rem - $journalBorder;
                height: 4 * $moduleH-rem - $journalBorder;
            }

            @include breakpoint(four) {
                width: 2 * $moduleW-rem - $journalBorder;
                height: 4 * $moduleH-rem - $journalBorder;
            }

            @include breakpoint(three) {
                width: 1 * $moduleW-rem - $journalBorder;
                height: 2 * $moduleH-rem - $journalBorder;
            }

            @include breakpoint(two) {
                width: 1 * $moduleW-rem - $journalBorder;
                height: 2 * $moduleH-rem - $journalBorder;
            }
        }

        .bigQuad {
            @include breakpoint(five) {
                width: 2 * $moduleW-rem - $journalBorder;
                height: 2 * $moduleW-rem - $journalBorder;
            }

            @include breakpoint(four) {
                width: 2 * $moduleW-rem - $journalBorder;
                height: 2 * $moduleW-rem - $journalBorder;
            }

            @include breakpoint(three) {
                width: 1 * $moduleW-rem - $journalBorder;
                height: 1 * $moduleW-rem - $journalBorder;
            }

            @include breakpoint(two) {
                width: 1 * $moduleW-rem - $journalBorder;
                height: 1 * $moduleW-rem - $journalBorder;
            }
        }
    }

    .journal-load-more-container {
        display: none;
        padding: 4rem $border 0 $border;
    }

    &.has-more-articles {
        .journal-load-more-container {
            display: block;
        }
    }

    .journal-load-more {
        position: relative;
        text-align: left;
        display: block;
        height: 2rem;
        width: 100%;
        overflow: hidden;

        a {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 1;
            transition: opacity $fastTransTime $easeInOutQuint,
                        transform $fastTransTime $easeInOutQuint;
        }

        .css-loading {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            transform: translateY(2rem);
            transition: opacity $fastTransTime $easeInOutQuint,
                        transform $fastTransTime $easeInOutQuint;
        }

        &.is-loading {
            .css-loading {
                opacity: 1;
                transform: translateY(0);
            }

            a {
                opacity: 0;
                transform: translateY(-2em);
            }
        }
    }

    .article-not-found {
        text-align: left;
        margin-top: 4rem;
        margin-bottom: 1rem;


        h1 {
            @include typoZurich;
            @include typoZurich3-rem;

            @include breakpoint(mobile) {
                @include typoZurich2-rem;
            }
        }
    }

    .article-not-found.fullSpace {
        margin-top: 4rem;
        margin-bottom: 32rem;

        @include breakpoint(mobile) {
            margin-top: 8rem;
        }
    }
}

// Journal detail
.journal-detail {
    margin-top: 8rem;

    @include breakpoint(mobile) {
        margin-top: 6rem;
    }

    .headline-wrapper {
        margin-bottom: 3.6rem;

        h1 {
            @include typoZurich;
            @include typoZurich3-rem;

            @include breakpoint(mobile) {
                @include typoZurich2-rem;
            }
        }
    }

    p {
        @include typoZurich;
        @include typoZurich2-rem;
        text-transform: none;
    }

    a {
        display: inline;
        line-height: 1.1em;
        text-decoration: none;
        border-bottom: solid 1px black;
    }

    p span {
        text-decoration: none !important;
    }

    // Set proper quotes
    q,
    blockquote {
        margin: 4em 0;

        p {
            @include typoZurichBd;
            @include typoZurich2-rem;
            text-transform: none;
            display: inline;
            margin: 0;

            &:before {
                content: "\201C";
            }

            &:after {
                content: "\201D";
            }
        }
    }

    h2 {
        @include typoZurich;
        @include typoZurich2-rem;
        text-transform: none;
        margin-bottom: 0.5em;
        margin-top: 1.5em;
    }

    h3 {
        @include typoZurich;
        @include typoZurich2-rem;
        text-transform: uppercase;
        margin-bottom: 0.5em;
    }

    h5 {
        @include typoZurich;
        @include typoZurich0-rem;
        text-transform: none;
        margin: 0 0 1em 0;
    }

    .rich-media + h5 {
        margin-top: -3em;
        margin-bottom: 4em;
    }

    .rich-media {
        @extend .clear;
        position: relative;
        margin: 4em 0;
    }

    .rich-media-auto-image {
        img {
            width: 100%;
        }
    }

    .rich-media-video {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    // Category & Share
    .category-share-wrapper {
        @extend .clear;
        position: relative;
        overflow: hidden;
        height: 2rem;

        @include breakpoint(one) {
            height: 4rem;
        }

        .category-container {
            display: inline-block;
            margin-right: 4rem;
            float: left;

            @include breakpoint(one) {
                display: block;
                margin-bottom: 1rem;
                width: 100%;
                float: none;
                margin-right: 0;
            }
        }

        .share-switch-container {
            display: inline-block;
            margin-right: 4rem;
            float: left;
        }

        .share-container {
            display: inline-block;
            opacity: 0;
            max-width: 0;
            overflow: hidden;
            float: left;
            white-space: nowrap;
            transition: opacity $defTransTime $easeInOutQuint,
                        max-width $defTransTime $easeInOutQuint;

            @include breakpoint(one) {
                float: none;
            }

            a {
                margin: 0 1em 0 0;
            }
        }

        &.share-active {
            .share-container {
                opacity: 1;
                max-width: 24rem;
            }
        }
    }

    .bigTower {
        @include breakpoint(desktop) {
            width: 3 * $moduleW-rem;
            height: 6 * $moduleH-rem;
        }

        @include breakpoint(two) {
            width: 2 * $moduleW-rem;
            height: 4 * $moduleH-rem;
        }

        @include breakpoint(one) {
            width: 1 * $moduleW-rem;
            height: 2 * $moduleH-rem;
        }
    }

    .bigQuad {
        @include breakpoint(deaktop) {
            width: 3 * $moduleW-rem;
            height: 3 * $moduleW-rem;
        }

        @include breakpoint(two) {
            width: 2 * $moduleW-rem;
            height: 2 * $moduleW-rem;
        }

        @include breakpoint(one) {
            width: 1 * $moduleW-rem;
            height: 1 * $moduleW-rem;
        }
    }
}

// Journal Related articles Footer
.journal-related-footer {
    text-align: left;
    margin-bottom: 8rem;

    .h2Wrapper {
        text-align: left;
        margin-bottom: 4rem;

        h2 {
            @include typoZurichBd;
            @include typoZurich2-rem;
        }
    }

    .journal-related-listing {
        display: block;
        position: relative;
        width: 100%;
        height: 1 * $moduleW-rem + 6rem;

        .flickity__slide {
            width: auto;
            height: auto;
            margin-right: 0.5rem;
        }

        .related-article {
            @extend .gridBorder;
            box-sizing: border-box;
            display: inline-block;
            width: 1 * $moduleW-rem;
            height: 1 * $moduleW-rem + 6rem;
            overflow: hidden;
        }

        .smallQuad {
            box-sizing: border-box;
        }

        .article-headline {
            margin-top: 1em;
            height: 4rem;

            h3 {
                @include typoZurich;
                @include typoZurich1-rem;
            }
        }
    }
}

// Journal archive Footer
.journal-archive-footer {
    text-align: left;
    margin-bottom: 8rem;

    .h2Wrapper {
        text-align: left;
        margin-bottom: 4rem;

        h2 {
            @include typoZurichBd;
            @include typoZurich2-rem;
        }
    }

    .journal-archive-listing {
        height: 24rem;

        @include breakpoint(desktop) {
            display: flex;
            justify-content: space-between;
        }

        // Enable flickity via CSS
        @include breakpoint(mobile) {
            &:after {
                content: 'flickity';
                display: none;
            }
        }

        @include breakpoint(four) {
            height: 20rem;
        }

        @include breakpoint(three) {
            height: 16rem;
        }

        @include breakpoint(two) {
            height: 18.5rem;
        }

        @include breakpoint(one) {
            height: 15rem;
        }

        .category-slide {
            width: auto;
            height: auto;

            @include breakpoint(two) {
                &:not(:last-child) {
                    margin-right: 1.5rem;
                }
            }

            @include breakpoint(one) {
                &:not(:last-child) {
                    margin-right: 1rem;
                }
            }
        }

        .smallQuad {
            box-sizing: border-box;
            width: 20rem;
            height: 20rem;

            @include breakpoint(four) {
                width: 16rem;
                height: 16rem;
            }

            @include breakpoint(three) {
                width: 12rem;
                height: 12rem;
            }

            @include breakpoint(two) {
                width: 14.5rem;
                height: 14.5rem;
            }

            @include breakpoint(one) {
                width: 11rem;
                height: 11rem;
            }
        }

        .category-name {
            margin-top: 1rem;

            h3 {
                @include typoZurich;
                @include typoZurich1-rem;
            }
        }
    }
}

// Style for 3 column archive
.three-column .journal-archive-footer {
    .journal-archive-listing {
        @include breakpoint(desktop) {
            height: 16rem;
        }

        @include breakpoint(two) {
            height: 18.5rem;
        }

        @include breakpoint(one) {
            height: 15rem;
        }

        .smallQuad {
            @include breakpoint(desktop) {
                width: 12rem;
                height: 12rem;
            }
        }
    }
}

// Journal Category listing
.journal-category {
    text-align: left;
    margin-top: 8rem;

    .headline-wrapper {
        margin-bottom: 4em;
        padding: 0 $borderExtended;

        h1 {
            @include typoZurichBd;
            @include typoZurich3-rem;

            @include breakpoint(mobile) {
                @include typoZurich2-rem;
            }
        }
    }

    .no-results-for-category {
        padding: 0 $borderExtended;

        h2 {
            @include typoZurich2-rem;
        }
    }

    article {
        box-sizing: border-box;
        width: 24rem;
        margin-bottom: 0;
    }

    .journal-category-listing {
        @extend .clear;
    }

    .journal-category-article {
        box-sizing: border-box;
        width: 1 * $moduleW-rem;
        float: left;
        overflow: hidden;
    }

    .article-picture-archive,
    .article-title-wrapper {
        box-sizing: border-box;
        width: 1 * $moduleW-rem;
        padding: $borderExtended;
        position: relative;
    }

    .article-picture-archive {
        margin-bottom: 0;
    }

    .article-title-wrapper {
        height: 8rem;
        padding-top: 0;

        h3 {
            @include typoZurich;
            @include typoZurich1-rem;
            margin: 0;
        }

        @include breakpoint(one) {
            height: auto;
            padding-bottom: 4rem;
        }
    }
}


