/* ==========================================================================
   Articles Item
   ========================================================================== */

.articlesItem {
    .headlineWrapper,
    .headline-wrapper {
        margin-bottom: 1rem;
        text-align: left;
    }

    h2 {
        @include typoZurichBd;
        @include typoZurich3-rem;
    }

    .collapsed .loadMoreProducts {
        display: none;
    }

    .expanded .loadMoreProducts {
        display: none;
    }

    .collection.expanded {
        .defaultProduct {
            display: block;
        }
    }
}

@include breakpoint(four) {
    .articlesItem {
        .collection.collapsed {
            .product[data-product="5"] {
                display: block;
            }
        }
    }
}

@include breakpoint(three) {
    .articlesItem {
        .collection.collapsed {
            .product[data-product="4"],
            .product[data-product="5"] {
                display: block;
            }
        }
    }
}

@include breakpoint(two) {
    .articlesItem {
        .collection.collapsed {
            .product {
                display: none;
            }

            .product[data-product="1"] .loadMoreProducts,
            .product[data-product="2"] .loadMoreProducts,
            .product[data-product="3"] .loadMoreProducts,
            .product[data-product="4"] .loadMoreProducts,
            .product[data-product="5"] .loadMoreProducts {
                display: none;
            }

            .product[data-product="6"] .loadMoreProducts {
                display: block;
            }

            .product[data-product="1"],
            .product[data-product="2"],
            .product[data-product="3"],
            .product[data-product="4"],
            .product[data-product="5"],
            .product[data-product="6"] {
                display: block;
            }
        }
    }
}

@include breakpoint(one) {
    .articlesItem {
        .collection.collapsed {
            .product {
                display: none;
            }

            .product[data-product="1"] .loadMoreProducts,
            .product[data-product="2"] .loadMoreProducts,
            .product[data-product="3"] .loadMoreProducts,
            .product[data-product="4"] .loadMoreProducts {
                display: none;
            }

            .product[data-product="5"] .loadMoreProducts {
                display: block;
            }

            .product[data-product="1"],
            .product[data-product="2"],
            .product[data-product="3"],
            .product[data-product="4"],
            .product[data-product="5"] {
                display: block;
            }
        }
    }
}
