/* ==========================================================================
   Imageitem
   ========================================================================== */
.auto-image-box {
    display: flex;
    justify-content: center;
}

.imageItem {
    position: relative;
    margin: 0 auto !important;

    &.logo {
        padding-top: 0;
        padding-bottom: 0;

        // SVG Logos
        &.is-svg {
            img {
                max-width: 100%;
                height: 8rem;

                @include breakpoint(desktop) {
                    max-width: 3 * $moduleW-rem;
                }

                @include breakpoint(two) {
                    height: 6rem;
                }

                @include breakpoint(one) {
                    height: 4rem;
                }
            }
        }

        // Default PNG/JPG logos
        &:not(.is-svg) {
            picture {
                max-height: 9rem;

            }

            .media-box {
                display: block;
            }

            img {
                width: auto;
                height: 100%;
                max-width: 80%;

                @include breakpoint(two) {
                    max-width: 90%;
                }

                @include breakpoint(one) {
                    max-width: 100%;
                }
            }
        }
    }

    .scrollDown  {
        display: block;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 3rem;
        text-align: center;
    }

    &.auto,
    &.autoMedium,
    &.autoSmall {
        img {
            width: 100%;
            max-height: 100%;
        }
    }

    &.autoMedium {
        width: 30rem;

        @include breakpoint(one) {
            width: 14rem;
        }
    }

    &.autoSmall {
        width: $moduleW-rem;

        @include breakpoint(one) {
            width: 14rem;
        }
    }
}

@include breakpoint(desktop) {
    .imageItem {
        position: relative;
        margin: 0 auto !important;

        &.auto {
            img {
                max-width: 3 * $moduleW;
            }
        }

        .scrollDown  {
            bottom: 4rem;
        }
    }
}
