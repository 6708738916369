// Custom sizing for sign in modal
.info-layer.info-layer--account {
    width: 1.5 * $moduleW-rem;

    @include breakpoint(mobile) {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: none;
        box-shadow: none;

        .info-layer-wrapper {
            max-height: 100%;
        }
    }
}

.account-modal {
    display: block;
    width: 100%;
    text-align: left;

    .account-modal__switch {
        @include pseudo-list;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;

        li {
            display: inline-block;
        }

        .btn {
            text-transform: uppercase;
            color: $text-color--light-1;
        }
    }

    .account-modal__create-account-link {
        margin-top: 1rem;
    }

    .account-modal__sections {
        margin-top: 3rem;
        display: flex;
        align-items: flex-start;
        width: 300%;
        overflow: hidden;
        max-height: 28rem;

        @include breakpoint(desktop) {
            transition: max-height $trans-time--default $trans-func--default $trans-time--default;
        }
    }

    .account-modal__section {
        position: relative;
        display: inline-block;
        flex-basis: 33.33%;
        flex-shrink: 0;
        transform: none;
        opacity: 1;
        padding: 0 1px; // Fix issue where border might not be visible through overflow hidden
        transition: opacity $trans-time--default $trans-func--default,
                    transform $trans-time--default $trans-func--default;
    }

    .account-modal__section--lostpw {
        margin-left: -33.33%;
    }

    .account-modal__section--register {
        margin-left: -33.33%;
    }


    // General section styling
    form {
        .form-row--action {
            margin-top: 2rem;
            margin-bottom: 0;
        }
    }

    // Register
    .account-modal__section--login {
        .account-modal__partner-portal-link {
            margin-top: 2rem;
        }
    }

    // Register
    .account-modal__section--register {
        .form-row--newsletter-subscription,
        .form-row--privacy {
            margin-top: 2rem;
        }

        .input-group--firstname {
            width: calc(50% - 0.5rem);
            margin-right: 0.5rem;

            @include breakpoint(one) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 1rem;
            }
        }

        .input-group--lastname {
            width: calc(50% - 0.5rem);
            margin-left: 0.5rem;

            @include breakpoint(one) {
                width: 100%;
                margin-left: 0;
            }
        }
    }

    @include breakpoint(mobile) {
        padding: 3rem 0;
    }

    // Active state
    &[data-active-section="login"] {
        .account-modal__switch li:first-child .btn {
            color: $text-color--default;
        }

        .account-modal__section--lostpw,
        .account-modal__section--register {
            opacity: 0;
            transform: translate3d(2rem, 0, 0);
            pointer-events: none;
        }
    }

    &[data-active-section="lostpw"] {
        .account-modal__sections {
            //max-height: 14rem;
        }

        .account-modal__section--login {
            opacity: 0;
            transform: translate3d(-2rem, 0, 0);
            pointer-events: none;
        }

        .account-modal__section--register {
            opacity: 0;
            transform: translate3d(2rem, 0, 0);
            pointer-events: none;
        }
    }

    &[data-active-section="register"] {
        .account-modal__switch li:last-child .btn {
            color: $text-color--default;
        }

        .account-modal__section--login,
        .account-modal__section--lostpw {
            opacity: 0;
            transform: translate3d(-2rem, 0, 0);
            pointer-events: none;
        }

        @include breakpoint(desktop) {
            .account-modal__sections {
                max-height: 50rem;
            }
        }

        @include breakpoint(mobile) {
            .account-modal__sections {
                max-height: none;
            }
        }
    }
}