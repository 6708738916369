.accordion {
    .accordion__head {
        position: relative;
        background: none;
        border: none;
        outline: none;
    }

    .accordion__body {
        display: block;
        width: 100%;
        position: relative;
        overflow: hidden;
    }

    .accordion__head-indicator {
        transition: transform $fastTransTime $defTransFunc;
        transform: scaleY(1);

        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -0.6rem;
        height: 1.2rem;
        width: 1.2rem;

        .svg-ico {
            width: 100%;
            height: 100%;
        }
    }

    // Mobile accordion
    &.accordion--mobile {
        @include breakpoint(one) {
            .accordion__head {
                cursor: pointer;
            }

            &[aria-expanded="false"] {
                .accordion__body {
                    height: 0;
                }
            }
        }

        @include breakpoint(twofive) {
            .accordion__head-indicator {
                display: none;
            }
        }
    }

    // Phablet accordion
    &.accordion--phablet {
        @include breakpoint(mobile) {
            .accordion__head {
                cursor: pointer;
            }

            &[aria-expanded="false"] {
                .accordion__body {
                    height: 0;
                }
            }
        }

        @include breakpoint(desktop) {
            .accordion__head-indicator {
                display: none;
            }
        }
    }

    // Default accordion
    &:not(.accordion--mobile):not(.accordion--phablet) {
        .accordion__head {
            cursor: pointer;
        }
    }

    &:not(.accordion--mobile):not(.accordion--phablet)[aria-expanded="false"] {
        .accordion__body {
            height: 0;
        }
    }

    // Toggle indicator
    &[aria-expanded="true"] {
        .accordion__head-indicator {
            transform: scaleY(-1);
        }
    }
}

// Related styling for footer accordion
.accordion.gbox {
    @include breakpoint(one) {
        margin-bottom: 0;
    }
}

// Accordion adjustments for navigation overlay
.navigation-overlay {
    .accordion__head {
        display: flex;
    }

    .accordion__head,
    .accordion__body {
        max-width: 1.25 * $moduleW-rem;
    }

    .accordion__body-content {
        padding: 1.5rem 0 2rem 0;
    }

    .accordion__head-title {
        @include typoZurich;
        @include typoZurich3-rem;
        flex: 1 1 auto;
    }

    .accordion__head-toggle-indicator {
        position: relative;
        width: 2rem;
        display: grid;
        grid-template-columns: 1fr;

        > span {
            transition: opacity $trans-time--default $trans-func--default;
            display: flex;
            justify-content: center;
            align-items: center;
            grid-row-start: 1;
            grid-column-start: 1;
            opacity: 0;
            @include typoZurich;
            @include typoZurich3-rem;
            line-height: 1;
        }
    }

    .accordion[aria-expanded="false"] {
        .accordion__head-toggle-indicator {
            > span:first-child {
                opacity: 1;
            }
        }
    }

    .accordion[aria-expanded="true"] {
        .accordion__head-toggle-indicator {
            > span:last-child {
                opacity: 1;
            }
        }
    }
}