.awesomplete {
    display: inline-block;
    position: relative;
    width: 100%;

    input {
        display: block;
        width: 100%;
    }

    > ul {
        box-sizing: border-box;
        position: absolute;
        display: inline-block;
        top: auto;
        left: 0;
        z-index: 1;
        min-width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
        background: $inputGrey2;
        text-shadow: none;
        overflow: hidden;
        max-height: 32rem;
        opacity: 1;
        transition: opacity $fastTransTime $defTransFunc,
                    max-height $fastTransTime $defTransFunc;
    }

    >ul > li {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        box-sizing: border-box;
        position: relative;
        padding: 0 1rem;
        cursor: pointer;
        width: 100%;
        height: 2.5rem;
        line-height: 2.5rem;
        background: $inputGrey2;
        transition: background $fastTransTime $defTransFunc;
        text-align: left;

        &:hover,
        &[aria-selected='true'] {
            background: $inputGrey2Hover;
        }
    }

    span {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        display: inline-block;
        height: 2.5rem;
        line-height: 2.5rem;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    mark {
        @include typoZurichReg;
        @include typoZurich1-rem;
        text-transform: none;
        background: none;
    }

    .visually-hidden {
        position: absolute;
        clip: rect(0, 0, 0, 0);
    }

    &[hidden] ul,
    ul[hidden],
    ul:empty {
        pointer-events: none;
        max-height: 0;
        opacity: 0;
    }
}