/**
 * Container for left and right Off Canvas (Navigation/Filter)
 */
@mixin mobileContainer {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
}

/**
 * Inner-Container for left and right Off Canvas (Navigation/Filter)
 */
@mixin mobileContainerInner {
    padding: 0 6%;
    width: 88%;
    max-width: 24em;
}
