// 3 - 5 columns
@include breakpoint(desktop) {
   .L-1-Bigger {
        height: 3 * $moduleH;
        width: 3 * $moduleW;

        // Bigger
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;
        }
    }
}

// 2 columns
@include breakpoint(two) {
    .L-1-Bigger {
        height: 2 * $moduleH;

        // Bigger
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;
        }
    }
}

// 1 columns
@include breakpoint(one) {
    .L-1-Bigger {
        height: 30rem;

        // Bigger
        .b1 {
            top: 0;
            left: 0;
            height: 30rem;
        }
    }
}
