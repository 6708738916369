// Custom error listing
.form-error-listing.form-error-listing--hp {
    ul {
        transition-property: opacity, max-height, margin;
        transition-duration: $defTransTime;
        transition-timing-function: $easeInOutQuint;
        max-height: 0;
        opacity: 0;
        margin-bottom: 0;
        overflow: hidden;
    }

    &.has-error {
        ul {
            max-height: 32rem;
            opacity: 1;
            margin-bottom: 2rem;
        }
    }

    li {
        transition-property: opacity, max-height;
        transition-duration: $defTransTime;
        transition-timing-function: $easeInOutQuint;
        max-height: 0;
        opacity: 0;
        overflow: hidden;

        &.is-visible {
            max-height: 8rem;
            opacity: 1;
        }
    }
}

// Heidelpay redirect
.checkout-process--payment .heidelpay-redirect {
    position: relative;
    text-align: center;
    width: 100%;
    padding: 4rem 0;

    img {
        max-width: 16rem;
        margin-bottom: 2rem;

        @include breakpoint(one) {
            max-width: 10rem;
        }
    }

    h2 {
        @include typoZurichBd;
        @include typoZurich2-rem;
        margin-bottom: 2rem;
        text-align: center;
    }
}

// Format Creditcards
input[type="text"].mastercard,
input[type="text"].amex,
input[type="text"].visa {
    background-position: 95% 50%;
    background-repeat: no-repeat;
    background-size: 3rem 2.15rem;
}

input[type="text"].mastercard {
    background-image: url('../images/payment-icons/128/mastercard.png');
}

input[type="text"].visa {
    background-image: url('../images/payment-icons/128/visa.png');
}

input[type="text"].amex {
    background-image: url('../images/payment-icons/128/american-express.png');
}

// Sofort payment
.hp-gateway-form--sue {
    .hp-sue-sepa {
        transition-property: opacity, max-height, margin;
        transition-duration: $defTransTime;
        transition-timing-function: $defTransFunc;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        margin-bottom: 0;
    }

    .hp-payment-sue[data-state="noiban"] {
        .hp-sue-account,
        .hp-sue-bank {
            max-height: 6rem;
            opacity: 1;
            margin-bottom: 1rem;
        }
    }

    .hp-payment-sue[data-state="iban"] {
        .hp-sue-iban,
        .hp-sue-bic {
            max-height: 6rem;
            opacity: 1;
            margin-bottom: 1rem;
        }
    }
}
