// 5 columns
@include breakpoint(five) {
    .L-1-Video-16-9 {
        height: 67.5em;        
    }       
}

// 4 columns
@include breakpoint(four) {
    .L-1-Video-16-9 {
        height: 54em;        
    }          
}

// 3 columns
@include breakpoint(three) {
   .L-1-Video-16-9 {
        height: 40.5em;        
    }  
}

// 2 columns
@include breakpoint(two) {
    .L-1-Video-16-9 {
        height: 27em;        
    }        
}

// 1 columns
@include breakpoint(one) {
    .L-1-Video-16-9 {
        height: 13.5em;        
    }            
}

.L-1-Video-16-9 {     
    // Huge
    .b1 {
        position: relative;
        width: 100%;
        height: 100%;      
    }
}
