/* ==========================================================================
   Textitem
   ========================================================================== */

.textItem {
    h3 {
        @include typoZurichBd;
        @include typoZurich2-rem;
    }

    h4 {
        @include typoZurichBd;
        @include typoZurich1-rem;
    }

    .textWrapper {
        max-width: 72em;
        margin: 0 auto;
    }


    p:last-child {
        margin-bottom: 0;
    }

    &.bigText {
    	p {
            @include typoZurichBd;
            @include typoZurich2-rem;
        }
    }

    &.bigThinText {
    	p {
            @include typoZurich;
            @include typoZurich3-rem;
        }
    }

    &.bigLetterText {
        p {
            @include typoZurich;
            @include typoZurich3-rem;
            text-transform: none;
        }

        .linkWrapper a {
            @include typoZurich2-rem;
            line-height: 1.2em;
        }

        @include breakpoint(mobile) {
            .linkWrapper a {
                @include typoZurich1-rem;
                line-height: 1.2em;
            }
        }
    }

    &.defaultHausText,
    &.defaultText,
    &.jobsItem,
    &.kids-text {
        text-align: left;

        .headlineWrapper,
        .textWrapper {
            max-width: 4 * $moduleW-rem;
            margin: 0;
            padding: 0;
        }

        h1,
        h2 {
            @include typoZurich;
            @include typoZurich3-rem;
            margin-bottom: 0.25em;
            text-align: inherit;

            @include breakpoint(one) {
                @include typoZurich1-rem;
            }
        }

        h3 {
            @include typoZurich;
            @include typoZurich3-rem;
            text-align: inherit;

            @include breakpoint(one) {
                @include typoZurich1-rem;
            }
        }

        .linkWrapper a,
        .textWrapper p,
        .textWrapper li {
            @include typoZurich;
            @include typoZurich3-rem;
            text-transform: none;
            text-align: inherit;

            @include breakpoint(one) {
                @include typoZurich1-rem;
            }
        }

        .textWrapper,
        .linkWrapper {
            h3,
            a,
            p {
                margin: 1em 0 0 0;
            }
        }

        // Make adjustments for samplesale
        .filtered-collection-content.is-samplesale & {
            .headlineWrapper {
                padding-bottom: 0;
                max-width: none;
            }

            .textWrapper {
                max-width: none;
            }
        }
    }

    &.kids-text {
        h1,
        h2 {
            text-align: center;
        }
    }

    &.filter-product-text,
    &.large-intro-text,
    &.large-intro-text-expandable {
        text-align: left;

        .headlineWrapper {
            padding-bottom: 0;
            max-width: none;
        }

        .textWrapper {
            max-width: none;
        }

        h1,
        h2 {
            @include typoZurich;
            @include typoZurich3-rem;
            margin-bottom: 0.25em;
            text-align: inherit;

            @include breakpoint(five) {
                @include typoZurich4-rem;
            }

            @include breakpoint(one) {
                @include typoZurich1-rem;
            }
        }

        .textWrapper p,
        .textWrapper li {
            @include typoZurich;
            @include typoZurich3-rem;
            text-transform: none;
            text-align: inherit;

            @include breakpoint(five) {
                @include typoZurich4-rem;
            }

            @include breakpoint(one) {
                @include typoZurich1-rem;
            }
        }

        .read-more-wrapper {
            display: none;
        }
    }

    // MYKITA Studio
    &.large-intro-text-studio {
        text-align: left;

        .headlineWrapper {
            padding-bottom: 0;
            max-width: none;
        }

        .textWrapper {
            max-width: none;
        }

        h1,
        h2 {
            @include typoZurich;
            @include typoZurich4-rem-studio;
            text-align: center;
            margin-bottom: 0;

            @include breakpoint(five) {
                @include typoZurich5-rem-studio;
            }

            @include breakpoint(one) {
                @include typoZurich3-rem-studio;
            }
        }

        .textWrapper p {
            @include typoZurich;
            @include typoZurich3-rem;
            text-transform: none;
            text-align: left;

            @include breakpoint(five) {
                @include typoZurich4-rem;
            }

            @include breakpoint(one) {
                @include typoZurich1-rem;
            }
        }

        .read-more-wrapper {
            display: none;
        }
    }

    // Expandable filter product text
    .filter-product-text {
        // Make it expandable
        @include breakpoint(one) {
            .headlineWrapper {
                margin-bottom: 0.5rem;
            }
        }
    }

    &.filter-product-text,
    &.large-intro-text-studio {
        // Make it expandable
        @include breakpoint(one) {
            .textWrapper {
                overflow: hidden;
                word-wrap: break-word;
                display: block;
                transition: max-height $trans-time--default $trans-func--easing;
            }

            .read-more-wrapper {
                display: block;
                margin-top: 1rem;
                text-align: left;
                max-height: 4rem;
                opacity: 1;
                transition: max-height $trans-time--default $trans-func--easing,
                            opacity $trans-time--default $trans-func--easing,
                            margin $trans-time--default $trans-func--easing;

                .btn {
                    span:last-child {
                        display: none;
                    }
                }
            }

            &:not(.is-expanded) {
                .textWrapper {
                    display: block;
                    max-height: 3 * 1.5 * 1.1rem;
                }
            }

            &.is-expanded {
                .textWrapper {
                    max-height: 128rem;
                }

                .read-more-wrapper {
                    .btn {
                        span:first-child {
                            display: none;
                        }

                        span:last-child {
                            display: block;
                        }
                    }

                }
            }
        }
    }

    // Expandable large intro text
    &.large-intro-text-expandable {
        // Make it expandable
        .textWrapper {
            overflow: hidden;
            word-wrap: break-word;
            display: block;
            transition: max-height $trans-time--default $trans-func--easing;
        }

        .read-more-wrapper {
            display: block;
            margin-top: 1rem;
            text-align: left;
            max-height: 4rem;
            opacity: 1;
            transition: max-height $trans-time--default $trans-func--easing,
                        opacity $trans-time--default $trans-func--easing,
                        margin $trans-time--default $trans-func--easing;

            .btn {
                span:last-child {
                    display: none;
                }

                @include breakpoint(five) {
                    font-size: 2.4rem;
                }

                @include breakpoint(twofour) {
                    font-size: 1.75rem;
                }
            }
        }

        &:not(.is-expanded) {
            .textWrapper {
                display: block;
                max-height: 3 * 1.5 * 1.1rem;

                @include breakpoint(five) {
                    max-height: 3 * 1.5 * 2.4rem;
                }

                @include breakpoint(twofour) {
                    max-height: 3 * 1.5 * 1.75rem;
                }
            }
        }

        &.is-expanded {
            .textWrapper {
                max-height: 128rem;
            }

            .read-more-wrapper {
                .btn {
                    span:first-child {
                        display: none;
                    }

                    span:last-child {
                        display: block;
                    }
                }

            }
        }
    }

    &.centerPadding {
        .textWrapper {
        	max-width: 60em;
    	}
    }

    &.filter-product-text,
    &.large-intro-text,
    &.large-intro-text-studio ,
    &.large-intro-text-expandable {
        ul:not([style*="square"]) {
            li {
                display: block;
                margin: 0;
                position: relative;
                padding-left: 1em;

                &:before {
                    content: "\25cf";
                    position: absolute;
                    left: 0;
                    font-size: 0.6em;
                    top: 0.4em;
                }
            }
        }

        ul[style*="square"] {
            margin: -0.5rem;
            list-style-type: none !important;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            li {
                @include typoZurich;
                @include typoZurich4-rem;
                text-transform: none;
                display: inline-block;
                margin: 0.5rem;
                background-color: #d2d1d1;
                color: #fff;
                padding: 0.25em 0.75em;
                line-height: 1;

                &:not(:first-child) {
                    border-radius: 2em;
                }
            }

            @include breakpoint(two) {
                li {
                    @include typoZurich;
                    @include typoZurich3-rem;
                    text-transform: none;
                }
            }

            @include breakpoint(one) {
                li {
                    @include typoZurich;
                    @include typoZurich1-rem;
                    text-transform: none;
                }
            }
        }
    }

    .button-wrapper {
        text-align: center;

        .btn {
            display: inline-block;
            width: auto;
        }
    }

    .headlineWrapper.headlineWrapper--large {
        h1,
        h2 {
            @include typoZurich;
            @include typoZurich6-rem;
            text-align: center;
            margin: 0;
            text-wrap: balance;

            @include breakpoint(two) {
                @include typoZurich4-rem;
            }

            @include breakpoint(one) {
                @include typoZurich3-rem;
            }

        }
    }

    .headlineWrapper--large + .textWrapper {
        margin-top: 4rem;

        @include breakpoint(mobile) {
            margin-top: 3rem;
        }
    }
}

@include breakpoint(three) {
    .textItem {

    }
}

@include breakpoint(three) {
    .textItem {
        &.centerPadding {
            .textWrapper {
                max-width: 52em;
            }
        }
    }
}

@include breakpoint(two) {
    .textItem {
        &.centerPadding {
            .textWrapper {
                max-width: 32em;
            }
        }
    }
}


@include breakpoint(one) {
    .textItem {
        &.centerPadding {
            .textWrapper {
                max-width: 20em;
            }
        }
    }
}
