// 5 columns
@include breakpoint(five) {
    .L-MYLON-3 {
        height: 2 * $moduleH;        
        
        // Bigger
        .b1 {
            top: 0 * $moduleH;
            left: 3 * $moduleW;
            width: 2 * $moduleW;    
            height: 2 * $moduleH;       
        }
    }       
}

// 4 columns
@include breakpoint(four) {
    .L-MYLON-3 {
        height: 2 * $moduleH;        
        
        // Bigger
        .b1 {
            top: 0 * $moduleH;
            left: 2 * $moduleW;
            width: 2 * $moduleW;    
            height: 2 * $moduleH;      
        }
    }          
}

// 3 columns
@include breakpoint(three) {
   .L-MYLON-3 {
        height: 2 * $moduleH;        
        
        // Bigger
        .b1 {
            top: 0 * $moduleH;
            left: 1 * $moduleW;
            width: 2 * $moduleW;    
            height: 2 * $moduleH;      
        }
    } 
}

// 2 columns
@include breakpoint(two) {
    .L-MYLON-3 {
        height: 2 * $moduleH;        
        
        // Bigger
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;  
        }
    }       
}

// 1 columns
@include breakpoint(one) {
    .L-MYLON-3 {
        height: 1 * $moduleH;        
        
        // Bigger
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;  
        }
    }           
}
