.L-SF-Shop.noService .b4 {
    display: none;
}

// One Column
@include breakpoint(one) {
    .L-SF-Shop {
        height: auto;        
        
        .b1,
        .b2,
        .b3,
        .b4 {
            position: relative;
            float: left;
        }
        
        .b2,
        .b3,
        .b4 {
            height: auto;
            margin-bottom: 2em;
        }
    }
}

// 2 columns
@include breakpoint(two) {
    .L-SF-Shop {
        height: 4.25 * $moduleH;        
        
        // Bigger SF / Store Image
        .b1 {
            top:  0;
            left: 0;
        }
        
        // Small / Address
        .b2 {
            top:  2.25 * $moduleH;
            left: 0 * $moduleW;
        }
        
        // Small / Services
        .b3 {
            top:  2.25 * $moduleH;
            left: 1 * $moduleW;  
        }
        
        // Small / Opening hours
        .b4 {
            top:  2.25 * $moduleH;
            left: 2 * $moduleW;
        }
        
        &.hasService {
            // Small / Address
            .b2 {
                top:  2.25 * $moduleH;
                left: 0 * $moduleW;
                width: 1 * $moduleH;   
            }
            
            // Small / Services
            .b3 {
                top:  2.25 * $moduleH;
                left: 1 * $moduleH;
                width: 1 * $moduleH;   
            }
            
            // Small / Opening hours
            .b4 {
                top:  2.25 * $moduleH;
                left: 2 * $moduleH;
                width: 1 * $moduleH;
            }    
        }
        
        &.noService {
            .b4 {
                display: none;
            }
        }
    }  
}

// 3 columns
@include breakpoint(desktop) {
    .L-SF-Shop {
        height: 5.25 * $moduleH;        
        
        // Bigger SF / Store Image
        .b1 {
            top:  0;
            left: 0;
        }
        
        // Small / Address
        .b2 {
            top:  3.25 * $moduleH;
            left: 0 * $moduleW;   
        }
        
        // Small / Services
        .b3 {
            top:  3.25 * $moduleH;
            left: 1 * $moduleW;   
        }
        
        // Small / Opening hours
        .b4 {
            top:  3.25 * $moduleH;
            left: 2 * $moduleW; 
        }
    } 
}