/* ==========================================================================
   Image Teaser
   ========================================================================== */

.imageTeaser,
.imgBox {
    a {
        h1,
        h2,
        h3,
        h4 {
            text-align: center;
        }

        h3 {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
        }

        h4 {
            @include typoZurich;
            @include typoZurich0-rem;
            text-transform: none;
            line-height: 1.1;
            display: inline-block;
            border-bottom: solid 1px #000;
        }
    }

    .article-tooltip {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .textBoxWrapper {
        position: absolute;
        width: 100%;
        display: block;
        z-index: 10;

        .h1Wrapper,
        .h2Wrapper,
        .h3Wrapper,
        .link-wrapper,
        .touchHoverWrapper {
            position: static;
        }
    }

    // White Teaser Color
    &.teaserColorWhite {
        h1,
        h2,
        h3,
        h4,
        span,
        a h1,
        a h2,
        a h3,
        a h4,
        a span{
            color: #fff;
        }

        .link-wrapper h4 {
            border-color: #fff;
        }
    }

    // Black Teaser color
    &.teaserColorBlack {
        a {
            h1,
            h2,
            h3,
            h4,
            span {
                color: #000;
            }
        }

        .link-wrapper h4 {
            border-color: #000;
        }
    }

    // Old Teaser
    &.teaserVer1 {
        .textBoxWrapper {
            top: 50%;
            left: 0;
        }

        .h2Wrapper {
            margin: 0 0 1em 0;
        }

        .h3Wrapper {
            margin: 0;
        }
    }

    // New Teaser
    &.teaserVer2,
    &.teaserVer4,
    &.teaserVer5 {
        .textBoxWrapper {
            bottom: 1.5em;
            left: 0;
            transform: translate3d(0, 0, 0);

            @include breakpoint(two) {
                bottom: 1em;
            }

            @include breakpoint(one) {
                bottom: 1em;
            }
        }

        h3 {
            @include typoZurichReg;
            @include typoZurich1-rem;
        }

        h2,
        h3 {
            transform: translate3d(0, 0, 0);
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;

            @include breakpoint(one) {
                @include typoZurich0-rem;
            }
        }

        .h2Wrapper {
            box-sizing: border-box;
            padding: 0 1rem;
            margin: 0.25em 0 0 0;
        }

        .h3Wrapper {
            box-sizing: border-box;
            padding: 0 1rem;
            margin: 0;

            img.overlayImg {
                max-width: 80%;
                max-height: 4em;
            }
        }

        .link-wrapper {
            box-sizing: border-box;
            padding: 0 1rem;
            margin: 0.25em 0 0 0;
        }
    }

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
    }
}

// Special positioning for hero modules
.extra .imageTeaser.teaserVer2,
.extra .imageTeaser.teaserVer4,
.extra .imageTeaser.teaserVer5 {
    .textBoxWrapper {
        bottom: 3em;

        @include breakpoint(two) {
            bottom: 2em;
        }

        @include breakpoint(one) {
            bottom: 1.5em;
        }
    }
}


.imageTeaser.loading {
    background: url(../images/icons/loader_32px_t.gif) 50% 50% no-repeat transparent;
}

// Image/Headline/Text combo teaser
.image-teaser.image-teaser--6 {
    .image-teaser__image-box {
        position: relative;
        width: 100%;
        padding-bottom: 75%;

        .boxLink {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &.image-teaser__image-box--portrait {
            padding-bottom: 150%;
        }
    }

    .image-teaser__context-box {
        display: block;
        text-align: left;


        h3 {
            @include typoZurich;
            @include typoZurich2-rem;
            margin-top: 0.5rem;
            margin-bottom: 0;
        }

        p {
            @include typoZurich;
            @include typoZurich2-rem;
            text-transform: none;
            margin-top: 0.5rem;
            margin-bottom: 0;
        }

        a {
            @include typoZurich;
            @include typoZurich2-rem;
            display: inline-block;
            text-transform: none;
            color: $text-color--hover;
            transition: color $trans-time--fast $trans-func--easing,
                        border $trans-time--fast $trans-func--easing;
        }

        .read-more-wrapper {
            display: none;
        }

        @include breakpoint(one) {
            .text-wrapper {
                overflow: hidden;
                word-wrap: break-word;
                display: block;
                transition: max-height $trans-time--default $trans-func--easing;
            }

            .read-more-wrapper {
                display: block;
                margin-top: 0.5rem;
                text-align: left;
                max-height: 4rem;
                opacity: 1;
                overflow: hidden;
                transition: max-height $trans-time--fast $trans-func--easing,
                            opacity $trans-time--fast $trans-func--easing,
                            margin $trans-time--fast $trans-func--easing;

                .btn {
                    @include typoZurich;
                    @include typoZurich2-rem;
                    text-transform: none;
                    color: $text-color--hover;
                }
            }

            &:not(.is-expanded) {
                .text-wrapper {
                    display: block;
                    max-height: 3 * 1.35 * 1.5rem;
                }
            }

            &.is-expanded {
                .text-wrapper {
                    max-height: 64rem;
                }

                .read-more-wrapper {
                    max-height: 0;
                    opacity: 0;
                    margin-top: 0;
                }
            }
        }
    }
}

// Startpage Teaser
.image-teaser.image-teaser--7 {
    width: 100%;
    height: 100%;

    .image-teaser__image-box {
        width: 100%;
        height: 100%;
    }

    .image-teaser__context-box {
        position: absolute;
        top: auto;
        left: 4rem;
        right: 4rem;
        bottom: 4rem;
        z-index: 2;
        text-align: left;

        &.image-teaser__context-box--top {
            top: 4rem;
            bottom: auto;
        }

        &.image-teaser__context-box--middle {
            top: 4rem;
            bottom: 4rem;
            display: flex;
            align-items: center;

            > * {
                width: 100%;
            }
        }
    }

    .image-teaser__box-link {
        display: block;
        width: 100%;
        height: 100%;
    }

    h2,
    h3 {
        @include typoZurich;
        @include typoZurich3-rem;
        text-align: inherit;
    }

    h3 {
        display: inline;
        text-transform: none;
        border-bottom: solid 1px currentColor;
    }

    &.is-inverted {
        h2,
        h3 {
            color: $text-color--light-2;
        }
    }

    &.image-teaser-text-left {
        .image-teaser__context-box {
            text-align: left;
        }
    }

    &.image-teaser-text-center {
        .image-teaser__context-box {
            text-align: center;
        }
    }

    &.image-teaser-text-right {
        .image-teaser__context-box {
            text-align: right;
        }
    }

    @include breakpoint(mobile) {
        .image-teaser__context-box {
            top: auto;
            left: 2rem;
            right: 2rem;
            bottom: 2rem;

            &.image-teaser__context-box--top {
                top: 2rem;
                bottom: auto;
            }
        }

        h2,
        h3 {
            @include typoZurich;
            @include typoZurich2-rem;
        }

        h3 {
            text-transform: none;
        }

    }

    &.has-hidden-text-mobile {
        @include breakpoint(mobile) {
            .image-teaser__context-box {
                display: none;
            }
        }
    }
}


// Video box
.image-teaser__video-box {
    width: 100%;
    height: 100%;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .video-poster {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        transition: opacity $trans-time--slow $trans-func--easing;
        transition-delay: $trans-time--default;
        opacity: 1;
        pointer-events: none;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .video-play-fallback {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        transition: opacity $trans-time--default $trans-func--easing;
        opacity: 0;
        pointer-events: none;

        .video-play-button {
            width: 5rem;
            height: 5rem;
            cursor: pointer;
            opacity: 0.75;

            .svg-ico {
                fill: #fff;
            }

            @include breakpoint(mobile) {
                width: 3rem;
                height: 3rem;
            }
        }
    }

    video.is-playing ~ .video-poster,
    video.played-before ~ .video-poster {
        opacity: 0;
    }

    video.video-autoplay-not-allowed:not(.is-playing) ~ .video-play-fallback {
        opacity: 1;

        .video-play-button {
            pointer-events: all;
        }
    }

    video.is-playing ~ .video-play-fallback {
        opacity: 0;

        .video-play-button {
            pointer-events: none;
        }
    }

    .video-mute-toggle {
        @include typoZurich;
        @include typoZurich2-rem;
        position: absolute;
        bottom: 3rem;
        transition: opacity $trans-time--fast $trans-func--easing,
                    color $trans-time--fast $trans-func--easing;
        padding: 0;
        background: none;
        width: auto;
        height: auto;
        opacity: 0;
        z-index: 1;
        line-height: 1;
        color: var(--text-color, currentColor);

        &.video-mute-toggle--left {
            left: 2rem;
        }

        &.video-mute-toggle--center {
            left: 50%;
            transform: translateX(-50%);
        }

        &.video-mute-toggle--right {
            right: 2rem;
        }

        @include breakpoint(mobile) {
            @include typoZurich;
            @include typoZurich1-rem;
            bottom: 0.9rem;

            &.video-mute-toggle--left {
                left: 1rem;
            }

            &.video-mute-toggle--right {
                right: 1rem;
            }
        }
    }

    video.is-playing ~ .video-mute-toggle,
    video.played-before ~ .video-mute-toggle {
        opacity: 1;
    }

    video[muted] ~ .video-mute-toggle span:last-child {
        display: none;
    }

    video:not([muted]) ~ .video-mute-toggle span:first-child {
        display: none;
    }
}

.image-teaser--6,
.image-teaser--7 {
    .image-teaser__logo {
        display: none;
        position: fixed;
        width: calc(100% - 6rem);
        top: 6rem;
        left: 50%;
        padding-bottom: 6rem; // Just some space for the intersection observer
        z-index: 5;
        pointer-events: none;
        transform: translate3d(-50%, 0, 0);
        transform-origin: top center;
        transition: transform $overlayTime $overlayFunc, width $overlayTime $overlayFunc;

        svg {
            width: 100%;
            height: auto;
            fill: var(--text-color, #fff);
        }

        .has-global-banner & {
            transform: translate3d(0, $global-banner-height--desktop, 0);
        }

        .has-scrolled & {
            transform: translate3d(-50%, -4rem, 0);
            width: 11.2rem;
        }
    }

    @include breakpoint(mobile) {
        .image-teaser__logo {
            inset: 2rem 2rem 0 2rem;

            .has-global-banner & {
                transform: translate3d(0, $global-banner-height--mobile, 0);
            }
        }
    }
}