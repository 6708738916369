// 5 columns
@include breakpoint(five) {
    .L-5-Teaser {
        height: 3.5 * $moduleH;

        // Extra
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;
        }
    }
}

// 4 columns
@include breakpoint(four) {
    .L-5-Teaser {
        height: 3 * $moduleH;

        // Extra
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;
        }
    }
}

// 3 columns
@include breakpoint(three) {
   .L-5-Teaser {
        height: 2.5 * $moduleH;

        // Extra
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;
        }
    }
}

@include breakpoint(mobile) {
    .L-5-Teaser {
        margin-left: auto;
        margin-right: auto;
        position: relative;
        text-align: center;
        height: auto;
        width: 100%;

        .b1 {
            width: 100%;
            float: none;
            position: relative;
            padding: 0;
            overflow: hidden;
            padding-bottom: 66.66666%;

            // Disable box link hover effect
            a.boxLink:hover .media-box img {
                opacity: 1;
            }

            & > * {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }


            @media (orientation: landscape) and (min-width: 480px) {
                padding-bottom: 0;
                height: $full-height-vh--desktop;
                height: $full-height-svh--desktop;
            }
        }
    }
}

@include breakpoint(one) {
    .L-5-Teaser {
        .b1 {
            padding-bottom: 100%;
        }
    }
}
