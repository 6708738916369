/* ==========================================================================
   Collaborations page
   ========================================================================== */

.collaborations {
    .collaboration {
    	margin-top: 4em;

    	h3 {
    	    color: #000;
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
    	}
    }
}

.collaborations .collaboration,
.projectsHead {
    .logoBox {
        padding: 0 0 1em 0;

        img {
           max-width: 80%;
           width: 40em;
        }
    }
}

// Reduce height for mobile
@include breakpoint(one) {
    .collaboration .huge {
        height: 16em;
    }
}
