// 5 columns
@include breakpoint(five) {
    .L-4-5-1 {
        height: 3 * $moduleH;

        // Image/Slideshow / Bigger
        .b1 {
            top: 0;
            left: 0;
        }

        // Info / Medium
        .b2 {
            top: 0;
            left: 3 * $moduleW;
        }

        // Info / Small
        .b3 {
            top: 2 * $moduleH;
            left: 3 * $moduleW;
        }

        // Maplink / Small
        .b4 {
            top: 2 * $moduleH;
            left: 4 * $moduleW;
        }
    }
}

// 4 columns
@include breakpoint(four) {
    .L-4-5-1 {
        height: 3 * $moduleH;

        // Image/Slideshow / Bigger
        .b1 {
            top: 0;
            left: 0;
        }

        // Info / Medium
        .b2 {
            top: 0;
            left: 3 * $moduleW;
            width: 1 * $moduleW;
            height: 1 * $moduleH;

            h2 {
			    @include typoZurich1-rem;
			}
        }


        // Info / Small
        .b3 {
            top: 1 * $moduleH;
            left: 3 * $moduleW;
        }

        // Maplink / Small
        .b4 {
            top: 2 * $moduleH;
            left: 3 * $moduleW;
        }
    }
}

// 3 columns
@include breakpoint(three) {
   .L-4-5-1 {
        height: 4 * $moduleH;

        // Image/Slideshow / Bigger
        .b1 {
            top: 0;
            left: 0;
        }

        // Info / Medium
        .b2 {
            top: 3 * $moduleH;
            left: 0 * $moduleW;
            width: 1 * $moduleW;
            height: 1 * $moduleH;

            h2 {
			    @include typoZurich1-rem;
			}
        }

        // Info / Small
        .b3 {
            top: 3 * $moduleH;
            left: 1 * $moduleW;
        }

        // Maplink / Small
        .b4 {
            top: 3 * $moduleH;
            left: 2 * $moduleW;
        }
    }
}

// 2 columns
@include breakpoint(two) {
    .L-4-5-1 {
        height: 5 * $moduleH;

        // Image/Slideshow / Bigger
        .b1 {
            top: 0;
            left: 0;
        }

        // Info / Medium
        .b2 {
            top: 2 * $moduleH;
            left: 0;
        }

        // Info / Small
        .b3 {
            top: 4 * $moduleH;
            left: 0;
        }

        // Maplink / Small
        .b4 {
            top: 4 * $moduleH;
            left: 1 * $moduleW;
        }
    }
}

// 1 columns
@include breakpoint(one) {
    .L-4-5-1 {
        height: 2.5 * $moduleH;

        // Image/Slideshow / Bigger
        .b1 {
            top: 0;
            left: 0;
        }

        // Info / Medium
        .b2 {
            top: 1 * $moduleH;
            left: 0;
        }

        // Info / Small
        .b3 {
            top: 2 * $moduleH;
            left: 0;
            width: 0.5 * $moduleW;
            height: 0.5 * $moduleH;

            h2 {
	            @include typoZurichS-rem;
            }

        }

        // Maplink / Small
        .b4 {
            top: 2 * $moduleH;
            left: 0.5 * $moduleW;
            width: 0.5 * $moduleW;
            height: 0.5 * $moduleH;

            h2 {
	            @include typoZurichS-rem;
            }
        }

    }
}
