.navigation-overlay {
    position: fixed;
    z-index: zIndex(navigation-overlay);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    box-sizing: border-box;
    opacity: 0;
    pointer-events: none;
    transition: opacity $trans-time--default $trans-func--default;

    * {
        box-sizing: border-box;
    }

    &[aria-hidden='false'] {
        opacity: 1;
        pointer-events: all;
    }

    @include breakpoint(mobile) {
        bottom: $header-height--mobile;
    }
}

.navigation-overlay__blur-backdrop {
    position: fixed;
    z-index: zIndex(navigation-overlay-blur);
    top: 0;
    left: 0;
    bottom: 0;
    width: 50vw;
    background: $backdrop-background;
    backdrop-filter: $backdrop-blur;
    opacity: 0;
    transition: opacity $trans-time--default $trans-func--default;
    pointer-events: none;

    @include breakpoint(mobile) {
        width: 100%;
        bottom: $header-height--mobile;
        backdrop-filter: none;
        background: #fff;
    }
}

.navigation-overlay[aria-hidden='false'] + .navigation-overlay__blur-backdrop {
    opacity: 1;
}

.has-navigation-overlay {
    overflow: hidden;
    margin-right: var(--scrollbar-w, 0px);
}

.navigation-overlay__close {
    position: absolute;
    top: 2rem;
    left: 3rem;
    width: 2.5rem;
    height: 2.5rem;
    z-index: 1;

    @include breakpoint(mobile) {
        display: none;
    }
}

.navigation-overlay__content-scroll {
    width: 100%;
    display: flex;
    align-items: flex-start;
    overflow-y: scroll;
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
}

.navigation-overlay__content {
    display: flex;
    flex-direction: column;
    width: 50vw;
    min-height: 100%;
    text-align: left;
    padding: 7rem 3rem 2rem 3rem;

    @include breakpoint(mobile) {
        width: 100%;
        padding: 2rem 1.5rem;
    }
}

.navigation-overlay__close-backdrop {
    display: inline-block;
    flex: 1 1 auto;
    height: 100%;
    cursor: pointer;
    position: sticky;
    top: 0;
}

.navigation-overlay__navigation {
    @include breakpoint(desktop) {
        flex: 1 1 auto;
    }

    @include breakpoint(mobile) {
        margin-top: 2rem;
    }
}

.navigation-overlay__navigation-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.navigation-overlay__navigation-item {
    display: block;
}

.navigation-overlay__navigation-item-link {
    @include typoZurich;
    @include typoZurich3-rem;
}

// Sub navigation related
.navigation-overlay__sub-navigation {
    &:not(:first-child) {
        margin-top: 1.5rem;
    }
}

.navigation-overlay__sub-navigation-group {
    @include typoZurich;
    @include typoZurich2-rem;

    &[aria-hidden="true"] {
        display: none;
    }
}

.navigation-overlay__sub-navigation-list {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin-top: 0.25rem;
}

.navigation-overlay__navigation-item {
    display: block;
}

.navigation-overlay__sub-navigation-item-link {
    @include typoZurich;
    @include typoZurich2-rem;
    text-transform: none;

    &.navigation-overlay__sub-navigation-item-link--main {
        display: inline-block;
    }
}

.navigation-overlay__sub-navigation + .navigation-overlay__sub-navigation-item-link--main {
    margin-top: 2rem;
}

// Quick access
.navigation-overlay__quick-access {
    margin-top: 4rem;

    @include breakpoint(mobile) {
        display: none;
    }
}

.navigation-overlay__quick-access-image {
    .media-box figure img {
        width: 100%;
    }
}

.navigation-overlay__quick-access-title,
.navigation-overlay__quick-access-description {
    @include typoZurich;
    @include typoZurich1-rem;
    text-transform: none;
    margin: 0.5rem 0 0 0;
}

// Navigation search
.navigation-overlay__search {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;

    .navigation-overlay__search-input {
        width: 100%;
        background: none;
        border-bottom: solid 1px $border-color-dark;
        padding: 0 0.25rem;
        height: 2.5rem;
        line-height: 2.5rem;
    }

    @include breakpoint(desktop) {
        display: none;
    }
}

.btn.navigation-overlay__search-toggle {
    display: grid;
    grid-template-columns: 1fr;
    width: 2rem;
    height: 2rem;

    > span {
        grid-row-start: 1;
        grid-column-start: 1;
        transition: opacity $trans-time--default $trans-func--default;
        opacity: 0;
    }

    .navigation-overlay__search[aria-expanded='false'] & {
        span:first-child {
            // opacity: 1;
        }
    }

    .navigation-overlay__search[aria-expanded='true'] & {
        span:last-child {
            opacity: 1;
        }
    }
}

.navigation-overlay__search-container {
    pointer-events: none;
    opacity: 0;
    transition: opacity $trans-time--default $trans-func--default;
    flex: 1 1 auto;

    .navigation-overlay__search[aria-expanded='true'] & {
        opacity: 1;
        pointer-events: all;
    }
}

// Mobile functions nav
.navigation-overlay__mobile-functions {
    margin-top: 2rem;

    @include breakpoint(desktop) {
        display: none;
    }
}

.navigation-overlay__mobile-functions-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.navigation-overlay__mobile-functions-item-link {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.navigation-overlay__mobile-functions-item-icon {
    width: 1.2rem;
    height: 1.2rem;

    .svg-ico {
        width: 100%;
        height: 100%;
    }

    .svg-ico-cart,
    .svg-ico-wishlist,
    .svg-ico-profile {
        stroke: #000;
    }
}

.navigation-overlay__mobile-functions-item-label {
    @include typoZurich;
    @include typoZurich1-rem;
    text-transform: none;

    .underlined {
        border-bottom: solid 1px $border-color-dark;

        .hairlines & {
            border-width: 0.5px;
        }
    }
}