.faq {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: flex-start;
    padding-bottom: 4rem;

    * {
        box-sizing: border-box;
    }

    @include breakpoint(mobile) {
        flex-direction: column;
    }
}

.faq__headline {
    width: 100%;
    height: 10rem;

    h2 {
        margin: 0;
    }

    @include breakpoint(mobile) {
        height: auto;
    }
}

.faq__content-category-headline {
    height: 3rem;
    padding-left: 0.5rem;

    @include breakpoint(mobile) {
        display: none;
    }
}

.faq__navigation {
    width: 1.5 * $moduleW-rem;
    position: sticky;
    top: $header-height--desktop;

    @include breakpoint(three) {
        width: 1 * $moduleW-rem;
    }

    @include breakpoint(desktop) {
        padding-left: $border;
    }

    @include breakpoint(mobile) {
        width: 100%;
        top: $header-height--mobile - 7rem;
        z-index: 2;
    }
}

.faq__content {
    width: 3 * $moduleW-rem;
    margin-top: 4rem;

    @include breakpoint(four) {
        width: 2.5 * $moduleW-rem;
    }

    @include breakpoint(three) {
        width: 2 * $moduleW-rem;
    }

    @include breakpoint(desktop) {
        padding-right: $border;
    }

    @include breakpoint(mobile) {
        width: 100%;
        margin-top: 3.5rem;
    }
}

.faq__categories--desktop {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include breakpoint(mobile) {
        display: none;
    }
}

.faq__categories--mobile {
    margin-top: 4rem;
    @include breakpoint(desktop) {
        display: none;
    }

    select,
    .select2 {
        width: 100%;
    }
}

.faq__category {
    display: block;
}

.faq__category-link {
    &[aria-selected='true'] {
        display: inline;
        border-bottom: solid 1px black;
    }
}

.faq__content {
    display: grid;
    place-content: start;

    > * {
        grid-area: 1 / 1;
    }
}

.faq__content-category {
    background: transparent;
    transition: background $trans-time--default $trans-func--default;

    > * {
        opacity: 0;
        //transform: translate3d(1rem, 0, 0);
        transition: transform $trans-time--default $trans-func--default,
        opacity $trans-time--default $trans-func--default;
    }

    &[aria-hidden='true'] {
        pointer-events: none;
    }

    &[aria-hidden='false'] {
        background: #fff;

        > * {
            opacity: 1;
            transform: none;
        }
    }
}


.faq__entries {
    margin-top: 2rem;

    @include breakpoint(mobile) {
        margin-top: 0;
    }
}

.faq-entry {
    border-bottom: solid 1px $border-color--light-1;

    .accordion__head {
        padding: 1rem 0.5rem;
        display: flex;
        align-items: center;
    }

    .accordion__head-indicator {
        position: relative;
        top: auto;
        left: auto;
        flex-shrink: 0;
        margin-top: 0;
        transform-origin: 50% 50%;
        transform: rotate(45deg);
    }

    .accordion[aria-expanded='true'] {
        .accordion__head-indicator {
            transform: rotate(0deg);
        }
    }
}

.faq-entry__title {
    flex: 1 1 auto;
    padding-right: 1rem;
}

.faq-entry__content {
    padding: 2rem 0.5rem 4rem 0.5rem;

    p {
        margin: 0;
    }

    ul {
        margin: 0;

        li {
            padding: 0 0 0 2rem;

            &:before {
                content: '–';
                text-indent: -2rem;
            }
        }

        li + li {
            margin-top: 1rem;
        }
    }
}

// FAQ Typo
.faq__headline h2,
.faq__content-category-headline {
    @include typoZurich;
    @include typoZurich3-rem;

    @include breakpoint(mobile) {
        @include typoZurich2-rem;
    }
}

.faq__category-link,
.faq-entry__title,
.faq-entry__content {
    @include typoZurich;
    @include typoZurich3-rem;
    text-transform: none;

    @include breakpoint(mobile) {
        @include typoZurich2-rem;
        text-transform: none;
    }
}

.faq__category-link,
.select2-enhanced-container .select2-chosen,
.select2-enhanced-dropdown .select2-result-label span {
    @include breakpoint(mobile) {
        text-transform: uppercase;
    }
}