.shopfinder-introduction {
    .module.imageItem  {
        width: $moduleW-rem;
        height: $moduleW-rem;
    }

    .textWrapper p,
    .textWrapper span {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
    }

    @include breakpoint(desktop) {
        display: none;
    }

    @include breakpoint(two) {
        .module.imageItem {
            width: 2 * $moduleW-rem;
            height: 2 * $moduleW-rem;
        }
    }
}