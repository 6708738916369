.timify-overlay {
    position: fixed;
    z-index: zIndex(timify-booking);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    background-color: rgba(250, 250, 253, 0.9);
    transition: opacity $trans-time--default $trans-func--default;
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(mobile) {
        //align-items: flex-end;
    }

    &[aria-hidden="false"] {
        opacity: 1;
        pointer-events: all;
    }
}

.timify-frame-wrapper {
    position: relative;
    background: #fff;
    width: calc(100% - 8rem);
    height: calc(100% - 8rem);
    max-width: 60rem;
    max-height: 60rem;
    opacity: 0;
    transform: translate3d(0, 2rem, 0);
    box-shadow: 0 0 5px 1px rgba(50, 50, 50, 0.15);
    transition: opacity $trans-time--default $trans-func--default,
                transform $trans-time--default $trans-func--default;

    .timify-overlay[aria-hidden='false'] & {
        opacity: 1;
        transform: none;
    }

    @include breakpoint(mobile) {
        width: 100%;
        height: 100%;
        max-width: calc(100% - 2rem);
        max-height: calc(100% - 2rem);
    }
}

.timify-iframe {
    border: none;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity $trans-time--default $trans-func--default;
    overscroll-behavior: contain;

    .timify-overlay[aria-busy="true"] & {
        opacity: 0;
    }
}

.timify-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 28px;
    height: 28px;
    z-index: 1;

    .btn {
        width: 100%;
        height: 100%;
    }

    .svg-ico {
        width: 100%;
        height: 100%;
    }
}

.has-timify-overlay {
    overflow: hidden;
}