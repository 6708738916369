/* ==========================================================================
   Article Notfication request
   ========================================================================== */
.artNotifyOpened {
    .toolsheader-module-articlenotification {
        @extend %toolsheader-module-active;
    }

    .model-details {
        padding: 0;
        margin: 1rem 0 0 0;

        .frame-name {
            @include typoZurichBd;
            @include typoZurich1-rem;
            margin-bottom: 0.25rem;
        }

        .frame-color,
        .lens-color {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
        }
    }
}
