/* Panels
   ========================================================================== */
.panel {
    @extend %cf;
    position: relative;
    text-align: left;
    margin-bottom: 3rem;

    .panel-head {
        position: relative;
        padding-bottom: 1rem;
        margin-bottom: 1rem;

        h4 {
            @include typoZurich;
            @include typoZurich1-rem;
        }
    }

    .panel-content {
        position: relative;
    }

    .panel-hint {
        margin-top: 1rem;

        span {
            @include typoZurich;
            @include typoZurichS2-rem;
            text-transform: none;
        }
    }

    .panel-change {
        @include typoZurichS2-rem;
        position: absolute;
        top: 0.5rem;
        right: 0;
        line-height: 1.1;
    }

    // Typography
    p {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        margin-bottom: 0;
    }



    &.panel-submit {
        margin-top: 3rem;
    }

    .css-loading-wrapper {
        .css-loading-inline {
            width: 100%;
            height: 8rem;
            background: url(../images/icons/loader_32px_t.gif) no-repeat 50% 50% transparent;
        }
    }

    // Grey version of the panel
    &.panel--grey {
        margin-bottom: 0;

        .panel-head {
            background-color: $backGrey;
            padding: 1rem;
            margin-bottom: 0;
        }

        .panel-content {
            background-color: $backGrey;
            padding: 1rem;
        }

        .panel-change {
            top: 1.2rem;
            right: 1rem;
        }
    }
}

.panel--grey + .panel {
    margin-top: 3rem;

    &.panel-submit {
        margin-top: 6rem;
    }
}
