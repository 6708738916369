/* ==========================================================================
   Print Stuff
   ========================================================================== */
#printContainer {
    display: none;
}

@media screen {
	.printContainer {
	   display: none;
	}
}

@media print {
    #toolsHeader,
    #toolbar,
    #toolbarClose,
    #headNav,
    #topNav,
    footer,
    #filter,
    #mobileNav,
    .printItem,
    .overlayHoverContainer
    .print,
    #mobileMenuToggle,
    #mobileFilterToggle,
    .overlayTitleContainer,
    .fixedOverlayTitleContainer,
    .productDetail .slideshow,
    #tooltip {
        display: none !important;
    }

    #header {
        top: 0 !important;
        position: absolute !important;
        opacity: 1 !important;
        margin-top: 0 !important;
        z-index: 100001;
        height: 8em;

        #logo {
            position: absolute;
            top: 0;
            left: $border;
            width: 12em;
            margin: 0;
            padding: 0;

            a {
                padding: 0;
            }
        }
    }

    .outer-wrapper {
        padding-top: 0;
    }

    body {
        margin: 20px 0;
    }

    .overlayContainerInner {
        padding-top: 8em;
    }

    .siteContainer {
    	padding-top: 6em;
    }

    .overlayContainer.added[data-overlayno="1"],
    .overlayContainer.added[data-overlayno="2"],
    .overlayContainer.added[data-overlayno="3"] {
        left: 0px;
        width: 100%;
        border: 0;
    }

    .printContainer {
        display: block;
        img {
            width: 100%;
        }
    }
}
