.search-results {
    text-align: left;
    display: none;

    .search-results-group {
        display: none;

        &.has-results {
            display: block;
            margin-bottom: 4rem;
        }
    }

    .search-results-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .search-results-list-item {
            box-sizing: border-box;
            display: none;
            text-align: left;
            padding: 0 $box-padding--extended 3rem $box-padding--extended;
            width: $moduleW-rem;

            @for $i from 1 through 3 {
                &:nth-child(#{$i}) {
                    display: inline-block;
                }
            }
        }

        &.is-expanded {
            .search-results-list-item {
                display: inline-block;
            }
        }
    }

    h3 {
        @include typoZurich;
        @include typoZurich1-rem;
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 1rem;
        padding: 0 $box-padding--extended;
    }

    h4 {
        @include typoZurich;
        @include typoZurich1-rem;
        margin-bottom: 0.25rem;
    }

    p {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        margin-bottom: 0.2rem;
        font-style: normal;
    }

    address {
        font-style: normal;
        text-align: left;
    }

    .text-link {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        line-height: 1.1;
    }

    .result-image {
        position: relative;
        width: 100%;
        margin-bottom: 2rem;

        .box {
            position: relative;
            width: 100%;
            padding-bottom: 100%;
            height: auto;
            float: none;
        }

        .module {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .result-links {
        margin-top: 0.5rem;

        li:not(:last-child) {
            margin-bottom: 0.5rem;
        }
    }

    .btn--view-more {
        display: block;
        text-align: center;
        width: 100%;
        overflow: hidden;
        margin: 0;
        opacity: 0;
        max-height: 0;
        pointer-events: none;
        transition: opacity $defTransTime $easeInOutQuint,
                    margin $defTransTime $easeInOutQuint,
                    max-height $defTransTime $easeInOutQuint;

        span {
            @include typoZurich;
            @include typoZurich1-rem;
            display: block;
        }

        .svg-ico {
            display: inline-block;
            width: 2rem;
            height: 2rem;
        }

        .svg-ico + span {
            margin-top: 2rem;
        }
    }

    .search-results-list.has-more:not(.is-expanded) + .btn--view-more {
        opacity: 1;
        max-height: 8rem;
        pointer-events: all;
    }

    &.has-results {
        display: block;
    }

    @include breakpoint(desktop) {
        display: none;

        &.has-results {
            display: none;
        }
    }

    @include breakpoint(one) {
        h3 {
            padding-left: $border;
            padding-right: $border;
        }

        .search-results-list {
            .search-results-list-item {
                padding-left: $border;
                padding-right: $border;
            }
        }
    }
}