.navigation-dialog.navigation-dialog--account {
    width: 1 * $moduleW-rem;

    @include breakpoint(mobile) {
        width: 100%;

        .navigation-dialog__wrapper {
            height: 100%;
        }
    }
}

.navigation-dialog-account {
    .account-links {
        @include pseudo-list;

        li {
            display: block;

            &:not(:first-child) {
                margin-top: 0.25rem;
            }
        }

        a {
            @include typoZurich;
            @include typoZurich2-rem;
            text-transform: none;
        }
    }

    @include breakpoint(mobile) {
        display: flex;
        flex-direction: column;
        height: 100%;

        .navigation-dialog__action {
            margin-top: auto;
        }
    }
}