/*
Version: 3.4.5 Timestamp: Mon Nov  4 08:22:42 PST 2013
*/
.select2-container {
    margin: 0;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 20rem;
    height: 3rem;
    text-align: left;
    box-sizing: border-box;
}

.select2-container,
.select2-drop,
.select2-search,
.select2-choice,
.select2-search input {
    box-sizing: border-box;
}

.select2-container .select2-choice {
    display: block;
    overflow: hidden;
    float: left;
    width: 100%;
    height: 3rem;
    line-height: 100%;
    position: relative;
    user-select: none;
}

.select2-container .select2-choice > .select2-chosen {
    display: inline-block;
    height: 3rem;
    width: 100%;
    line-height: 3rem;
}

.select2-container.input--large .select2-choice > .select2-chosen {
    height: $input-size-large;
    line-height: $input-size-large;
}

.select2-container .select2-choice abbr {
    display: none;
}

.select2-container.select2-allowclear .select2-choice abbr {
    display: inline-block;
}

.select2-container .select2-choice abbr:hover {
    background-position: right -11px;
    cursor: pointer;
}

.select2-drop-mask {
    border: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: zIndex(select2-mask);
    /* styles required for IE to work */
    background-color: #fff;
    filter: alpha(opacity=0);
}

.select2-drop {
    width: 100%;
    margin-top: -1px;
    position: absolute;
    z-index: zIndex(select2-dropdown);
    top: 100%;
    background: $inputGrey2;
}

.select2-drop-auto-width {
    width: auto;
}

.select2-drop-auto-width .select2-search {
    padding-top: 4px;
}

.select2-drop.select2-drop-above {
    margin-top: 1px;
}

.select2-drop-active {
    border-top: none;
}

.select2-drop.select2-drop-above.select2-drop-active {

}

#mobileNav ul.subMenu li span.select2-arrow,
.select2-container .select2-choice .select2-arrow {
	position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    background: url(../images/form/dd-down.png);
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 1.25rem 1.25rem;
    width: 2rem;
    height: 1.5rem;
    margin-top: 0.75rem;
}

.select2-container.input--large .select2-choice .select2-arrow {
    margin-top: 1rem;
}

.select2-container .select2-choice .select2-arrow b {
    display: none;
}

.select2-search {
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 0.5em;
    position: relative;
    white-space: nowrap;
}

.select2-search input {
    width: 100%;
    background: $inputGrey;
    padding: 0 0.5em;
}

.select2-search input.select2-active {
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
    outline: none;
}

.select2-dropdown-open .select2-choice {

}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
}

.select2-dropdown-open .select2-choice .select2-arrow {
    background-image: url(../images/form/dd-up.png);
    filter: none;
}

/* results */
.select2-results {
    max-height: 20em;
    padding: 0;
    margin: 0;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-overflow-scrolling: touch;
    text-align: left;
    background: $inputGrey2;
}

.select2-results ul.select2-result-sub {
    margin: 0;
    padding-left: 0;
}

/*
.select2-results ul.select2-result-sub > li .select2-result-label { padding-left: 20px }
.select2-results ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 40px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 60px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 80px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 100px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 110px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 120px }
*/

.select2-results li {
    list-style: none;
    display: list-item;
    background-image: none;
    padding: 0;
}

.select2-results .select2-result-sub li {
    padding: 0;
}

.select2-results li.select2-result-with-children > .select2-result-label {

}

.select2-results .select2-result-label {
    height: 2.5em;
    margin: 0;
    cursor: pointer;
    overflow: hidden;
    padding: 0 1em;

    -webkit-touch-callout: none;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
}

.select2-results .select2-result-label,
.select2-results .select2-result-sub .select2-result-label {
    @include typoZurich;
    @include typoZurich1-rem;
    text-transform: none;
    line-height: 2.5em;
}

.select2-results .select2-result.select2-result-with-children > .select2-result-label {
    @include typoZurich;
    @include typoZurich1-rem;
    text-transform: uppercase;
    line-height: 2.5em;
    margin-top: 1em;
}

.select2-results .select2-result-label.hasStore {
    background-image: url(../images/icons/svg/cart.svg);
    background-position: 95% 50%;
    background-repeat: no-repeat;
    background-size: 1.5rem 1.5rem;
}

.select2-results .select2-highlighted {
    background-color: $inputGrey2Hover;
}

.select2-results li em {
    background: #feffde;
    font-style: normal;
}

.select2-results .select2-highlighted em {
    background: transparent;
}

.select2-results .select2-highlighted ul {
    background: #fff;
    color: #000;
}


.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
    display: list-item;
    @include typoZurich;
    @include typoZurich1-rem;
    text-transform: none;
    line-height: 2.5em;
    height: 2.5em;
    margin: 0;
    overflow: hidden;
    padding: 0 1em;
}

/*
disabled look for disabled choices in the results dropdown
*/
.select2-results .select2-disabled.select2-highlighted {
    color: #666;
    background: #f4f4f4;
    display: list-item;
    cursor: default;
}
.select2-results .select2-disabled {
  background: #f4f4f4;
  display: list-item;
  cursor: default;
}

.select2-results .select2-selected {
    display: none;
}


/* disabled styles */

.select2-container.select2-container-disabled .select2-choice {
    background-color: #f4f4f4;
    background-image: none;
    border: 1px solid #ddd;
    cursor: default;
}

.select2-container.select2-container-disabled .select2-choice .select2-arrow {
    background-color: #f4f4f4;
    background-image: none;
    border-left: 0;
}

.select2-container.select2-container-disabled .select2-choice abbr {
    display: none;
}


.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
    text-decoration: underline;
}

.select2-offscreen, .select2-offscreen:focus {
    clip: rect(0 0 0 0) !important;
    width: 1px !important;
    height: 1px !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    position: absolute !important;
    outline: 0 !important;
    left: 0px !important;
    top: 0px !important;
}

.select2-display-none {
    display: none;
}

.select2-measure-scrollbar {
    position: absolute;
    top: -10000px;
    left: -10000px;
    width: 100px;
    height: 100px;
    overflow: scroll;
}

// New updated dropdown
.select2-enhanced-container,
.select2-enhanced-dropdown .select2-results {
    background: #fff;
    border: solid 1px $border-color--light-1;
}

.select2-enhanced-dropdown .select2-results {
    border-top: none;
}

.select2-enhanced-container.select2-dropdown-open {
    border-bottom-color: #fff;
}

.select2-enhanced-container .select2-chosen,
.select2-enhanced-dropdown .select2-result-label span {
    @include typoZurich;
    @include typoZurich2-rem;
    text-transform: none;
}

.select2-enhanced-dropdown {
    .select2-highlighted {
        background: none;

        span {
            display: inline;
            border-bottom: solid 1px black;
        }
    }
}