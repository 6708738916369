$_column-function-width--desktop: 16rem;
$_column-function-width--phablet: 8rem;
$_column-function-width--mobile: 5rem;

.polarized-pro-lens-comparison-wrapper {
    overflow: hidden;
    position: relative;
    margin: 4rem auto 16rem auto;

    @include breakpoint(five) {
        width: 5 * $moduleW-rem;
    }

    @include breakpoint(four) {
        width: 4 * $moduleW-rem;
    }

    @include breakpoint(three) {
        width: 3 * $moduleW-rem;
    }

    @include breakpoint(two) {
        width: 2 * $moduleW-rem;
        margin-bottom: 8rem;
    }

    @include breakpoint(one) {
        width: $moduleW-rem;
        margin-bottom: 4rem;
    }
}

.polarized-pro-lens-comparison {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;

    * {
        box-sizing: border-box;
    }
}

.polarized-pro-lens-comparison__column {
    @include breakpoint(desktop) {
        width: 12rem;

        &:not(:last-child) {
            margin-right: 2rem;
        }
    }

    @include breakpoint(two) {
        width: $_column-function-width--phablet;

        &:last-child {
            width: 4rem;
        }
    }

    @include breakpoint(one) {
        width: $_column-function-width--mobile;

        &:last-child {
            width: 2.5rem;
        }
    }
}

.polarized-pro-lens-comparison__head {
    width: 100%;
    display: flex;
}

.polarized-pro-lens-comparison__head-item {
    @include breakpoint(fourfive) {
        &:first-child {
            margin-left: $_column-function-width--desktop / 2;
        }
    }

    @include breakpoint(three) {
        &:first-child {
            margin-left: $_column-function-width--desktop;
        }
    }

    @include breakpoint(mobile) {
        display: flex;
        align-items: flex-end;
    }

    @include breakpoint(two) {
        padding-left: 0.8rem;
    }

    @include breakpoint(one) {
        padding-left: 0.3rem;
    }
}

.polarized-pro-lens-comparison__head-item-figure {
    width: 100%;
    margin-top: 2rem;

    svg {
        width: 100%;
    }

    @include breakpoint(mobile) {
        display: none;
    }
}

.polarized-pro-lens-comparison__head-item-type,
.polarized-pro-lens-comparison__body-function-name {
    @include typoZurich;
    @include typoZurich2-rem;
    text-align: center;
    text-transform: none;
    margin: 0;
}

.polarized-pro-lens-comparison__head-item-type {
    @include breakpoint(mobile) {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
}

.polarized-pro-lens-comparison__body-row {
    display: flex;
    margin-top: 0.5rem;

    @include breakpoint(desktop) {
        &:first-child {
            margin-top: 1.5rem;
        }
    }

    @include breakpoint(mobile) {
        flex-wrap: wrap;
        margin-top: 1.5rem;
    }

    @include breakpoint(two) {
        width: 28rem;

        &:first-child {
            margin-top: 1rem;
        }
    }

    @include breakpoint(one) {
        width: 18rem;

        &:first-child {
            margin-top: 1rem;
        }
    }
}

.polarized-pro-lens-comparison__body-function {
    display: flex;
    width: $_column-function-width--desktop;
    align-items: center;

    @include breakpoint(fourfive) {
        margin-left: $_column-function-width--desktop * (-0.5);
    }

    @include breakpoint(mobile) {
        width: 100%;
        order: 5;
        margin-top: 0.2rem;
    }
}

.polarized-pro-lens-comparison__body-value {
    text-align: center;

    svg {
        width: 2.5rem;
    }

    @include breakpoint(mobile) {
        margin-right: 0;
        transition: opacity $trans-time--default $trans-func--default;

        &[aria-selected="false"] {
            opacity: 0.4;
        }
    }

    @include breakpoint(two) {
        text-align: left;
        width: $_column-function-width--phablet;

        svg {
            width: 4rem;
        }
    }

    @include breakpoint(one) {
        text-align: left;
        width: $_column-function-width--mobile;

        svg {
            width: 2.5rem;
        }
    }
}