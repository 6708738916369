/* ==========================================================================
   Jobs
   ========================================================================== */
.siteContainer .jobsContainer {
    margin-top: 8rem;

    @include breakpoint(mobile) {
        margin-top: 6rem;
    }
}

.jobs-wrapper {
    max-width: 4 * $moduleW-rem;
    margin-top: 2rem;
    margin-bottom: 2rem;

    @include breakpoint(desktop) {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    h3 {
        margin-top: 1rem;
    }
}

.jobs-header,
.jobs-entry,
.textItem .textWrapper .jobs-header,
.textItem .textWrapper .jobs-entry {
    display: flex;
    width: 100%;

    h1,
    h2 {
        @include typoZurich;
        @include typoZurich3-rem;
        margin-bottom: 0.25em;
        text-align: inherit;
        margin-top: 0;

        @include breakpoint(one) {
            @include typoZurich2-rem;
        }
    }

    h3 {
        @include typoZurich;
        @include typoZurich3-rem;
        text-align: inherit;
        margin-top: 0;

        @include breakpoint(one) {
            @include typoZurich2-rem;
        }
    }

    p {
        @include typoZurich;
        @include typoZurich3-rem;
        text-transform: none;
        text-align: inherit;

        @include breakpoint(one) {
            @include typoZurich2-rem;
        }
    }

    .jobs-position-mobile {
        @include breakpoint(desktop) {
            display: none;
        }
    }
}

// Make sure we dont have top margin
.jobs-wrapper,
.textItem .jobs-wrapper {
    .jobs-header,
    .jobs-entry {
        p {
            margin-top: 0;
        }
    }
}

.jobs-entry {
    padding: 1rem 0;
    border-bottom: solid 1px $border-color-dark;

    @include breakpoint(desktop) {
        padding: 1.5rem 0;
    }
}

.jobs-header + .jobs-entry {
    margin-top: 1rem;
    border-top: solid 1px $border-color-dark;

    @include breakpoint(desktop) {
        margin-top: 1.5rem;
    }
}

.jobs-table-column {
    &:not(:last-child) {
        padding-right: 1rem;
    }
}

.jobs-table-column--position {
    width: 55%;

    @include breakpoint(mobile) {
        width: 100%;
    }
}

.jobs-table-column--location {
    width: 20%;

    @include breakpoint(mobile) {
        display: none;
    }
}

.jobs-table-column--department {
    width: 25%;

    @include breakpoint(mobile) {
        display: none;
    }
}

// Jobs Detail page
.jobs-detail {
    ul {
        margin-top: 0.5rem;

        & > li {
            position: relative;
            display: block;
            padding-left: 2rem;
            padding-right: 0;
        }

        & > li:before {
            position: absolute;
            top: 0;
            left: 0;
            content: '\2f';
            text-align: left;
            display: inline-block;
            text-indent: 0;
        }
    }


    .textItem.defaultText .jobs-detail__contact-headline {
        margin-top: 4em;
    }
}

// Job not found
// ----------------------------------------------------------------
.job-gone-container {
    text-align: left;
    margin-bottom: 16rem;

    @include breakpoint(one) {
        margin-bottom: 8rem;
    }

    &.is-direct-request {
        margin-top: 4rem;
    }

    h2 {
        @include typoZurichBd;
        @include typoZurich3-rem;

        @include breakpoint(two) {
            @include typoZurich2-rem;
        }

        @include breakpoint(one) {
            @include typoZurich1-rem;
        }
    }

    .hint-job-gone {
        margin-bottom: 4rem;
    }

    .gone-redirect-countdown-hint {
        transition: opacity $defTransTime $defTransFunc;
        opacity: 1;

        &.is-hidden {
            opacity: 0;
        }
    }
}
