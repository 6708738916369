/* ==========================================================================
   tools header
   ========================================================================== */
.toolsheader {
    transition: transform $overlayTime $overlayFunc;
    transform: translate3d(100%, 0, 0);
    position: fixed;
    z-index: zIndex(toolsheader);
    top: 0;
    right: 0;
    width: 2 * $moduleW-rem + 4rem;
    height: 100%;
    overflow: hidden;
    background-color: $backGrey;
    border-left: solid 2px #fff;

    @include breakpoint(two) {
        width: $moduleW-rem + 4rem;
    }

    @include breakpoint(one) {
        width: $moduleW-rem;
    }

        // Define proper wrapper
    .toolsheader-wrapper {
        transition-property: opacity, visibility;
        transition-duration: $toolsHeaderTime, 0s;
        transition-timing-function: $toolsHeaderFunc;
        transition-delay: 0s, $toolsHeaderTime;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 4rem 2rem;
        opacity: 0;
        visibility: hidden;
        overflow: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;

        @include breakpoint(one) {
            padding-top: 1.25rem;
            padding-bottom: 2rem;
        }
    }

    .toolsheader-inner {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
    }

    .toolsheader-head {
        margin-bottom: 2rem;
        text-align: left;

        &.toolsheader-head-lower {
            h3,
            h4 {
                text-transform: none;
            }
        }
    }

    .toolsheader-content {
        flex: 1;
        overflow-y: auto;
    }

    .toolsheader-foot,
    .toolsheader-action {
        margin-top: 2rem;
    }


    // Remove standard styling from lists
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    .boxLink {
        overflow: hidden;

        &.trans-image {
            .respImage,
            .media-box {
                transition: background $defTransTime $defTransFunc;
                background: $backGrey;

                img {
                    opacity: 0;
                }

                &.loaded {
                    background: #bbb;

                    img {
                        opacity: 0.78;
                    }
                }
            }
        }
    }

    .boxLink:hover {
        &.trans-image {
            .respImage.loaded,
            .media-box.media-box--loaded {
                background: #dcdcdc;

                img {
                    opacity: 0.6;
                }
            }
        }

        .product-description {
            opacity: 0.6;
        }
    }

    // Typography
    h3 {
       @include typoZurich;
       @include typoZurich1-rem;
    }

    h4 {
        @include typoZurich;
        @include typoZurich1-rem;
    }

    h5 {
        @include typoZurich;
        @include typoZurich1-rem;
        margin-top: 0;
        margin-bottom: 1rem;
    }

    label {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        line-height: 2;
    }

    li span,
    .toolsheader-head span,
    .form-row-message span,
    .form-row-message p,
    dt span,
    dd span {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
    }

    .message {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        text-align: left;
        margin-bottom: 0;
    }

    dt.primary,
    dd.primary {
        span {
            @include typoZurichBd;
            text-transform: none;
        }
    }

    // Actions
    .actions {
        margin-top: 1rem;

        li {
            display: block;
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    // Definition list
    dl {
        @extend %cf;
        margin: 0;

        dt {
            display: inline-block;
            float: left;
        }

        dd {
            display: inline-block;
            float: right;
        }
    }

    // Loading wrapper
    .css-loading-wrapper {
        height: 100%;

        .css-loading-inline {
            width: 100%;
            height: 100%;
            background: url(../images/icons/loader_32px_t.gif) no-repeat 50% 50% transparent;
        }
    }

    // Form rows
    .form-row {
        text-align: left;
        margin-bottom: 1.25rem;

        &.form-row-small {
            margin-bottom: 1.25rem;
        }

        &.form-row-submit {
            //margin-top: 3rem;
        }

        &.is-collapsed {
            @extend %hideable-row-hidden;
        }

        &.form-row--large {
            margin-bottom: 2rem;
        }

        &.form-row--message {
            margin-bottom: 2rem;
        }

        @include input() {
            width: 100%;
        }

        .input-group--firstname {
            width: calc(50% - 0.5rem);
            margin-right: 0.5rem;

            @include breakpoint(one) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 1.25rem;
            }
        }

        .input-group--lastname {
            width: calc(50% - 0.5rem);
            margin-left: 0.5rem;

            @include breakpoint(one) {
                width: 100%;
                margin-left: 0;
            }
        }

        &.form-row--newsletter-subscription {
            margin-bottom: 4rem;

            label {
                line-height: 1.5;
            }
        }
    }
}

// States
.toolsheader-opened {
    overflow: hidden;

    .toolsheader {
        transform: translate3d(0, 0, 0);
    }

    .header.header--main,
    #filter,
    #wrapper footer,
    .siteContainer {
        transform: translate3d((($moduleW-rem * 2) + 4rem) * (-1), 0, 0);

        @include breakpoint(two) {
            transform: translate3d(($moduleW-rem + 4rem) * (-1), 0, 0);
        }

        @include breakpoint(one) {
            transform: translate3d($moduleW-rem * (-1), 0, 0);
        }
    }

    &.has-global-banner {
        > .global-banner {
            transform: translate3d((($moduleW-rem * 2) + 4rem) * (-1), 0, 0);

            @include breakpoint(two) {
                transform: translate3d(($moduleW-rem + 4rem) * (-1), 0, 0);
            }

            @include breakpoint(one) {
                transform: translate3d($moduleW-rem * (-1), 0, 0);
            }
        }
    }
}

.toolsheader-opened {
    $offset: ($moduleW-rem + 4rem) * (-1);
    $offset-wide-desktop: (($moduleW-rem * 2) + 4rem) * (-1);

    // Desktop indent
    @include breakpoint(desktop) {
        .overlay-container.is-visible {
            &[data-no="1"] {
                transform: translate3d(calc(#{$offset-wide-desktop} + 40px), 0, 0);
            }

            &[data-no="2"] {
                transform: translate3d(calc(#{$offset-wide-desktop} + 80px), 0, 0);
            }

            &[data-no="3"] {
                transform: translate3d(calc(#{$offset-wide-desktop} + 120px), 0, 0);
            }
        }

        .overlay-hover-container.is-visible {
            &[data-no="1"] {
                transform: translate3d(calc(#{$offset-wide-desktop}), 0, 0);
            }

            &[data-no="2"] {
                transform: translate3d(calc(#{$offset-wide-desktop} + 41px), 0, 0);
            }

            &[data-no="3"] {
                transform: translate3d(calc(#{$offset-wide-desktop} + 81px), 0, 0);
            }
        }
    }

    // Mobile indent
    @include breakpoint(two) {
        .overlay-container.is-visible {
            &[data-no="1"],
            &[data-no="2"],
            &[data-no="3"] {
                transform: translate3d($offset, 0, 0);
            }
        }
    }

    @include breakpoint(one) {
        .overlay-container.is-visible {
            &[data-no="1"],
            &[data-no="2"],
            &[data-no="3"] {
                transform: translate3d($moduleW * (-1), 0, 0);
            }
        }
    }
}

// Pseudoclasses
%toolsheader-module-active {
    transition-property: opacity;
    transition-delay: 0s;
    visibility: visible;
    opacity: 1;
    z-index: zIndex(toolsheader-active);
}


// Include subheaders
@import "toolsheader/contact";
@import "toolsheader/shopfinder";
@import "toolsheader/article-notification";
//@import "toolsheader/filter";
