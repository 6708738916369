// 5 columns
@include breakpoint(five) {
    .L-S-1 {
        height: 11.75 * $moduleH;        
        
        // Extra / Top Slideshow
        .b1 {
            top: 0;
            left: 0;    
        }
        
        // Bigger
        .b2 {
            top: 3.75 * $moduleH;
            left: 0;    
        }
        
        // Big Tower
        .b3 {
            top: 3.75 * $moduleH;
            left: 3 * $moduleW;    
        }
        
        // Big Quad
        .b4 {
            top: 6.75 * $moduleH;
            left: 0 * $moduleW;
            height: 5 * $moduleH;
                
        }
        
        // Big Tower
        .b5 {
            top: 7.75 * $moduleH;
            left: 3 * $moduleW;    
        }
    }        
}

// 4 columns
@include breakpoint(four) {
    .L-S-1 {
        height: 9.25 * $moduleH;        
        
        // Extra / Top Slideshow
        .b1 {
            top: 0;
            left: 0;    
        }
        
        // Bigger
        .b2 {
            top: 3.25 * $moduleH;
            left: 0;
            width: 2 * $moduleW;    
            height: 2 * $moduleH;    
        }
        
        // Big Tower
        .b3 {
            top: 3.25 * $moduleH;
            left: 2 * $moduleW;    
        }
        
        // Big Quad
        .b4 {
            top: 7.25 * $moduleH;
            left: 2 * $moduleW;
            width: 2 * $moduleW;    
            height: 2 * $moduleH;      
        }
        
        // Big Tower
        .b5 {
            top: 5.25 * $moduleH;
            left: 0 * $moduleW;    
        }
    }       
}

// 3 columns
@include breakpoint(three) {
   .L-S-1 {
        height: 6.75 * $moduleH;        
        
        // Extra / Top Slideshow
        .b1 {
            top: 0;
            left: 0;    
        }
        
        // Bigger
        .b2 {
            top: 2.75 * $moduleH;
            left: 0;
            width: 2 * $moduleW;    
            height: 2 * $moduleH;    
        }
        
        // Big Tower
        .b3 {
            top: 2.75 * $moduleH;
            left: 2 * $moduleW;    
        }
        
        // Big Quad
        .b4 {
            top: 4.75 * $moduleH;
            left: 1 * $moduleW;
            width: 2 * $moduleW;    
            height: 2 * $moduleH;      
        }
        
        // Big Tower
        .b5 {
            top: 4.75 * $moduleH;
            left: 0 * $moduleW;    
        }
    }       
}

// 2 columns
@include breakpoint(two) {
    .L-S-1 {
        height: 9.25 * $moduleH;        
        
        // Extra / Top Slideshow
        .b1 {
            top: 0;
            left: 0;    
        }
        
        // Bigger
        .b2 {
            top: 2.25 * $moduleH;
            left: 0;    
        }
        
        // Big Tower
        .b3 {
            top: 4.25 * $moduleH;
            left: 0 * $moduleW;    
        }
        
        // Big Quad
        .b4 {
            top: 6.25 * $moduleH;
            left: 0 * $moduleW;
            width: 2 * $moduleW;    
            height: 3 * $moduleH;      
        }
        
        // Big Tower
        .b5 {
            top: 4.25 * $moduleH;
            left: 1 * $moduleW;    
        }
    }       
}

// 1 columns
@include breakpoint(one) {
    .L-S-1 {
        height: 7 * $moduleH;        
        
        // Extra / Top Slideshow
        .b1 {
            top: 0;
            left: 0;    
        }
        
        // Bigger
        .b2 {
            top: 1 * $moduleH;
            left: 0;    
        }
        
        // Big Tower
        .b3 {
            top: 2 * $moduleH;
            left: 0 * $moduleW;    
        }
        
        // Big Quad
        .b4 {
            top: 4 * $moduleH;
            left: 0 * $moduleW;
            height: 1 * $moduleH;      
        }
        
        // Big Tower
        .b5 {
            top: 5 * $moduleH;
            left: 0 * $moduleW;    
        }
    }       
}
