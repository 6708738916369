// 5 columns
@include breakpoint(five) {
   .L-2-Bigger {
        height: 4 * $moduleH-rem;

        .b1 {
            top: 0 * $moduleH-rem;
            left: 0.5 * $moduleW-rem;
            height: 4 * $moduleH-rem;
            width: 4 * $moduleW-rem;
        }
    }
}

// 4 columns
@include breakpoint(four) {
    .L-2-Bigger {
        height: 3 * $moduleH-rem;

        .b1 {
            top: 0 * $moduleH-rem;
            left: 0.5 * $moduleW-rem;
            height: 3 * $moduleH-rem;
            width: 3 * $moduleW-rem;
        }
    }
}

// 35 columns
@include breakpoint(three) {
    .L-2-Bigger {
        height: 2.5 * $moduleH-rem;

        .b1 {
            top: 0 * $moduleH-rem;
            left: 0.25 * $moduleW-rem;
            height: 2.5 * $moduleH-rem;
            width: 2.5 * $moduleW-rem;
        }
    }
}

// 2 columns
@include breakpoint(two) {
    .L-2-Bigger {
        height: 2 * $moduleH-rem;

        .b1 {
            top: 0 * $moduleH-rem;
            left: 0 * $moduleW-rem;
            height: 2 * $moduleH-rem;
            width: 2 * $moduleW-rem;
        }
    }
}

// 1 columns
@include breakpoint(one) {
    .L-2-Bigger {
        height: 1 * $moduleH-rem;

        .b1 {
            top: 0 * $moduleH-rem;
            left: 0 * $moduleW-rem;
            height: 1 * $moduleH-rem;
            width: 1 * $moduleW-rem;
        }
    }
}
