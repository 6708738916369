// 5 columns
@include breakpoint(five) {
    .L-2-3-1 {
        height: 3 * $moduleH;

        // Bigger
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;
        }

        // Quad
        .b2 {
            top: 0 * $moduleH;
            left: 3 * $moduleW;
        }
    }
}

// 4 columns
@include breakpoint(four) {
    .L-2-3-1 {
        height: 3 * $moduleH;

        // Bigger
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;
            width: 2 * $moduleW;
        }

        // Quad
        .b2 {
            top: 0 * $moduleH;
            left: 2 * $moduleW;
        }
    }
}

// 3 columns
@include breakpoint(three) {
   .L-2-3-1 {
        height: 2 * $moduleH;

        // Bigger
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;
            width: 2 * $moduleW;
            height: 2 * $moduleH;
        }

        // Quad
        .b2 {
            top: 0 * $moduleH;
            left: 2 * $moduleW;
            width: 1 * $moduleW;
            height: 2 * $moduleH;
        }
    }
}

// 2 columns
@include breakpoint(two) {
    .L-2-3-1 {
        height: 4 * $moduleH;

        // Bigger
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;
        }

        // Quad
        .b2 {
            top: 2 * $moduleH;
            left: 0 * $moduleW;
            height: 2 * $moduleH;
        }
    }
}

// 1 columns
@include breakpoint(one) {
    .L-2-3-1 {
        height: 2 * $moduleH;

        // Bigger
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;
        }

        // Quad
        .b2 {
            top: 1 * $moduleH;
            left: 0 * $moduleW;
            height: 1 * $moduleH;
        }
    }
}
