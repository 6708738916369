/* ==========================================================================
   Serivce
   ========================================================================== */

.service-container {
    margin: 8rem auto 0 0;
    text-align: left;

    @include breakpoint(mobile) {
        margin-top: 6rem;
    }

    h1,
    h2,
    h3,
    span,
    p,
    .linkWrapper,
    .defaultText {
        text-align: left;
    }

    ul {
        margin: 0 0 1rem 0;
        list-style-type: none;

        li:before {
            content: '\2f';
            text-indent: -1.5rem;
            display: inline-block;
        }

        li {
            padding: 0 1.5rem;
            display: block;
        }
    }

    .payLogosContainer {
        @extend %cf;
        margin-bottom: 1rem;
    }
}
