// Appointment view
.shopfinder-appointment {
    text-align: left;

    .textWrapper {
        margin-bottom: 4rem;

        p {
            @include typoZurich;
            @include typoZurich2-rem;
            text-transform: none;
        }
    }

    h2 {
        @include typoZurichBd;
        @include typoZurich3-rem;
        margin-bottom: 1rem;
    }

    h3 {
        @include typoZurich;
        @include typoZurich1-rem;
        margin-bottom: 1rem;
    }

    .cell {
        padding-bottom: 2rem;
    }

    .row.row-submit {
        margin-top: 2rem;
    }

    .cell {
        input[type="text"],
        input[type="email"],
        input[type="tel"],
        input[type="submit"],
        textarea,
        select,
        .select2-container {
            width: 22rem;
        }
    }

    .appointment-links {
        margin-top: $moduleH-rem / 4;
        margin-bottom: $moduleH-rem;

        .textLink {
            margin-bottom: 0.5rem;
        }
    }

    @include breakpoint(mobile) {
        h2 {
            @include typoZurichBd;
            @include typoZurich2-rem;
        }
    }
}