// Productdetail error
// ------------------
.product-gone-container {
    text-align: left;
    margin-top: 8rem;
    margin-bottom: 4rem;

    h2 {
        @include typoZurichBd;
        @include typoZurich3-rem;

        @include breakpoint(two) {
            @include typoZurich2-rem;
        }

        @include breakpoint(one) {
            @include typoZurich1-rem;
        }
    }

    .product-gone-target {
        margin-bottom: 4em;

        @include breakpoint(one) {
            margin-bottom: 2em;
        }

        .respImage,
        .media-box {
            opacity: 0.55;
        }
    }

    .product {
        @include breakpoint(one) {
            //margin-top: -2em;
        }
    }

    @include breakpoint(mobile) {
        margin-top: 6rem;
    }
}

.headline-alternative-products {
    margin-top: 2rem;
}

.headline-alternative-products + .product-listing {
    margin-top: 4rem;

}