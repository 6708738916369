.nl-signup {
    text-align: left;

    // Typography
    h2 {
        @include typoZurichBd;
        @include typoZurich2-rem;
        margin-bottom: 1rem;
    }

    h4 {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: uppercase;
        margin-bottom: 0;
    }

    p {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
    }

    .nl-signup__wrapper,
    .nl-signup__form {
        padding: 4rem 3rem;
    }

    .nl-signup__form {
        opacity: 1;
        transition: opacity $defTransTime $defTransFunc;

        &[aria-busy="true"] {
            opacity: 0.3;
            pointer-events: none;
        }
    }

    .nl-signup__content__intro {
        margin-top: 2rem;
    }

    .nl-signup__content__fields {
        margin-top: 3rem;

        .input-group--firstname {
            width: calc(50% - 0.5rem);
            margin-right: 0.5rem;

            @include breakpoint(one) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 1.25rem;
            }
        }

        .input-group--lastname {
            width: calc(50% - 0.5rem);
            margin-left: 0.5rem;

            @include breakpoint(one) {
                width: 100%;
                margin-left: 0;
            }
        }
    }

    .nl-signup__content__privacy {
        margin-top: 3rem;
    }

    .nl-signup__actions {
        margin-top: 3rem;
    }

    .nl-signup__content__confirm {
        margin-top: 2rem;
    }

    .nl-signup__section {
        display: block;

        &[aria-hidden="true"] {
            display: none;
        }
    }
}