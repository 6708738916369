/* ==========================================================================
   Images
   ========================================================================== */

// For compat reasons
.respImage {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: block;
    min-height: 64px;
}

.media-box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: inline-block;
    line-height: 1;
    transition: background $defTransTime $defTransFunc;

    picture,
    figure {
        width: 100%;
        height: 100%;
        display: block;
        position: relative;
        overflow: hidden;

        img {
            width: auto;
            height: auto;
            box-sizing: border-box;
        }

        &[data-hfocus],
        &[data-vfocus] {
            img {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}

// Pending media boxes
.layout:not(.L-1-Def),
.lookbook {
    .media-box.media-box--pending {
        background: $backGrey;
    }
}

// Lazy loading
.lazy {
    opacity: 0;
    transition: opacity $defTransTime $defTransFunc;

    &.lazy--loaded {
        opacity: 1;
    }
}
