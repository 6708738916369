// 3 columns
@include breakpoint(desktop) {
   .L-3-8-1 {
        height: 5 * $moduleH;
        width: 3 * $moduleW;        
        
        // Bigger
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;   
        }
        
        // Medium
        .b2 {
            top: 3 * $moduleH;
            left: 0 * $moduleW; 
        }
        
        // Medium
        .b3 {
            top: 3 * $moduleH;
            left: 2 * $moduleW;
            width: 1 * $moduleW;
        }
    }
}

// 2 columns
@include breakpoint(two) {
    .L-3-8-1 {
        height: 6 * $moduleH;        
        
        // Bigger
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;   
        }
        
        // Medium
        .b2 {
            top: 2 * $moduleH;
            left: 0 * $moduleW; 
        }
        
        // Medium
        .b3 {
            top: 4 * $moduleH;
            left: 0 * $moduleW;
        }
    }      
}

// 1 columns
@include breakpoint(one) {
    .L-3-8-1 {
        height: 3 * $moduleH;        
        
        // Bigger
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;   
        }
        
        // Medium
        .b2 {
            top: 1 * $moduleH;
            left: 0 * $moduleW; 
        }
        
        // Medium
        .b3 {
            top: 2 * $moduleH;
            left: 0 * $moduleW;
        }
    }          
}
