// Definition of the productdetailpage
.product-detail {
    margin-bottom: 12rem;
    text-align: left;

    @include breakpoint(desktop) {
        margin-top: 5rem;
    }

    @include breakpoint(mobile) {
        margin-bottom: 0;
    }

    @include breakpoint(one) {
        width: 100%;

        .detail-information,
        .detail-media-control--mobile {
            width: 24rem;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @include breakpoint(two) {
        width: 100%;

        .detail-information,
        .detail-media-control--mobile {
            width: 48rem;
            margin-left: auto;
            margin-right: auto;
        }
    }

    article {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .detail-breadcrumb {
            order: 1;
        }

        .detail-information {
            order: 2;
        }

        .detail-media-control--mobile {
            order: 3;
        }

        .detail-media {
            order: 4;
        }

        @include breakpoint(mobile) {
            flex-direction: column;

            .detail-information {
                order: 5;
            }

            .detail-media-gender-image-controls--mobile {
                order: 3;
            }

            .detail-media-control--mobile {
                order: 2;
            }

            .detail-media {
                order: 1;
                overflow: visible;
            }

            .detail-breadcrumb {
                order: 4;
            }
        }
    }

    // Breadcrumb
    .detail-breadcrumb {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .detail-breadcrumb__item {
            @include typoZurich;
            @include typoZurich1-rem;
            display: inline-block;

            &:not(:last-child) {
                &:after {
                    content: '/';
                    display: inline-block;
                    margin: 0 0.5rem;
                }
            }
        }

        @include breakpoint(desktop) {
            position: sticky;
            top: $header-height--desktop;
            z-index: 4;
            margin: -2rem $border 1rem $border;
            padding: 2.5rem 0 2.5rem 0;
            transition: top $overlayTime $overlayFunc;

            .has-global-banner & {
                top: $header-height--desktop + $global-banner-height--desktop;
            }
        }

        @include breakpoint(mobile) {
            margin: 0 auto 2rem auto;
            padding-left: $border;
            padding-right: $border;
        }

        @include breakpoint(two) {
            width: 2 * $moduleW-rem;
        }

        @include breakpoint(one) {
            width: $moduleW-rem;

            .detail-breadcrumb__item {
                @include typoZurichS2-rem;

                &:first-child {
                    display: none;
                }

                &:not(:last-child) {
                    &:after {
                        margin: 0 0.4rem;
                    }
                }
            }
        }
    }

    // Images and videos
    .detail-media {
        display: block;
        width: calc(#{4 * $moduleW-rem} - #{2 * $border});

        // Container for mobile
        .detail-media-elements {
            @include breakpoint(desktop) {
                margin-top: -1rem;
            }

            @include breakpoint(mobile) {
                box-sizing: border-box;
                width: 100%;
            }

            @include breakpoint(two) {
                height: 2 * $moduleH-rem;
            }

            @include breakpoint(one) {
                //height: 1 * $moduleW-rem;
                position: absolute;
                inset: 0 0 0 0;
            }
        }

        .detail-model-portraits +.detail-media-elements {
            @include breakpoint(desktop) {
                margin-top: 0;
            }
        }

        // General media element
        .detail-media-element {
            position: relative;
            width: calc(#{4 * $moduleW-rem} - #{2 * $border});
            height: 4 * $moduleH-rem;

            .detail-media-element__sticker {
                position: absolute;
                top: 8rem;
                right: 8rem;
                width: 18rem;
                transform: rotate(8deg);
                pointer-events: none;

                svg {
                    width: 100%;
                }
            }

            .detail-media-element__label {
                position: absolute;
                display: inline-block;
                top: 3rem;
                right: 2rem;
                z-index: 1;

                a,
                span {
                    @include typoZurich;
                    @include typoZurich1-rem;
                    text-decoration: none;
                    border: none;
                }

                @include breakpoint(mobile) {
                    display: none;
                }
            }

            @include breakpoint(four) {
                width: calc(#{3 * $moduleW-rem} - #{2 * $border});
                height: 3 * $moduleH-rem;

                .detail-media-element__sticker {
                    top: 6rem;
                    right: 6rem;
                    width: 16rem;
                }
            }

            @include breakpoint(three) {
                width: calc(#{2 * $moduleW-rem} - #{2 * $border});
                height: 2 * $moduleH-rem;

                .detail-media-element__sticker {
                    top: 4rem;
                    right: 4rem;
                    width: 10rem;
                }
            }

            @include breakpoint(two) {
                width: 100%;
                height: 2 * $moduleH-rem;

                .detail-media-element__sticker {
                    top: 2rem;
                    right: 2rem;
                    width: 8rem;
                }

                .detail-media-element__label {
                    top: 2rem;
                    right: 0.5rem;
                }
            }

            @include breakpoint(one) {
                box-sizing: border-box;
                width: 100%;
                height: 100%;

                //padding-top: 4rem;
                //padding-bottom: 4rem;

                .detail-media-element__sticker {
                    top: 0.5rem;
                    right: 0.5rem;
                    width: 6rem;
                }

                .detail-media-element__label {
                    top: 2rem;
                    right: 0.5rem;
                }
            }
        }

        .detail-media-element + .detail-media-element {
            @include breakpoint(desktop) {
                margin-top: 2rem;
            }
        }

        // Image media element
        .detail-media-element--image {
            img {
                cursor: none;
                mix-blend-mode: multiply;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform-origin: 50% 50%;
                object-position: 50% 50%;
            }

            @include breakpoint(desktop) {
                .respImage.loaded {
                    background-color: $detail-image-background;
                }

                .media-box {
                    picture,
                    figure {
                        background-color: $detail-image-background;
                    }
                }

                .module {
                    box-sizing: border-box;
                    padding: 1rem 0 1rem 2rem;
                }
            }

            @include breakpoint(mobile) {
                img {
                    object-fit: contain;
                    transform: scale3d(1.2, 1.2, 1);
                }
            }
        }

        // Polarised Pro
        .detail-polarised-pro-slider {
            margin-top: 4rem;

            @include breakpoint(mobile) {
                display: none;
            }
        }

        .detail-media-element--polarised-pro {
            > .box {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: auto;
                height: auto;
                float: none;
                box-sizing: border-box;
                padding: 1rem 0 1rem 2rem;
            }

            @include breakpoint(mobile) {
                display: none;
            }
        }

        // Shades hint
        .detail-media-element-hint {
            margin-bottom: 2rem;
            padding-left: 2rem;

            p {
                @include typoZurich;
                @include typoZurich1-rem;
                text-transform: none;
                display: inline-block;
                margin-right: 1rem;
                margin-bottom: 0;
            }
        }

        // Video controls for switching Male/Femal/Unisex
        .detail-video-controls {
            position: relative;
            padding-left: 2rem;
            margin-top: 1rem;

            .btn:not(:last-child) {
                margin-right: 1rem;
            }

            &.has-active-video-male {
                .btn[data-target='male'] {
                    @include typoZurichBd;
                    text-transform: none;
                }
            }

            &.has-active-video-female {
                .btn[data-target='female'] {
                    @include typoZurichBd;
                    text-transform: none;
                }
            }

            &.has-active-video-unisex {
                .btn[data-target='unisex'] {
                    @include typoZurichBd;
                    text-transform: none;
                }
            }

            // Hide video controls on mobile
            @include breakpoint(mobile) {
                display: none;
            }
        }

        // Video information - Showing the certain version the model is wearing
        .detail-video-information {
            position: relative;
            padding: 0 2rem;
            margin-top: 1rem;

            .video-information {
                transition-property: opacity;
                transition-duration: $slowTransTime;
                transition-timing-function: $defTransFunc;
                box-sizing: border-box;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 2rem;
                padding-left: 2rem;
                pointer-events: none;
                opacity: 0;
            }

            p {
                @include typoZurich;
                @include typoZurich1-rem;
                text-transform: none;
                margin-bottom: 0;
            }

            &.has-active-video-male {
                .video-information--male {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            &.has-active-video-female {
                .video-information--female {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            &.has-active-video-unisex {
                .video-information--unisex {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            // Hide video information on mobile
            @include breakpoint(mobile) {
                display: none;
            }
        }

        // Responsive rendering of the elements
        @include breakpoint(four) {
            width: calc(#{3 * $moduleW-rem} - #{2 * $border});
        }

        @include breakpoint(three) {
            width: calc(#{2 * $moduleW-rem} - #{2 * $border});
        }

        @include breakpoint(two) {
            width: 100%;
            height: 2 * $moduleH-rem;
        }

        @include breakpoint(one) {
            width: 100%;
            height: auto;
            padding-bottom: 150%;
        }

        @include breakpoint(mobile) {
            overflow: hidden;
            margin-bottom: 1rem;
        }
    }

    .detail-media-element--image.detail-media-element--gender-image {
        @include breakpoint(desktop) {
            display: none;
        }

        @include breakpoint(mobile) {
            img {
                transform: none;
            }
        }
    }

    .detail-media-element--image.detail-media-element--packaging-image {
        @include breakpoint(mobile) {
            img {
                transform: none;
            }
        }
    }

    .detail-media-element--image.detail-media-element--model-portrait {
        @include breakpoint(desktop) {
            display: none;
        }

        @include breakpoint(mobile) {
            padding: 0;

            img {
                //transform: none;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform-origin: 50% 25%;
                object-position: 50% 50%;
                transform: scale3d(1.1, 1.1, 1);
            }
        }

        @include breakpoint(two) {
            box-sizing: border-box;
        }
    }

    // Gender images / Model Portraits
    .detail-media-element--gender,
    .detail-media-element--model-portraits {
        position: relative;

        @include breakpoint(mobile) {
            display: none;
        }

        .detail-media-element-gender-slideshow,
        .detail-media-element-model-portraits-slideshow {
            position: absolute;
            top: 1rem;
            left: 2rem;
            right: 0;
            bottom: 1rem;
        }

        .detail-media-element-gender-slideshow__slide,
        .detail-media-element-model-portraits-slideshow__slide{
            width: 100%;
            height: 100%;
        }
    }

    .detail-model-portraits {
        picture,
        figure {
            background: $portrait-image-background;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform-origin: 50% 25%;
            object-position: 50% 50%;
            transform: scale3d(1.2, 1.2, 1);
        }

        .detail-model-portraits__description {
            display: none;
            margin-top: 1rem;
            padding-left: 2rem;
        }

        @include breakpoint(mobile) {
            display: none;
        }
    }

    // Rimowa Slideshow
    .detail-media-element-rimowa-slideshow-wrapper {
        position: relative;

        .detail-media-element-rimowa-slideshow {
            position: absolute;
            top: 1rem;
            left: 2rem;
            right: 0;
            bottom: 1rem;
        }

        .detail-media-element-rimowa-slideshow__slide{
            width: 100%;
            height: 100%;
        }

        picture,
        figure {
            background: $detail-image-background;
        }

        img {
            mix-blend-mode: multiply;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform-origin: 50% 50%;
            object-position: 50% 50%;
        }

        @include breakpoint(mobile) {
            display: none;
        }
    }

    // Aside -> Information of the article
    .detail-information {
        display: block;
        width: $moduleW-rem;
        position: relative;

        @include breakpoint(desktop) {
            margin-left: $border;
        }

        @include breakpoint(two) {
            width: 2 * $moduleW-rem;
        }

        // General subheadlines
        h3 {
            @include typoZurich;
            @include typoZurich1-rem;
            line-height: 1;
        }

        .detail-information-section {
            margin-bottom: 4rem;
        }

        // Fixable detail information
        .detail-information-fixable {
            position: relative;

            @include breakpoint(desktop) {
                width: $moduleW-rem;
                position: sticky;
                top: $header-height--desktop + 7.7rem;
                background-color: $back-color--default;
                z-index: 3;
                transition: top $overlayTime $overlayFunc;

                &:after {
                    content: '';
                    position: absolute;
                    top: ($header-height--desktop + 7.7rem) * (-1);
                    left: 0;
                    width: 100%;
                    height: $header-height--desktop + 7.7rem;
                    background-color: $back-color--default;
                }

                .has-global-banner & {
                    top: $header-height--desktop + $global-banner-height--desktop + 7.7rem;
                }
            }
        }

        // Main information
        .detail-information-section--main {
            margin-bottom: 3.25rem;

            .article-collection {
                @include typoZurich;
                @include typoZurich2-rem;
                line-height: 1;
                margin-bottom: 1.5rem;
            }

            .article-name {
                @include typoZurichBd;
                @include typoZurich4-rem;
                line-height: 1;
                margin-bottom: 1.5rem;

                @include breakpoint(one) {
                    @include typoZurich3-rem;
                    line-height: 1;
                }
            }

            .article-color,
            .article-number {
                @include typoZurich;
                @include typoZurich1-rem;
                text-transform: none;
                margin-bottom: 0;
            }
        }

        // Price and shipping
        .detail-information-section--price {
            display: block;
            margin-bottom: 3em;

            .article-price {
                @include typoZurichBd;
                @include typoZurich4-rem;
                white-space: nowrap;
                line-height: 1;

                @include breakpoint(one) {
                    @include typoZurich3-rem;
                    line-height: 1;
                }
            }

            .article-price-information {
                @include typoZurich;
                @include typoZurichS2-rem;
                text-transform: none;
                margin-bottom: 0;
                text-align: right;
            }

            // Display items as flexbox
            @include breakpoint(desktop) {
                @include flex-display;

                .article-price {
                    white-space: nowrap;
                }

                .article-price-information {
                    padding-left: 2rem;
                    flex: 1 1 auto;
                }

                .article-price-information {
                    @include flex-align-self(center);
                }
            }

            @include breakpoint(two) {
                .article-price {
                    text-align: right;
                    margin-bottom: 1.5rem;
                }
            }
        }

        // Price/Shipping information
        .detail-information-section--price,
        .detail-information-section--price-information-mobile {
            .article-price-information {
                @include typoZurich;
                @include typoZurichS2-rem;
                text-transform: none;
                margin-bottom: 0;
                text-align: right;
            }
        }

        // Article could be bought
        &.is-buyable {
            // Detail information wrapper, to display elements properly
            .detail-information-wrapper--main {
                @include breakpoint(mobile) {
                    @include flex-display;
                    .detail-information-section--optical {
                        width: 100%;
                    }
                }

                @include breakpoint(two) {
                    .detail-information-section--main,
                    .detail-information-section--price {
                        width: $moduleW-rem;
                    }
                }

                @include breakpoint(one) {
                    .detail-information-section--main {
                        flex: 1 1 auto;
                    }

                    .detail-information-section--price {
                        width: $moduleW-rem * 0.4;
                        text-align: right;

                        .article-price-information {
                            display: none;
                        }
                    }
                }
            }
        }

        // Add to basket
        .detail-information-section--actions-primary {
            margin-bottom: 5rem;

            @include breakpoint(one) {
                margin-bottom: 1rem;
            }
        }

        // Price info for mobile
        .detail-information-section--price-information-mobile {
            display: none;

            @include breakpoint(one) {
                display: block;

                .article-price-information {
                    text-align: center;
                }
            }
        }

        // Holiday hint
        .detail-information-section--holiday-delivery-hint {
            p {
                @include typoZurich;
                @include typoZurichS2-rem;
                text-transform: none;
                margin: 0;
            }

            @include breakpoint(twofive) {
                margin-top: -4rem;
                padding-bottom: 1rem;
            }

            @include breakpoint(one) {
                margin-bottom: 2rem;

                p {
                    text-align: center;
                }
            }
        }

        // Optical information
        .detail-information-section--optical {
            margin-bottom: 3.5rem;

            p {
                @include typoZurich;
                @include typoZurich0-rem;
                text-transform: none;
                margin-bottom: 0;
            }
        }

        // Samplesale
        .detail-information-section--samplesale,
        .detail-information-section--rx-buyable {
            text-align: left;
            margin-top: -1.75rem;
            margin-bottom: 2rem;

            //h4 {
            //    @include typoZurichBd;
            //    @include typoZurich2-rem;
            //    line-height: 1.1;
            //    margin-bottom: 0.25rem;
            //    color: $color-samplesale;
            //}

            p {
                @include typoZurich;
                @include typoZurich0-rem;
                margin-bottom: 0;
                text-transform: none;
                //color: $color-samplesale;
            }
        }

        // Toggle panel group
        .toggle-panel-group {
            margin-bottom: 2.25rem;
        }

        // Additional padding to set
        .toggle-panel--size-guide {
            .toggle-panel-body {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }

        // Subarticles / Color Variants
        .detail-list-colours {
            @extend %cf;

            .detail-list-colour-item {
                transition-property: border;
                transition-duration: $defTransTime;
                transition-timing-function: $defTransFunc;

                float: left;
                position: relative;
                display: inline-block;
                width: 33%;
                height: $moduleH-rem / 3;
                border: solid 1px white;
                box-sizing: border-box;
                margin-bottom: 1px;

                @include breakpoint(two) {
                    width: 25%;
                    height: $moduleH-rem / 2;
                }

                //&.is-samplesale:after {
                //    content: '';
                //    position: absolute;
                //    top: 0.4rem;
                //    left: 0.4rem;
                //    width: 8px;
                //    height: 8px;
                //    background-color: $color-samplesale;
                //    border-radius: 100%;
                //}
            }

            a {
                box-sizing: border-box;
                display: block;
                width: 100%;
                height: 100%;
            }

            .detail-list-colour-item.is-active,
            .detail-list-colour-item:hover {
                border-color: #7f7f7f;
            }

            img {
                width: 100%;
                max-height: 100%;
            }

            // Hovering colour item
            &:hover {
                .detail-list-colour-item.is-active:not(:hover) {
                    border-color: #fff;
                }
            }
        }

        // Product features
        .detail-list-product-features {
            li {
                @include typoZurich;
                @include typoZurich1-rem;
                text-transform: none;
                display: block;
                position: relative;
                padding-left: 1rem;
                margin-bottom: 0.25rem;

                &:last-child {
                    margin-bottom: 0;
                }

                &:before {
                    content: '\B7';
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
        }

        // Guard One Certificates
        .detail-list-product-features + .btn {
            margin-top: 2rem;
        }

            // Frame pictograms
        .frame-pictograms {
            @extend %cf;

            .frame-pictogram {
                display: inline-block;
                width: 10.5rem;
                float: left;

                &.frame-pictogram--side {
                    margin-right: 1rem;
                }

                svg {
                    width: 100%;

                    text {
                        font-family: ZurichBT-Light, Arial, Helvetica, sans-serif;
                        text-transform: uppercase;
                        font-weight: 100;
                    }
                }

                @include breakpoint(two) {
                    width: 16rem;
                }
            }
        }

        // Product description
        .product-description {
            p {
                @include typoZurich;
                @include typoZurich1-rem;
                text-transform: none;
            }
        }

        // About collection
        .about-collection {
            p {
                @include typoZurich;
                @include typoZurich1-rem;
                text-transform: none;
            }
        }

        // Delivery and Returns
        .delivery-returns {
            p {
                @include typoZurich;
                @include typoZurich1-rem;
                text-transform: none;
            }

            a:first-of-type {
                margin-right: 1rem;
            }
        }

        // Share Wishlish etc.
        .detail-information-section--actions-secondary {
            li {
                display: block;

                &:not(:last-child) {
                    margin-bottom: 1rem;
                }
            }

            // Shades
            .action-frame-shades {
                position: relative;
            }

            // Share dialog
            .action-share {
                .share-links {
                    display: block;
                    margin-top: 1rem;

                    li {
                        transform: translate3d(0, -0.25rem, 0);
                        transition: opacity $defTransTime $easeInOutQuint,
                                    transform $defTransTime $easeInOutQuint;
                        display: inline-block;
                        opacity: 0;
                        pointer-events: none;

                        &:not(:last-child) {
                            margin-right: 1rem;
                        }

                        &:nth-child(2) {
                            transition-delay: 0.1s;
                        }

                        &:nth-child(3) {
                            transition-delay: 0.2s;
                        }
                    }

                    &.is-visible {
                        li {
                            transform: translate3d(0, 0, 0);
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                }
            }
        }

        // Responsive rendering of article information
        @include breakpoint(mobile) {
            box-sizing: border-box;
            padding-left: $border;
            padding-right: $border;
        }
    }

    // Samplesale adjustments
    &.is-samplesale {
        // Price adjustments
        .detail-information-section--price {
            .article-price--original {
                span {
                    display: inline-block;
                    position: relative;
                    color: $text-color--light-3;

                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 50%;
                        margin-top: -0.1rem;
                        height: 0.2rem;
                        background: $text-color--light-3;
                    }
                }

                @include breakpoint(desktop) {
                    margin-right: 2rem;
                }

                @include breakpoint(mobile) {
                    margin-bottom: 0.5rem;
                }
            }
        }

        .article-price--original,
        .article-price--samplesale {
            &[data-currency='SEK'],
            &[data-currency='DKK'] {
                font-size: 2.0rem;
            }
        }

        // Mobile price information
        .detail-information-section--price-information-mobile {
            display: none;
        }

        .detail-information-section--actions-primary {
            @include breakpoint(one) {
                margin-bottom: 4rem;
            }
        }
    }

    // Reduced Vat adjustments
    &.has-reduced-vat:not(.is-samplesale) {
        .detail-information-section--price {
            flex-wrap: wrap;

            .article-price-information {
                width: 100%;
                margin-top: 0.5rem;
            }
        }
    }

    @mixin pdp-extra-landscape () {
        //background: red;

        .detail-breadcrumb {
            padding: 1rem 0 0.5rem 0;
        }

        .detail-information {
            .detail-information-fixable {
                top: $header-height--desktop + 4.7rem;

                &:after {
                    top: ($header-height--desktop + 4.7rem) * (-1);
                    height: $header-height--desktop + 4.7rem;
                }
            }

            .detail-information-section--main {
                margin-bottom: 1.5rem;

                .article-name {
                    margin-bottom: 1rem;
                }
            }

            .detail-information-section--price {
                margin-bottom: 1.5rem;
            }
        }
    }

    // Handle widescreen
    @media (min-width: $bp3Start) and (max-width: $bp3End) and (min-aspect-ratio: 1.5/1) {
        @include pdp-extra-landscape ;
    }

    @media (min-width: $bp4Start) and (max-width: $bp4End) and (min-aspect-ratio: 2.1/1) {
        @include pdp-extra-landscape ;
    }

    @media (min-width: $bp5Start) and (min-aspect-ratio: 2.5/1) {
        @include pdp-extra-landscape ;
    }
}

// Additions for product detail page
.product-detail-additions {
    // Hover controls
    @include breakpoint(desktop) {
        .detail-media-elements-hover {
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            pointer-events: none;
            z-index: 102;
            width: 3rem;
            height: 3rem;

            .action-indicator {
                transition-property: opacity;
                transition-duration: 0.1s;
                transition-timing-function: $defTransFunc;
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;
                width: 100%;
                height: 100%;
                opacity: 0;
            }

            .svg-ico {
                width: 100%;
                height: 100%;
            }

            &[aria-hidden='true'] {
                display: none;
            }

            &[data-action='scroll-up'] {
                .action-indicator--scroll-up {
                    opacity: 1;
                }
            }

            &[data-action='scroll-down'] {
                .action-indicator--scroll-down {
                    opacity: 1;
                }
            }

            &[data-action='zoom-in'] {
                .action-indicator--zoom-in {
                    opacity: 1;
                }
            }

            &[data-action='zoom-out'] {
                .action-indicator--zoom-out {
                    opacity: 1;
                }
            }
        }
    }

    @include breakpoint(mobile) {
        .detail-media-elements-hover {
            display: none;
        }
    }

    // Image zoom
    .detail-media-element-zoom {
        transition-property: opacity, visibility;
        transition-duration: $slowTransTime, 0s;
        transition-timing-function: $defTransFunc;
        transition-delay: 0s, $slowTransTime;

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        z-index: 101;
        pointer-events: none;

        .detail-media-element-zoom-content {
            transform: translate3d(0, -50%, 0);
            position: absolute;
            top: 50%;
            left: 0;
            height: auto;
            width: 100%;
            padding-bottom: 66.66%;
            transition: opacity $slowTransTime $defTransFunc;
            opacity: 1;

            .module {
                position: absolute;
                top: 0;
                left: 0;
            }

            img {
                mix-blend-mode: multiply;
                cursor: none;
                width: 100%;
                height: 100%;
            }
        }

        .detail-media-element-zoom-pagination {
            display: flex;
            position: absolute;
            left: 0;
            bottom: 6rem;
            width: 100%;

            ul {
                display: flex;
                gap: 0.5rem;
                list-style: none;
                margin: 0 auto;

                &:hover li.is-active:not(:hover) {
                    border-color: transparent;
                }
            }

            li {
                display: inline-block;
                width: $moduleW-rem / 3;
                height: $moduleH-rem / 3;
                border: solid 1px transparent;
                transition: border $defTransTime $defTransFunc;

                &.is-active,
                &:hover {
                    border-color: #7f7f7f;
                }
            }

            img {
                width: 100%;
            }
        }

        &.is-visible {
            transition-property: opacity;
            transition-delay: 0s;
            visibility: visible;
            opacity: 1;
            pointer-events: all;
        }

        &.is-changing {
            .detail-media-element-zoom-content {
                opacity: 0;
            }
        }

        @include breakpoint(desktop) {
            background-color: $detail-image-background;

            .respImage.loaded {
                background-color: $detail-image-background;
            }

            .media-box {
                picture,
                figure {
                    background-color: $detail-image-background;
                }
            }

            .detail-media-element-zoom-pagination {
                li {
                    background-color: $detail-image-background;
                }

                img {
                    mix-blend-mode: multiply;
                }
            }
        }

        @include breakpoint(mobile) {
            background-color: #fff;

            .detail-media-element-zoom-pagination {
                bottom: 1.5rem;
                text-align: left;
                overflow: scroll;
                overflow-y: hidden;
                overscroll-behavior: contain;
                -webkit-overflow-scrolling: touch;

                ul {
                    padding: 0 5% 0.5rem 5%;
                }

                li {
                    background-color: #fff;
                    width: $moduleW-rem / 4;
                    height: $moduleH-rem / 4;
                    flex-shrink: 0;
                }
            }
        }
    }

    .detail-media-zoom-close {
        position: fixed;
        top: 2rem;
        right: 2rem;
        z-index: 102;
        pointer-events: none;
        transition: opacity $slowTransTime $defTransFunc;
        opacity: 0;

        button {
            background: none;
            border: none;
            width: 2rem;
            height: 2rem;
        }

        .svg-ico {
            width: 100%;
            height: 100%;
        }
    }

    .detail-media-element-zoom.is-visible ~ .detail-media-zoom-close {
        opacity: 1;
        pointer-events: all;
    }
}

// Hide scroll overflow
body.has-active-zoom {
    overflow: hidden;
}

// Detail media control
.product-detail,
.product-detail-additions {
    // Default styling
    .detail-media-control {
        display: none;

        ul {
            display: inline-block;
        }

        li {
            display: block;
            text-align: center;
        }
    }

    // Desktop control
    @include breakpoint(desktop) {
        .detail-media-control--desktop {
            transform: translate3d(0, -50%, 0);
            display: block;
            position: fixed;
            top: 50%;
            right: 3rem;
            z-index: 10;

            li {
                margin: 1rem 0;

                &.has-dot {
                    height: 10px;
                }

                &:last-child:not(.has-dot) {
                    margin-top: 2rem;
                }
            }
        }

        .detail-media-control--mobile {
            display: none;
        }
    }

    // Mobile control
    @include breakpoint(mobile) {
        .detail-media-control--mobile {
            display: block;
            text-align: center;
            margin-bottom: 3.5rem;
            margin-top: -4rem;
            position: relative;
            z-index: 2;

            ul {
                @include flex-display;
                @include flex-align-content(center);
                @include flex-justify(center);

                width: 100%;
                height: 2rem;
            }

            li {
                @include flex-align-self(center);
                padding: 0 0.4rem;

                &.has-dot {
                    height: 10px;
                }

                &.media-control-video-label {
                    padding-right: 0;
                }

                span,
                .btn--link {
                    @include typoZurich;
                    @include typoZurich0-rem;
                    line-height: 1.1;
                    text-transform: none;
                }

                // Hide dots on mobile
                @for $i from 10 through 20 {
                    &:nth-child(#{$i}):not(.media-control-video) {
                        display: none;
                    }
                }
            }

            .btn.is-active.is-active-video {
                @include typoZurichBd;
                text-transform: none;
            }
        }

        .detail-media-control--desktop {
            display: none;
        }
    }
}

// Guard One related
.product-detail.is-guard-one {
    .guard-one-wholesale-info {
        @include typoZurich;
        @include typoZurich0-rem;
        text-align: center;
        text-transform: none;
        margin: 1rem 0 0 0;
        padding-bottom: 1rem;
    }

    @include breakpoint(desktop) {
        .detail-media-element--video-general {
            display: none;
        }
    }

    @include breakpoint(mobile) {
        .detail-media-element--video-desktop {
            display: none;
        }
    }
}

.mykita-032c-product-info {
    @include typoZurich;
    @include typoZurich0-rem;
    text-align: left;
    text-transform: none;
    margin: 0.5rem 0 0 0;
}

.product-detail.is-rimowa {
    .detail-media-element-rimowa-slideshow-wrapper {
        @include breakpoint(mobile) {
            display: none;
        }
    }

    .detail-media-elements,
    .detail-media-control--desktop {
        @include breakpoint(desktop) {
            display: none;
        }
    }
}