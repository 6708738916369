.shop-listing {
    box-sizing: border-box;
    display: block;
    list-style: none;
    text-align: left;
    max-width: 4 * $moduleW-rem;
    padding: $border;

    @include breakpoint(desktop) {
        margin-top: 2rem;
        margin-bottom: 4rem;
    }
}

.shop-listing__item {
    display: inline;
    @include typoZurich;
    @include typoZurich6-rem;
    text-transform: none;

    &:not(:last-child) {
        &:after {
            content: ', ';
        }
    }

    @include breakpoint(mobile) {
        @include typoZurich4-rem;
        text-transform: none;
    }
}

.shop-listing__item-link {
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-skip-ink: none;
    text-underline-offset: 0.1em;

    @include breakpoint(mobile) {
        text-decoration-thickness: 2px;
    }
}