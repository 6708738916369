.L-4-Hero-Teaser {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    text-align: center;
    height: auto;
    width: 100%;

    @include breakpoint(desktop) {
        .b1 {
            width: 100%;
            float: none;
            position: relative;
            padding: 0;
            overflow: hidden;
            padding-bottom: 66.66666%;

            // Disable box link hover effect
            a.boxLink:hover .media-box img {
                opacity: 1;
            }

            & > * {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
    }

    @media (orientation: landscape) and (min-width: 760px) {
        .b1 {
            padding-bottom: 0;
            height: $full-height-vh--desktop;
            height: $full-height-svh--desktop;
        }
    }

    // Mobile
    @include breakpoint(mobile) {
        position: relative;
        text-align: center;
        height: auto;
        width: 100%;

        // FirstItem
        .b1 {
            width: 100%;
            float: none;
            position: relative;
            padding: 0;
        }
    }
}