.mykita-haus-sample-sale-form {
    opacity: 1;
    transition: opacity $defTransTime $defTransFunc;

    &[aria-busy="true"] {
        opacity: 0.3;
        pointer-events: none;
    }

    .label {
        @include typoZurich;
        @include typoZurich2-rem;
        text-transform: none;
    }

    .input-group--firstname {
        width: calc(50% - 0.5rem);
        margin-right: 0.5rem;

        @include breakpoint(one) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 1rem;
        }
    }

    .input-group--lastname {
        width: calc(50% - 0.5rem);
        margin-left: 0.5rem;

        @include breakpoint(one) {
            width: 100%;
            margin-left: 0;
        }
    }

    .mykita-haus-sample-sale-form__privacy {
        margin-top: 1rem;
    }

    .mykita-haus-sample-sale-form__actions {
        margin-top: 1rem;
    }

    input {
        @include typoZurich;
        @include typoZurich2-rem;
        text-transform: none;
        height: 3rem;
        line-height: 3rem;
    }

    .btn {
        @include typoZurich;
        @include typoZurich2-rem;
        height: 3rem;
        line-height: 3rem;
    }

    p {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        text-align: left;
    }

    .mykita-haus-sample-sale-form__success {
        padding: 2rem 0;

        p {
            @include typoZurich;
            @include typoZurich3-rem;
            text-transform: none;
            text-align: center;
        }
    }

    @include breakpoint(desktop) {
        .label {
            @include typoZurich;
            @include typoZurich3-rem;
            text-transform: none;
        }

        p {
            @include typoZurich;
            @include typoZurich2-rem;
            text-transform: none;
        }

        input,
        .btn {
            @include typoZurich;
            @include typoZurich3-rem;
            text-transform: none;
            height: 4rem;
            line-height: 4rem;
        }

        .btn {
            text-transform: uppercase;
        }
    }
}

.mykita-haus-sample-sale {
    padding-bottom: 4rem;

    &[data-state="pending"] {
        .mykita-haus-sample-sale__success {
            display: none;
        }
    }

    &[data-state="complete"] {
        .mykita-haus-sample-sale__intro,
        .mykita-haus-sample-sale__form-container {
            display: none;
        }
    }
}