@keyframes mobile-polarized-layers {
    0% {
        margin-top: 0;
    }

    10% {
        opacity: 1;
        color: #606060;
    }

    50% {
        margin-top: 0.5rem;
        opacity: 1;
        color: #606060;
    }

    90% {
        opacity: 1;
        color: #606060;
    }

    100% {
        margin-top: 0;
    }
}
@keyframes mobile-polarized-layers-ext {
    0% {
        margin-top: 0;
    }

    10% {
        opacity: 1;
        color: #606060;
    }

    50% {
        margin-top: 0.6rem;
        opacity: 1;
        color: #606060;
    }

    90% {
        opacity: 1;
        color: #606060;
    }

    100% {
        margin-top: 0;
    }
}

// Container
.polarized-pro-layers {
    @include breakpoint(desktop) {
        margin-left: auto;
        margin-right: auto;
    }

    @include breakpoint(five) {
        max-width: 3.5 * $moduleW-rem;
    }

    @include breakpoint(four) {
        max-width: 3.5 * $moduleW-rem;
    }

    @include breakpoint(three) {
        max-width: 3 * $moduleW-rem;
    }

    @include breakpoint(twofive) {
        margin-top: 4rem;
        padding-bottom: 8rem;
    }

    @include breakpoint(one) {
        transition: padding 3s $trans-func--easing-out;
        padding-bottom: 12rem;
        min-height: 8rem;
        margin-top: 4rem;

        &[aria-expanded="false"] {
            cursor: pointer;
        }

        &[aria-expanded="true"] {
            padding-bottom: 4rem;
        }
    }
}

// Headline for mobile
.polarized-pro-layers__headline-mobile {
    display: none;

    @include breakpoint(one) {
        display: block;
        @include typoZurich;
        @include typoZurich2-rem;
        text-align: left;
    }
}

// Layer items list
.polarized-pro-layers__item-list {
    @include breakpoint(one) {
        margin-top: 2rem;
    }
}

// Layer row
.polarized-pro-layers__item {
    position: relative;
    text-align: left;
    transition: min-height $trans-time--default $trans-func--default,
                max-height $trans-time--default $trans-func--default,
                margin $trans-time--default $trans-func--default,
                opacity $trans-time--default $trans-func--default;


    &[aria-expanded="true"] {
        min-height: 19rem;

        &:not(:first-child) {
            margin-top: 4rem;
        }
    }

    @include breakpoint(desktop) {
        min-height: 6rem;

        &:not(:first-child) {
            margin-top: 1rem;
        }
    }

    @include breakpoint(two) {
        min-height: 4rem;

        &:not(:first-child) {
            margin-top: 1rem;
        }
    }

    @include breakpoint(one) {
        max-height: 0;
        transition: max-height 3s $trans-func--easing-out,
                    margin 3s $trans-func--easing-out,
                    opacity $trans-time--fast $trans-func--easing-out;

        .polarized-pro-layers[aria-expanded="false"] & {
            &:not(:nth-child(4)) {
                opacity: 0;
            }
        }

        &:nth-child(6) {
            .polarized-pro-layers__item-figure {
                top: 0.25rem;
            }
        }

        // Show in view animation
        .polarized-pro-layers[aria-expanded="false"].in-view & {
            animation: mobile-polarized-layers 4s ease-in-out 0s 1;

            &:nth-child(6) {
                animation: mobile-polarized-layers-ext 4s ease-in-out 0s 1;
            }
        }

        .polarized-pro-layers[aria-expanded="true"] & {
            position: sticky;
            top: 5rem;
            max-height: 14rem;

            @for $i from 1 through 9 {
                &:nth-child(#{$i}) {
                    top: 5rem + (($i - 1) * 1rem);
                }
            }

            &:not(:first-child) {
                margin-top: 2rem;
            }

            &[aria-expanded="true"]:not(.is-stuck),
            &.has-been-expanded:not(.is-stuck) {
                max-height: none;
            }

            &.is-stuck {
                .polarized-pro-layers__item-description {
                    transform: translate3d(0, -2rem, 0);
                    opacity: 0;
                    pointer-events: none;
                    transition: opacity $trans-time--default $trans-func--default,
                                transform $trans-time--default $trans-func--default;
                }
            }
        }

        .no-animation & {
            transition: none;
        }
    }
}

// Description container
.polarized-pro-layers__item-description {
    @include breakpoint(one) {
        opacity: 0;
        padding-top: 10rem;
        transform: translate3d(0, -2rem, 0);
        transition: opacity 1.5s $trans-func--default 0.75s,
                    transform 1.5s $trans-func--default 0.75s;

        .polarized-pro-layers[aria-expanded="false"] & {
            pointer-events: none;
        }

        .polarized-pro-layers[aria-expanded="true"] & {
            opacity: 1;
            transform: none;
        }

        .has-scrolled-up & {
            transition-delay: 0s;
        }
    }
}

// Headline of a layer
.polarized-pro-layers__item-headline {
    @include typoZurich;
    @include typoZurich3-rem;
    text-transform: none;

    @include breakpoint(one) {
        @include typoZurich;
        @include typoZurich2-rem;
        text-transform: none;
    }
}

// Lens graphic wrapper
.polarized-pro-layers__item-figure {
    position: absolute;
    top: 0;
    right: 0;
    width: 26rem;
    transform: translate3d(0, -2rem, 0);
    transition: transform $trans-time--default $trans-func--default;

    svg {
        width: 100%;
        fill: currentColor;
    }

    @include breakpoint(twofive) {
        .polarized-pro-layers__item[aria-expanded="true"] & {
            transform: translate3d(0, 3rem, 0);
        }
    }

    @include breakpoint(two) {
        width: 18rem;
    }

    @include breakpoint(one) {
        width: 20rem;
        right: 2rem;
        transform: none;
    }
}

// Title for lens
.polarized-pro-layers__item-figure-title {
    @include typoZurich;
    @include typoZurich2-rem;
    text-transform: none;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    margin-top: -1rem;
    opacity: 0;
    transition: opacity $trans-time--default $trans-func--default;
    user-select: none;

    .polarized-pro-layers__item[aria-expanded="true"] & {
        opacity: 1;
    }

    @include breakpoint(two) {
        @include typoZurich;
        @include typoZurich1-rem;
    }

    @include breakpoint(one) {
        transition: opacity $trans-time--slow $trans-func--default;
    }
}

// Container for the bulletpoints
.polarized-pro-layers__item-bulletpoints-container {
    max-height: 0;
    overflow: hidden;
    transition: max-height $trans-time--default $trans-func--default;

    .polarized-pro-layers__item[aria-expanded="true"] & {
        max-height: 24rem;

        @supports (--css: variables) {
            max-height: var(--expanded-height, 24rem);
        }
    }

    @include breakpoint(one) {
        transition: max-height $trans-time--slow $trans-func--default;
    }
}

// Bulletpoints list
.polarized-pro-layers__item-bulletpoints {
    display: block;
    max-width: 2.25 * $moduleW-rem;

    li {
        @include typoZurich;
        @include typoZurich2-rem;
        display: block;
        text-transform: none;
        margin: 0;
        position: relative;
        padding-left: 1.5rem;

        &:before {
            content: "\25cf";
            position: absolute;
            left: 0;
            top: 0;
            margin-top: 0.2rem;
            font-size: 0.9rem;
        }

        @include breakpoint(one) {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
        }
    }


    @include breakpoint(four) {
        max-width: 2 * $moduleW-rem;
    }

    @include breakpoint(three) {
        max-width: 1.5 * $moduleW-rem;
    }

    @include breakpoint(two) {
        max-width: $moduleW-rem;
    }
}

// Read more/Read less toggle
.polarized-pro-layers__item-toggle {
    span {
        @include typoZurich;
        @include typoZurich2-rem;
        text-transform: none;
    }

    .polarized-pro-layers__item[aria-expanded="false"] & {
        span:last-child {
            display: none;
        }
    }

    .polarized-pro-layers__item[aria-expanded="true"] & {
        span:first-child {
            display: none;
        }
    }

    @include breakpoint(twofive) {
        transition: margin $trans-time--default $trans-func--default;
        margin-bottom: 1rem;

        .polarized-pro-layers__item[aria-expanded="true"] & {
            margin-top: 0.5rem;
        }
    }

    @include breakpoint(one) {
        margin: 0.5rem 0 1rem 0;
        span {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
        }
    }
}