// 3 - 5 columns
@include breakpoint(desktop) {
   .L-2-Video {
        height: 2.5 * $moduleH;        
        
        // Bigger
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;
            height: 2.5 * $moduleH;      
        }
    }  
}

// 2 columns
@include breakpoint(two) {
    .L-2-Video {
        height: 2 * $moduleH;        
        
        // Bigger
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;      
        }
    }        
}

// 1 columns
@include breakpoint(one) {
    .L-2-Video {
        height: 1 * $moduleH;        
        
        // Bigger
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;
            height: 1 * $moduleH;      
        }
    }            
}
