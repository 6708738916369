.navigation-dialog-wishlist {
    .listing {
        @include pseudo-list;
    }

    .listing__item {
        display: block;
        border-top: solid 1px $border-color-dark;
        padding-top: 1rem;
        opacity: 0;
        transform: translate3d(0, 1rem, 0);
        transition: opacity $trans-time--default $trans-func--easing,
                    transform $trans-time--default $trans-func--easing;
        @include transition-delay(0.1s, 0s, 20);

        &:not(:first-child) {
            margin-top: 1rem;
        }

        .is-loaded & {
            opacity: 1;
            transform: none;
        }

        &.is-removed {
            opacity: 0;
            transition-delay: 0s;
        }

        &[hidden] {
            display: none;
        }
    }

    .listing__article {
        display: flex;
    }

    // Image
    .listing__article-image {
        @include product-box(12rem, 1.2, 1.2);
        flex-shrink: 0;

        .ie &,
        .edge & {
            border: solid 1px $border-color--light-1;
        }
    }

    // Information
    .listing__article-info {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        margin-left: 1rem;
    }

    .listing__article-main {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .listing__article-name {
        @include typoZurich;
        @include typoZurich2-rem;
        display: inline-block;
    }

    .listing__article-price {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        display: inline-block;
        width: 8rem;
        text-align: right;
    }

    .listing__article-variant {
        display: flex;
        align-items: flex-start;
        margin-top: 2rem;

        p {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
            flex: 1 1 auto;
        }
    }

    .listing__article-dismiss {
        margin-left: 4rem;

        .svg-ico {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    // Empty wishlist
    .wishlist-empty {
        padding-bottom: 4rem;

        img {
            width: 100%;
        }
    }

    // Add to basket action
    .listing__article-action {
        display: flex;
        margin-top: auto;
        justify-content: flex-end;

        @include breakpoint(twofive) {
            button {
                width: auto;
                padding-left: 4rem;
                padding-right: 4rem;
            }
        }
    }

    @include breakpoint(one) {
        .listing__article {
            flex-direction: column;
        }

        .listing__article-image {
            width: 100%;
            height: 24rem * 0.6;

            img {
                transform: scale(1.1);
            }
        }

        .listing__article-info {
            margin-left: 0;
            margin-top: 1rem;
        }

        .listing__article-variant {
            margin-top: 1rem;
        }
    }

    // Empty wishlist
    .wishlist-empty {
        border-top: solid 1px $border-color-dark;
        padding: 1rem 0 3rem 0;

        h4 {
            @include typoZurich;
            @include typoZurich2-rem;
        }

        .wishlist-empty__teaser {
            margin-top: 1.5rem;
            text-align: center;

            .box {
                position: relative;
                padding: 0 0 66.66% 0;
                width: 100%;
                height: auto;
                float: none;
            }

            .module {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
    }
}