// 5 - 3 columns
@include breakpoint(desktop) {
    .L-2-WL {
        height: 2 * $moduleH;     
        
        // Small
        .b1 {
            top: 0;
            left: 0;
            height: 2 * $moduleH;  
        }   
        
        // Medium
        .b2 {
            top: 0; 
            left: 1 * $moduleW; 
        }
        
    }       
}

// 2 columns
@include breakpoint(two) {
    .L-2-WL {
        height: 1 * $moduleH;     
        
        // Small
        .b1 {
            top: 0;
            left: 0;
        }   
        
        // Medium
        .b2 {
            top: 0; 
            left: 1 * $moduleW;
            width: 1 * $moduleW; 
            height: 1 * $moduleH;
        }        
    }       
}

// 1 columns
@include breakpoint(one) {
    .L-2-WL {
        height: 2 * $moduleH;     
        
        // Small
        .b1 {
            top: 1 * $moduleH;
            left: 0;
        }   
        
        // Medium
        .b2 {
            top: 0; 
            left: 0;
        }        
    }       
} 
