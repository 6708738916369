.download-group-container {
    margin-top: 8rem;

    @include breakpoint(mobile) {
        margin-top: 6rem;
    }
}

// Download Category
.download-category,
.download-group-detail {
    .h2Wrapper {
        padding: $borderExtended;
        text-align: left;
        margin-bottom: 1em;
        position: relative;

        h2 {
            @include typoZurichBd;
            @include typoZurich3-rem;
        }
    }
}

.download-image-box {
    box-sizing: border-box;
    padding: $borderExtended;
    overflow: hidden;
}

// Download groups
.download-groups {
    @extend .clear;
    margin: 0;
    padding: 0;
    list-style-type: none;

    .download-group {
        float: left;
        position: relative;
        width: 1 * $moduleW;
        height: 2 * $moduleH;
        text-align: left;
    }

    .group-image {
        position: relative;
    }

    .group-info {
        box-sizing: border-box;
        padding: $borderExtended;
    }

    .group-headline-wrapper {
        margin-bottom: 0.5em;
    }

    .group-headline {
        @include typoZurich;
        @include typoZurich1-rem;
    }

    .group-description {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        margin-bottom: 0;
    }
}

// Download items
.download-items {
    @extend .clear;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.download-item {
    float: left;
    position: relative;
    width: 1 * $moduleW;
    height: 2.5 * $moduleH;
    text-align: left;

    &.download-item-video {
        height: 2 * $moduleH;
    }
}

.download-item-info {
    box-sizing: border-box;
    padding: $borderExtended;
}

.download-item-image {
    position: relative;

    &.download-item-image-ratio {
        img {
            transform: translate3d(-50%, -50%, 0);
            position: absolute;
            top: 50%;
            left: 50%;
            max-width: $moduleW - 4em;
            max-height: $moduleW - 4em;
            width: auto;
            height: auto;
        }

        .download-item-image-inner {
            position: relative;
            background-color: $footerBackground;
            text-align: center;
            width: 100%;
            height: 100%;
        }
    }
}

.download-item-headline-wrapper {
    margin-bottom: 0.5em;
}

.download-item-headline {
    @include typoZurich;
    @include typoZurich1-rem;
    text-align: left;
}

.download-item-description {
    @include typoZurich;
    @include typoZurich1-rem;
    text-transform: none;
    margin-bottom: 0;
    text-align: left;
}

.download-item-files {
    @extend %cf;
    margin: 1em 0 0 0;
    padding: 0;
    list-style-type: none;

    li {
        display: block;
        margin-bottom: 0.5em;
    }
}

// Video player
.download-video-player {
    .gridBorder {
        box-sizing: border-box;
        padding: $borderExtended;
    }

    .download-item-info {
        text-align: left;
    }
}

// Language selection
.download-language-selection {
    @include breakpoint(mobile) {
        text-align: right;
        display: block;
        margin-bottom: 1em;
    }

    @include breakpoint(one) {
        text-align: center;
    }

    @include breakpoint(desktop) {
        display: inline-block;
        position: absolute;
        top: 1.5em;
        right: 1em;
    }

    ul {
        @extend .clear;
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: inline-block;
    }

    a {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: uppercase;
        display: inline-block;
        float: left;
    }

    li {
        display: inline-block;
        float: left;
        margin-left: 0.25em;

        a:before {
            @include typoZurich;
            letter-spacing: 0;
            font-size: 1em;
            content: '|';
            display: inline-block;
            float: left;
            margin-right: 0.25em;
            line-height: 1.3em;
        }

        &:first-child a:before {
            content: '';
            margin: 0;
        }

        &.is-selected a {
            @include typoZurichBd;
        }
    }
}

// Published states
.download-category,
.download-group,
.download-item {
    &.published {
        box-shadow: inset 0 0 0 1px $colorPublished;
    }
}

.no-downloads-found {
    padding: $borderExtended;
    text-align: left;
    margin-top: 4em;
    margin-bottom: 16em;


    h3 {
        @include typoZurichBd;
        @include typoZurich2-rem;

        @include breakpoint(mobile) {
            @include typoZurich2-rem;
        }
    }
}
