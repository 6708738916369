.wishlist-page {
    margin-top: 8rem;

    @include breakpoint(mobile) {
        margin-top: 6rem;
    }

    margin-bottom: 4rem;

    // Use border-box for elements
    * {
        box-sizing: border-box;
    }

    // Main overview
    .wishlist-page__overview {
        position: relative;
        display: flex;
        padding: 0 1rem 4rem 1rem;
        text-align: left;
    }

    .wishlist-page__meta {
        position: relative;
        width: $moduleW-rem * 1.5;
        padding-right: $moduleW-rem * 0.75;

        h1,
        h2 {
            @include typoZurich;
            @include typoZurich3-rem;
        }

        h2 {
            text-transform: none;
        }
    }

    .wishlist-page__subline {
        .wishlist-page__subline-singular {
            display: none;
        }

        .wishlist-page__subline-plural {
            display: block;
        }

        &[data-count="1"] {
            .wishlist-page__subline-singular {
                display: block;
            }

            .wishlist-page__subline-plural {
                display: none;
            }
        }
    }

    // Listing
    .wishlist-page__listing {
        @include pseudo-list;
        display: block;
        flex: 1 1 auto;
    }

    // Row in listing
    .listing__item {
        position: relative;
        display: block;
        border-top: solid 1px $border-color-dark;
        padding-top: 2rem;
        opacity: 1;
        transition: opacity $defTransTime $defTransFunc;

        &:not(:first-child) {
            margin-top: 2rem;
        }

        &:last-child {
            padding-bottom: 2rem;
            border-bottom: solid 1px $border-color-dark;
        }

        &.is-removed {
            opacity: 0;
            transition-delay: 0s;
        }

        &[hidden] {
            display: none;
        }
    }

    // Article wrapper/link
    .listing__article {
        display: flex;
        opacity: 1;
        transition: opacity $defTransTime $defTransFunc;

        &:hover {
            //opacity: 0.7;
        }
    }

    // Image
    .listing__article-image {
        @include product-box(28rem, 1.2, 1.2);
        flex-shrink: 0;

        .ie &,
        .edge & {
            border: solid 1px $border-color--light-1;
        }
    }

    // Information
    .listing__article-info {
        flex: 1 1 auto;
        margin-left: 6rem;
        display: flex;
        flex-direction: column;
    }

    // Article name
    .listing__article-name {
        @include typoZurich;
        @include typoZurich3-rem;
        display: block;
    }

    // General iformation like color and price
    .listing__article-content {
        margin-top: 4rem;

        p {
            @include typoZurich;
            @include typoZurich2-rem;
            text-transform: none;
            margin-bottom: 0;

            & + p {
                margin-top: 1rem;
            }
        }
    }

    // Add to basket action
    .listing__article-action {
        display: block;
        margin-top: auto;
    }

    // Remove button
    .listing__remove-item {
        position: absolute;
        top: 2rem;
        right: 0;
        width: 2rem;
        height: 2rem;
    }

    @include breakpoint(desktop) {
        // Sticky meta information
        .wishlist-page__meta-sticky {
            position: relative;
            position: sticky;
            top: $header-height--desktop;
        }
    }

    @include breakpoint(four) {
        .listing__article-image {
            @include product-box-responsive(24rem, 1.2, 1.2);
        }

        .listing__article-info {
            margin-left: 4rem;
        }
    }

    @include breakpoint(three) {
        .wishlist-page__meta {
            width: $moduleW-rem * 1.25;
            padding-right: $moduleW-rem * 0.25;
        }

        .listing__article-image {
            @include product-box-responsive(18rem, 1.2, 1.2);
        }

        .listing__article-info {
            margin-left: 2rem;
        }
    }

    @include breakpoint(mobile) {
        .wishlist-page__overview {
            flex-direction: column;
            padding: 2rem $border;
        }

        .wishlist-page__meta {
            width: 100%;
            padding-right: 0;
        }

        .wishlist-page__listing {
            margin-top: 2rem;
        }

        .listing__article-info {
            margin-left: 2rem;
        }

        .listing__article-content {
            margin-top: 2rem;
        }
    }

    @include breakpoint(two) {
        .listing__article-image {
            @include product-box-responsive(16rem, 1.2, 1.2);
        }
    }

    @include breakpoint(one) {
        .wishlist-page__meta {
            h1,
            h2 {
                @include typoZurich2-rem;
            }

            h2 {
                text-transform: none;
            }
        }

        .listing__article {
            flex-direction: column;
        }

        .listing__article-image {
            width: 100%;
            height: 24rem * 0.7;

            img {
                transform: scale(1.1);
            }
        }

        .listing__article-info {
            margin-top: 1.5rem;
            margin-left: 0;
        }

        .listing__article-name {
            @include typoZurich;
            @include typoZurich2-rem;
            display: block;
        }

        .listing__article-content {
            margin-top: 1.5rem;

            p {
                @include typoZurich;
                @include typoZurich1-rem;
                text-transform: none;
            }
        }

        .listing__article-action {
            margin-top: 1.5rem;
        }

        .listing__remove-item {
            top: 3rem;
            right: 1rem;
        }
    }

    // Print adjustments
    @media print {
        .listing__article-action {
            display: none;
        }
    }
}