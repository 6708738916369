// Width of the product boxes
$_product-width--default: (
    one: 12.4rem,
    two: 22rem,
    three: 22rem,
    four: 28rem,
    five: 32rem,
);

// Height ratio of the product boxes
$_product-height-ratio--default: (
    one: 1.2,
    two: 1.2,
    three: 1.2,
    four: 1.2,
    five: 1.2,
);

// Width of the product boxes
$_product-width--slideshow: (
    one: 12.4rem,
    two: 22rem,
    three: 22rem,
    four: 28rem,
    five: 32rem,
);

// Height ratio of the product boxes
$_product-height-ratio--slideshow: (
    one: 0.9,
    two: 0.9,
    three: 0.9,
    four: 0.9,
    five: 0.9,
);

// Space between rows
$_row-gap--small-mobile: 2rem;
$_row-gap--mobile: 3rem;
$_row-gap--desktop: 4rem;

.product-listing {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    list-style: none;

    &:not(.product-listing--slideshow) {
        @include breakpoint(one) {
            width: 25.6rem;
            margin-left: -0.8rem;
            margin-right: -0.8rem;
        }

        @supports (display: grid) {
            display: grid;
            grid-row-gap: $_row-gap--small-mobile;
            grid-template-columns: repeat(2, map-get($_product-width--default, one));

            @include breakpoint(two) {
                grid-row-gap: $_row-gap--mobile;
                grid-template-columns: repeat(2, map-get($_product-width--default, two));
                padding: 0 $border;
            }

            @include breakpoint(three) {
                grid-template-columns: repeat(3, map-get($_product-width--default, three));
            }

            @include breakpoint(four) {
                grid-template-columns: repeat(3, map-get($_product-width--default, four));
            }

            @include breakpoint(five) {
                grid-template-columns: repeat(3, map-get($_product-width--default, five));
            }

            @include breakpoint(desktop) {
                padding: 0 $border;
                grid-row-gap: $_row-gap--desktop;
            }
        }
    }

    // Expandable product listing
    &.product-listing--expandable {
        @include breakpoint(desktop) {
            &[data-count='1'],
            &[data-count='2'],
            &[data-count='3'],
            &[data-count='4'],
            &[data-count='5'],
            &[data-count='6'] {
                .product-listing__expand-action {
                    display: none;
                }
            }

            &:not([data-count='1']):not([data-count='2']):not([data-count='3']):not([data-count='4']):not([data-count='5']):not([data-count='6']) {
                &[aria-expanded='false'] {
                    .product-listing-item {
                        &:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)) {
                            display: none;
                        }
                    }

                    .product-listing__expand-action--less {
                        display: none;
                    }
                }

                &[aria-expanded='true'] {
                    .product-listing__expand-action--more {
                        display: none;
                    }
                }
            }
        }

        @include breakpoint(mobile) {
            &[data-count='1'],
            &[data-count='2'],
            &[data-count='3'],
            &[data-count='4'] {
                .product-listing__expand-action {
                    display: none;
                }
            }

            &:not([data-count='1']):not([data-count='2']):not([data-count='3']):not([data-count='4']) {
                &[aria-expanded='false'] {
                    .product-listing-item {
                        &:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
                            display: none;
                        }
                    }

                    .product-listing__expand-action--less {
                        display: none;
                    }
                }

                &[aria-expanded='true'] {
                    .product-listing__expand-action--more {
                        display: none;
                    }
                }
            }
        }

        //@include breakpoint(one) {
        //    &[data-count="1"],
        //    &[data-count="2"],
        //    &[data-count="3"],
        //    &[data-count="4"],
        //    &[data-count="5"] {
        //        .product-listing__expand-action {
        //            display: none;
        //        }
        //    }
        //
        //    &:not([data-count="1"]):not([data-count="2"]):not([data-count="3"]):not([data-count="4"]):not([data-count="5"]) {
        //        &[aria-expanded="false"] {
        //            .product-listing-item {
        //                &:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)) {
        //                    display: none;
        //                }
        //            }
        //
        //            .product-listing__expand-action--less {
        //                display: none;
        //            }
        //        }
        //
        //        &[aria-expanded="true"] {
        //            .product-listing__expand-action--more {
        //                display: none;
        //            }
        //        }
        //    }
        //}
    }

    // Infinite product listing via load more button
    &.is-infinite[data-enabled='true'] {
        .product-listing-item:nth-last-child(2) {
            display: none;
        }
    }


    &.is-infinite[data-enabled='false'],
    &:not(.is-infinite) {
        .product-listing__load-more-action {
            display: none;
        }
    }
}

// Set some space between two listings
.product-listing + .product-listing {
    margin-top: 8rem;

    @include breakpoint(one) {
        margin-top: 4rem;
    }
}

.product-listing-item,
.product-listing-logo {
    text-align: left;
    width: map-get($_product-width--default, one);
    margin-bottom: $_row-gap--mobile;

    @supports (display: grid) {
        margin-bottom: 0;
    }

    @include breakpoint(desktop) {
        margin-bottom: $_row-gap--desktop;

        @supports (display: grid) {
            margin-bottom: 0;
        }
    }

    // Portrait rhythms
    // 3 Columns: Row Mix 2/1/3/0
    @include breakpoint(desktop) {
        &:nth-child(12n - 10),
        &:nth-child(12n - 8),
        &:nth-child(12n - 3) {
            .product-listing-item__portrait {
                display: block;
            }
        }
    }

    // 2 Columns: Row Mix 2/1/0
    @include breakpoint(mobile) {
        &:nth-child(6n - 4),
        &:nth-child(6n - 3) {
            .product-listing-item__portrait {
                display: block;
            }
        }
    }

    // 1 Column: Prod, Port, Prod, Port, Prod, Prod, Port
    //@include breakpoint(one) {
    //    &:nth-child(7n - 5),
    //    &:nth-child(7n - 3),
    //    &:nth-child(7n - 0) {
    //        .product-listing-item__portrait {
    //            display: block;
    //        }
    //    }
    //}

    .product-listing-item__box,
    .product-listing-logo__box {
        position: relative;

        .ie &,
        .edge & {
            border: solid 1px $border-color--light-1;
        }
    }

    // Slideshow settings
    .product-listing-item__slideshow {
        position: relative;
        width: 100%;
        height: map-get($_product-width--default, one) * map-get($_product-height-ratio--default, one);

        .flickity-prev-next-button {
            .flickity__arrow-icon {
                width: 1.8rem;
                height: 1.8rem;
                background-size: 1.8rem 1.8rem;
            }

            &.previous {
                left: 0.5rem;

                .flickity__arrow-icon {
                    background-image: url(../images/icons/svg/arrow-left.svg);
                }
            }

            &.next {
                right: 0.5rem;

                .flickity__arrow-icon {
                    background-image: url(../images/icons/svg/arrow-right.svg);
                }
            }
        }

        .dot {
            width: 16px;
            height: 16px;

            &:before {
                background: $backGrey3;
            }

            &.is-selected:before {
                background: #000;
            }
        }

        // Prevent old firefox version bug by transforming sldieshow variant
        &:not(.slideshow) {
            .product-listing-item__variant {
                transform: translate3d(0, 0, 0);
            }
        }
    }

    .product-listing-item__variant,
    .product-listing-logo__image {
        @include product-box(map-get($_product-width--default, one), map-get($_product-height-ratio--default, one), 1.3, 100%);
        width: map-get($_product-width--default, one);
        cursor: pointer;

        figure {
            display: flex;
            align-items: center;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .product-listing-item__variant {
        // Hide out of stock
        &.is-lazy,
        &[data-buyable='true']  {
            .product-listing-item__out-of-stock {
                display: none;
                pointer-events: none;
            }
        }
    }

    .product-listing-logo__image {
        cursor: default;
        justify-content: center;

        svg {
            width: 90%;
        }
    }

    .product-listing-item__out-of-stock {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        background: rgba(255, 255, 255, 0.6);

        p {
            @include typoZurich;
            @include typoZurich1-rem;
            margin-bottom: 0;
        }
    }

    .product-listing-item__portrait {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        opacity: 1;
        transition: opacity $trans-time--default $trans-func--easing;
        display: none;
        pointer-events: none;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
            transform: scale(1.25);
        }
    }

    .product-listing-item__label {
        position: absolute;
        top: 1rem;
        right: 1rem;
        z-index: 2;

        span {
            @include typoZurich;
            @include typoZurich1-rem;
            color: $text-color--default;
            position: absolute;
            display: inline-block;
            top: 0;
            right: 0;
            transition: opacity $trans-time--fast $trans-func--easing;
            margin-bottom: 0;
            white-space: nowrap;

            &[aria-hidden='true'] {
                opacity: 0;
                pointer-events: none;
            }
        }

        @include breakpoint(one) {
            top: 0.6rem;
            right: 0.6rem;

            span {
                @include typoZurichS-rem;
            }
        }
    }

    .product-listing-item__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 4rem;

        @include breakpoint(onethree) {
            height: 3.5rem;
            padding-top: 0.4rem;
            align-items: flex-start;

            .product-listing-item__actions {
                margin-top: 0.1rem;
            }
        }
    }

    .product-listing-item__name {
        @include typoZurich;
        @include typoZurich2-rem;
        flex: 1 0 auto;

        @include breakpoint(one) {
            @include typoZurichS2-rem;
            max-width: 9.4rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        @include breakpoint(onethree) {
            .product-listing-item__name-sample-sale {
                display: block;
            }
        }
    }

    .product-listing-item__price {
        position: relative;

        &.product-listing-item__price--original {
            color: $text-color--light-3;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                margin-top: -0.05rem;
                height: 0.1rem;
                background: $text-color--light-3;
            }
        }
    }

    .product-listing-item__actions {
        list-style: none;
        display: flex;
        transition: opacity $trans-time--default $trans-func--easing;

        li {
            &:not(:first-child) {
                margin-left: 1rem;

                @include breakpoint(one) {
                    margin-left: 0.5rem;
                }
            }
        }

        .btn {
            position: relative;
            width: 1.6rem;
            height: 1.6rem;

            @include breakpoint(one) {
                width: 1rem;
                height: 1rem;
            }
        }

        .svg-ico {
            width: 100%;
            height: 100%;
        }
    }

    .product-listing-item__action-cart {
        &[aria-hidden='true'] {
            display: none;
        }
    }

    // Wishlist button
    .product-listing-item__action-wishlist-button {
        .svg-ico {
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100% - 1px);
            height: calc(100% - 1px);
            transition: opacity $trans-time--fast $trans-func--easing;
        }

        .svg-ico-wishlist {
            opacity: 1;
        }

        .svg-ico-wishlist-filled {
            opacity: 0;
        }

        &.is-active {
            .svg-ico-wishlist {
                opacity: 0;
            }

            .svg-ico-wishlist-filled {
                opacity: 1;
            }
        }
    }

    .flickity-page-dots {
        bottom: 1.5rem;

        .dot {
            margin: 0;
        }

        @include breakpoint(mobile) {
            bottom: 1rem;
        }
    }

    // Touch/No-Touch related
    .no-touch & {
        .flickity-prev-next-button,
        .flickity-page-dots,
        .product-listing-item__actions {
            opacity: 0;
        }

        &:hover {
            .flickity-prev-next-button,
            .flickity-page-dots,
            .product-listing-item__actions {
                opacity: 1;
            }

            .product-listing-item__portrait {
                opacity: 0;

            }
        }
    }

    .touch & {
        .flickity-prev-next-button {
            display: none;
        }

        // Hide portrait if focussed
        &.is-focussed {
            .product-listing-item__portrait {
                opacity: 0;
                pointer-events: none;
            }
        }
    }



    // Responsive settings
    @include breakpoint(two) {
        width: map-get($_product-width--default, two);

        .product-listing-item__slideshow {
            height: map-get($_product-width--default, two) * map-get($_product-height-ratio--default, two);
        }

        .product-listing-item__variant,
        .product-listing-logo__image {
            @include product-box-responsive(map-get($_product-width--default, two), map-get($_product-height-ratio--default, two));
        }
    }

    @include breakpoint(three) {
        width: map-get($_product-width--default, three);

        .product-listing-item__slideshow {
            height: map-get($_product-width--default, three) * map-get($_product-height-ratio--default, three);
        }

        .product-listing-item__variant,
        .product-listing-logo__image {
            @include product-box-responsive(map-get($_product-width--default, three), map-get($_product-height-ratio--default, three));
        }
    }

    @include breakpoint(four) {
        width: map-get($_product-width--default, four);

        .product-listing-item__slideshow {
            height: map-get($_product-width--default, four) * map-get($_product-height-ratio--default, four);
        }

        .product-listing-item__variant,
        .product-listing-logo__image {
            @include product-box-responsive(map-get($_product-width--default, four), map-get($_product-height-ratio--default, four));
        }
    }

    @include breakpoint(five) {
        width: map-get($_product-width--default, five);

        .product-listing-item__slideshow {
            height: map-get($_product-width--default, five) * map-get($_product-height-ratio--default, five);
        }

        .product-listing-item__variant,
        .product-listing-logo__image {
            @include product-box-responsive(map-get($_product-width--default, five), map-get($_product-height-ratio--default, five));
        }
    }

    // Collection separator
    &.product-listing-item--category {
        .product-listing-item__box {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            @include breakpoint(one) {
                height: map-get($_product-width--default, one) * map-get($_product-height-ratio--default, one);
            }
        }

        h3 {
            @include typoZurich;
            @include typoZurich3-rem;
            text-align: center;

            @include breakpoint(one) {
                @include typoZurich2-rem;
            }
        }
    }
}

// Expand action + Load more action
.product-listing__expand-action,
.product-listing__load-more-action {
    display: inline-block;
    padding-bottom: 4rem;

    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: $product-image-background;

        span {
            @include typoZurich;
            @include typoZurich2-rem;
            text-transform: lowercase;

            @include breakpoint(one) {
                @include typoZurich1-rem;
                text-transform: lowercase;
            }
        }
    }

    @include breakpoint(one) {
        .btn {
            width: map-get($_product-width--default, one);
            height: map-get($_product-width--default, one) * map-get($_product-height-ratio--default, one);
        }
    }

    @include breakpoint(two) {
        .btn {
            width: map-get($_product-width--default, two);
            height: map-get($_product-width--default, two) * map-get($_product-height-ratio--default, two);
        }
    }

    @include breakpoint(three) {
        .btn {
            width: map-get($_product-width--default, three);
            height: map-get($_product-width--default, three) * map-get($_product-height-ratio--default, three);
        }
    }

    @include breakpoint(four) {
        .btn {
            width: map-get($_product-width--default, four);
            height: map-get($_product-width--default, four) * map-get($_product-height-ratio--default, four);
        }
    }

    @include breakpoint(five) {
        .btn {
            width: map-get($_product-width--default, five);
            height: map-get($_product-width--default, five) * map-get($_product-height-ratio--default, five);
        }
    }
}

// Lazy loading box
.media-box-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 32px;
    height: 32px;
    margin: -16px 0 0 -16px;
    background: url(../images/icons/loader_32px_t.gif) no-repeat 50% 50% transparent;
}

// Product listing slideshow
.product-listing.product-listing--slideshow {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: map-get($_product-width--default, one) * map-get($_product-height-ratio--default, one);
    overflow: hidden;
    background: $product-image-background;

    &.flickity-enabled {
        display: block;
    }

    .product-listing-item:not(:last-child) {
        border-right: solid 1px $back-color--default;
    }

    .product-listing-item__label {
        display: none;
    }

    .product-listing-item__footer {
        display: block;
        width: 100%;
        position: relative;
        z-index: 3;
        padding: 0;
        margin-top: -2.5rem;
        height: 2rem;

        @include breakpoint(five) {
            height: 4rem;
            margin-top: -4rem;
        }

        @include breakpoint(four) {
            height: 4rem;
            margin-top: -4rem;
        }

        @include breakpoint(three) {
            height: 4rem;
            margin-top: -4rem;
        }

        @include breakpoint(two) {
            height: 3rem;
            margin-top: -3rem;
        }
    }

    .product-listing-item__name {
        box-sizing: border-box;
        margin: 0;
        padding: 0 3rem;
        display: flex;
        justify-content: space-between;
        width: 100%;

        @include breakpoint(one) {
            padding: 0 1rem;
            max-width: 100%;
        }
    }

    .product-listing-item__article-name {
        display: inline-block;
        width: 100%;
        text-align: center;
    }

    .product-listing-item__price,
    .product-listing-item__actions {
        display: none;
    }

    .media-box {
        margin-top: -1rem;
    }

    // Responsive settings
    @include breakpoint(two) {
        height: map-get($_product-width--slideshow, two) * map-get($_product-height-ratio--slideshow, two);

        .product-listing-item__slideshow {
            height: map-get($_product-width--slideshow, two) * map-get($_product-height-ratio--slideshow, two);
        }

        .product-listing-item__variant,
        .product-listing-logo__image {
            @include product-box-responsive(map-get($_product-width--slideshow, two), map-get($_product-height-ratio--slideshow, two));
        }
    }

    @include breakpoint(three) {
        height: map-get($_product-width--slideshow, three) * map-get($_product-height-ratio--slideshow, three);

        .product-listing-item__slideshow {
            height: map-get($_product-width--slideshow, three) * map-get($_product-height-ratio--slideshow, three);
        }

        .product-listing-item__variant,
        .product-listing-logo__image {
            @include product-box-responsive(map-get($_product-width--slideshow, three), map-get($_product-height-ratio--slideshow, three));
        }
    }

    @include breakpoint(four) {
        height: map-get($_product-width--slideshow, four) * map-get($_product-height-ratio--slideshow, four);

        .product-listing-item__slideshow {
            height: map-get($_product-width--slideshow, four) * map-get($_product-height-ratio--slideshow, four);
        }

        .product-listing-item__variant,
        .product-listing-logo__image {
            @include product-box-responsive(map-get($_product-width--slideshow, four), map-get($_product-height-ratio--slideshow, four));
        }
    }

    @include breakpoint(five) {
        height: map-get($_product-width--slideshow, five) * map-get($_product-height-ratio--slideshow, five);

        .product-listing-item__slideshow {
            height: map-get($_product-width--slideshow, five) * map-get($_product-height-ratio--slideshow, five);
        }

        .product-listing-item__variant,
        .product-listing-logo__image {
            @include product-box-responsive(map-get($_product-width--slideshow, five), map-get($_product-height-ratio--slideshow, five));
        }
    }

    &.show-hint {
        .flickity-slider {
            animation-duration: 3s;
            animation-name: product-slideshow-hint;
            animation-iteration-count: 1;
        }
    }
}

// Slideshow activity show
@keyframes product-slideshow-hint {
    0% {
        margin-left: 0;
    }

    50% {
        margin-left: -8rem;
    }

    100% {
        margin-left: 0;
    }
}