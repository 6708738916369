.L-2-Teaser .b1 {
    top: 0 * $moduleH;
    left: 0 * $moduleW;      
}

// 5 columns
@include breakpoint(five) {
    .L-2-Teaser,
    .L-2-Teaser .b1 {
        height: 5 * $moduleH;       
    }       
}

// 4 columns
@include breakpoint(four) {
    .L-2-Teaser,
    .L-2-Teaser .b1 {
        height: 4 * $moduleH;       
    }          
}

// 3 columns
@include breakpoint(three) {
    .L-2-Teaser,
    .L-2-Teaser .b1 {
        height: 3 * $moduleH;       
    }  
}

// 2 columns
@include breakpoint(two) {
    .L-2-Teaser,
    .L-2-Teaser .b1 {
        height: 2 * $moduleH;       
    }        
}

// 1 columns
@include breakpoint(one) {
    .L-2-Teaser,
    .L-2-Teaser .b1 {
        height: 1 * $moduleH;       
    }            
}
