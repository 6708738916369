.farfetch-group {
    margin-top: 4rem;
    margin-bottom: 8rem;

    .h1Wrapper {
        text-align: left;
    }

    .farfetch-table {
        width: 100%;

        td,
        th {
            text-align: left;
            vertical-align: top;
            padding: 0.25rem 1rem;
        }

        th {
            white-space: nowrap;
            border-bottom: solid 1px #000;
        }

        td {
            border-bottom: solid 1px #929292;
        }

        td.farfetch-price,
        td.farfetch-quantity {
            text-align: right;
        }
    }
}