// Custom sizing for localisation modal
.info-layer.info-layer--localisation {
    @include breakpoint(mobile) {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: none;
        box-shadow: none;

        .info-layer-wrapper {
            max-height: 100%;
        }
    }
}

.localisation-settings {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: left;

    h3 {
        margin-bottom: 2rem;
    }

    .localisation-settings__country {
        width: 1 * $moduleW-rem;
    }

    .localisation-settings__language {
        display: block;
        width: 0.5 * $moduleW-rem;

        ul {
            @include pseudo-list;
        }

        li {
            display: block;
        }

        input[type="radio"] {
            @include hide;
        }

        label {
            @include typoZurich;
            @include typoZurich1-rem;

            display: inline-block;
            width: auto;
            padding: 0;
            text-transform: none;

            &:before {
                display: none;
            }

            &:after {
                position: absolute;
                top: auto;
                left: 0;
                right: 0;
                bottom: 0;
                width: auto;
                height: 1px;
                background: none;
                border-radius: 0;
                border-bottom: solid 1px #000000;
            }
        }
    }

    .localisation-settings__action {
        width: 1 * $moduleW-rem;
        margin-top: 3rem;
    }

    .select2-container {
        margin-top: 3rem;
    }

    .localisation-settings__hint + .btn {
        margin-top: 1rem;
    }

    @include breakpoint(desktop) {
        .localisation-settings__language {
            li {
                &:not(:first-child) {
                    margin-top: 0.5rem;
                }
            }
        }
    }

    @include breakpoint(mobile) {
        flex-direction: column;
        padding-top: 3rem;

        .localisation-settings__language {
            order: 1;
            width: 100%;
        }

        .localisation-settings__country {
            order: 2;
            margin-top: 3rem;
            width: 100%;
        }

        .localisation-settings__action {
            @include fade-cover(top, 2rem);
            padding-bottom: 3rem;
            order: 3;
            width: 100%;
            position: sticky;
            bottom: 0;
            background: #fff;
            z-index: 1;
        }
    }

    @include breakpoint(one) {
        .localisation-settings__language {
            ul {
                display: flex;
            }

            li {
                display: inline-block;
                width: 50%;
                margin-top: 0;
            }
        }
    }
}