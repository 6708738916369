.L-1-Hero-Teaser {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    text-align: center;
    height: auto;
    width: 100%;

    .b1 {
        width: 100%;
        float: none;
        position: relative;
        padding: 0;
        overflow: hidden;
        padding-bottom: 66.66666%;

        // Disable box link hover effect
        a.boxLink:hover .media-box img {
            opacity: 1;
        }

        & > * {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        @include breakpoint(one) {
            padding-bottom: 100%;
        }

        @media (orientation: landscape) and (min-width: 480px) {
            padding-bottom: 0;
            height: $full-height-vh--desktop;
            height: $full-height-svh--desktop;
        }
    }
}