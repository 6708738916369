/* ==========================================================================
   rollover
   ========================================================================== */

.textBox a.boxLink:hover {
	background: $backGrey;
}

// transitions
a.boxLink,
.box-link {
    transition: background $defTransTime $defTransFunc;
}


a.boxLink img,
a.box-link img,
a.boxLink .hoverWrapper,
a.boxLink .h1Wrapper,
a.boxLink .h2Wrapper,
a.boxLink .h3Wrapper,
a.boxLink .h4Wrapper,
a.boxLink .link-wrapper,
a.boxLink .textBoxWrapper,
a.boxLink .playLink,
a.boxLink .pauseLink,
a.boxLink video,
a.boxLink .touchHoverWrapper,
.box-link video,
.box-link .video-button {
    transition: opacity $defTransTime $defTransFunc;
}


// Default style for a box link
a.boxLink {
    img:not(.lazy),
    video {
        opacity: 1;
    }

    .hoverWrapper,
    .h1Wrapper,
    .h2Wrapper,
    .h3Wrapper,
    .h4Wrapper,
    .link-wrapper,
    .playLink,
    .touchHoverWrapper {
        opacity: 0;
    }

    .touchHoverWrapper {
        display: none;
    }


}

// Display by default for new Teaser Version and video modules
.teaserVer2 a.boxLink,
.teaserVer4 a.boxLink,
.teaserVer5 a.boxLink,
.moduleVideo a.boxLink {
    .hoverWrapper,
    .h1Wrapper,
    .h2Wrapper,
    .h3Wrapper,
    .h4Wrapper,
    .link-wrapper,
    .touchHoverWrapper {
        opacity: 1;
    }

    .playLink {
        opacity: 0.6;
    }
}

// Defaul hover action
a.boxLink:hover {
    .respImage.loaded img,
    .media-box img,
    img {
        opacity: 0.5;
    }

    .hoverWrapper,
    .h1Wrapper,
    .h2Wrapper,
    .h3Wrapper,
    .h4Wrapper,
    .link-wrapper {
        opacity: 1;

        img {
            opacity: 1;
        }
    }

    .playLink {
        opacity: 0.6;
    }
}

// A video is playing
.moduleVideo {
    a.boxLink {
        .playLink {
            opacity: 0;
        }
    }
}

// A video is playing
.moduleVideo.showActionLink:not(.isPlaying) a.boxLink {
    .playLink {
        opacity: 0.6;
    }
}

// Touchhover functionallity
.touch {
    //.imageTeaser a.boxLink {
		//.touchHoverWrapper {
		//    display: block;
		//}
    //
		//.h2Wrapper,
		//.h3Wrapper,
    //    .h4Wrapper {
		//    display: none;
		//}
    //}
    //
    //.teaserVer2.imageTeaser a.boxLink,
    //.teaserVer4.imageTeaser a.boxLink {
		//.touchHoverWrapper {
		//    display: none;
		//}
    //
		//.h2Wrapper,
		//.h3Wrapper,
    //    .h4Wrapper {
		//    display: block;
		//}
    //}
    //
    //.imageTeaser a.boxLink.touchHover,
    //.imageTeaser a.boxLink:hover {
    //    .respImage.loaded img,
    //    img {
    //        opacity: 0.5;
    //    }
    //
    //    .touchHoverWrapper {
    //        opacity: 1;
    //    }
    //}

    .moduleVideo a.boxLink:hover video {
        opacity: 1;
    }
}

// TextTeaser Rollover 2
.rollover2 .textTeaser {
    a.boxLink {
        background: $backGrey;
    }

    a.boxLink:hover {
        background: transparent;
    }
}

// Always display within products
.product a.boxLink {
    .hoverWrapper,
    .h1Wrapper,
    .h2Wrapper,
    .h3Wrapper,
    .h4Wrapper {
        opacity: 1;
    }
}

// New video rollovers
.module--video {
    &.has-visible-actions:not(.is-playing) {
        .video-button--play {
            opacity: 0.6;
        }
    }
}

.touch {
    .module--video {
        .video-button--play {
            opacity: 0.6;
        }

        &.is-playing {
            .video-button--play {
                opacity: 0;
            }
        }
    }
}
