// General newsletter
.newsletter-container__content {
    h1 {
        @include typoZurichBd;
        @include typoZurich2-rem;
        margin-bottom: 2rem;
    }

    p {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        margin-bottom: 0;
    }
}

.newsletter-container__action {
    margin-top: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

// Confirmation page
.newsletter-container--confirm {
    width: 100%;
    max-width: $moduleW-rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8rem;
    padding-bottom: 8rem;

    @include breakpoint(one) {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
}

// Unsubscribe page
.newsletter-container--unsubscribe {
    width: 100%;
    max-width: 1.5 * $moduleW-rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8rem;
    padding-bottom: 8rem;

    @include breakpoint(one) {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
}

.newsletter-container__form {
    margin-top: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;

    label,
    input {
        text-align: center;
    }

    @include breakpoint(one) {
        padding-left: 0;
        padding-right: 0;
    }
}

.newsletter-container__section {
    &[aria-hidden="true"] {
        display: none;
    }
}

.newsletter-container__section--unsubscribe-confirm {
    padding-left: 4rem;
    padding-right: 4rem;

    @include breakpoint(one) {
        padding-left: 0;
        padding-right: 0;
    }
}