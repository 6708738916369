/* ==========================================================================
   font face declarations
   ========================================================================== */
@font-face {font-family: 'ZurichBT-Light';src: url('fonts/23CA42_0_0.eot');src: url('fonts/23CA42_0_0.eot?#iefix') format('embedded-opentype'),url('fonts/23CA42_0_0.woff') format('woff'),url('fonts/23CA42_0_0.ttf') format('truetype');}
@font-face {font-family: 'ZurichBT-Bold';src: url('fonts/23CA42_1_0.eot');src: url('fonts/23CA42_1_0.eot?#iefix') format('embedded-opentype'),url('fonts/23CA42_1_0.woff') format('woff'),url('fonts/23CA42_1_0.ttf') format('truetype');}
@font-face {font-family: 'ZurichBT-Roman';src: url('fonts/2AF094_0_0.eot');src: url('fonts/2AF094_0_0.eot?#iefix') format('embedded-opentype'),url('fonts/2AF094_0_0.woff') format('woff'),url('fonts/2AF094_0_0.ttf') format('truetype');}
@font-face {font-family: "FontAwesome";font-style: normal;font-weight: normal;src: url("fonts/fontawesome-webfont.eot?#iefix&v=3.0.1") format("embedded-opentype"), url("fonts/fontawesome-webfont.woff?v=3.0.1") format("woff"), url("fonts/fontawesome-webfont.ttf?v=3.0.1") format("truetype");}

@font-face {
    font-family: 'ZurichMM6-Light';
    src: url('fonts/ZurichMM6-Light.woff') format('woff'), url('fonts/ZurichMM6-Light.woff2') format('woff2');
}