/* ==========================================================================
   header
   ========================================================================== */
.header {
    box-sizing: border-box;
    pointer-events: none;
    position: relative;

    * {
        box-sizing: border-box;
    }
}

.header.header--main {
	width: 100%;

    .header__container {
        background: transparent;
        position: relative;
        z-index: 2;
    }

    .header__container--main {
        pointer-events: all;
    }

    &:before {
        content: '';
        background: transparent;
        position: absolute;
        inset: 0 0 0 0;
        z-index: -1;
        pointer-events: none;
        transition: background $trans-time--fast $trans-func--default;
    }

	@include breakpoint(desktop) {
        position: fixed;
        inset: 0 0 auto 0;
        z-index: zIndex(header);
        transition: transform $trans-time--default $trans-func--default;

        .header__container--main {
            height: $header-height--desktop;
        }

        .has-global-banner & {
            transform: translate3d(0, $global-banner-height--desktop, 0);
        }

        &.has-expanded-subnav {
            &:before {
                background: #ffffff;
            }

            .head-nav,
            .header__nav-functions {
                --text-color: #000 !important;
            }

            .header__logo {
                body:not(.is-startpage) &,
                .is-startpage.has-scrolled & {
                    --text-color: #000 !important;
                }
            }

            @media (pointer: coarse) {
                .header__backdrop {
                    pointer-events: all;
                    display: block;
                    cursor: pointer;
                }
            }
        }
    }

    // Hide the main nav on mobile
    @include breakpoint(mobile) {
        height: 100%;

        &.header--checkout {
            position: fixed;
            inset: 0 0 auto 0;
            z-index: zIndex(header);
            height: $header-height--mobile;

            .head-nav {
                display: none;
            }
        }

        //.header-nav-container {
        //    display: none;
        //}
        //
        //.has-visible-map & {
        //    transform: translate3d(0, -4.5rem, 0);
        //    opacity: 0;
        //}
        //
        //.has-reduced-filter-header & {
        //    transform: translate3d(0, -3.5rem, 0);
        //}
        //
        //.has-scrolled:not(.has-scrolled-up) & {
        //    transform: translate3d(0, $header-height--mobile, 0);
        //}
    }

    // Tansitions for content aware header
    .svg-ico {
        transition: fill $trans-time--fast $trans-func--default,
                    stroke $trans-time--fast $trans-func--default;
    }

    .svg-ico-wishlist-filled {
        stroke: var(--text-color, $text-color--default);
        fill: var(--text-color, $text-color--default);
    }

    .svg-ico-wishlist,
    .svg-ico-profile,
    .svg-ico-cart,
    .svg-ico-search,
    .svg-ico-nav,
    .svg-ico-close {
        stroke: var(--text-color, $text-color--default);
    }

    .header__nav-functions-item--localisation a {
        color: var(--text-color, $text-color--default);
        transition: color $trans-time--fast $trans-func--default;
    }
}

.header__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 0 3rem;

    @include breakpoint(two) {
        padding: 0 1.5rem;
    }

    @include breakpoint(one) {
        width: $moduleW-rem;
        padding: 0;
        margin: 0 auto;

        .header--checkout & {
            flex-direction: row;
        }
    }
}

// Proper sizing of elements
.header__nav-toggle,
.header__nav-functions,
.header__back-to-shop,
.head-nav {
    width: 40%;
}

.header__logo {
    width: 20%;
}

.header__logo {
    display: flex;
    justify-content: center;
    text-align: center;
    opacity: 1;
    pointer-events: all;
    z-index: 2;

    .header__logo-link {
        transform-origin: 50% 0;
        display: inline-block;
        width: 11.2rem;
    }

    span {
        display: block;
        width: 100%;
        height: 100%;
    }

    h1 {
        font-size: 1rem;
        line-height: 1;
    }

    svg {
        width: 100%;
        fill: var(--text-color, #000);
        transition: fill $trans-time--fast $trans-func--default;

        // Use white as default for startpage
        .is-startpage & {
            fill: var(--text-color, #fff);
        }
    }

    .has-large-logo-visible & {
        opacity: 0;
        pointer-events: none;
    }

    @include breakpoint(one) {
        .header--checkout & {
            justify-content: flex-start;
        }
    }

    @include breakpoint(desktop) {
        .is-startpage & {
            position: fixed;
            top: 6rem;
            left: 0;
            right: 0;
            width: 100%;
            text-align: center;
            pointer-events: none;

            .header__logo-link {
                width: calc(100% - 6rem);
                transition: transform $trans-time--fast $trans-func--default,
                            width $trans-time--fast $trans-func--default;
            }
        }

        .is-startpage.has-scrolled & {
            .header__logo-link {
                transform: translate3d(0, -4rem, 0);
                width: 11.2rem;
                pointer-events: all;
            }
        }
    }

    @include breakpoint(mobile) {
        position: fixed;
        top: 1.5rem;
        left: 0;
        right: 0;
        width: 100%;
        pointer-events: none;
        text-align: center;

        .header__logo-link {
            pointer-events: all;
            transform: none;
            width: 8rem;
            transition: transform $trans-time--fast $trans-func--default,
                        width $trans-time--fast $trans-func--default;
        }

        .has-scrolled:not(.is-startpage):not(.has-scrolled-up) &,
        .has-visible-map & {
            .header__logo-link {
                transform: translate3d(0, -4rem, 0);
            }

        }

        .is-startpage & {
            .header__logo-link {
                width: calc(100% - 3rem);
                pointer-events: none;
                transform: translate3d(0, 0, 0);
            }
        }

        .is-startpage.has-scrolled & {
            .header__logo-link {
                transform: translate3d(0, -4rem, 0);
                width: 8rem;
                pointer-events: all;
            }
        }

        .is-startpage.has-scrolled.has-scrolled-up & {
            .header__logo-link {
                transform: translate3d(0, 0, 0);
                pointer-events: all;
            }
        }

        .is-startpage.has-scrolled.has-scrolled-up.has-global-banner & {
            .header__logo-link {
                transform: translate3d(0, $global-banner-height--mobile, 0);
                pointer-events: all;
            }
        }

        .has-global-banner & {
            .header__logo-link {
                transform: translate3d(0, $global-banner-height--mobile, 0);
            }
        }

        .header--checkout & {
            position: relative;
            left: auto;
            top: auto;
            transform: none !important;
            width: auto;
            text-align: left;

            .header__logo-link {
                transform: none !important;
            }
        }
    }
}

.header__back-to-shop {
    text-align: right;

    @include breakpoint(one) {
        width: 50%;
    }
}

.header__backdrop {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
}