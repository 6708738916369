// 5 columns
@include breakpoint(five) {
    .L-4-6-2 {
        height: 5 * $moduleH;        
        
        // Bigger
        .b1 {
            top: 0;
            left: 0;    
        }
        
        // medT
        .b2 {
            top: 0;
            left: 3 * $moduleW;    
        }
        
        // Big
        .b3 {
            top: 3 * $moduleH;
            left: 0 * $moduleW;    
        }
        
        // Quad
        .b4 {
            top: 2 * $moduleH;
            left: 3 * $moduleW;    
        }
    }       
}

// 4 columns
@include breakpoint(four) {
    .L-4-6-2 {
        height: 6 * $moduleH;        
        
        // Bigger
        .b1 {
            top: 0;
            left: 1 * $moduleW;    
        }
        
        // medT
        .b2 {
            top: 0;
            left: 0 * $moduleW;    
        }
        
        // Big
        .b3 {
            top: 3 * $moduleH;
            left: 2 * $moduleW;
            width: 2 * $moduleW;
            height: 3 * $moduleH;    
        }
        
        // Quad
        .b4 {
            top: 3 * $moduleH;
            left: 0 * $moduleW;    
        }
    }       
}

// 3 columns
@include breakpoint(three) {
   .L-4-6-2 {
        height: 5 * $moduleH;        
        
        // Bigger
        .b1 {
            top: 0;
            left: 1 * $moduleW;
            width: 2 * $moduleW;
            height: 2 * $moduleH;     
        }
        
        // medT
        .b2 {
            top: 0;
            left: 0 * $moduleW;    
        }
        
        // Big
        .b3 {
            top: 2 * $moduleH;
            left: 2 * $moduleW;
            width: 1 * $moduleW;
            height: 3 * $moduleH;    
        }
        
        // Quad
        .b4 {
            top: 2 * $moduleH;
            left: 0 * $moduleW;    
        }
    }
}

// 2 columns
@include breakpoint(two) {
    .L-4-6-2 {
        height: 7 * $moduleH;        
        
        // Bigger
        .b1 {
            top: 0;
            left: 0;    
        }
        
        // medT
        .b2 {
            top: 2  * $moduleH;
            left: 0 * $moduleW;   
        }
        
        // Big
        .b3 {
            top: 6 * $moduleH;
            left: 0 * $moduleW;
            height: 1 * $moduleH;    
        }
        
        // Quad
        .b4 {
            top: 3 * $moduleH;
            left: 0 * $moduleW;    
        }
    }       
}

// 1 columns
@include breakpoint(one) {
    .L-4-6-2 {
        height: 5 * $moduleH;        
        
        // Bigger
        .b1 {
            top: 0;
            left: 0;    
        }
        
        // medT
        .b2 {
            top: 1  * $moduleH;
            left: 0 * $moduleW;   
        }
        
        // Big
        .b3 {
            top: 4 * $moduleH;
            left: 0 * $moduleW;
            height: 1 * $moduleH;    
        }
        
        // Quad
        .b4 {
            top: 2 * $moduleH;
            left: 0 * $moduleW;    
        }
    }        
}
