/* ==========================================================================
   module images
   ========================================================================== */

.moduleImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
/* boxes images */
.module .boxImage  {
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	width: 100%;
}

.quad .boxImage { // different image resize for modules which might change their ratio
	width: auto;
	height: 101%;
}
