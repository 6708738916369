/* ==========================================================================

   Meta Declarations
 * normalize, compass, mixins, etc

   ========================================================================== */

/* Mixins & Vars */
@import "mixins/vars";
@import "mixins/breakpoints";
@import "mixins/typesetup";
@import "mixins/functions";
@import "mixins/mixins";
@import "mixins/extends";
@import "mixins/layout";

/* fontface */
@import "layout/fontface";

/* normalize includes */
@import "normalize/normalize";

/* ==========================================================================

   Layout
 * Includes meta declarations concerning type, fonts, main setup etc

   ========================================================================== */

@import "layout/type";
@import "layout/forms";
@import "layout/iconfont";
@import "layout/mykita-icons";
@import "layout/setup";
@import "layout/grid";
@import "layout/preview";

/* ==========================================================================

   Layout / Modules
 * Includes specific layout settings for our modules

   ========================================================================== */

// import all module definitions
@import "layout/modules/module-setup";
@import "layout/modules/module-type";
@import "layout/modules/module-images";
@import "layout/modules/module-rollover";


@import "layout/modules/teaser-default";
//@import "layout/modules/slideshow";
@import "layout/modules/teaser-image";
@import "layout/modules/teaser-text";
@import "layout/modules/teaser-vimeo";
@import "layout/modules/layout-item-text";
@import "layout/modules/layout-item-image";
@import "layout/modules/layout-item-articles";
@import "layout/modules/layout-item-faq";
@import "layout/modules/layout-item-contact";
@import "layout/modules/layout-item-iframe";


/* ==========================================================================

   Layout / Container
 * Includes specific layout settings for our layout container

   ========================================================================== */
/* Default Content Layouts */
@import "layout/container/L-1-Def";
@import "layout/container/L-1-Three";
@import "layout/container/L-1-Hero";
@import "layout/container/L-1-Hero-Teaser";
@import "layout/container/L-1-Video";
@import "layout/container/L-1-Video-16-9";
@import "layout/container/L-2-Video";
@import "layout/container/L-1-Teaser";
@import "layout/container/L-1-Teaser-Extended";
@import "layout/container/L-1-Bigger";
@import "layout/container/L-2-Bigger";
@import "layout/container/L-2-Teaser";
@import "layout/container/L-3-Hero-Teaser";
@import "layout/container/L-4-Hero-Teaser";
@import "layout/container/L-5-Teaser";

/* Shopfinder container */
@import "layout/container/L-SF-Map";
@import "layout/container/L-SF-Shop";

/* product detail container */
@import "layout/container/L-P-Detail";
@import "layout/container/L-P-Detail-Art";
@import "layout/container/L-P-Detail-Palm";

/* Wishlist Layout */
@import "layout/container/L-2-WL";

/* container layouts */
@import "layout/container/L-2-1-1";
@import "layout/container/L-2-1-2";
@import "layout/container/L-2-2-1";
@import "layout/container/L-2-3-1";

@import "layout/container/L-2-4-1";
//@import "layout/container/L-2-4-2";
@import "layout/container/L-2-Hero-Teaser";

@import "layout/container/L-3-6-1";

@import "layout/container/L-4-5-1";
@import "layout/container/L-4-5-2";

@import "layout/container/L-4-6-1";
@import "layout/container/L-4-6-2";
@import "layout/container/L-4-7-1";
@import "layout/container/L-4-8-1"; // Startpage
@import "layout/container/L-4-9-1"; // Catwalk 1
@import "layout/container/L-4-9-2"; // Catwalk 2
@import "layout/container/L-6-1-1"; // Startpage
@import "layout/container/L-6-2-1"; // Project Detail
@import "layout/container/L-6-3-1"; // Catwalk 3

@import "layout/container/L-3-7-1"; // MYkita Haus 2
@import "layout/container/L-3-7-2"; // MYkita Haus 2 - Inverted
@import "layout/container/L-3-8-1"; // Collectiondetails

// Mylon Layouts
@import "layout/container/L-MYLON-1";
@import "layout/container/L-MYLON-2";
@import "layout/container/L-MYLON-3";

// Haus Layouts
@import "layout/container/L-HAUS-1";

// Startpage Layouts
@import "layout/container/L-S-1";
@import "layout/container/L-S-2";
@import "layout/container/L-S-3";
@import "layout/container/L-S-4";
@import "layout/container/L-S-5";
@import "layout/container/L-S-5-2";
@import "layout/container/L-S-6";
@import "layout/container/L-S-6-2";

// New Layouts
@import "layout/container/L-N-4-2";


/* ==========================================================================
   3rd party plugins
 * Includes 3rd party (s)css files
   ========================================================================== */
@import "plugins/select2";
@import "plugins/flickity";
@import "plugins/pikaday";
@import "plugins/heidelpay";
@import "plugins/nosto";
@import "plugins/awesomplete";
@import "plugins/cookiefirst";

/* ==========================================================================

   Elements
 * Includes declarations concerning page elements like header, footer, etc

   ========================================================================== */

/* Header */
@import "elements/header";
// @import "elements/overlay";
@import "elements/toolsheader";
@import "elements/notification-bar";
@import "elements/toolbar";
// @import "elements/mobile-nav";
@import "elements/footer";
@import "elements/tooltip";
@import "elements/loading";
@import "elements/infoLayer";
@import "elements/panel";
@import "elements/toggle-panel";
@import "elements/sidebar-basket";
//@import "elements/filter-content";
@import "elements/accordion";
@import "elements/countdown";
@import "elements/banner";
@import "elements/nl-signup";
@import "elements/slideshow";
@import "elements/navigation-dialog";
@import "elements/notification";
@import "elements/product-listing";
@import "elements/product-filter";
@import "elements/comparison";

// Info layer sub templates
@import "elements/info-layer/localisation";
@import "elements/info-layer/account";
@import "elements/info-layer/shop-appointment";

// Navigation Dialog sub templates
@import "elements/navigation-dialog/wishlist";
@import "elements/navigation-dialog/cart";
@import "elements/navigation-dialog/account";

// Polarized Pro
@import "elements/polarized-pro-lens-comparison";
@import "elements/polarized-pro-layers";

// Leica lenses
@import "elements/leica-lenses";
@import "elements/lookbook-v2";

// Schema.org / Structured data
@import "elements/structured-data";

// Timify booking
@import "elements/timify";

// Newnavigation overlay
@import "elements/navigation-overlay";

// New/old navigation
@import "elements/head-nav";
@import "elements/functions-nav";

/* ==========================================================================

   Pages
 * Includes declarations concerning certain page types

   ========================================================================== */

/* collections */
@import "pages/collection";
@import "pages/collection-detail";
@import "pages/product-detail";
@import "pages/product-detail-error";
@import "pages/shop-finder";
@import "pages/register";
@import "pages/checkout-v2";
@import "pages/collaborations";
@import "pages/account";
@import "pages/wishlist";
@import "pages/search";
@import "pages/mylon";
@import "pages/haus";
@import "pages/service";
@import "pages/jobs";
@import "pages/payment";
@import "pages/error";
@import "pages/lookbook";
@import "pages/journal";
@import "pages/download";
@import "pages/first";
@import "pages/farfetch";
@import "pages/newsletter";
@import "pages/mykita-haus-sample-sale";
@import "pages/faq";
@import "pages/sitemap";
@import "pages/rimowa-signup";

/* ==========================================================================

   Tools
 * toolbar, custom cursors, else

   ========================================================================== */

/* collection */
@import "tools/customcursor";


/* ==========================================================================

   Responsive
 * Includes all the RWD stuff, Breakpoints, Devices, Orientation

   ========================================================================== */

@import "responsive/modulescaling";
@import "responsive/responsive";

// Print stuff
@import "layout/print";

.xdebug-var-dump {
   font-size: 12px;
   font-family: Consolas, Lucida Console, monospace;
   line-height: 1.1;
   border: solid 1px #000;
   background-color: rgb(245, 245, 245);
   border-radius: 10px;
   padding: 10px;
   text-align: left;
   max-height: 100%;
   overflow: auto;
}
