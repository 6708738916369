.L-2-4-1 {
    position: relative;
    display: flex;

    .b1,
    .b2 {
        position: relative;
        width: 50%;
        height: auto;
    }

    @include breakpoint(desktop) {
        .b1 {
            padding-right: $borderExtended;
        }

        .b2 {
            padding-left: $borderExtended;
        }
    }

    @include breakpoint(mobile) {
        flex-direction: column;

        .b1,
        .b2 {
            width: 100%;
        }

        .b1 {
            padding-bottom: $borderExtended;
        }

        .b2 {
            padding-top: $borderExtended;
        }
    }
}