// 5 columns
@include breakpoint(five) {
    .L-6-2-1 {
        height: 9 * $moduleH;        
        
        // Quad
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;  
        }
        
        // Bigger
        .b2 {
            top: 0 * $moduleH;
            left: 2 * $moduleW;    
        }
        
        // Bigger
        .b3 {
            top: 3 * $moduleH;
            left: 0 * $moduleW;    
        }
        
        // Medium
        .b4 {
            top: 3 * $moduleH;
            left: 3 * $moduleW;    
        }
        
        // Bigger
        .b5 {
            top: 6 * $moduleH;
            left: 0 * $moduleW;
            
            .textWrapper {
                padding: 0 0.5 * $moduleW;
            }    
        }
        
        // Big Tower
        .b6 {
            top: 5 * $moduleH;
            left: 3 * $moduleW;     
        }
    }       
}

// 4 columns
@include breakpoint(four) {
    .L-6-2-1 {
        height: 9 * $moduleH;        
        
        // Quad
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;
            width: 1 * $moduleW;
            height: 3 * $moduleH;  
        }
        
        // Bigger
        .b2 {
            top: 0 * $moduleH;
            left: 1 * $moduleW;    
        }
        
        // Bigger
        .b3 {
            top: 3 * $moduleH;
            left: 0 * $moduleW;
            width: 2 * $moduleW;
            height: 2 * $moduleH;     
        }
        
        // Medium
        .b4 {
            top: 3 * $moduleH;
            left: 2 * $moduleW;    
        }
        
        // Bigger
        .b5 {
            top: 5 * $moduleH;
            left: 0 * $moduleW;
            width: 2 * $moduleW;
            height: 4 * $moduleH;    
        }
        
        // Big Tower
        .b6 {
            top: 5 * $moduleH;
            left: 2 * $moduleW;     
        }
    }         
}

// 3 columns
@include breakpoint(three) {
   .L-6-2-1 {
        height: 6 * $moduleH;        
        
        // Quad
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;
            width: 1 * $moduleW;
            height: 2 * $moduleH;  
        }
        
        // Bigger
        .b2 {
            top: 0 * $moduleH;
            left: 1 * $moduleW;
            width: 2 * $moduleW;
            height: 2 * $moduleH;    
        }
        
        // Bigger
        .b3 {
            top: 2 * $moduleH;
            left: 0 * $moduleW;
            width: 2 * $moduleW;
            height: 2 * $moduleH;     
        }
        
        // Medium
        .b4 {
            top: 2 * $moduleH;
            left: 2 * $moduleW;
            width: 1 * $moduleW;    
        }
        
        // Bigger
        .b5 {
            top: 4 * $moduleH;
            left: 0 * $moduleW;
            width: 2 * $moduleW;
            height: 2 * $moduleH;    
        }
        
        // Big Tower
        .b6 {
            top: 4 * $moduleH;
            left: 2 * $moduleW;     
        }
    }
}

// 2 columns
@include breakpoint(two) {
    .L-6-2-1 {
        height: 10 * $moduleH;        
        
        // Quad
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW; 
        }
        
        // Bigger
        .b2 {
            top: 2 * $moduleH;
            left: 0 * $moduleW;   
        }
        
        // Bigger
        .b3 {
            top: 6 * $moduleH;
            left: 0 * $moduleW;   
        }
        
        // Medium
        .b4 {
            top: 4 * $moduleH;
            left: 0 * $moduleW;
            width: 1 * $moduleW;    
        }
        
        // Bigger
        .b5 {
            top: 8 * $moduleH;
            left: 0 * $moduleW;  
        }
        
        // Big Tower
        .b6 {
            top: 4 * $moduleH;
            left: 1 * $moduleW;     
        }
    }      
}

// 1 columns
@include breakpoint(one) {
    .L-6-2-1 {
        height: 8 * $moduleH;        
        
        // Quad
        .b1 {
            top: 0 * $moduleH;
            left: 0 * $moduleW;
            height: 1 * $moduleH; 
        }
        
        // Bigger
        .b2 {
            top: 1 * $moduleH;
            left: 0 * $moduleW;   
        }
        
        // Bigger
        .b3 {
            top: 5 * $moduleH;
            left: 0 * $moduleW;   
        }
        
        // Medium
        .b4 {
            top: 2 * $moduleH;
            left: 0 * $moduleW; 
        }
        
        // Bigger
        .b5 {
            top: 6 * $moduleH;
            left: 0 * $moduleW;
            height: 2 * $moduleH;  
        }
        
        // Big Tower
        .b6 {
            top: 3 * $moduleH;
            left: 0 * $moduleW;     
        }
    }          
}
