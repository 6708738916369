.leica-lenses {
    max-width: 60rem;
    margin: 4rem auto 8rem auto;

    @include breakpoint(two) {
        max-width: 48rem;
    }

    @include breakpoint(one) {
        max-width: 24rem;
        margin-bottom: 4rem;
    }
}

.leica-lenses__list {
    display: flex;
    flex-direction: column;

    @include breakpoint(desktop) {
        gap: 1rem;
    }
}

.leica-lenses__list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(twofive) {
        &:not(:last-child):not(.leica-lenses__list-item--headline) {
            .leica-lenses__list-item-description {
                padding-top: 1.5rem;
            }
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
            .leica-lenses__list-item-description {
                padding-left: 1.5rem;
            }
        }
    }

    @include breakpoint(one) {
        flex-direction: column;

        &:not(:last-child) {
            flex-direction: column-reverse;
        }
    }
}

.leica-lenses__list-item + .leica-lenses__list-item {
    @include breakpoint(one) {
        margin-top: 2rem;
    }
}

.leica-lenses__list-item-lens {
    margin-right: 2rem;

    svg {
        width: $moduleW-rem;
        height: auto;
    }

    @include breakpoint(two) {
        svg {
            width: 18rem;
        }
    }

    @include breakpoint(one) {
        margin-right: 0;
    }
}

.leica-lenses__list-item--headline {
    justify-content: flex-start;

    h2 {
        @include typoZurich;
        @include typoZurich2-rem;
        margin-left: 28rem;
        text-align: left;
    }

    @include breakpoint(two) {
        h2 {
            margin-left: 22rem;
        }
    }

    @include breakpoint(one) {
        h2 {
            margin-left: 0;
        }
    }
}

.leica-lenses__list-item-description {
    margin-left: 2rem;
    flex: 1 1 auto;
    text-align: left;

    h3 {
        @include typoZurich;
        @include typoZurich2-rem;
    }

    p {
        @include typoZurich;
        @include typoZurich2-rem;
        text-transform: none;
        margin-bottom: 0;
    }

    @include breakpoint(one) {
        width: 100%;
        margin-left: 0;
    }
}