.countdown {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 6rem;

    .countdown__cell {
        display: inline-block;
        margin: 0 1.5rem;
    }

    .countdown__value,
    .countdown__label {
        display: block;
        width: 100%;
        margin: 0;
        text-align: center;
    }

    .countdown__value {
        @include typoZurich;
        @include typoZurich6-rem;
        line-height: 1.5;
    }

    .countdown__label {
        @include typoZurich;
        @include typoZurich0-rem;
        text-transform: uppercase;
    }

    @include breakpoint(one) {
        margin-bottom: 4rem;

        .countdown__cell {
            margin: 0 0.75rem;
        }

        .countdown__value {
            @include typoZurich5-rem;
        }
    }
}