.comparison {
    position: relative;
    width: 100%;
    height: 100%;
    user-select: none;

    .comparison__item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &:nth-child(1) {
            z-index: 2;
            clip-path: inset(0 50% 0 0);

            @supports (--css: variables) {
                clip-path: inset(0 var(--offset, 50%) 0 0);
            }
        }

        &:nth-child(2) {
            clip-path: inset(0 0 0 50%);

            @supports (--css: variables) {
                clip-path: inset(0 0 0 var(--offset, 50%));
            }
        }
    }

    .comparison__divider {
        position: absolute;
        top: 0;
        left: 50%;
        bottom: 0;
        z-index: 3;
        width: 2rem;
        margin-left: -1rem;
        cursor: col-resize;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            bottom: 0;
            width: 2px;
            background: #fff;
            margin-left: -1px;
        }
    }

    .comparison__handle {
        width: 2rem;
        height: 2rem;
        border: none;
        background: #fff;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -1rem;
        cursor: col-resize;
        transition: transform $trans-time--fast $trans-func--default;
        touch-action: pan-x;
    }

    &.is-dragging {
        .comparison__handle {
            transform: scale3d(1.5, 1.5, 1);
        }
    }
}