/* ==========================================================================
   Footer
   ========================================================================== */

footer {
	text-align: left;
	padding: 4rem 0 4rem 0;
	position: relative;
	background-color: $footerBackground;
	transition: transform $overlayTime $overlayFunc;

    .footer__row {
        display: flex;
        justify-content: space-between;

        @include breakpoint(mobile) {
            flex-wrap: wrap;
        }
    }

    .footer__box {
        display: inline-block;
        padding: $box-padding--default;
        text-align: left;

        @include breakpoint(two) {
            width: 22rem;
        }

        @include breakpoint(one) {
            width: 100%;
        }
    }

	.h3Wrapper {
        margin: 1rem 0;
    }

    .footer__link-list-item {
        display: block;
        padding-bottom: 0.5rem;

        span {
           text-transform: none;
        }
    }

    h3,
    a,
    p,
    span {
        @include typoZurich;
        @include typoZurich0-rem;
        text-transform: none;
    }

    h3 {
        text-transform: uppercase;
    }

    .newsletter-introduction {
        width: 20rem;
    }

    .copyright {
        margin-top: 2rem;
    }

    &.footer--checkout,
    &.footer--basket {
        .footer__box {
            padding: 0;
        }
    }
}