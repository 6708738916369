// Custom sizing for shop appointment modal
.info-layer.info-layer--shop-appointment {
    @include breakpoint(mobile) {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: none;
        box-shadow: none;

        .info-layer-wrapper {
            max-height: 100%;
            padding: 3rem 0;
        }
    }
}

.info-layer-content.info-layer-content--shop-appointment {
    text-align: left;

    .shop-appointment__headline, {
        @include typoZurich;
        @include typoZurich3-rem;
        margin-bottom: 0.25em;

        @include breakpoint(one) {
            @include typoZurich2-rem;
        }
    }

    .shop-appointment__text {
        @include typoZurich;
        @include typoZurich2-rem;
        margin-bottom: 0.25em;
        text-transform: none;

        @include breakpoint(one) {
            @include typoZurich1-rem;
        }
    }

    .shop-appointment__fields-headline {
        @include typoZurich;
        @include typoZurich2-rem;
        margin: 1.5rem 0 0.5rem 0;

        @include breakpoint(one) {
            @include typoZurich1-rem;
        }
    }

    .input-group--date {
        @include breakpoint(twofive) {
            width: calc(50% - 0.5rem);
            margin-right: 0.5rem;
        }

        @include breakpoint(one) {
            width: 100%;
            margin-bottom: 1rem;
        }
    }

    .input-group--time {
        @include breakpoint(twofive) {
            width: calc(50% - 0.5rem);
            margin-left: 0.5rem;
        }

        @include breakpoint(one) {
            width: 100%;
        }
    }

    .date-field-wrapper,
    .time-field-wrapper {
        width: 100%;
    }

    .form-row.form-row--action {
        margin-top: 2rem;
    }

    .shop-appointment__success {
        margin-top: 1rem;

        .form-row {
            margin: 2rem 0 0 0;
            text-align: center;

            .btn {
                display: inline-block;
                width: auto;
                padding-left: 4rem;
                padding-right: 4rem;
            }
        }
    }

    .shop-appointment__form:not(.is-success) {
        .shop-appointment__success {
            display: none;
        }
    }

    .shop-appointment__form.is-success {
        .shop-appointment__fields {
            display: none;
        }
    }
}
