// Info layer backgrop
.info-layer-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $backdrop-background-info-layer;
    backdrop-filter: $backdrop-blur;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: zIndex(info-layer);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity $defTransTime $defTransFunc 0s,
                visibility 0s $defTransFunc $defTransTime;

    // Use proper z index for newsletter overlay
    &.info-layer-overlay--newsletter,
    &.info-layer-overlay--newsletter-mr {
        z-index: zIndex(info-layer-newsletter);
    }

    // Use proper z index for async overlay
    &.info-layer-overlay--async {
        z-index: zIndex(info-layer-async);
    }

    &.is-visible,
    &[aria-hidden="false"] {
        transition-property: opacity;
        transition-delay: 0s;
        opacity: 1;
        visibility: visible;
        pointer-events: all;

        .info-layer {
            visibility: visible;
            pointer-events: all;
            transition: opacity $defTransTime $defTransFunc 0s,
                        transform $defTransTime $defTransFunc 0s;
            opacity: 1;

            &.info-layer--async,
            &.info-layer--nl-signup,
            &.info-layer--anniversary,
            &.info-layer--shades,
            &.info-layer--guard-one-certificates,
            &.info-layer--rx-hint,
            &.info-layer--no-store-hint,
            &.info-layer--related-products,
            &.info-layer--newsletter,
            &.info-layer--newsletter-mr {
                transform: none;
            }
        }
    }
}

// Info layer
.info-layer {
    box-sizing: border-box;
    transform: translate3d(0, 2rem, 0);
    box-shadow: 0 0 5px 1px rgba(50, 50, 50, 0.15);
    transition: opacity $defTransTime $defTransFunc 0s,
                transform $defTransTime $defTransFunc 0s,
                visibility 0s $defTransFunc $defTransTime;
    display: inline-block;
    width: 2 * $moduleW-rem;
    max-width: 90%;
    background-color: white;
    padding: 4rem 5rem;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    overflow: auto;
    overflow-x: hidden;
    max-height: 80vh;
    pointer-events: none;

    .info-layer-close {
        position: absolute;
        top: 1.5rem;
        right: 2rem;
        cursor: pointer;
        width: auto;
        height: auto;
        line-height: 1;
        border: none;
        background: none;
        padding: 0;
        z-index: 10;

        .ico-myk {
            font-size: 1.5rem;
        }

        .svg-ico {
            width: 1.8rem;
            height: 1.8rem;
        }
    }

    .btn-close-layer {
        position: absolute;
        top: 2rem;
        right: 3rem;
        width: 2rem;
        height: 2rem;
        z-index: 1;

        .svg-ico {
            width: 100%;
            height: 100%;
        }
    }

    .info-layer-image {
        position: relative;
        padding-bottom: 66.66666%;
        margin-bottom: 2em;

        .info-layer-image-box {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
        }
    }

    .info-layer-header {
        box-sizing: border-box;
        margin-bottom: 2rem;

        h3 {
            @include typoZurich;
            @include typoZurich1-rem;
            text-align: left;
            padding-right: 4rem;
        }

        @include breakpoint(one) {
            padding: 0 2rem;
        }
    }

    .info-layer-content {
        margin-bottom: 2em;

        h2 {
            @include typoZurichBd;
            @include typoZurich2-rem;
        }

        p {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
            margin-bottom: 0;
        }
    }

    .info-layer-controls {
        text-align: center;

        .btn {
            display: inline-block;
            width: 100%;
            max-width: 14em;
        }
    }

    .info-layer-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 32px;
        height: 32px;
        margin: -16px 0 0 -16px;
        opacity: 1;
        pointer-events: none;
        background: #ffffff url('../images/icons/loader_32px_t.gif') 50% 50% no-repeat;
        background-size: 32px 32px;
        transition: opacity $defTransTime $defTransFunc;
    }

    // Country info layer
    &.info-layer--country {
        position: absolute;
        top: 14rem;
        left: 50%;
        max-height: none;
        box-shadow: 0 0 5px 1px rgba(50, 50, 50, 0.15);

        h2 + p {
            margin-top: 1rem;
        }

        @include breakpoint(mobile) {
            top: 8rem;
        }

        @include breakpoint(one) {
            padding: 2rem 3rem;
            width: $moduleW-rem;

            .info-layer-close {
                top: 0.5em;
                right: 0.75em;
            }

            .info-layer-controls .btn:first-child {
                margin-bottom: 1em;
            }
        }
    }

    // Shades overlay / Related Products / Guard One Certificates
    &.info-layer--shades,
    &.info-layer--guard-one-certificates,
    &.info-layer--related-products {
        transform: translate3d(0, 2rem, 0);
        display: inline-block;
        position: relative;
        width: auto;
        max-height: none;
        overflow: hidden;
        padding: 2rem 0 0 0;

        .info-layer-header {
            padding: 0 3rem;

            @include breakpoint(two) {
                max-width: 30rem;
            }

            @include breakpoint(one) {
                max-width: 26rem;
            }
        }

        .info-layer-content {
            overflow: auto;
            overflow-x: hidden;
            max-height: 60vh;
            margin-bottom: 0;
            overscroll-behavior: contain;
            -webkit-overflow-scrolling: touch;
        }

        .info-layer-content {
            padding: 0 3rem 2rem 3rem;
        }

        .info-layer-content-inner {
            width: 3 * $moduleW-rem;

            @include breakpoint(three) {
                width: 2 * $moduleW-rem;
            }

            @include breakpoint(two) {
                width: $moduleW-rem;
            }

            @include breakpoint(one) {
                width: $moduleW-rem;
            }
        }

        @include breakpoint(one) {
            padding: 2rem 0 0 0;

            .info-layer-content {
                padding: 0 0 2rem 0;
            }

            .info-layer-header {
                padding: 0 2rem;
            }

            .btn-close-layer {
                right: 2rem;
            }
        }
    }

    // Info layer that is filled async
    &.info-layer--async {
        transform: translate3d(0, 2rem, 0);
        position: relative;
        width: 2 * $moduleW-rem;
        min-height: 16rem;
        overflow: hidden;
        max-height: none;
        padding: 0;
        box-sizing: border-box;

        * {
            box-sizing: border-box;
        }

        .info-layer-wrapper {
            padding: 4rem 0;
            overflow: auto;
            overflow-x: hidden;
            overscroll-behavior: contain;
            -webkit-overflow-scrolling: touch;
            max-height: 70vh;
        }

        .info-layer-content {
            margin-bottom: 0;
            padding: 0 4rem;
            opacity: 0;
            transition: opacity $defTransTime $defTransFunc,
                        transform $defTransTime $defTransFunc;
        }

        @include breakpoint(mobile) {
            .info-layer-wrapper {
                padding: 0;
                max-height: 80vh;
            }

            .info-layer-content {
                padding: 0 3rem;
            }
        }

        @include breakpoint(one) {
            width: $moduleW-rem;
        }

        &.is-loaded {
            .info-layer-content {
                opacity: 1;
            }
        }
    }

    // Guard One Certificates
    &.info-layer--guard-one-certificates {
        .guard-one-certificates {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: -2rem;
        }

        .guard-one-certificates__item {
            width: 16rem;
            margin-bottom: 2rem;
        }

        .guard-one-certificates__item-image {
            width: 16rem;
            height: 16rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .guard-one-certificates__item-title {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
            text-align: left;
            margin: 0.5rem 0 0 0;
        }

        @include breakpoint(three) {
            .guard-one-certificates__item {
                width: 14rem;
            }

            .guard-one-certificates__item-image {
                width: 14rem;
                height: 14rem;
            }
        }

        @include breakpoint(two) {
            .guard-one-certificates__item {
                width: 11rem;
            }

            .guard-one-certificates__item-image {
                width: 11rem;
                height: 11rem;
            }
        }

        @include breakpoint(one) {
            .guard-one-certificates {
                margin: 0 1rem;
            }
            .guard-one-certificates__item {
                width: 10rem;
            }

            .guard-one-certificates__item-image {
                width: 10rem;
                height: 10rem;
            }
        }
    }

    &.is-visible {
        transform: translate3d(0, 0, 0);
        transition: opacity $defTransTime $defTransFunc 0s,
                    transform $defTransTime $defTransFunc 0s;
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }

    // Busy state
    &[aria-busy="false"] {
        .info-layer-loader {
            opacity: 0;
        }
    }
}


// Newsletter Popup overlay
.info-layer.info-layer--newsletter,
.info-layer.info-layer--newsletter-mr {
    transform: translate3d(0, 2rem, 0);
    display: inline-block;
    position: relative;
    width: 3 * $moduleW-rem;
    max-height: none;
    overflow: hidden;
    padding: 0;

    // Typography
    h1 {
        @include typoZurichBd;
        @include typoZurich4-rem;
        margin-bottom: 2rem;
    }

    h2 {
        @include typoZurichBd;
        @include typoZurich2-rem;
        margin-bottom: 1rem;
    }

    h4 {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: uppercase;
        margin-bottom: 2rem;
    }

    p {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;

        &.newsletter-layer-introduction,
        &.newsletter-layer-error-message {
            margin-bottom: 2rem;
            min-height: 5rem;
        }

        &.newsletter-layer-policy-hint {
            @include typoZurich0-rem;
            margin-top: 2rem;
        }
    }

    label {
        font-size: 1.1rem;
        line-height: 2;
        text-transform: none;
    }

    .info-layer-close {
        top: 2rem;
    }

    .info-layer-content {
        display: flex;
        margin: 0;

        .info-layer-column {
            width: 50%;
        }
    }

    .info-layer-column--right {
        opacity: 1;
        transition: opacity $defTransTime $defTransFunc;

        .is-loading-ajax & {
            opacity: 0.3;
            pointer-events: none;
        }
    }

    .newsletter-layer-teaser {
        height: 3 * $moduleH-rem;

        &.newsletter-layer-teaser--desktop {
            height: 100%;
            min-height: 52rem;

            @include breakpoint(mobile) {
                display: none;
            }
        }

        &.newsletter-layer-teaser--mobile {
            @include breakpoint(desktop) {
                display: none;
            }
        }
    }

    .newsletter-layer-content-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .newsletter-layer-content-main {
            //flex: 1;
            padding: 2rem;
            text-align: left;
        }

        .newsletter-layer-content-actions {
            padding: 2rem;
        }
    }

    // Reponsive behavior
    @include breakpoint(mobile) {
        width: $moduleW-rem;

        .info-layer-content {
            display: block;
            max-height: 80vh;
            overflow: auto;
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch;

            .info-layer-column {
                width: 100%;
            }
        }

        .newsletter-layer-content-wrapper {
            display: block;
            height: auto;
        }
    }

    @include breakpoint(two) {
        width: 2 * $moduleW-rem;

        .newsletter-layer-teaser--mobile  {
            height: 2 * $moduleW-rem;
        }
    }

    @include breakpoint(one) {
        width: $moduleW-rem;

        .newsletter-layer-teaser--mobile  {
            height: $moduleW-rem;
        }
    }
}

// News Newsletter Signup
.info-layer.info-layer--nl-signup {
    transform: translate3d(0, 2rem, 0);
    display: inline-block;
    position: relative;
    width: 1.5 * $moduleW-rem;
    max-height: none;
    overflow: hidden;
    padding: 0;
    z-index: zIndex(info-layer-nl-signup);

    .info-layer-close {
        top: 2rem;
    }

    .info-layer-content {
        margin-bottom: 0;
        overflow: auto;
        overflow-x: hidden;
        max-height: 80vh;
        overscroll-behavior: contain;
    }

    // Reponsive behavior
    @include breakpoint(mobile) {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: none;
        box-shadow: none;

        .nl-signup__form {
            padding: 2.5rem 3rem 3rem 3rem;
        }

        .info-layer-content {
            display: block;
            -webkit-overflow-scrolling: touch;
            max-height: 100%;
        }

        .info-layer-wrapper {
            max-height: 100%;
        }
    }
}

// RX Hint / Non-webshop hint
.info-layer.info-layer--rx-hint,
.info-layer.info-layer--no-store-hint {
    transform: translate3d(0, 2rem, 0);
    display: inline-block;
    position: relative;
    width: auto;
    max-height: none;
    overflow: hidden;

    .info-layer-content-inner {
        width: 1.5 * $moduleW-rem;
    }

    h3 {
        @include typoZurichBd;
        @include typoZurich2-rem;
        margin-bottom: 1rem;
        text-align: center;
        padding-right: 0;
    }

    p {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
    }

    p + p {
        margin-top: 1rem;
    }

    @include breakpoint(one) {
        padding: 2rem 3rem;
        width: $moduleW-rem;

        .info-layer-content-inner {
            width: 100%;
        }

        .btn-close-layer {
            right: 2rem;
        }
    }
}