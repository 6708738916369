.no-touch {
	#tooltip {
        display: inline-block;
        position: absolute;
        z-index: zIndex(tooltip);
        top: 0;
        left: -9999px;
        opacity: 0;
        background-color: white;
        padding: 0.4em;
        box-shadow: 0 0 4px 1px rgba(50, 50, 50, 0.15);
        max-width: 18rem;

        transition-property: opacity;
        transition-duration: $toolsHeaderTime;
        transition-timing-function: $toolsHeaderFunc;

        span {
            @include typoZurich;
            @include typoZurichS2-rem;
            text-align: left;
            text-transform: none;
            display: inline-block;
            user-select: none;
        }
    }

    #tooltip.active {
        opacity: 1;
    }
}

.touch #tooltip {
    display: none;
}

.tooltip {
    transition-property: opacity, visibility;
    transition-duration: $defTransTime, 0s;
    transition-timing-function: linear;
    transition-delay: 0s, $defTransTime;
    box-shadow: 0 0 4px 1px rgba(50, 50, 50, 0.15);
    position: absolute;
    display: inline-block;
    width: $moduleW-rem + 4rem;
    padding: 1.5rem 2rem;
    top: 0;
    left: $moduleW-rem + 9.5rem;
    z-index: 100;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    &:after {
        transition-property: top;
        transition-duration: $fastTransTime;
        transition-timing-function: $defTransFunc;
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: 2.5rem;
        left: 0;
        box-sizing: border-box;
        border-style: solid;
        border-width: 1rem;
        border-color: transparent transparent #fff #fff;
        transform-origin: 0 0;
        transform: rotate(45deg);
        box-shadow: -2px 2px 3px 0 rgba(50, 50, 50, 0.15);
    }

    &.is-visible {
        transition-property: opacity;
        transition-delay: 0s;
        visibility: visible;
        opacity: 1;
        pointer-events: all;
    }

    h3 {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
    }

    p {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;

        &:last-child {
            margin-bottom: 0;
        }
    }

    // Small website hint
    &.tooltip--function-hint {
        width: 16rem;
        padding: 1rem;
        left: auto;
        top: auto;
        margin-left: 2rem;
        margin-top: -1rem;
        cursor: default;

        &:after {
            top: 1rem;
            border-width: 0.5rem;
        }

        p {
            @include typoZurich;
            @include typoZurichS2-rem;
            text-transform: none;
            margin-bottom: 0;
            padding-right: 3rem;
        }

        .btn--link,
        .link-underlined {
            @include typoZurich;
            @include typoZurichS2-rem;
            text-transform: none;
            line-height: 1.1;
        }

        .btn--close-tooltip {
            position: absolute;
            top: 1rem;
            right: 1rem;
            width: 1rem;
            height: 1rem;

            .svg-ico {
                width: 100%;
                height: 100%;
            }
        }

        @include breakpoint(one) {
            left: 4rem;
            margin-left: -2rem;
            margin-top: 2.5rem;

            &:after {
                top: 0;
                left: 2rem;
                transform: rotate(135deg);
            }
        }
    }
}
