/* ==========================================================================
   Payment
   ========================================================================== */

.checkout-process--payment {
    text-align: left;

    .h2Wrapper {
        margin-bottom: 1em;

        h2 {
            @include typoZurich;
            @include typoZurich1-rem;
        }
    }

    .textWrapper {
        max-width: 48em;

        p,
        span {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
            text-align: left;
        }
    }

    .paymentFrame {
        padding-top: 2em;
    }

    iframe {
        width: 100%;
        height: 340px;
        border: none;
    }

    .bottomText {
        margin-bottom: 1em;
    }
}
