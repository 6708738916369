h1,
h2,
h3 {
    @include typoZurich;
    @include typoZurich3-rem;
    margin-bottom: 0;
    text-align: inherit;

    @include breakpoint(five) {
        @include typoZurich4-rem;
    }

    @include breakpoint(one) {
        @include typoZurich1-rem;
    }
}

p,
li {
    @include typoZurich;
    @include typoZurich3-rem;
    text-transform: none;
    text-align: inherit;

    @include breakpoint(five) {
        @include typoZurich;
        @include typoZurich4-rem;
        text-transform: none;
    }

    @include breakpoint(one) {
        @include typoZurich1-rem;
    }

    a,
    .text-link {
        display: inline;
        font-size: inherit;
        font-weight: inherit;
    }
}

p:not(:last-child) {
    margin-bottom: 0.2rem;
}

p:last-child {
    margin-bottom: 0;
}

.text-link {
    @include typoZurich;
    @include typoZurich1-rem;
    text-transform: none;
    line-height: 1.1;
}

address {
    font-style: normal;
    text-align: left;
}

.shopfinder-detail {
    text-align: left;
    margin-bottom: 4rem;

    .shopfinder-detail-name,
    .shopfinder-detail-intro,
    .shopfinder-detail-about-headline {
        box-sizing: border-box;
        width: 100%;
        padding: 0 $box-padding--default;
    }

    .shopfinder-detail-name {
        margin-top: 4rem;

        h1 {
            @include typoZurich;
            @include typoZurich6-rem;
            text-align: center;
            text-wrap: balance;
        }

        @include breakpoint(mobile) {
            h1 {
                @include typoZurich3-rem;
            }
        }

        @include breakpoint(desktop) {
            margin-top: 8rem;
        }
    }

    .shopfinder-detail-information {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 6rem;

        @include breakpoint(mobile) {
            margin-top: 4rem;
        }
    }

    .shopfinder-detail-information-item {
        box-sizing: border-box;
        display: inline-block;
        padding: $box-padding--default 1rem 2rem $box-padding--default;
        width: 33%;

        @include breakpoint(five) {
            max-width: $moduleW-rem * 1.3;
        }

        @include breakpoint(four) {
            max-width: $moduleW-rem * 1.1;
        }

        @include breakpoint(three) {
            max-width: $moduleW-rem * 0.95;
        }

        @include breakpoint(two) {
            width: $moduleW-rem * 0.95;

            &:last-child {
                width: 100%;
            }

            &.shopfinder-detail-information-item--care {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 0;

                > div {
                    box-sizing: border-box;
                    width: $moduleW-rem * 0.95;
                    padding: $box-padding--default 1rem 2rem $box-padding--default;
                }
            }
        }

        @include breakpoint(one) {
            width: 100%;
            padding-right: $box-padding--default;
        }
    }

    .shopfinder-detail-information-links {
        margin-top: 2rem;

        li {
            display: block;

            &:not(:last-child) {
                margin-bottom: 0.5rem;
            }
        }

        @include breakpoint(mobile) {
            margin-top: 1rem;
        }
    }

    .shopfinder-detail-view-on-map--mobile {
        margin-bottom: 2rem;

        @include breakpoint(desktop) {
            display: none;
        }
    }



    .shopfinder-detail-about-headline + .shopfinder-detail-intro {
        margin-top: 1rem;
    }

    .shopfinder-detail-intro p {
        @include typoZurich;
        @include typoZurich3-rem;
        text-transform: none;
        text-align: inherit;
        margin-bottom: 0;

        @include breakpoint(five) {
            @include typoZurich4-rem;
        }

        @include breakpoint(one) {
            @include typoZurich1-rem;
        }
    }

    .shopfinder-detail-fb-messenger {
        a {
            position: relative;
            margin-left: 1.5em;
            display: inline-block;
        }

        .svg-ico {
            position: absolute;
            left: -1.5em;
            top: 50%;
            margin-top: -0.5em;
            width: 1em;
            height: 1em;
        }
    }
}

.shopfinder-detail-teaser {
    box-sizing: border-box;
    position: relative;
    padding: 0;
    width: 100%;
    margin: 0;

    .box {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
    }

    .flickity-prev-next-button.next {
        //@include breakpoint(five) {
        //    right: -6rem;
        //}
        //
        //@include breakpoint(four) {
        //    right: -6rem;
        //}
        //
        //@include breakpoint(three) {
        //    right: -4rem;
        //}
    }

    .flickity-prev-next-button.previous {
        //@include breakpoint(five) {
        //    left: -6rem;
        //}
        //
        //@include breakpoint(four) {
        //    left: -6rem;
        //}
        //
        //@include breakpoint(three) {
        //    left: -4rem;
        //}
    }

    @include breakpoint(mobile) {
        width: 100%;
        padding-bottom: 100%;
        height: auto;

        > .box {
            position: absolute;
            inset: 0 0 0 0;
        }
    }

    @include breakpoint(desktop) {
        height: calc(100vh - 20rem);
    }
}

.shopfinder-cms-content {
    .L-S-6 {
        @media (orientation: landscape) and (min-width: 480px) {
            height: auto;
            display: block;
            padding-bottom: 40%;

            .b1,
            .b2 {
                position: absolute;
                top: 0;
                width: 50%;
                height: 100%;
            }

            .b1 {
                left: 0;
            }

            .b2 {
                right: 0;
            }
        }
    }

    .L-1-Def.mykitaContainer {
        @include breakpoint(desktop) {
            width: 100%;
            margin-left: 4rem;
            margin-right: 4rem;

            .box.gridBorder {
                padding: 0;
            }
        }
    }
}

.shopfinder-about-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 4rem;

    @include breakpoint(fourfive) {
        margin-top: 6rem;
    }
}

.store-detail-newsletter-signup {
    h3 {
        margin-bottom: 0.25em;
    }

    p {
        text-transform: none;
    }

    &[data-state="signup"] {
        .store-detail-newsletter-signup__state--success {
            display: none;
        }
    }

    &[data-state="success"] {
        .store-detail-newsletter-signup__state--signup {
            display: none;
        }
    }
}

.store-detail-newsletter-signup__form {
    opacity: 1;
    transition: opacity $trans-time--fast $trans-func--default;

    &[aria-busy="true"] {
        opacity: 0.3;
        pointer-events: none;
        cursor: wait;
    }

    @include breakpoint(mobile) {
        margin-top: 1rem;
    }
}

.store-detail-newsletter-signup__form-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    button {
        text-transform: uppercase;
    }
    input,
    button {
        width: 100%;
    }
}

.shopfinder-map--mykita-shop {
    margin-top: 2rem;

    @include breakpoint(desktop) {
        margin-top: 0;
    }
}

.store-detail-information {
    width: 100%;
    padding: $box-padding--default;

    @include breakpoint(desktop) {
        margin-top: 2rem;
    }
}

.store-detail-information__row {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 4rem;

    @include breakpoint(desktop) {
        flex-direction: row;
    }

    @include breakpoint(fourfive) {
        margin-top: 6rem;
    }
}

.store-detail-information__title {
    @include breakpoint(desktop) {
        flex-basis: 33.33%;
    }
}

.store-detail-information__content {
    &.store-detail-information__content--multi-column {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include breakpoint(fourfive) {
            gap: 4rem;
            flex-direction: row;
        }
    }

    &.store-detail-information__content--single-column {
        .store-detail-information__column {
            @include breakpoint(desktop) {
                width: 50%;
                padding-right: 2rem;
            }
        }
    }

    @include breakpoint(mobile) {
        margin-top: 0.5rem;
    }

    @include breakpoint(desktop) {
        flex-basis: 66.66%;
    }
}

.store-detail-information__column {
    @include breakpoint(fourfive) {
        flex-basis: 50%;
    }
}

.store-detail-information__content-columns {
    @include breakpoint(fourfive) {
        columns: 2;
        column-gap: 4rem;
    }
}

.store-detail-information__row--appointments {
    .btn--cta {
        width: 100%;
    }

    .btn--cta + .btn--cta {
        margin-top: 0.5rem;
    }

    @include breakpoint(desktop) {
        &:has(.btn--cta:only-child) {
            align-items: center;
        }
    }
}

.store-detail-about {
    margin-top: 4rem;
    margin-bottom: 2rem;

    @include breakpoint(desktop) {
        margin-left: auto;
        margin-right: auto;
        margin-top: 8rem;
    }
}

.store-detail-about__content--mobile {
    @include breakpoint(desktop) {
        display: none;
    }
}

.store-detail-about__content--mobile + .store-detail-about__content--desktop {
    @include breakpoint(mobile) {
        display: none;
    }
}

.store-detail-mycare {
    width: 100%;
    display: flex;
    margin-top: 4rem;
    flex-direction: column;

    @include breakpoint(desktop) {
        flex-direction: row;
        height: 80vh;
        align-items: flex-end;
        margin-top: 6rem;
    }

    @include breakpoint(fourfive) {
        margin-top: 6rem;
    }
}

.store-detail-mycare__content {
    text-align: left;
    box-sizing: border-box;

    @include breakpoint(one) {
        padding: $box-padding--default;
        width: $moduleW-rem;
        margin: 0 auto;
    }

    @include breakpoint(two) {
        padding: $box-padding--default;
        width: 2 * $moduleW-rem;
        margin: 0 auto;
    }

    @include breakpoint(desktop) {
        padding: 4rem;
        flex-basis: 50%;
    }
}

.store-detail-mycare__image {
    @include breakpoint(mobile) {
        height: 40rem;
        margin-top: 2rem;
    }

    @include breakpoint(desktop) {
        flex-basis: 50%;
        height: 100%;
    }
}

.store-detail-map-intro {
    text-align: left;
    margin-top: 4rem;
    @include breakpoint(desktop) {
        display: none;
    }
}