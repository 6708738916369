/* ==========================================================================
   Account Pages
   ========================================================================== */

.account-overview {
    text-align: left;
    margin-top: 8rem;

    @include breakpoint(mobile) {
        margin-top: 6rem;
    }

    .h1Wrapper {
        margin-bottom: 8em;

        h1 {
            @include typoZurichBd;
            @include typoZurich3-rem;


        }

        @include breakpoint(mobile) {
            margin-bottom: 4em;
        }
    }

    .last-orders {
        list-style-type: none;

        li {
            display: block;
            padding-bottom: 0.5em;

            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
        }

        .last-orders-view-all {
            padding-top: 1.5em;
        }
    }

    .payLogoSmall,
    .payment-logo--small {
        max-width: 4rem;
    }
}

.address-box,
.payment-details {
    min-height: 12em;
    margin-bottom: 4em;

    @include breakpoint(one) {
        min-height: 0;
    }

    p {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        margin-bottom: 0;
    }
}

.payment-details p {
    margin-bottom: 1em;
}

.touch {
    .account-overview {
        .last-orders li {
            padding: 0.5em 0;
        }
    }
}

/* Lost password
   ========================================================================== */
.lostPassword {
    .gridFormCell,
    .gridFormCell.smallCell {
        height: auto;
        padding-bottom: 4em;
    }

    .actions {
        .backButton {
            float: right;
        }

        .sendButton {
            float: left;
        }
    }

    .h2Wrapper {
        display: inline-block;
        width: 30em;
    }

    .inputHintWrapper,
    input[type="text"] {
        width: 30em;
    }
}

@include breakpoint(one) {
    .lostPassword {
        .h2Wrapper,
        .inputHintWrapper,
        input[type="text"] {
            width: 24em;
        }
    }
}

/* Changeformulars Shipping, Billing, Payment ...
   ========================================================================== */
.accountChangeContainer {
    h2 {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        margin-bottom: 1em;
        text-align: left;
    }

    .actions {
        .button {
            width: 24em;
        }

        .buttonBack {
            float: left;
        }

        .primary {
            float: right;
            margin-right: 6em;
        }
    }
}

@include breakpoint(two) {
    .accountChangeContainer {
        .actions {
            .button {
                width: 48em;
            }

            .buttonBack {
                float: none;
                border-top: none;
            }

            .primary {
                float: none;
                margin: 0;
            }
        }
    }
}

@include breakpoint(one) {
    .accountChangeContainer {
        .actions {
        	margin-top: 2em;

            .button {
                width: 24em;
            }

            .buttonBack {
                float: none;
                border-top: none;
            }

            .primary {
                float: none;
                margin: 0;
            }
        }
    }
}

/* Site Login
   ========================================================================== */
.siteLogin {
    .gbox {
        min-height: 30em;
        position: relative;

    	.action {
    	   position: absolute;
    	   display: inline-block;
    	   width: 100%;
    	   left: 0;
    	   bottom: 0.5em;
    	}
    }

    .login-box {
        @include breakpoint(one) {
            float: none;
        }

        .default-registration,
        .registration-without-account {
            margin-bottom: 0.5em;
        }
    }

    .register-box {
        float: left;

        @include breakpoint(one) {
            float: none;
        }
    }

    .site-login-box,
    .lost-pw-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .site-login-box {
        max-height: 36em;
        opacity: 1;
    }

    .lost-pw-box {
        max-height: 0;
        opacity: 0;

        .inputHintWrapper {
            width: 100%;
        }

        .lostPwHintBox .inputHintWrapper {
            margin-bottom: 1em;
        }
    }

    &.show-lost-pw {
        .lost-pw-box {
            max-height: 36em;
            opacity: 1;
        }

        .site-login-box {
            max-height: 0;
            opacity: 0;
        }
    }
}

/* Payment and shipping
   ========================================================================== */
.payment-listing,
.dispatch-listing {
    @extend %cf;
    margin: 0;
    padding: 0;
    list-style-type: none;

    .gbox {
        min-height: 8rem;

        @include breakpoint(one) {
            min-height: 0;
            margin-bottom: 2rem;
        }
    }

    .gbox-left,
    .gbox-right {
        float: left;
    }

    .gbox-left {
        @include breakpoint(desktop) {
            margin-right: 8rem;
        }

        @include breakpoint(two) {
            margin-right: 4rem;
        }
    }

    .payment-entry,
    .dispatch-entry {
        display: block;
        width: 100%;
        position: relative;

        &:not(:first-child) {
            margin-top: 1rem;
        }
    }
}

.payment-listing,
.dispatch-listing,
.panel-summary-payment,
.panel-summary-dispatch {
    p,
    .panel-content p {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        padding-bottom: 0.5rem;
    }

    .name {
        padding-bottom: 0.5rem;
    }

    .description {
        p {
            padding-bottom: 0;
        }
    }

    .payLogoSmall {
        max-width: 4rem;
    }
}

/* Account overview
   ========================================================================== */
.accountContainer {
	text-align: left;
    margin-top: 8rem;

    @include breakpoint(mobile) {
        margin-top: 6rem;
    }

	.h1Wrapper {
       margin-bottom: 2em;

       h1 {
           @include typoZurichBd;
           @include typoZurich3-rem;
       }
    }

	.h2Wrapper {
	   margin-bottom: 4em;

	   h2 {
    	   @include typoZurich;
    	   @include typoZurich1-rem;
    	   text-transform: none;
	   }
	}

    .lastOrders li {
    	display: block;
        padding-bottom: 0.5em;
    }

    .successMessage {
        margin-bottom: 4em;

        span {
           @include typoZurich;
           @include typoZurich1-rem;
           text-transform: none;
        }
    }

    p {
       @include typoZurich;
       @include typoZurich0-rem;
       text-transform: none;
       margin-bottom: 0;
    }
}

.accountOverview {
    .changeBox {
        text-align: left;

        h2 {
            @include typoZurich;
            @include typoZurich1-rem;
            margin-bottom: 1.5em;
        }

        h3 {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: uppercase;
            margin-bottom: 1em;
        }

        h4 {
            @include typoZurich;
            @include typoZurich1-rem;
            margin-bottom: 0.5em;
        }

        p {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
            margin-bottom: 0;
        }

        .actions {
            position: absolute;
            display: block;
            width: 100%;
            left: 0;
            bottom: 0;

            a {
                margin-right: 2em;
            }
        }
    }

    .nameAddress,
    .emailAddress {
       min-height: 6em;
       position: relative;
    }

    .invoiceAddress,
    .shippingAddress {
        min-height: 12em;
        position: relative;
    }

    .paymentSelection {
        min-height: 12em;
        position: relative;

        p {
            margin-bottom: 1em;
        }
    }

    .newsletter p {
        margin-bottom: 1em;
    }
}

@include breakpoint(one) {
    .accountOverview {
        .nameAddress,
        .emailAddress,
        .invoiceAddress,
        .shippingAddress {
            min-height: 0;
            margin-bottom: 4em;

            .actions {
                position: static;
            }
        }
    }
}

/* Account overview
   ========================================================================== */
.retailer-sign-up-box {
    position: relative;
}

.retailer-sign-up-info {
    text-align: left;

    p {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;
        text-align: left;
    }
}

.tooltip-retailer-signup {
    @include breakpoint(two) {
        width: $moduleW - 4em;
        top: 1em;
        left: $moduleW - 0.5em;

        &:after {
            margin-top: -1em;
        }
    }

    &.password-focussed:after {
        top: 8.5em;
    }
}

/* Press office changes
   ========================================================================== */
.address-box.press-office {
    .press-office-name,
    .press-office-address,
    .press-office-contact,
    .press-office-tel {
        margin-bottom: 0.5em;
    }
}



/* Orders overview
   ========================================================================== */
.accountContainer .ordersListing {
    margin-top: 4rem;
}

.order {
    margin-bottom: 8rem;
}


// Order header
.order__header {
    margin-bottom: 2rem;

    h1 {
        @include typoZurichBd;
        @include typoZurich3-rem;
    }

    @include breakpoint(mobile) {
        h1 {
            @include typoZurichBd;
            @include typoZurich2-rem;
        }
    }
}

// Order info
.order__info {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4rem;

    .order__info__box {
        width: $moduleW-rem;
        display: inline-block;
        padding-bottom: 3rem;

        .order__info__box__wrapper {
            padding-top: 1rem;
            max-width: 20rem;
            word-wrap: break-word;
        }

        h3 {
            @include typoZurich;
            @include typoZurich1-rem;
        }

        p {
            margin-bottom: 0;
        }
    }
}

// Order positions
.order__positions {
    // Row definitions
    .order__positions__row {
        display: flex;
        flex-wrap: wrap;

        &.order__positions__row--head {
            padding-bottom: 1rem;
            border-bottom: solid 1px $border-color-dark;
        }

        &.order__positions__row--item {
            padding: 1rem 0;
        }

        &.order__positions__row--foot {
            padding-top: 1rem;
            border-top: solid 1px $border-color-dark;
            flex-direction: column;
        }
    }

    // Cell definitions
    .order__positions__cell {
        position: relative;
        display: flex;
        align-items: center;
        box-sizing: border-box;

        &.order__positions__cell--product {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        &.order__positions__cell--quantity {
            width: 10rem;
        }

        &.order__positions__cell--unit-price {
            width: 12rem;
        }

        &.order__positions__cell--total {
            width: 12rem;
        }

        &.order__positions__cell--sum {
            padding-bottom: 1rem;
            border-bottom: solid 1px $border-color-dark;
        }
    }

    // Subcell definitions
    .order__positions__subcell {
        position: relative;
        display: inline-block;
        box-sizing: border-box;

        &.order__positions__subcell--image {
            width: 12rem;
            height: 8rem;
        }

        &.order__positions__subcell--details {
            flex: 1 1 auto;
        }

        &.order__positions__subcell--label {
            flex: 1 1 auto;

            .order__positions__label {
                text-transform: none;
            }
        }

        &.order__positions__subcell--value {
            width: 12rem;

            .order__positions__label {
                text-transform: none;
            }
        }

        p {
            margin-bottom: 0;
        }

        .box-link:hover {
            img {
                opacity: 0.5;
            }
        }

        @include breakpoint(desktop) {
            &.order__positions__subcell--mobile-label {
                display: none;
            }
        }
    }

    // Label definitions
    .order__positions__label {
        @include typoZurich;
        @include typoZurich1-rem;
        text-transform: none;

        &.order__positions__label--bold {
            @include typoZurichBd;
            @include typoZurich1-rem;
        }

        &.order__positions__label--small {
            @include typoZurich;
            @include typoZurich0-rem;
            text-transform: none;
        }
    }

    // Special desktop styling
    @include breakpoint(desktop) {
        .order__positions__row {
            &.order__positions__row--foot {
                & > .order__positions__cell {
                    width: 24rem;
                    margin-left: auto;
                }
            }
        }
    }

    // General responsive sizing
    @include breakpoint(mobile) {
        .order__positions__cell {
            &.order__positions__cell--unit-price,
            &.order__positions__cell--total {
                display: flex;
                align-items: center;
            }

            &.order__positions__cell--quantity,
            &.order__positions__cell--unit-price,
            &.order__positions__cell--total {
                .order__positions__subcell {
                    width: 50%;
                }

                .order__positions__subcell--price {
                    text-align: right;
                }
            }

            &.order__positions__cell--quantity {
                justify-content: flex-end;

                .order__positions__subcell--quantity {
                    justify-content: flex-end;
                }
            }
        }

        .order__positions__subcell {
            &.order__positions__subcell--value {
                text-align: right;
            }
        }
    }

    @include breakpoint(two) {
        .order__positions__row--head {
            .order__positions__cell {
                &.order__positions__cell--unit-price,
                &.order__positions__cell--total {
                    display: none;
                }
            }
        }

        .order__positions__cell {
            &.order__positions__cell--product {
                width: $moduleW-rem * 1.5;
            }

            &.order__positions__cell--quantity {
                width: $moduleW-rem / 2;
                text-align: right;
            }

            // Do not display mobile label
            &.order__positions__cell--quantity {
                .order__positions__subcell--mobile-label {
                    display: none;
                }
            }

            &.order__positions__cell--unit-price {
                width: 32rem;
                padding-left: 12rem;
                padding-right: 4rem;
            }

            &.order__positions__cell--total {
                width: 16rem;
                padding-left: 4rem;
            }
        }
    }

    @include breakpoint(one) {
        .order__positions__row--head {
            .order__positions__cell {
                &.order__positions__cell--quantity,
                &.order__positions__cell--unit-price,
                &.order__positions__cell--total {
                    display: none;
                }
            }
        }

        .order__positions__cell {
            &.order__positions__cell--product {
                margin-bottom: 1rem;

                .order__positions__subcell--image {
                    width: $moduleW-rem;
                    height: $moduleH-rem - 2rem;
                }

                .order__positions__subcell--details {
                    width: 100%;
                }
            }

            &.order__positions__cell--quantity,
            &.order__positions__cell--unit-price,
            &.order__positions__cell--total {
                display: flex;
                align-items: center;
                width: 100%;

                .order__positions__subcell--mobile-label {
                    width: 50%;
                    text-align: left;
                }

                .order__positions__subcell--price,
                .order__positions__subcell--quantity {
                    width: 50%;
                    text-align: right;
                }
            }
        }
    }
}