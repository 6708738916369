/* ==========================================================================
   Error Page
   ========================================================================== */
.notFound {
    text-align: left;

    h1 {
        @include typoZurichBd;
        @include typoZurich3-rem;
    }
}

@include breakpoint(mobile) {
    .notFound {
        h1 {
           @include typoZurich2-rem;
        }
    }
}

@include breakpoint(desktop) {
    .notFound {
         margin-top: 8em;
    }
}