/* ==========================================================================
   Collection detail
   ========================================================================== */

.collectionDetail {
	.collectionInfo {
	    text-align: left;
	}

	.h1Wrapper {
	   margin-bottom: 1em;
	}

	h1 {
	    @include typoZurichBd;
	    @include typoZurich3-rem;
	}

	p {
        @include typoZurich;
        @include typoZurich1-rem;
	    text-transform: none;
	}

	.prevArticleLink,
	.nextArticleLink {
	    display: none;
	}
}

