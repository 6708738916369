// 5 columns
@include breakpoint(five) {
    .L-4-6-1 {
        height: 5 * $moduleH;

        // Bigger
        .b1 {
            top: 0;
            left: 0;
        }

        // medT
        .b2 {
            top: 0;
            left: 3 * $moduleW;
        }

        // Big
        .b3 {
            top: 3 * $moduleH;
            left: 0 * $moduleW;
        }

        // Quad
        .b4 {
            top: 2 * $moduleH;
            left: 3 * $moduleW;
        }
    }
}

// 4 columns
@include breakpoint(four) {
    .L-4-6-1 {
        height: 6 * $moduleH;

        // Bigger
        .b1 {
            top: 0;
            left: 0;
        }

        // medT
        .b2 {
            top: 0;
            left: 3 * $moduleW;

            .text-teaser__wrapper {
                top: 2rem;
                left: 2rem;
                right: 2rem;
            }
        }

        // Big
        .b3 {
            top: 3 * $moduleH;
            left: 0 * $moduleW;
            width: 2 * $moduleW;
            height: 3 * $moduleH;

            .text-teaser__wrapper {
                top: 2rem;
                left: 2rem;
                right: 2rem;
            }
        }

        // Quad
        .b4 {
            top: 3 * $moduleH;
            left: 2 * $moduleW;
        }
    }
}

// 3 columns
@include breakpoint(three) {
   .L-4-6-1 {
        height: 5 * $moduleH;

        // Bigger
        .b1 {
            top: 0;
            left: 0;
            width: 2 * $moduleW;
            height: 2 * $moduleH;
        }

        // medT
        .b2 {
            top: 0;
            left: 2 * $moduleW;

            .text-teaser__wrapper {
                top: 2rem;
                left: 2rem;
                right: 2rem;
            }
        }

        // Big
        .b3 {
            top: 2 * $moduleH;
            left: 0 * $moduleW;
            width: 1 * $moduleW;
            height: 3 * $moduleH;

            .text-teaser__wrapper {
                top: 2rem;
                left: 2rem;
                right: 2rem;
            }
        }

        // Quad
        .b4 {
            top: 2 * $moduleH;
            left: 1 * $moduleW;
        }
    }
}

// 2 columns
@include breakpoint(two) {
    .L-4-6-1 {
        display: flex;
        flex-direction: column;

        // Bigger
        .b1 {
            position: relative;
            order: 1;
        }

        // medT
        .b2 {
            position: relative;
            order: 2;

            &.contains-text-teaser {
                height: auto;

                .text-teaser__wrapper {
                    position: relative;
                    top: auto;
                    left: auto;
                    right: auto;
                    bottom: auto;
                    padding: 2rem 0;
                }
            }
        }

        // Big
        .b3 {
            position: relative;
            order: 4;
            height: 1 * $moduleH;

            &.contains-text-teaser {
                height: auto;

                .text-teaser__wrapper {
                    position: relative;
                    top: auto;
                    left: auto;
                    right: auto;
                    bottom: auto;
                    padding: 2rem 0;
                }
            }
        }

        // Quad
        .b4 {
            position: relative;
            order: 3;
        }
    }
}

// 1 columns
@include breakpoint(one) {
    .L-4-6-1 {
        display: flex;
        flex-direction: column;

        // Bigger
        .b1 {
            position: relative;
            order: 1;
        }

        // medT
        .b2 {
            position: relative;
            order: 2;

            &.contains-text-teaser {
                height: auto;

                .text-teaser__wrapper {
                    position: relative;
                    top: auto;
                    left: auto;
                    right: auto;
                    bottom: auto;
                    padding: 2rem 0;
                }
            }
        }

        // Big
        .b3 {
            position: relative;
            height: 2 * $moduleH;
            order: 4;

            &.contains-text-teaser {
                height: auto;

                .text-teaser__wrapper {
                    position: relative;
                    top: auto;
                    left: auto;
                    right: auto;
                    bottom: auto;
                    padding: 2rem 0;
                }
            }
        }

        // Quad
        .b4 {
            position: relative;
            order: 3;
        }
    }
}
