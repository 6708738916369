.mykita-container.mykita-container--map {
    width: 100%;
    margin: 0;
}

.shopfinder-map {
    .shopfinder-map-group--map {
        box-sizing: border-box;
        position: relative;
        width: 100%;
        height: calc(100vh - 20rem);
        padding: 0;
        overflow: hidden;

        .shopfinder-map-canvas {
            width: 100%;
            height: 100%;
        }

        .shopfinder-map-controls {
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 4;
            padding: 0 3rem 3rem 0;

            .svg-ico {
                width: 3rem;
                height: 3rem;
            }

            .btn:first-child {
                margin-bottom: 2rem;
            }
        }

        .shopfinder-map-close {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
            padding: 2rem 2rem 0 0;
            transform: translate3d(0, 0, 0);

            .svg-ico {
                width: 2rem;
                height: 2rem;
            }
        }
    }

    .shopfinder-map-group--filter {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 1rem auto 0 auto;
        width: 5 * $moduleW-rem;

        @include breakpoint(four) {
            width: 4 * $moduleW-rem;
        }

        @include breakpoint(three) {
            width: 3 * $moduleW-rem;
        }

        @include breakpoint(two) {
            width: 2 * $moduleW-rem;
        }

        @include breakpoint(one) {
            width: $moduleW-rem;
        }

        .shopfinder-map-filter {
            box-sizing: border-box;
            padding: $box-padding--extended;
            width: $moduleW-rem;
        }

        .shopfinder-map-filter-input {
            position: relative;
            width: 100%;
        }

        input,
        select,
        .select2,
        .select2-container {
            width: 100%;
        }

        label {
            @include typoZurich;
            @include typoZurich1-rem;
            margin-bottom: 1rem;
        }

        @include breakpoint(one) {
            display: block;

            .shopfinder-map-filter--store,
            .shopfinder-map-filter--search {
                width: 100%;
                padding-left: $border;
                padding-right: $border;

                .awesomplete {
                    max-width: 100%;
                }
            }
        }

        @include breakpoint(mobile) {
            .shopfinder-map-filter--store,
            .shopfinder-map-filter--store-type {
                display: none;
            }
        }
    }

    // Search filter
    .shopfinder-map-filter--search {
        .btn {
            position: absolute;
            top: 0.75rem;
            right: 0.75rem;
            background-color: $inputGrey2;

            .svg-ico {
                width: 1.5rem;
                height: 1.5rem;
            }
        }

        .awesomplete,
        .awesomplete ul {
            max-width: calc(#{$moduleW-rem} - #{($box-padding--extended * 2)});
        }
    }

    // Dealer information
    .shopfinder-map-retailer {
        position: absolute;
        top: 50%;
        left: 50%;
        width: $moduleW-rem;
        background: url(../images/icons/loader_32px_t.gif) no-repeat 50% 50% #fff;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
        transform: translate3d(-50%, 96px, 0);
        min-height: 12rem;
        opacity: 0;
        pointer-events: none;
        z-index: 3;
        transition: transform $defTransTime $easeInOutQuint,
                    opacity $defTransTime $easeInOutQuint;


        .shopfinder-map-retailer-content {
            box-sizing: border-box;
            text-align: left;
            padding: 2rem;
            width: $moduleW-rem;
            margin: 0 auto;
        }

        .shopfinder-map-retailer-close {
            position: absolute;
            top: 0;
            right: 0;
            padding: 1.5rem 1.5rem 0 0;

            .svg-ico {
                width: 1.5rem;
                height: 1.5rem;
            }
        }

        h3 {
            @include typoZurichBd;
            @include typoZurich1-rem;
        }

        .shopfinder-retailer-info-type {
            margin-bottom: 0.5rem;
        }

        p {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
            margin-bottom: 0.2rem;
            font-style: normal;
        }

        address {
            font-style: normal;
            text-align: left;
        }

        .text-link {
            @include typoZurich;
            @include typoZurich1-rem;
            text-transform: none;
            line-height: 1.1;
        }

        .shopfinder-retailer-info-links {
            margin-top: 2rem;
        }

        // Caret
        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            top: 0;
            left: 50%;
            margin-left: 22px;
            border-style: solid;
            border-width: 16px;
            border-color: transparent transparent #fff #fff;
            transform-origin: 0 0;
            transform: rotate(135deg);
            box-shadow: -2px 2px 3px 0 rgba(0, 0, 0, 0.2);
        }

        &.is-visible {
            transform: translate3d(-50%, 48px, 0);
            opacity: 1;
            pointer-events: all;
        }

        &.is-loaded {
            background: #fff;
        }
    }

    @include breakpoint(desktop) {
        .shopfinder-map-close {
            display: none;
        }
    }

    &:not(.shopfinder-map--mykita-shop) {
        @include breakpoint(mobile) {
            z-index: 1;

            .shopfinder-map-group--map {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 0;
                transform: translate3d(0, 100%, 0);
                visibility: hidden;
                z-index: 10;
                transition: transform $defTransTime $toolsHeaderFunc,
                            visibility 0s $toolsHeaderFunc $defTransTime;

                .has-visible-map & {
                    transform: translate3d(0, 0, 0);
                    visibility: visible;
                    transition: transform $defTransTime $toolsHeaderFunc;
                }
            }

            //.shopfinder-map-filter--store-type,
            .shopfinder-map-controls {
                display: none;
            }

            .shopfinder-map-retailer {
                width: 100%;
                height: calc(50% - 48px);
                box-shadow: none;

                &:after {
                    box-shadow: none;
                }
            }

            .shopfinder-map-canvas {
                z-index: 1;
            }

            .shopfinder-map-close {
                z-index: 2;
            }
        }
    }

    &.shopfinder-map--mykita-shop {
        .shopfinder-map-group {
            padding-bottom: 40%;
            height: auto;

            @include breakpoint(mobile) {
                padding-bottom: 0;
                height: 60vh;
                height: 60svh;

                .shopfinder-map-controls {
                    display: none;
                }

                .shopfinder-map-close {
                    display: none;
                }
            }

            @media (orientation: portrait) and (min-width: 480px) {
                padding-bottom: 66.66666%;
            }


        }

        .shopfinder-map-canvas {
            position: absolute;
            inset: 0 0 0 0;
            width: 100%;
            height: 100%;
        }
    }
}

// Set z-index to map
@include breakpoint(mobile) {
    .mykita-container--map {
        z-index: 1000;
    }
}
