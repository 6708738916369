.borderBox,
%border-box {
    box-sizing: border-box;
}

.clear,
.cf,
%cf {
	&:before,
    &:after {
        display: table;
        line-height: 0;
        content: "";
    }

    &:after {
        clear: both;
    }
}

%hideable-container,
%hideable-row {
    transition-property: max-height, opacity, margin;
    transition-duration: $defTransTime;
    transition-timing-function: $defTransFunc;
    overflow: hidden;
}

%hideable-container-hidden,
%hideable-row-hidden {
    max-height: 0;
    opacity: 0;
    margin-bottom: 0;
}


/* ==========================================================================
   Content Typo
   ========================================================================== */

.typoZ {
    @include typoZ;
}

.typoZurich {
    @include typoZurich;
}

.typoZurichBd {
    @include typoZurichBd;
}

.typoZurichReg {
    @include typoZurichReg;
}

.typoZurichS,
.typoZurich.typoZurichS,
.typoZurichBd.typoZurichS {
    @include typoZurichS-rem;
}

.typoZurichS2,
.typoZurich.typoZurichS2,
.typoZurichBd.typoZurichS2 {
    @include typoZurichS2-rem;
}

.typoZurich0,
.typoZurich.typoZurich0,
.typoZurichBd.typoZurich0  {
    @include typoZurich0-rem;
}

.typoZurich1,
.typoZurich.typoZurich1,
.typoZurichBd.typoZurich1 {
    @include typoZurich1-rem;
}

.typoZurich1LH,
.typoZurich.typoZurich1LH,
.typoZurichBd.typoZurich1LH  {
    @include typoZurich1-rem;
    line-height: 1;
}

.typoZurich1-1,
.typoZurich.typoZurich1-1,
.typoZurichBd.typoZurich1-1 {
    @include typoZurich1-1-rem;
}

.typoZurich2,
.typoZurich.typoZurich2,
.typoZurichBd.typoZurich2  {
    @include typoZurich2-rem;
}

.typoZurich3,
.typoZurich.typoZurich3,
.typoZurichBd.typoZurich3  {
    @include typoZurich3-rem;
}

.typoZurich4,
.typoZurich.typoZurich4,
.typoZurichBd.typoZurich4 {
    @include typoZurich4-rem;
}

.typoZurich5,
.typoZurich.typoZurich5,
.typoZurichBd.typoZurich5 {
    @include typoZurich5-rem;
}



